import module from '../../models/AxiosModule'
const axios = module.Axios


import { DELETE_REPORT, REPORTS_DOWNLOAD, REPORTS_GET, AUTOREPORTS_GET, DELETE_AUTOREPORT } from '../actions/reports'

const state = {
  reports: '',
  autoReports: '',

}

const getters = {
  getReports: state => state.reports,
  getAutoReports: state => state.autoReports

}





const actions = {
  [REPORTS_GET]: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {
      axios.post('get-reports', {}).then(response => {
        commit(REPORTS_GET, response.data.reports)
        resolve()

      })
      // commit(INTRO_BY_SOCIAL, data)


    })
  },
  [AUTOREPORTS_GET]: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {

      axios.post('get-auto-reports', {}).then(response => {
        commit(AUTOREPORTS_GET, response.data.reports)
        resolve()

      })
      // commit(INTRO_BY_SOCIAL, data)


    })
  },
  [REPORTS_DOWNLOAD]: ({ commit, dispatch }, { hashId, type }) => {
    return new Promise((resolve, reject) => {

      axios.post('download-report',
        {
          hashId, type,
        },
        {
          responseType: 'blob'
        }
      ).then(response => {
        resolve(response)
      })


    })
  },
  [DELETE_AUTOREPORT]: ({ commit, dispatch }, data) => {
    return new Promise((resolve, reject) => {

      axios.post('delete-auto-report', { id: data.id }).then(response => {
        resolve()

      })


    })
  },
  [DELETE_REPORT]: ({ commit, dispatch, getters }, { id, type }) => {
    return new Promise((resolve, reject) => {
      axios.post('delete-report', { id, type }).then(response => {
        const updateReports = getters.getReports.filter(report => report.id != id)
        commit(REPORTS_GET, updateReports)
        resolve()

      })


    })
  },




}

const mutations = {
  [REPORTS_GET]: (state, resp) => {
    state.reports = resp
  },
  [AUTOREPORTS_GET]: (state, resp) => {
    state.autoReports = resp
  },
  // [INTRO_SET_PAGES]: (state, resp) => {
  //     state.pages = JSON.parse(JSON.stringify(resp))
  // },

}

export default {
  state,
  getters,
  actions,
  mutations,
}