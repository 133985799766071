import * as echarts from 'echarts';

const create = function ({ ref, chartData, chartColumns, colors }) {
  return new Promise((resolve, reject) => {
    const chart = echarts.init(ref[0], null, {
      renderer: 'canvas',
      useDirtyRect: false,
    });

    // Calculate the maximum value for scaling
    const dataValues = chartColumns.map((col) => chartData[col]);
    const maxValue = Math.max(...dataValues) * 1.3;

    // Prepare the series data
    const series = chartColumns.map((column, idx) => {
      const value = chartData[column];
      const remainingValue = maxValue - value;

      // Set the inner and outer radius for each ring
      const radiusWidth = 10; // Adjust as needed for the thickness of the rings
      const outerRadius = 100 - idx * (radiusWidth + 5) - 5; // Spacing between rings
      const innerRadius = outerRadius - radiusWidth;

      const color = Object.values(colors)[idx];

      return {
        name: column,
        type: 'pie',
        clockwise: true,
        radius: [`${innerRadius}%`, `${outerRadius}%`],
        center: ['50%', '50%'],
        label: {
          show: false,
        },
        clickable: false,
        labelLine: {
          show: false,
        },
        data: [
          {
            value: value,
            name: column,
            itemStyle: {
              color: color,
              borderRadius: 10,
            },
            emphasis: {
              scale: true,
              scaleSize: 1.2,
              itemStyle: {
                color: color,
                borderWidth: 1,
                borderColor: color
              },
            },
          },
          {
            value: remainingValue,
            name: 'empty',
            itemStyle: {
              color: 'transparent', // Make the remaining part transparent
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              itemStyle: {
                color: 'transparent',
              },
            },
          },
        ],
      };
    });

    const option = {
      series: series,
    };

    chart.setOption(option);

    resolve({ chart, colors: chartColumns.map((col) => colors[col]) });
    window.addEventListener('resize', () => {
      chart.resize();
      });
  });
};

export default create;
