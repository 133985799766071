<template>
  <div>
    <form @submit.prevent="handleSubmit">
      <!-- Collect customer email (optional) -->
      <input
        type="email"
        v-model="customerEmail"
        placeholder="Email"
        required
      />

      <div class="payment-container">
        <!-- Payment Element will be inserted here -->
        <div id="payment-element" :class="{ 'loading': isFormLoading }"></div>
        
        <!-- Loading indicator -->
        <div class="loading-message" v-if="isFormLoading">
          <scroll-loader
            loader-size="24"
            :loader-method="() => {}"
            :loader-enable="true"
          ></scroll-loader>
          <p>Loading payment form...</p>
        </div>
      </div>

      <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
      <button type="submit" :disabled="isLoading || isFormLoading">
        <span v-if="isLoading">Processing...</span>
        <span v-else>{{ payButtonText }}</span>
      </button>
    </form>
  </div>
</template>


<script>
import { loadStripe } from '@stripe/stripe-js';
import module from '@/models/AxiosModule'

export default {
  props: {
    plan: {
      type: Object,
      required: true,
    },
    email: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      stripe: null,
      elements: null,
      paymentElement: null,
      errorMessage: '',
      customerEmail: '',
      clientSecret: '',
      isLoading: false,
      isFormLoading: true,
      paymentAmount: 0,
    };
  },
  computed: {
    payButtonText() {
      if (this.paymentAmount > 0) {
        return `Pay $${this.paymentAmount}`;
      }
      return 'Pay Now';
    },
    planName() {
      // Extract plan name from productId or use a default
      const planMap = {
        100: 'Basic Plan',
        200: 'Agency Plan',
        300: 'Corporate Plan'
      };
      return planMap[this.plan.productId] || 'Subscription';
    }
  },
  async mounted() {
    this.customerEmail = this.email;
    this.stripe = await loadStripe('pk_test_b9iFA8KiDk5rcIPeGqBauw8S');

    if (this.plan.displayPrice) {
      this.paymentAmount = Math.ceil(this.plan.displayPrice);
    } else if (this.plan.isYearly && this.plan.yearlyPriceUSD) {
      this.paymentAmount = Math.ceil(this.plan.yearlyPriceUSD);
    } else if (!this.plan.isYearly && this.plan.priceUSD) {
      this.paymentAmount = Math.ceil(this.plan.priceUSD);
    }

    try {
      if (!this.plan.productId) {
        this.errorMessage = "Missing product ID in plan data";
        this.isFormLoading = false;
        return;
      }
      
      const axios = module.Axios;
      
      const requestData = {
        productId: this.plan.productId,
        email: this.customerEmail,
        isYearly: this.plan.isYearly
      };
      
      if (this.plan.additionalUsers) {
        requestData.additionalUsers = this.plan.additionalUsers;
      }
      
      if (this.plan.additionalPages) {
        requestData.additionalPages = this.plan.additionalPages;
      }
      
      if (this.plan.isYearly) {
        requestData.yearlyPrice = this.plan.yearlyPriceUSD;
        requestData.totalMonthlyPrice = this.plan.priceUSD;
      } else {
        requestData.totalMonthlyPrice = this.plan.priceUSD;
      }

      let lang = this.$i18n.locale || 'EN';
      lang = lang.toUpperCase();
      requestData.lang = lang;
      
      const response = await axios.post(process.env.VUE_APP_API + 'create-payment-intent', requestData);
      const { clientSecret, error, amount } = response.data;

      if (error) {
        this.errorMessage = error;
        this.isFormLoading = false;
      } else {
        if (amount) {
          this.paymentAmount = amount / 100;
        }
        
        this.clientSecret = clientSecret;
        this.elements = this.stripe.elements({ 
          clientSecret,
          appearance: {
            theme: 'stripe',
            variables: {
              colorPrimary: '#5469d4',
              fontSizeBase: '14px',
            },
            rules: {
              '.Input': {
                fontSize: '14px'
              },
              '.Label': {
                fontSize: '14px'
              }
            }
          }
        });
        
        this.paymentElement = this.elements.create('payment');
        this.paymentElement.mount('#payment-element');
        
        // Allow a small delay for the element to initialize before showing it
        this.paymentElement.on('ready', () => {
          setTimeout(() => {
            this.isFormLoading = false;
          }, 300);
        });
      }
    } catch (error) {
      if (error?.response?.data) {
        this.errorMessage = error.response.data?.error || "Payment processing failed";
      } else {
        this.errorMessage = error.message;
      }
      this.isFormLoading = false;
    }
  },
  methods: {
    async handleSubmit() {
      this.isLoading = true;

      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: process.env.VUE_APP_URL + 'payment-success',
          payment_method_data: {
            billing_details: {
              email: this.customerEmail,
            },
          },
        },
      });

      if (error) {
        this.errorMessage = error.message;
        this.isLoading = false;
      } else {
        this.$emit('subscription-success');
      }
    },
  },
};
</script>

<style scoped>
.modal-close {
    z-index: 1;
}
input[type="email"] {
    width: 100%;
    font-size: 16px;
    padding: 10px;
    margin: 20px 0 10px;
    color:#7a7b85;
    border: 1px solid #e6e6e6;
    border-radius: 6px;
}
#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.payment-container {
  position: relative;
  min-height: 150px;
}

#payment-element {
  margin-bottom: 24px;
  transition: opacity 0.3s ease;
}

#payment-element.loading {
  opacity: 0;
}

.loading-message {
  padding:40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.loading-message p {
  margin-top: 16px;
  color: #5469d4;
  font-size: 14px;
}

.error {
  color: #dc3545;
  font-size: 14px;
  margin-bottom: 10px;
}

button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}
button:hover {
  filter: contrast(115%);
}
.loader-container{
  max-width: 32px;
}

button:disabled {
  opacity: 0.5;
  cursor: default;
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: initial;
  }
}
</style>
