import module from "../../models/AxiosModule";
const axios = module.Axios;

const state = {
  alerts: [],
};

const mutations = {
  ADD_ALERT(state, alert) {
    if (!alert.id) {
      alert.id = Date.now();
    }
    if (!state.alerts.some(a => a.message === alert.message)) {
      state.alerts.push(alert);
    }
  },
  REMOVE_ALERT(state, id) {
    state.alerts = state.alerts.filter((alert) => alert.id !== id);
  },
};

const actions = {
  addAlert({ commit }, alert) {
    if (!alert.id) {
      alert.id = Date.now();
    }
    commit('ADD_ALERT', alert);
  },
  async removeAlert({ commit }, { id, is_server = false }) {
    try {
      if (is_server) {
        await axios.post('close-alert', { alertId: id });
      }
      commit('REMOVE_ALERT', id);
    } catch (error) {
      console.error('Failed to remove alert:', error);
    }
  },
};

const getters = {
  allAlerts(state) {
    return state.alerts;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};