import axios from "axios";
import router from "@/router/index"; // Импортируйте ваш роутер

const token = localStorage.getItem("user-token");
const Axios = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    authorization: `bearer ${token}`,
  },
});

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("user-token"); // Удаляем токен
      // router.push("/auth/login"); // Редирект на страницу логина через Vue Router
      router.push("/");
    } else {
      return Promise.reject(error); // Возвращаем ошибку для дальнейшей обработки
    }
  }
);

export default {
  Axios,
};