<template>
  <div>
    <Header :showInput="false" />
    <div class="pricing_block">
      <div class="container">
        <div class="pricing_header">
          <h2 class="header_sublvl">{{ $t('PRC_HEADER') }}</h2>
          <div class="types_change">
            <span 
              :class="{ active: isYearly }"
              @click="setBillingCycle('yearly')"
            >
              {{ $t('PRC_YEAR') }}
              <u>{{ $t('PRC_DSCNT') }} 17%</u>
            </span>
            <span 
              :class="{ active: !isYearly }"
              @click="setBillingCycle('monthly')"
            >
              {{ $t('PRC_MNTH') }}
            </span>
          </div>
        </div>
        <div class="pricing_body">
          <div
            class="pricing_plan"
            v-for="plan in currentPlans"
            :key="plan.name"
            :class="{ 
              highlight: plan.popular,
              'custom-plan': plan.name === 'PRC_PLAN_CUSTOM',
              'active-plan-container': isActivePlan(plan)
            }"
          >
            <span class="active-plan" v-if="isActivePlan(plan)">{{ $t('ACTIVE_PLAN') }}</span>
            <h4>{{ $t(plan.name) }}</h4>
            <template v-if="plan.name === 'PRC_PLAN_CUSTOM'">
              <h5>{{ $t('PRC_CUSTOM_SUBTITLE') }}</h5>
              <div class="btn-container">
                <a href="mailto:support@popsters.com" class="btn-st pay-button">
                  {{ $t("PRC_CONTACT") }}
                </a>
              </div>
              <p class="custom-description">{{ $t('PRC_CUSTOM_DESCRIPTION') }}</p>
              <ul class="feat_list">
                <li>{{ $t("PRC_CMPR") }}</li>
                <li>{{ $t("PRC_STR") }}</li>
                <li>{{ $t("PRC_INN") }}</li>
                <li>{{ $t("PRC_EXPORT") }} PDF, XLSX, PPTX</li>
                <li :class="{ inactive: !plan.autoReports }">
                  {{ $t("PRC_AREML") }}
                </li>
                <li :class="{ inactive: !plan.api }">
                  {{ $t("PRC_API") }}
                </li>
              </ul>
            </template>
            <template v-else>
              <div class="pricing_plan_price">
                <span class="plan-price">{{ formattedPrice(plan) }}</span>/{{ $t("PRC_PRD_MTH") }}
                <span class="plan-billed">
                  <template v-if="isYearly">({{ $t("PRC_BILLED") }})</template>
                </span>
              </div>
              <div class="btn-container">
                <button 
                  v-if="!isActivePlan(plan) && (hasActivePlan || !plan.hasTrial)"
                  type="button" 
                  class="btn-st pay-button" 
                  @click.stop="handlePayButtonClick(plan)"
                >
                  {{ $t("PRC_ACT") }}
                </button>
                <button 
                  v-if="isActivePlan(plan) && getProfile.subscription_status !== 'cancelled' && getProfile.payment_system !== 'trial'"
                  type="button" 
                  class="btn-st pay-button cancel-sub" 
                  @click.stop="cancelSubscription"
                >
                  {{ $t("PRC_CANCEL_SUBSCRIPTION") }}
                </button>
                <button 
                  v-if="isActivePlan(plan) && (getProfile.subscription_status === 'cancelled' || getProfile.payment_system === 'trial')"
                  type="button" 
                  class="btn-st pay-button" 
                  @click.stop="handlePayButtonClick(plan)"
                >
                  {{ $t("PRC_ACT") }}
                  <span class="plan-duration">{{ $t("ACTIVE_TILL") }} {{ getProfile.expire }}</span>
                </button>
                <ul class="pay-list" v-if="isRussianLocale && activePayList === plan.name">
                  <li @click.stop="selectPlanCloudPayments(plan)">{{ $t("PRC_PAY_RUSSIAN_CARD") }}</li>
                  <li @click.stop="selectPlanStripe(plan)">{{ $t("PRC_PAY_FOREIGN_CARD") }}</li>
                </ul>
                <button 
                  type="button" 
                  class="btn-st pay-button start-trial" 
                  v-if="!hasActivePlan && plan.hasTrial"
                  @click="startTrial"
                >
                  {{ $t("PRC_TRIAL") }}
                  <span class="trial-period">{{ $t("PRC_TRIAL_PERIOD") }}</span>
                </button>
              </div>
              <ul class="feat_list">
                <li>
                  {{ $t("PRC_USR") }} 
                  <div class="counts_block">
                    <template v-if="['PRC_PLAN_AGENCY', 'PRC_PLAN_CORPORATE'].includes(plan.name)">
                      <button class="btn-sm" @click="handleAccessCountChange(plan.name, '-')">-</button>
                    </template>
                    <span>{{ plan.accessCount }}</span>
                    <template v-if="['PRC_PLAN_AGENCY', 'PRC_PLAN_CORPORATE'].includes(plan.name)">
                      <button class="btn-sm" @click="handleAccessCountChange(plan.name, '+')">+</button>
                    </template>
                  </div>
                </li>
                <li>
                  {{ $t("PRC_SMP") }} 
                  <div class="counts_block">
                    <template v-if="['PRC_PLAN_AGENCY', 'PRC_PLAN_CORPORATE'].includes(plan.name)">
                      <button class="btn-sm" @click="handlePageCountChange(plan.name, '-')">-</button>
                    </template>
                    <span>{{ plan.pageCount }}</span>
                    <template v-if="['PRC_PLAN_AGENCY', 'PRC_PLAN_CORPORATE'].includes(plan.name)">
                      <button class="btn-sm" @click="handlePageCountChange(plan.name, '+')">+</button>
                    </template>
                  </div>
                </li>
                <li>
                  {{ $t("PRC_DEPTH") }} <span>{{ formattedRetrospective(plan) }}</span>
                </li>
                <li>
                  {{ $t("PRC_SPP") }} <span>{{ plan.api ? $t('PRC_YES') : '-' }}</span>
                </li>
              </ul>
              <ul class="feat_list">
                <li>{{ $t("PRC_CMPR") }}</li>
                <li>{{ $t("PRC_STR") }}</li>
                <li>{{ $t("PRC_INN") }}</li>
                <li>{{ $t("PRC_EXPORT") }} PDF, XLSX, PPTX</li>
                <li :class="{ inactive: !plan.autoReports }">
                  {{ $t("PRC_AREML") }}
                </li>
                <li :class="{ inactive: !plan.api }">
                  {{ $t("PRC_API") }}
                </li>
              </ul>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isRussianLocale" class="direct-payment-disclaimer">
      Для оплаты безналичным расчетом пишите на <a target="_blank" href="mailto:support@popsters.ru">support@popsters.ru</a>. Счет выставляется только при оплате на срок от полугода.
      <br><br>
      Используя сервис, вы соглашаетесь с условиями <a target="_blank" href="https://lucmus.com/assets/docs/terms_lucmus.pdf">лицензионного соглашения</a>
    </div>
    <!-- Payment Modal -->
    <div v-if="showPaymentModal" class="mod-dial" @click="showPaymentModal = false">
      <div class="modal-payment" @click.stop>
        <button class="modal-close" @click="showPaymentModal = false">×</button>
        <PaymentForm
          :plan="selectedPlan"
          :email="userEmail"
          @subscription-success="handleSubscriptionSuccess"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PaymentForm from './PaymentForm.vue';
import Header from "../components/Header";
import { mapGetters, mapActions } from "vuex";
import module from '@/models/AxiosModule'
import ClickOutside from "vue-click-outside";

export default {
  components: {
    PaymentForm,
    Header
  },
  directives: {
    ClickOutside
  },
  data() {
    return {
      isYearly: true,
      activePayList: null,
      selectedPlan: null,
      showPaymentModal: false,
      plans: [
        { 
          id: 1,
          productId: 100,
          name: "PRC_PLAN_BASIC", 
          popular: false, 
          api: false, 
          accessCount: 1, 
          pageCount: 5, 
          retrospective: 1, 
          autoReports: false, 
          price: 990,
          yearlyPrice: 9900,
          priceUSD: 25,
          yearlyPriceUSD: 250,
          baseAccessCount: 1,
          basePageCount: 5,
          priceId: 100,
          yearlyPriceId: 101,
          hasTrial: true
        },
        { 
          id: 2,
          productId: 200,
          name: "PRC_PLAN_AGENCY", 
          popular: true, 
          api: false, 
          accessCount: 2, 
          pageCount: 15, 
          retrospective: 2, 
          autoReports: true, 
          price: 2890,
          yearlyPrice: 28788,
          priceUSD: 65,
          yearlyPriceUSD: 650,
          baseAccessCount: 2,
          basePageCount: 15,
          addonUserPrice: 500,
          addonUserPriceUSD: 10,
          addonPagePrice: 200,
          addonPagePriceUSD: 4,
          addonUserYearlyPriceUSD: 100,
          addonPageYearlyPriceUSD: 40,
          priceId: 200,
          yearlyPriceId: 201,
          hasTrial: false
        },
        { 
          id: 3,
          productId: 300,
          name: "PRC_PLAN_CORPORATE", 
          popular: false, 
          api: true, 
          accessCount: 5, 
          pageCount: 50, 
          retrospective: 2, 
          autoReports: true, 
          price: 8900,
          yearlyPrice: 89000,
          priceUSD: 159,
          yearlyPriceUSD: 1590,
          baseAccessCount: 5,
          basePageCount: 50,
          addonUserPrice: 400,
          addonUserPriceUSD: 8,
          addonPagePrice: 180,
          addonPagePriceUSD: 3,
          addonUserYearlyPriceUSD: 80,
          addonPageYearlyPriceUSD: 30,
          priceId: 300,
          yearlyPriceId: 301,
          hasTrial: false
        },
        { 
          id: 4,
          name: "PRC_PLAN_CUSTOM", 
          popular: false, 
          api: true, 
          accessCount: null, 
          pageCount: null, 
          retrospective: null, 
          autoReports: true, 
          price: null,
          yearlyPrice: null,
          priceUSD: null,
          yearlyPriceUSD: null,
          baseAccessCount: null,
          basePageCount: null,
          hasTrial: false
        }
      ]
    };
  },
  computed: {
    ...mapGetters([
      "getProfile"
    ]),
    userEmail() {
      return this.getProfile?.email;
    },
    isRussianLocale() {
      return this.$i18n.locale === 'ru';
    },
    hasActivePlan() {
      return this.getProfile && 
             (this.getProfile.plan || this.getProfile.planId) && 
             (this.getProfile.daysLeft === undefined || this.getProfile.daysLeft > 0);
    },
    currentPlans() {
      return this.plans.map(plan => {
        if (!['PRC_PLAN_AGENCY', 'PRC_PLAN_CORPORATE'].includes(plan.name)) {
          return plan;
        }

        const extraUsers = Math.max(0, plan.accessCount - plan.baseAccessCount);
        const extraPages = Math.max(0, plan.pageCount - plan.basePageCount);

        const addonMonthlyPrice = (extraUsers * plan.addonUserPrice || 0) + 
                                (extraPages * plan.addonPagePrice || 0);
        const addonMonthlyPriceUSD = (extraUsers * plan.addonUserPriceUSD || 0) + 
                                    (extraPages * plan.addonPagePriceUSD || 0);

        const totalMonthlyPrice = plan.price + addonMonthlyPrice;
        const totalMonthlyPriceUSD = plan.priceUSD + addonMonthlyPriceUSD;

        const yearlyDiscount = 0.17;
        const yearlyAddonPrice = Math.round(addonMonthlyPrice * 12 * (1 - yearlyDiscount));
        const totalYearlyPrice = plan.yearlyPrice + yearlyAddonPrice;
        
        const yearlyAddonPriceUSD = (extraUsers * plan.addonUserYearlyPriceUSD || 0) + 
                                   (extraPages * plan.addonPageYearlyPriceUSD || 0);
        
        const totalYearlyPriceUSD = plan.yearlyPriceUSD + yearlyAddonPriceUSD;

        return {
          ...plan,
          price: totalMonthlyPrice,
          priceUSD: totalMonthlyPriceUSD,
          yearlyPrice: totalYearlyPrice,
          yearlyPriceUSD: totalYearlyPriceUSD,
          addons: {
            extraUsers,
            extraPages,
            monthlyPrice: addonMonthlyPrice,
            monthlyPriceUSD: addonMonthlyPriceUSD,
            yearlyPrice: Math.round(addonMonthlyPrice * 12 * (1 - yearlyDiscount)),
            yearlyPriceUSD: yearlyAddonPriceUSD
          }
        };
      });
    }
  },
  methods: {
    ...mapActions({
      activateTrial: 'AUTH_ACTIVATE_TRIAL',
      fetchProfile: 'AUTH_GET_PROFILE',
      cancelSubscription: 'AUTH_CANCEL_SUBSCRIPTION'
    }),
    formattedPrice(plan) {
      if (this.isRussianLocale) {
        if (!plan.price) return '-- ₽';
        let price = this.isYearly ? plan.yearlyPrice / 12 : plan.price;
        price = Math.ceil(price);
        return `${price.toLocaleString()} ₽`;
      } else {
        if (!plan.priceUSD) return '$--';
        let price = this.isYearly ? plan.yearlyPriceUSD / 12 : plan.priceUSD;
        price = Math.ceil(price);
        return `$${price.toLocaleString()}`;
      }
    },
    formattedRetrospective(plan) {
      if (!plan.retrospective) return '-';
      return `${plan.retrospective} ${this.$tc('PRC_YEARS')}`;
    },
    setBillingCycle(cycle) {
      this.isYearly = cycle === 'yearly';
    },
    handleAccessCountChange(planName, operation) {
      const planToUpdate = this.plans.find(p => p.name === planName);
      if (!planToUpdate) return;

      if (operation === '+') {
        if (planName === 'PRC_PLAN_AGENCY' && planToUpdate.accessCount < 5) {
          planToUpdate.accessCount++;
        } else if (planName === 'PRC_PLAN_CORPORATE' && planToUpdate.accessCount < 10) {
          planToUpdate.accessCount++;
        }
      } else if (operation === '-') {
        if (planName === 'PRC_PLAN_AGENCY' && planToUpdate.accessCount > 2) {
          planToUpdate.accessCount--;
        } else if (planName === 'PRC_PLAN_CORPORATE' && planToUpdate.accessCount > 5) {
          planToUpdate.accessCount--;
        }
      }
    },
    handlePageCountChange(planName, operation) {
      const planToUpdate = this.plans.find(p => p.name === planName);
      if (!planToUpdate) return;

      const step = 5;

      if (operation === '+') {
        if (planName === 'PRC_PLAN_AGENCY' && planToUpdate.pageCount < 30) {
          planToUpdate.pageCount += step;
        } else if (planName === 'PRC_PLAN_CORPORATE' && planToUpdate.pageCount < 100) {
          planToUpdate.pageCount += step;
        }
      } else if (operation === '-') {
        if (planName === 'PRC_PLAN_AGENCY' && planToUpdate.pageCount > 15) {
          planToUpdate.pageCount -= step;
        } else if (planName === 'PRC_PLAN_CORPORATE' && planToUpdate.pageCount > 50) {
          planToUpdate.pageCount -= step;
        }
      }
    },
    handlePayButtonClick(plan) {
      this.selectedPlan = plan;
      if (this.isRussianLocale) {
        this.activePayList = this.activePayList === plan.name ? null : plan.name;
      } else {
        this.selectPlanStripe(plan);
      }
    },
    async selectPlanStripe(plan) {
      if (!plan.priceUSD) {
        return this.activateFreePlan();
      }
      
      try {
        this.selectedPlan = {
          ...plan,
          email: this.userEmail,
          isYearly: this.isYearly,
          additionalUsers: plan.baseAccessCount && plan.accessCount > plan.baseAccessCount 
            ? plan.accessCount - plan.baseAccessCount 
            : 0,
          additionalPages: plan.basePageCount && plan.pageCount > plan.basePageCount 
            ? plan.pageCount - plan.basePageCount 
            : 0,
          displayPrice: this.isYearly ? plan.yearlyPriceUSD : plan.priceUSD
        };
        
        this.showPaymentModal = true;
      } catch (error) {
        console.error('Error preparing Stripe payment:', error);
      }
    },
    async selectPlanCloudPayments(plan) {
      if (!plan.price) {
        return this.activateFreePlan();
      }
      try {
        const requestData = {
          productId: plan.productId,
          email: this.userEmail,
          isYearly: this.isYearly
        };
        
        if (plan.baseAccessCount && plan.accessCount > plan.baseAccessCount) {
          requestData.additionalUsers = plan.accessCount - plan.baseAccessCount;
        }
        
        if (plan.basePageCount && plan.pageCount > plan.basePageCount) {
          requestData.additionalPages = plan.pageCount - plan.basePageCount;
        }
        
        if (this.isYearly) {
          requestData.totalYearlyPrice = plan.yearlyPrice;
          
          if (plan.addons) {
            requestData.addonYearlyPrice = plan.addons.yearlyPrice;
          }
        } else {
          requestData.totalMonthlyPrice = plan.price;
        }
        
        const axios = module.Axios;
        const response = await axios.post(process.env.VUE_APP_API + 'create-cloudpayments-intent', requestData);
        const { desc, summ, invId } = response.data;
        
        const widget = new window.cp.CloudPayments({ language: 'ru' });
        widget.charge({
          publicId: 'pk_27c6948cc219f234ce78f9504f4ef',
          description: desc,
          amount: parseFloat(summ),
          currency: 'RUB',
          invoiceId: invId,
          accountId: this.userEmail,
          data: { 
            cloudPayments: { 
              recurrent: { 
                interval: 'Month',
                period: this.isYearly ? 12 : 1
              } 
            } 
          }
        },
        (options) => {
          window.location.href = process.env.VUE_APP_URL + 'payment-success';
        },
        (reason, options) => {
        });
      } catch (error) {
        console.error('Error with CloudPayments:', error);
      }
    },
    activateFreePlan() {
      // Free plan activation logic
    },
    handleSubscriptionSuccess() {
      this.showPaymentModal = false;
    },
    handleDocumentClick() {
      if (this.activePayList) {
        this.activePayList = null;
      }
    },
    async startTrial() {
      try {
        const result = await this.activateTrial();
        
        if (result && result.success === true) {
          // The redirect and alert are now handled in the AUTH_ACTIVATE_TRIAL action
          // No need to do anything here as the action will handle everything
        } else {
          throw new Error('Trial activation failed');
        }
      } catch (error) {
        let errorMessage = this.$t('UNKNOWN_ERROR_OCCURRED');
        if (error && error.error) {
          errorMessage = error.error;
        }
      }
    },
    isActivePlan(plan) {
      if (!this.getProfile || !this.getProfile.plan) return false;
      const planCode = parseInt(this.getProfile.plan);
      
      // Map plan codes to plan IDs
      let activePlanId;
      switch (planCode) {
        case 100: activePlanId = 1; break;
        case 200: activePlanId = 2; break;
        case 300: activePlanId = 3; break;
        case 400: activePlanId = 4; break;
        default: return false;
      }
      
      return plan.id === activePlanId && 
             this.hasActivePlan &&
             (this.getProfile.daysLeft === undefined || this.getProfile.daysLeft > 0);
    },
    async cancelSubscription() {
      try {
        // Position the confirmation dialog near the button
        const positionDialog = (event) => {
          // Get the button's position
          const buttonRect = event.target.getBoundingClientRect();
          
          // Create or get the confirmation container
          let container = document.getElementById('global-confirmation-container');
          if (!container) {
            container = document.createElement('div');
            container.id = 'global-confirmation-container';
            container.style.position = 'fixed';
            container.style.zIndex = '9999';
            container.style.pointerEvents = 'none';
            document.body.appendChild(container);
          }
          
          // Clear previous content
          container.innerHTML = '';
          
          // Create the confirmation dialog
          const dialog = document.createElement('div');
          dialog.className = 'delete-confirm';
          dialog.style.position = 'absolute';
          dialog.style.background = '#FFFFFF';
          dialog.style.boxShadow = '0px 10px 25px rgba(0, 0, 0, 0.08)';
          dialog.style.borderRadius = '8px';
          dialog.style.padding = '8px 10px';
          dialog.style.width = '220px';
          dialog.style.fontSize = '14px';
          dialog.style.color = '#182228';
          dialog.style.textAlign = 'center';
          dialog.style.cursor = 'auto';
          dialog.style.pointerEvents = 'auto';
          
          // Add content to the dialog
          dialog.innerHTML = `
            <p>${this.$t('PRC_CANCEL_SUBSCRIPTION_CONFIRM')}</p>
            <div class="delete-actions">
              <button class="btn-cancel">${this.$t('DELETE_CANCEL')}</button>
              <button class="btn-confirm">${this.$t('PRC_CONFIRM_CANCEL')}</button>
            </div>
          `;
          
          // Add event listeners
          dialog.querySelector('.btn-cancel').addEventListener('click', (e) => {
            e.stopPropagation();
            removeDialog();
          });
          
          dialog.querySelector('.btn-confirm').addEventListener('click', async (e) => {
            e.stopPropagation();
            await confirmCancellation();
            removeDialog();
          });
          
          // Add the dialog to the container
          container.appendChild(dialog);
          
          // Position the container
          container.style.top = `${buttonRect.bottom + 10}px`;
          container.style.left = `${buttonRect.left}px`;
          
          // Ensure the dialog is visible within the viewport
          const dialogRect = dialog.getBoundingClientRect();
          
          // Check if the dialog is outside the viewport
          if (dialogRect.right > window.innerWidth) {
            container.style.left = `${window.innerWidth - dialogRect.width - 10}px`;
          }
          
          if (dialogRect.bottom > window.innerHeight) {
            container.style.top = `${buttonRect.top - dialogRect.height - 10}px`;
          }
          
          // Add click outside handler
          const handleClickOutside = (e) => {
            if (!dialog.contains(e.target) && !event.target.contains(e.target)) {
              removeDialog();
              document.removeEventListener('click', handleClickOutside);
            }
          };
          
          // Add the event listener with a slight delay to avoid immediate triggering
          setTimeout(() => {
            document.addEventListener('click', handleClickOutside);
          }, 0);
        };
        
        const removeDialog = () => {
          const container = document.getElementById('global-confirmation-container');
          if (container) {
            container.innerHTML = '';
          }
        };
        
        const confirmCancellation = async () => {
          try {
            // Call store action to cancel subscription
            const result = await this.$store.dispatch('AUTH_CANCEL_SUBSCRIPTION');
            
            if (result && result.success) {
              // Show success message
              this.$store.dispatch('alerts/addAlert', {
                message: this.$t('PRC_SUBSCRIPTION_CANCELLED_SUCCESS'),
                color: 'success',
                duration: 5000
              }, { root: true });
              
              // Refresh profile data to update UI
              await this.fetchProfile();
            } else {
              throw new Error('Cancellation failed');
            }
          } catch (error) {
            console.error('Error cancelling subscription:', error);
            // Show error message
            this.$store.dispatch('alerts/addAlert', {
              message: error.error_message || this.$t('PRC_CANCELLATION_ERROR'),
              color: 'danger',
              duration: 5000
            }, { root: true });
          }
        };
        
        // Get the event from the click
        const event = window.event;
        if (event) {
          positionDialog(event);
        }
      } catch (error) {
        console.error('Error with subscription cancellation:', error);
      }
    }
  },
  mounted() {
    // Fetch profile with error silencing
    this.fetchProfile().catch(() => {});
    
    if (typeof window.cp === "undefined") {
      const script = document.createElement("script");
      script.src = "https://widget.cloudpayments.ru/bundles/cloudpayments";
      document.body.appendChild(script);
    }

    document.addEventListener('click', this.handleDocumentClick);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleDocumentClick);
  }
};
</script>

<style>
/*Instruction:
Never add any styles to the component. If you need styles - let me know, I will add it myself.
*/
</style>