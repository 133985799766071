<template>
  <div>
    <div class="line-charts">
      <div class="panel" :key="index" v-for="(name, index) in canShowChart">
        <h2>{{ $t(`DASHBOARD_ACTIVITY_CHARTS_${name}`) }}
          <div class="settings">
            <div class="period-btns">
              <button @click="setPeriod('daily')" :class="{ active: activePeriod === 'daily' }">{{ $t('DASH_DAILY') }}</button>
              <button @click="setPeriod('weekly')" :class="{ active: activePeriod === 'weekly' }">{{ $t('DASH_WEEKLY') }}</button>
              <button @click="setPeriod('monthly')" :class="{ active: activePeriod === 'monthly' }">{{ $t('DASH_MONTHLY') }}</button>
            </div>
          </div>
        </h2>
        <div class="m-chart">
          <div
            class="chart"
            :ref="`activity${name}`"
            style="width: 100%; height: 100%"
          ></div>
          
        </div>
      </div>

      <div class="panel">
        <h2>{{ $t('DASH_GRW') }}
          <div class="settings">
            <div class="period-btns">
              <button @click="setPeriod('daily')" :class="{ active: activePeriod === 'daily' }">{{ $t('DASH_DAILY') }}</button>
              <button @click="setPeriod('weekly')" :class="{ active: activePeriod === 'weekly' }">{{ $t('DASH_WEEKLY') }}</button>
              <button @click="setPeriod('monthly')" :class="{ active: activePeriod === 'monthly' }">{{ $t('DASH_MONTHLY') }}</button>
            </div>
          </div>
        </h2>
        <div class="m-chart">
          <div
            class="chart"
            :ref="`activitygrowth`"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
      <div
        class="panel"
        :key="page.hash_id"
        v-for="(page, index) in getAnalizedAdminPages"
      >
        <div class="aud-header">
          <h2>{{ $t('DASHBOARD_ACTIVITY_CHARTS_subunsub') }}
            
          </h2>
          <div class="settings">
              <div class="period-btns">
                <button @click="setPeriod('daily')" :class="{ active: activePeriod === 'daily' }">{{ $t('DASH_DAILY') }}</button>
                <button @click="setPeriod('weekly')" :class="{ active: activePeriod === 'weekly' }">{{ $t('DASH_WEEKLY') }}</button>
                <button @click="setPeriod('monthly')" :class="{ active: activePeriod === 'monthly' }">{{ $t('DASH_MONTHLY') }}</button>
              </div>
            </div>
          <div :class="`soc-sea ${page.colorName}`">
            <span :class="`soc-ico soc-${page.social.toLowerCase()}`"></span>
            {{ page.owner_name_medium }}
          </div>
        </div>
        <div class="m-chart">
          <div
            class="chart"
            :ref="`activitysubunsub${index}`"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import PageCharts from "@/models/page-charts";
import linesChart from "@/models/charts/elines";
import Columns from "@/models/charts/ecolumns";
import ColumnsOne from "@/models/charts/ecolumns-one";
import moment from 'moment';

export default {
  name: "Dash-activity-body",
  computed: {
    ...mapGetters([
      "getSelectedPages",
      "loadingStatus",
      "getAnalizedPages",
      "getUnionPagesData",
      "getSocialOfPageInAnalize",
      "getAnalizedAdminPages",
    ]),
    locale() {
      return this.$locale.DASHBOARD.ACTIVITY_CHARTS;
    },
    canShowChart() {
      const charts = [
        "likes",
        "comments",
        "reposts",
        "views",
        "posts",
        "stories",
        "interactions",
      ];
      const socials = this.getSocialOfPageInAnalize;
      const isAdmins = !!this.getAnalizedAdminPages.length;
      return charts.filter((name) => {
        if (name == "reposts") {
          if (socials.VK || socials.FB) return true;
          else return false;
        } else if (name == "stories") {
          const hasStories = this.getAnalizedAdminPages.some(
            (page) => page.stories.length
          );
          if ((socials.VK || socials.IG) && isAdmins && hasStories) return true;
          else return false;
        } else if (name == "interactions") {
          if (isAdmins) return true;
          else return false;
        } else if (name == "views") {
          if (socials.VK) return true;
          else return false;
        } else return true;
      });
    },
  },

  components: {},
  data() {
    return {
      chartNames: [],
      loaded: false,
      charts: [],
      activePeriod: 'daily',
    };
  },
  methods: {
    // Helper method to determine the appropriate aggregation type for each metric
    getAggregationType(metric) {
      // Most activity metrics should be summed when in weekly/monthly view
      const sumMetrics = [
        "likes", "comments", "reposts", "views", "posts", 
        "stories", "interactions"
      ];
      
      if (sumMetrics.includes(metric)) {
        return "sum";
      }
      
      // Subscribers and other metrics that should show the latest value
      return "last";
    },
    
    // Helper to safely get DOM element
    getRefElement(refName) {
      if (this.$refs[refName]) {
        if (Array.isArray(this.$refs[refName])) {
          if (this.$refs[refName].length > 0) {
            return this.$refs[refName][0];
          }
        } else {
          return this.$refs[refName];
        }
      }
      return null;
    },
    
    // Recreate all charts with the current period
    async renderCharts() {
      // Clear existing charts
      if (this.charts.length) {
        this.charts.forEach(chart => {
          if (chart && typeof chart.dispose === 'function') {
            chart.dispose();
          }
        });
        this.charts = [];
      }
      
      const { pages, datesRangePosts, datesRangeStories } = this.getUnionPagesData;
      const colors = Object.entries(this.$colors).map(([key, value]) => {
        return { key, value };
      });

      // Render activity line charts
      for (const [index, name] of this.canShowChart.entries()) {
        const refKey = `activity${name}`;
        const chartElement = this.getRefElement(refKey);
        
        if (chartElement) {
          try {
            this.charts.push(
              await linesChart({
                pages,
                datesRangePosts,
                datesRangeStories,
                name,
                ref: chartElement,
                color: colors[index],
                type: "activity",
                period: this.activePeriod,
                aggregationType: this.getAggregationType(name),
              })
            );
          } catch (error) {
            console.error(`Error creating ${name} chart:`, error);
          }
        }
      }

      // Render growth chart
      const growthElement = this.getRefElement('activitygrowth');
      if (growthElement) {
        try {
          const dataGrowth = PageCharts.growth({ pages });
          
          // Process growth data based on period if needed
          let processedGrowth = dataGrowth;
          if (this.activePeriod !== 'daily') {
            // If aggregation is needed, apply it here
            processedGrowth = this.aggregateChartData(dataGrowth, this.activePeriod, 'growth');
          }
          
          // Debug: Check if growth data is properly structured
          console.log('Growth data before chart init:', processedGrowth);
          
          // Fix: Ensure processedGrowth is formatted correctly for the chart
          // If it's not an array or empty, handle the error
          if (!Array.isArray(processedGrowth) || processedGrowth.length === 0) {
            console.error('Invalid growth data format:', processedGrowth);
            return;
          }
          
          // Fix: Ensure each item has a readable date property for the chart
          processedGrowth = processedGrowth.map(item => {
            // Make sure date is properly formatted
            if (typeof item.date === 'number') {
              // Already a timestamp, which is what we need
              return item;
            } else if (item.date instanceof Date) {
              // Convert Date to timestamp
              return { ...item, date: item.date.getTime() };
            } else {
              // Try to parse as date if it's a string
              return { ...item, date: new Date(item.date).getTime() };
            }
          });

          this.charts.push(
            await Columns({
              pages,
              ref: [growthElement],
              chartData: processedGrowth,
              field: "growth",
              category: "date",
              colors: this.$colors,
              period: this.activePeriod
            })
          );
        } catch (error) {
          console.error('Error creating growth chart:', error);
        }
      }

      // Render sub/unsub charts for admin pages
      for (let idx = 0; idx < this.getAnalizedAdminPages.length; idx++) {
        const page = this.getAnalizedAdminPages[idx];
        const refKey = `activitysubunsub${idx}`;
        const chartElement = this.getRefElement(refKey);
        
        if (chartElement) {
          try {
            const subUnsub = PageCharts.SubUnsub({ page });
            
            // Process sub/unsub data based on period if needed
            let processedSubUnsub = subUnsub;
            if (this.activePeriod !== 'daily') {
              // If aggregation is needed, apply it here
              processedSubUnsub = this.aggregateChartData(subUnsub, this.activePeriod, 'subunsub');
            }
            
            this.charts.push(
              await ColumnsOne({
                page,
                ref: [chartElement],
                chartData: processedSubUnsub,
                fields: [
                  { field: "subscribed", stacked: false },
                  { field: "unsubscribed", stacked: true },
                ],
                category: "date",
                color: page.colorName,
                period: this.activePeriod
              })
            );
          } catch (error) {
            console.error(`Error creating sub/unsub chart ${idx}:`, error);
          }
        }
      }
    },
    
    // Helper to aggregate data for different periods
    aggregateChartData(data, period, chartType) {
      if (period === 'daily' || !data) return data;
      
      // Clone the data to avoid mutations
      const result = JSON.parse(JSON.stringify(data));
      
      if (chartType === 'growth') {
        // For growth chart, we need to group by week or month
        if (!Array.isArray(result)) return result;
        
        const aggregated = [];
        const groupedData = {};
        
        // Group data by period
        result.forEach(item => {
          const date = moment(item.date);
          let key;
          
          if (period === 'weekly') {
            // Get first day of the week as key
            const weekStart = date.clone().startOf('week');
            key = weekStart.valueOf();
          } else if (period === 'monthly') {
            // Get first day of the month as key
            const monthStart = date.clone().startOf('month');
            key = monthStart.valueOf();
          }
          
          if (!groupedData[key]) {
            // Initialize a new group with the correct structure
            const newGroup = {
              date: key
            };
            
            // Copy all properties that match growth_X pattern
            Object.keys(item).forEach(prop => {
              if (prop.startsWith('growth_')) {
                newGroup[prop] = 0;
              }
            });
            
            groupedData[key] = newGroup;
          }
          
          // Sum up growth values for each page
          Object.keys(item).forEach(prop => {
            if (prop.startsWith('growth_')) {
              groupedData[key][prop] += (item[prop] || 0);
            }
          });
        });
        
        // Convert grouped data back to array
        return Object.values(groupedData).sort((a, b) => a.date - b.date);
      }
      else if (chartType === 'subunsub') {
        // For sub/unsub data
        if (!Array.isArray(result)) return result;
        
        const aggregated = [];
        const groupedData = {};
        
        // Group data by period
        result.forEach(item => {
          const date = moment(item.date);
          let key;
          
          if (period === 'weekly') {
            // Get first day of the week as key
            const weekStart = date.clone().startOf('week');
            key = weekStart.valueOf();
          } else if (period === 'monthly') {
            // Get first day of the month as key
            const monthStart = date.clone().startOf('month');
            key = monthStart.valueOf();
          }
          
          if (!groupedData[key]) {
            groupedData[key] = {
              date: key,
              subscribed: 0,
              unsubscribed: 0,
              count: 0
            };
          }
          
          // Sum up subscribed and unsubscribed values
          groupedData[key].subscribed += (item.subscribed || 0);
          groupedData[key].unsubscribed += (item.unsubscribed || 0);
          groupedData[key].count++;
        });
        
        // Convert grouped data back to array
        Object.values(groupedData).forEach(group => {
          aggregated.push({
            date: group.date,
            subscribed: group.subscribed,
            unsubscribed: group.unsubscribed
          });
        });
        
        return aggregated;
      }
      
      // Default case, return original data
      return result;
    },
    
    setPeriod(period) {
      if (this.activePeriod !== period) {
        this.activePeriod = period;
        
        // Use Vue's nextTick to ensure DOM is updated before recreating charts
        this.$nextTick(() => {
          this.renderCharts();
        });
      }
    },
  },
  
  mounted() {
    // Use nextTick to ensure DOM is ready before rendering charts
    this.$nextTick(() => {
      setTimeout(() => {
        this.renderCharts();
      }, 100); // Small delay to ensure DOM elements are fully rendered
    });
  },

  beforeDestroy() {
    // Clean up all charts when component is destroyed
    this.charts.forEach(chart => {
      if (chart && typeof chart.dispose === 'function') {
        chart.dispose();
      }
    });
  },
};
</script>
