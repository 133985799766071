import * as echarts from 'echarts';
import PageCharts from '../page-charts';
import i18n from '@/i18nSetup';

const days = {
  Sun: i18n.t("DAYS_OF_WEEK_SUN"),
  Mon: i18n.t("DAYS_OF_WEEK_MON"),
  Tue: i18n.t("DAYS_OF_WEEK_TUE"),
  Wed: i18n.t("DAYS_OF_WEEK_WED"),
  Thu: i18n.t("DAYS_OF_WEEK_THU"),
  Fri: i18n.t("DAYS_OF_WEEK_FRI"),
  Sat: i18n.t("DAYS_OF_WEEK_SAT")
};

const agregate = (page) => {
  return PageCharts.onlineFansIGFB(page.audienceStats.online_followers)
    .map((item) => ({
      ...item,
      hour: `${parseInt(item.hour)}:00`,
      label: days[item.weekday],
      index: Object.keys(days).indexOf(item.weekday),
    }))
    .sort((a, b) => a.index - b.index);
};

const hexToRgb = (hex) => {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  return { r, g, b };
};

const rgbToHex = (r, g, b) => {
  return (
    '#' +
    ((1 << 24) + (r << 16) + (g << 8) + b)
      .toString(16)
      .slice(1)
      .toUpperCase()
  );
};
const interpolateColor = (color1, color2, factor) => {
  const result = hexToRgb(color1);
  const color2rgb = hexToRgb(color2);

  result.r = Math.round(result.r + factor * (color2rgb.r - result.r));
  result.g = Math.round(result.g + factor * (color2rgb.g - result.g));
  result.b = Math.round(result.b + factor * (color2rgb.b - result.b));

  return rgbToHex(result.r, result.g, result.b);
};

const create = function ({ page, ref, refLegend }) {
  return new Promise((resolve, reject) => {

    const data = agregate(page);
    const formattedData = data.map((item) => [
      parseInt(item.hour.split(':')[0]),
      item.index,
      item.value,
    ]);

    const chart = echarts.init(ref);
    const minValue = Math.min(...data.map((item) => item.value));
    const maxValue = Math.max(...data.map((item) => item.value));

    const baseColor = page.colorCode || '#ff2e94';
    const minColor = interpolateColor(baseColor, '#ffffff', 0.9);
    const maxColor = baseColor;
    const option = {
      grid: {
        top: 0,
        right: 0,
        bottom: 80,
        left: 12,
        containLabel: true,
      },
      tooltip: {
        position: 'top',
        backgroundColor: 'transparent',
        borderWidth: 0,
        padding: 0,
        transitionDuration: 0.4,
        formatter: (params) => {
          const weekday = days[Object.keys(days)[params.value[1]]];
          const hour = `${params.value[0]}:00`;
          return `<div class='tooltip'>
              <span class='date'>${weekday}, ${hour}</span>
              <ul class='tooltip-list'>
                <li>
                  <div class="soc-sea ${page.colorName}">
                    <span class="soc-ico soc-${page.social.toLowerCase()}"></span>${page.owner_name_medium}
                  </div>
                  ${params.value[2]}
                </li>
              </ul>
            </div>`;
        },
      },
      xAxis: {
        type: 'category',
        data: Array.from(Array(24).keys()).map((hour) => `${hour}:00`),
        axisLabel: {
          show: true,
          hideOverlap: true,
          fontSize: 12,
          fontFamily: 'Lato, Helvetica, Arial, sans-serif',
          color: '#000',
        },
        axisLine: {show:false},
        axisTick: { show: false },
      },
      yAxis: {
        type: 'category',
        data: Object.keys(days).map(day => day.toUpperCase()),
        inverse: true,
        axisLine: {show:false},
        axisLabel: {
          fontSize: 10,
          fontWeight: 700,
          padding: [0, 10, 0, 0],
          fontFamily: 'Lato, Helvetica, Arial, sans-serif',
          color: '#000'
        },
        axisTick: { show: false },
      },
      visualMap: {
        min: minValue,
        max: maxValue,
        calculable: false,
        orient: 'horizontal',
        itemWidth:40,
        splitNumber:16,
        show:false,
        itemHeight: 2,
        type: 'piecewise',
        showLabel: false,
        itemGap:1,
        left: 'center',
        bottom: 50,
        inRange: {
          color: [minColor, maxColor],
        },
      },
      series: [
        {
          type: 'heatmap',
          data: formattedData,
          label: {
            show: false,
          },
          itemStyle: {
            borderRadius: [4, 4, 4, 4],
            borderWidth: 1,
            borderColor: '#fff',
            shadowBlur: 0,
          },
          emphasis: {
            scale: true,
            scaleSize: 1.1,
            itemStyle: {
              borderWidth: 2,
              borderColor: 'transparent',
            },
          },
        },
      ],
    };

    chart.setOption(option);
    
    const labelMin = i18n.t("STATS_LOWEST");
    const labelMax = i18n.t("STATS_HIGHEST");
    let intermediateDivs = '';
    for (let i = 1; i <= 8; i++) {
      const interpolatedColor = interpolateColor(minColor, maxColor, i / 10); // Adjust factor for 10 segments
      intermediateDivs += `<div class="inter-points" style="background-color: ${interpolatedColor};" data-level="${i}"></div>`;
    }
    refLegend.innerHTML = `
      <span>${labelMin}</span>
      <div class="inter-points" style="background-color: ${minColor};" data-level="0"></div>
      ${intermediateDivs}
      <div class="inter-points" style="background-color: ${maxColor};" data-level="10"></div>
      <span>${labelMax}</span>
    `;
    const legendItems = refLegend.querySelectorAll('.inter-points');
    const segmentCount = 9;
    const rangeSize = (maxValue - minValue) / segmentCount;
    legendItems.forEach((item, index) => {
      item.addEventListener('mouseenter', function () {
        const lowerThreshold = minValue + index * rangeSize;
        const upperThreshold = lowerThreshold + rangeSize;
        chart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: formattedData
            .map((data, idx) => (data[2] >= lowerThreshold && data[2] < upperThreshold ? idx : -1))
            .filter((idx) => idx !== -1),
        });
      });
      item.addEventListener('mouseleave', function () {
        chart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
        });
      });
    });
    
    resolve(chart);
    window.addEventListener('resize', () => {
      chart.resize();
      });
  });
};

export default create;
