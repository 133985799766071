<template>
  <div id="app">
    <Modals />
    <router-view />
    <AlertManager />
  </div>
</template>

<script>
import Modals from "./components/modals/Modals";
import { mapGetters } from "vuex";
import locale from "../src/locale/locale.js";
import AlertManager from './components/AlertManager.vue';
import Vue from "vue";
import utils from "@/utils.js"

window.moment = require("moment");
export default {
  name: "App",
  components: {
    Modals,
    AlertManager
  },
  beforeCreate() {
    // let language = "";
    // if (window.navigator.languages) {
    //   language = window.navigator.languages[0];
    // } else {
    //   language = window.navigator.userLanguage || window.navigator.language;
    // }
    const language = "EN";
    Vue.prototype.$locale = locale[language];
    window.locale = locale[language];
  },
  computed: {
    ...mapGetters(["getProfile"]),
  },
  mounted() {
    this.preventCopy();
    const { getSearchParameters, changeLanguage } = utils;

    const params = getSearchParameters();
    if (params?.lang) {
      console.log('lang', params?.lang);
      changeLanguage(params.lang, this.getProfile)
    }    
  },
  methods: {
    preventCopy() {
      document.addEventListener("copy", (event) => {
        const selection = document.getSelection().toString().replace(/\s/g, "");

        if (isNaN(selection)) return;
        event.clipboardData.setData("text/plain", selection);
        event.preventDefault();
      });
    },
  },
};
</script>

<style>
@import "https://unpkg.com/ionicons@4.2.4/dist/css/ionicons.min.css";
@import "https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css";
#app {
  height: 100%;
}
</style>
