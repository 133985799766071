export const sortOptionsMap = [
    {
      key: 'date',
      label: 'SORT_DATE',
      socials: ['VK', 'IG'],
      metric: {
        VK: 'date',
        IG: 'date',
      },
    },
    {
      key: 'views_impressions',
      label: 'SORT_VIEWS',
      socials: ['VK', 'IG'],
      metric: {
        VK: 'views',
        IG: 'impressions',
      },
    },
    {
      key: 'likes',
      label: 'SORT_LIKES',
      socials: ['VK', 'IG'],
      metric: {
        VK: 'likes',
        IG: 'likes',
      },
    },
    {
      key: 'open_link',
      label: 'STATS_STORIES_OPEN_LINK',
      socials: ['VK', 'IG'],
      metric: {
        VK: 'open_link',
        IG: 'open_link',
      },
    },
    {
      key: 'shares',
      label: 'SORT_SHARES',
      socials: ['VK', 'IG'],
      metric: {
        VK: 'shares',
        IG: 'shares',
      },
    },
    {
      key: 'new_subscribers',
      label: 'STATS_TABLE_FOLLOWERS_SUBSCRIBERS',
      socials: ['VK'],
      metric: {
        VK: 'subscribers',
      },
    },
    {
      key: 'navigation',
      label: 'POST_NAVIG',
      socials: ['IG'],
      metric: {
        IG: 'navigation',
      },
    },
    {
      key: 'replies_answers',
      label: 'STATS_STORIES_REPLIES',
      socials: ['VK', 'IG'],
      metric: {
        VK: 'replies',
        IG: 'answers',
      },
    },
    // Add other sorting options as needed
  ];
  