<template>
    <div>
        <Header :showInput="false" />
        <div class="mod-dial">
            <div class="mod-cont mod-payment-status">
                <div class="status-wrapper">
                    <div class="status-circle fail">
                        <span></span>
                    </div>
                    <span class="status-message">{{ $t('PAY_FAIL') }}</span>
                    <p class="status-text">
                        <!--можно текст ошибки сюда-->
                        {{ $t('PAY_FAIL_TXT') }}
                    </p>
                    <button class="close-modal btn-st-tr" @click="redirectToPlans">{{ $t('PAY_PRIC') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState, mapMutations } from "vuex";
import { PAYMENT_STATUS } from "../store/actions/pay";

import Header from "../components/Header";
export default {
  name: "PayCancel",
  mounted() {},
  components: {
    Header
  },
  methods: {
    ...mapMutations([PAYMENT_STATUS]),
    redirectToPlans() {
      this.$router.push({ path: '/plans' });
    },
  }
}
</script>
