import * as echarts from 'echarts';

const create = function ({ ref, country, color, maxValue, pages }) {
  return new Promise((resolve, reject) => {
    const chart = echarts.init(ref[0], null, {
      renderer: 'canvas',
      useDirtyRect: false,
    });

    // Prepare the series data and percentage for each page
    const series = pages.map((page, idx) => {
      const currentValue = country[`count_${idx}`] || 0;
      const maxValForPage = maxValue[`count_${idx}`] || 1; // Avoid division by zero

      // Calculate the percentage based on the respective max value for each page
      const percentageValue = ((currentValue / maxValForPage) * 100).toFixed(0);

      // Set the inner and outer radius for each ring
      const radiusWidth = 6; // Adjust as needed for the thickness of the rings
      const outerRadius = 100 - idx * (radiusWidth + 5) - 10; // Spacing between rings
      const innerRadius = outerRadius - radiusWidth;

      const colorCode = page.colorCode;

      return {
        name: page.owner_name,
        type: 'pie',
        clockwise: true,
        radius: [`${innerRadius}%`, `${outerRadius}%`],
        center: ['50%', '50%'],
        label: {
          show: false,
        },
        clickable: false,
        labelLine: {
          show: false,
        },
        data: [
          {
            value: percentageValue,
            name: page.owner_name,
            itemStyle: {
              color: colorCode,
              borderRadius: 10,
            },
            emphasis: {
              scale: true,
              scaleSize: 1.2,
              itemStyle: {
                color: colorCode,
                borderWidth: 1,
                borderColor: colorCode,
              },
            },
          },
          {
            value: 100, // Remaining part to complete the circle
            name: 'empty',
            itemStyle: {
              color: '#F0F1F4', // Placeholder for the remaining part
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              disabled: true,
            },
          },
        ],
      };
    });

    // Tooltip Content
    const tooltipContent = pages
      .map((page, idx) => {
        const maxValForPage = maxValue[`count_${idx}`] || 1;
        const percentage = ((country[`count_${idx}`] / maxValForPage) * 100).toFixed(1);
        return `<li>
          <div class="soc-sea ${page.colorName}">
            <span class="soc-ico soc-${page.social.toLowerCase()}"></span>
            ${page.owner_name_medium}
          </div>
          ${percentage}%
        </li>`;
      })
      .join('');

    const option = {
      tooltip: { show: false },
      series: series,
    };

    chart.setOption(option);

    if (pages.length === 1) {
        const centerLabel = document.createElement('div');
        centerLabel.className = 'center-label';
        centerLabel.textContent = `${((country['count_0'] / maxValue[`count_0`]) * 100).toFixed(1)}%`;
        ref[0].appendChild(centerLabel);
      } else {
        ref[0].style.position = 'relative';
        const tooltipEl = document.createElement('div');
        tooltipEl.className = 'tooltip';
        tooltipEl.innerHTML = `<span class='date'>${country.name || ''}</span><ul class='tooltip-list'>${tooltipContent}</ul>`;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.pointerEvents = 'none';
        tooltipEl.style.display = 'none';
        ref[0].appendChild(tooltipEl);
      
        ref[0].addEventListener('mouseenter', function (event) {
          tooltipEl.style.display = 'block';
        });
        ref[0].addEventListener('mouseleave', function () {
          tooltipEl.style.display = 'none';
        });
      }

    resolve({ chart, colors: pages.map((page) => page.colorCode) });
    window.addEventListener('resize', () => {
      chart.resize();
      });
  });
};

export default create;
