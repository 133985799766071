import Vue from 'vue';
import VueI18n from 'vue-i18n';
import translations from './translations';

Vue.use(VueI18n);

function transformTranslations(translations) {
  const result = {
    en: {}, // Initialize English by default
    ru: {}, // Initialize Russian
    es: {}, // Initialize Spanish
    pt: {}, // Initialize Portuguese
    de: {}  // Initialize German
  };

  Object.keys(translations).forEach(key => {
    if (typeof translations[key] === 'object') {
      // This key has language-specific translations
      Object.keys(translations[key]).forEach(lang => {
        if (result[lang]) {
          result[lang][key] = translations[key][lang];
        }
      });

      // Ensure English fallback for all languages
      Object.keys(result).forEach(lang => {
        if (!result[lang][key] && translations[key]['en']) {
          result[lang][key] = translations[key]['en'];
        }
      });
    } else {
      // This key should be used as-is for all languages
      const value = translations[key];
      Object.keys(result).forEach(lang => {
        result[lang][key] = value;
      });
    }
  });

  return result;
}

// Get the browser's language
function getBrowserLanguage() {
  const browserLang = navigator.language.split('-')[0];
  const supportedLanguages = ['en', 'ru', 'es', 'pt', 'de'];
  return supportedLanguages.includes(browserLang) ? browserLang : 'en';
}

// Get the preferred language in this order:
// 1. localStorage setting
// 2. Browser language (if supported)
// 3. Default to English
const savedLocale = localStorage.getItem('userLanguage');
const defaultLocale = savedLocale || getBrowserLanguage();

const i18nMessages = transformTranslations(translations);

const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages: i18nMessages,
  silentTranslationWarn: true, // Optional: suppress warnings for missing translations
});

// Ensure the locale is saved to localStorage
if (!savedLocale) {
  localStorage.setItem('userLanguage', defaultLocale);
}

export default i18n;
