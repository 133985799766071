import alasql from 'alasql';
import moment from 'moment'
import PageCharts from '@/models/page-charts'
import PageUtils from '@/models/page-utils'

import i18n from '@/i18nSetup';

// Add a helper function to get the correct locale for number formatting
const getNumberFormatLocale = (locale) => {
  // Map i18n locales to valid toLocaleString locales
  const localeMap = {
    'en': 'en-US',
    'ru': 'ru-RU',
    'es': 'es-ES',
    'pt': 'pt-BR',
    'de': 'de-DE'
  };
  return localeMap[locale] || 'en-US';
};

const mapStat = {
  likes: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "ALL_LIKES",
    social: ['VK', "FB", "IG"],
    type: 'total'
  },
  reposts: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "ALL_REPOSTS",
    social: ['VK', "FB"],
    type: 'total'

  },
  comments: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "ALL_COMMENTS",
    social: ['VK', "FB", "IG"],
    type: 'total'

  },
  views: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "ALL_VIEWS",
    social: ['VK'],
    type: 'total'

  },
  posts: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "ALL_RECORDS",
    social: ['VK', "FB", "IG"],
    type: 'total'

  },
  interactions: {
    is_posts: true,
    is_admin: true,
    is_stories: false,
    locale: "INTERACTIONS",
    social: ['VK', "FB", "IG"],
    type: 'total'
  },

  er_day: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "ER_DAY",
    social: ['VK', "FB", "IG"],
    type: 'engagement'
  },
  er_post: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "ER_POST",
    social: ['VK', "FB", "IG"],
    type: 'engagement'

  },
  lr: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "LR",
    social: ['VK', "FB", "IG"],
    type: 'engagement'

  },
  tr: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "TR",
    social: ['VK', "FB", "IG"],
    type: 'engagement'

  },
  er_view: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "ER_VIEW",
    social: ['VK'],
    type: 'engagement'

  },
  err: {
    is_posts: true,
    is_admin: true,
    is_stories: false,
    locale: "ERR",
    social: ['VK', "FB", "IG"],
    type: 'engagement'

  },

  posts_reach_avg: {
    is_posts: true,
    is_admin: true,
    is_stories: false,
    locale: "REACH_POST_AVG",
    social: ['VK', "FB", "IG"],
    type: 'average'
  },
  post_reachrate_avg: {
    is_posts: true,
    is_admin: true,
    is_stories: false,
    locale: "REACH_RATE_AVG",
    social: ['VK', "FB", "IG"],
    type: 'engagement'

  },
  visibility_rate: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "VIS_RATE",
    social: ['VK'],
    type: 'engagement'

  },
  er_progressive: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "ER_PROGRESSIVE",
    social: ['VK', "FB", "IG"],
    type: 'engagement'

  },
  /* views_rate: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "VIEWS_RATE",
    social: ['VK',],
    type: 'engagement'

  }, */
  likes_avg: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "LIKES_AVG",
    social: ['VK', "FB", "IG"],
    type: 'average'
  },
  interactions_avg: {
    is_posts: true,
    is_admin: true,
    is_stories: false,
    locale: "INTERACTIONS_AVG",
    social: ['VK', "FB", "IG"],
    type: 'average'
  },
  reposts_avg: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "REPOSTS_AVG",
    social: ['VK', "FB"],
    type: 'average'
  },
  comments_avg: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "COMMENTS_AVG",
    social: ['VK', "FB", "IG"],
    type: 'average'
  },

  views_avg: {
    is_posts: true,
    is_admin: false,
    is_stories: false,
    locale: "VIEWS_AVG",
    social: ['VK'],
    type: 'average'

  },
  page_reach_avg: {
    is_posts: false,
    is_admin: true,
    is_stories: false,
    locale: "PAGE_REACH_AVG",
    social: ['VK', "FB", "IG"],
    type: 'average'

  },
  page_reach_mobile_avg: {
    is_posts: false,
    is_admin: true,
    is_stories: false,
    locale: "PAGE_REACH_MOBILE_AVG",
    social: ['VK'],
    type: 'average'

  },

  ir_post_avg: {
    is_posts: true,
    is_admin: true,
    is_stories: false,
    locale: "IR_POST_AVG",
    social: ['VK', "FB", "IG"],
    type: 'engagement'


  },
  irr_post_avg: {
    is_posts: true,
    is_admin: true,
    is_stories: false,
    locale: "IRR_POST_AVG",
    social: ['VK', "FB", "IG"],
    type: 'engagement'

  },
  subscribers: {
    is_posts: false,
    is_admin: false,
    is_stories: false,
    locale: "SUBSCRIBERS",
    social: ['VK', "FB", "IG"],
    type: 'followers'

  },
  growth: {
    is_posts: false,
    is_admin: false,
    is_stories: false,
    locale: "GROWTH",
    social: ['VK', "FB", "IG"],
    type: 'followers'
  },
  stories: {
    is_posts: false,
    is_admin: true,
    is_stories: true,
    locale: "STORIES",
    social: ['VK', "IG"],
    type: 'total'
  },
  stories_reach_avg: {
    is_posts: false,
    is_admin: true,
    is_stories: true,
    locale: "STORIES_REACH_AVG",
    social: ['VK', "IG"],
    type: 'average'
  },

}


const calculateRawData = (rawData, dayCount, locale) => {

  const { pages, dates } = rawData

  const unionPosts = []
  const unionStories = []


  pages.forEach(page => {
    page.subscribers = { last: page.members, items: [] };
    page.dataset_admin_stat = [];
    page.owner_name_short = page.owner_name.length > 15 ? page.owner_name.slice(0, 15) + '...' : page.owner_name
    page.owner_name_medium = page.owner_name.length > 25 ? page.owner_name.slice(0, 25) + '...' : page.owner_name
    if (!page.audienceStats && (page.stats && page.stats.stats)) {
      page.audienceStats = JSON.parse(page.stats.stats)
    } else if (!page.audienceStats) {
      page.audienceStats = undefined
    }
    // page.audienceStats = page.stats ? JSON.parse(page.stats.stats) : undefined

    page.growth = []

    page.metrics.forEach((item, index) => {

      page.dataset_admin_stat.push({
        reach: item.reach,
        reach_subscribers: item.reach_subscribers,
        mobile_reach: null,
        ...JSON.parse(item.admin_stats),
        day: moment(new Date(item.metrics_date)).unix()
      });

      page.subscribers.items.push({
        subscribers: item.members,
        day: moment(new Date(item.metrics_date))
          .startOf("day")
          .unix()
      });

      if (index) {
        const currentMembers = page.subscribers.items[index - 1]
        const previousMembers = page.subscribers.items[index]
        if (previousMembers.subscribers == null) return
        page.growth.push(
          {
            growth: currentMembers.subscribers - previousMembers.subscribers,
            day: currentMembers.day
          }
        )
      }


    });


    const rows_act = page.activity.map(row => {
      return JSON.parse(row.posts_activity).map(item => {
        item.subscribers_thisday = row.members;
        return item;
      });
    });
    const rows_attach = page.attachments.map(row => {
      return JSON.parse(row.posts_attachments);
    });

    page.hashtags = [];
    const rawPosts = postUnion(rows_act, rows_attach).pop();
    if (rawPosts) {
      rawPosts.forEach(post => {
        post.images = [];
        if (post.attach === null) return;

        if (post.attach) {
          post.attach.forEach(att => {
            if (att.type == "photo" || "video")
              post.images.push({ image: att.image_url });
          });
        }
        if (post.hashtags && post.hashtags.length) {
          page.hashtags = page.hashtags.concat(
            post.hashtags.map(ht => ({
              hashtag: ht,
              er: page.subscribers.last
                ? post.activity / page.subscribers.last
                : 0,
              activity: post.activity
            }))
          );
        }
      });
    }
    page.posts = preparePagePosts(page, rawPosts || [], locale);
    page.stories = preparePageStories(page.stories, locale)

    unionPosts.push(...page.posts)
    unionStories.push(...page.stories)


    page.stats = calculatePageStat(
      page,
      dayCount,
      locale
    );
  })
  const datesRangePosts = PageCharts.datesRangePosts(unionPosts)
  const datesRangeStories = PageCharts.datesRangeStories(unionStories)


  return {
    pages,
    dates,
    datesRangePosts,
    datesRangeStories,
    unionPosts,
    unionStories
  }
}


const calculatePageStat = (page, dayCount, locale) => {

  const posts = page.posts
  const stories = page.stories.map(({ stat }) => stat.stats)
  const subscribers = page.subscribers.last
  let postStats, storiesStats = {}

  if (posts.length > 0) {
    const sqlCalc = {
      VK: `SELECT 
          SUM(likes) AS likes,
          SUM(reposts) AS reposts,
          SUM(comments) AS comments,
          SUM(views) AS views,
          SUM(interactions) as interactions,
          ROUND(AVG(likes)) AS likes_avg,
          ROUND(AVG(reposts)) AS reposts_avg,
          ROUND(AVG(comments)) AS comments_avg,
          ROUND(AVG(views)) AS views_avg,
          ROUND(AVG(interactions)) AS interactions_avg,
          COUNT(1) AS posts,
          SUM(activity) AS activity,
          AVG(er) AS er_post,
          AVG(er_views) AS er_view,
          AVG(visibility_rate) as visibility_rate,
          AVG(er_progressive) as er_progressive,
          
          AVG(err) as err FROM ?`,
      //AVG(views_rate) as views_rate,
      FB: `SELECT 
          SUM(likes) AS likes,
          SUM(reposts) AS reposts,
          SUM(comments) AS comments,
          SUM(interactions) as interactions,
          ROUND(AVG(likes)) AS likes_avg,
          ROUND(AVG(reposts)) AS reposts_avg,
          ROUND(AVG(comments)) AS comments_avg,
          ROUND(AVG(interactions)) AS interactions_avg,
          COUNT(1) AS posts,
          SUM(activity) AS activity,
          AVG(er_progressive) as er_progressive,
          AVG(er) AS er_post,
          AVG(err) as err FROM ?`,

      IG: `SELECT 
          SUM(likes) AS likes,
          SUM(comments) AS comments,
          SUM(interactions) as interactions,
          ROUND(AVG(likes)) AS likes_avg,
          ROUND(AVG(comments)) AS comments_avg,
          ROUND(AVG(interactions)) AS interactions_avg,
          COUNT(1) AS posts,
          SUM(activity) AS activity,
          AVG(er_progressive) as er_progressive,
          AVG(er) AS er_post,
          AVG(err) as err FROM ?`
    }

    postStats = alasql(sqlCalc[page.social], [posts]).pop();
    const group = alasql('select day, ARRAY(_) AS items FROM ? group by day', [posts])
    group.forEach(el => {
      el.er_day = (alasql(`select (SUM(activity)/COUNT(1))/${subscribers} as er_day from ?`, [el.items])).pop().er_day
    });
    postStats.er_day = alasql('select AVG(er_day) as er_day from ?', [group]).pop().er_day * 100;
    postStats.lr = ((postStats.likes / postStats.posts) / subscribers) * 100;
    postStats.tr = ((postStats.comments / postStats.posts) / subscribers) * 100;
    postStats.posts_reach_avg = Math.round(postsreach_avg(posts))
    postStats.post_reachrate_avg = reachrate_avg(posts)

    postStats.ir_post_avg = irposts_avg(posts)
    postStats.irr_post_avg = irrposts_avg(posts)
    delete postStats.activity
  }

  if (stories.length) {
    const sqlCalc = {
      VK: `SELECT 
          SUM(views) as stories_total_reach,
          ROUND(AVG(views)) AS stories_reach_avg,
          COUNT(1) AS stories 
          FROM ?`,
      IG: `SELECT 
          SUM(reach) as stories_total_reach,
          ROUND(AVG(reach)) AS stories_reach_avg,
          COUNT(1) AS stories 
          from ?`
    }
    storiesStats = alasql(sqlCalc[page.social], [stories]).pop();
  }



  let growth = 0
  if (page.metrics.length > 1) {
    const members = page.metrics.filter(metric => metric.members).map(metric => metric.members)
    const currentMembers = members[0]
    const lastMembers = members.slice(-1)[0]
    growth = currentMembers - lastMembers
  }

  let commonStats = {
    subscribers,
    page_reach_avg: Math.round(reach_avg(page, dayCount)),
    page_reach_mobile_avg: Math.round(reachmobile_avg(page, dayCount)),
    growth: growth > 0 ? "+" + growth : growth
  }

  const stat = {
    ...commonStats,
    ...postStats,
    ...storiesStats
  }



  return filtratePageStat(page, stat, locale)


}

const filtratePageStat = (page, stat, locale) => {
  const finalStat = {
    total: {},
    engagement: {},
    average: {},
    followers: {}
  }
  
  // Get the correct locale for number formatting
  const numberFormatLocale = getNumberFormatLocale(i18n.locale);
  
  for (const key in mapStat) {
    if (!key in stat) continue

    const canByPosts = mapStat[key].is_posts ? page.posts.length ? true : false : true
    const canBySocial = mapStat[key].social.includes(page.social)
    const canByAdmin = mapStat[key].is_admin ? page.is_admin ? true : false : true
    const canByStories = mapStat[key].is_stories ? page.stories.length ? true : false : true
    let canByVkSubscribersLimits = true
    if (
      page.social === "VK" &&
      mapStat[key].is_admin === true && !!page.is_admin &&
      page.subscribers.last < 5000
    )
      canByVkSubscribersLimits = false;

    if (!canByPosts || !canBySocial || !canByAdmin || !canByStories || !canByVkSubscribersLimits) continue

    if (stat[key] === undefined || isNaN(stat[key]) || stat[key] === null) stat[key] = ''
    else {
      // Format numbers according to the current locale
      stat[key] = stat[key].toLocaleString(numberFormatLocale)
    }

    finalStat[mapStat[key].type][key] = {
      locale: i18n.t("STATS_TABLE_" + mapStat[key].type.toUpperCase() + "_" + mapStat[key].locale),
      value: stat[key]
    }
  }

  return finalStat
}

const analizeIntroPages = (rawPages, metrics, activity, addedHashes) => {
  const postsCount = alasql(
    "SELECT SUM(posts_count) AS posts_count_total, page_id FROM ? GROUP BY page_id",
    [activity]
  );

  let postsActivity = activity.map(item => {
    const page_id = item.page_id;
    const activity_agg = alasql("SELECT IFNULL(SUM(_), 0) AS val FROM ?", [
      JSON.parse(item.activity)
    ]);
    let activity = 0;
    if (activity_agg && activity_agg.length && activity_agg[0].val) {
      activity = activity_agg[0].val;
    }

    const viral_agg = alasql("SELECT IFNULL(AVG(_), 0) AS val FROM ?", [
      JSON.parse(item.viral)
    ]);
    let viral = 0;
    if (viral_agg && viral_agg.length && viral_agg[0].val) {
      viral = viral_agg[0].val;
    }

    return {
      page_id,
      activity,
      viral
    };
  });

  postsActivity = alasql(
    "SELECT SUM(activity) AS activity_total, AVG(viral) as viral_avg, page_id FROM ? GROUP BY page_id",
    [postsActivity]
  );
  const postsActivityCount = alasql(
    "SELECT a.page_id, a.posts_count_total, b.activity_total, b.viral_avg FROM ? a JOIN ? b USING page_id",
    [postsCount, postsActivity]
  );



  let pagesMetrics = alasql(
    "SELECT ARRAY(members) as members, AVG(reach) as reach, page_id FROM ? GROUP BY page_id",
    [metrics]
  );

  const pages = alasql(
    'SELECT p.*, IFNULL(a.activity_total, 0) AS activity, IFNULL(a.posts_count_total, 0) AS posts_count, IFNULL(m.members, 0) AS members, IFNULL(CEILING(m.reach), "") AS reach_avg, IFNULL(a.viral_avg, "") as virality FROM ? p LEFT JOIN ? m USING page_id LEFT JOIN ? a USING page_id',
    [rawPages, pagesMetrics, postsActivityCount]
  );

  const added = []
  const pagesByHash = {}
  for (const [index, page] of pages.entries()) {
    pagesByHash[page.hash_id] = index
    if (!page.owner_name) page.owner_name = "";
    page.fullowner_name = page.owner_name;

    if (page.owner_name.length > 20)
      page.owner_name = `${page.owner_name.substring(0, 20)}...`

    page.isSelected = false;
    page.er = "";
    page.followers = ''
    page.growth = ''
    page.isGrew = false;

    if (!page.members) page.members = []
    page.members = page.members.filter(members => Number.isInteger(members))
    if (page.members.length) {
      page.followers = page.members[0];


      if (page.members.length > 1) {
        const currentMembers = page.members[0]
        const lastMembers = page.members.slice(-1)[0]
        const growth = currentMembers - lastMembers
        page.growth = growth > 0 ? "+" + growth : growth;
      } else {
        page.growth = 0
      }

      if (page.growth > 0) {
        page.members_class = true;
      } else {
        page.members_class = false;
      }

      if (page.posts_count)
        page.er =
          ((page.activity / page.posts_count / page.followers) * 100).toFixed(3)



    }

    if (
      page.status == "added" &&
      !addedHashes.includes(page.hash_id)
    )
      added.push(page.hash_id);
  }
  return [pages, added, pagesByHash]
}

const preparePagePosts = function (page, posts, locale) {
  return posts.map(item => {
    item.owner_id = page.owner_id;
    item.owner_domain = page.owner_domain;
    item.page_id = page.page_id;
    item.name = page.owner_name;
    item.date = new Date(item.date * 1000);
    item.day = moment(item.date).startOf('day').unix();
    item.week = moment(item.date).weekday();
    item.hour = moment(item.date).hour();
    item.er = page.subscribers.last ? (item.activity / page.subscribers.last) * 100 : 0;

    // Calculate ERReach and ERR
    if (page.social === 'VK') {
      item.reach_total = item.reach_viral + item.reach_subscribers;  // ERReach for VK
    } else {
      item.reach_total = item.reach;  // ERReach for FB and IG
    }

    // Calculate ERR
    item.err = isFinite(item.activity / item.reach_total) ? (item.activity / item.reach_total) * 100 : 0;

    // Other calculations
    item.er_views = item.activity / item.views * 100;
    item.er_thisday = item.activity / item.members_thisday;
    item.ir = item.ir * 100;

    if (page.social == 'VK') {
      item.link = `https://vk.com/wall-${page.owner_id}_${item.post_id}`;
    } else if (page.social == 'FB') {
      item.link = `https://facebook.com/${page.owner_id}/posts/${item.post_id.substr(item.post_id.indexOf("_") + 1)}`;
    }

    item.social = page.social;
    item.owner_name = page.owner_name;
    item.visibility_rate = item.views / page.subscribers.last;
    item.er_progressive = item.er_progressive * 100;
    item.formatedStats = formatPostStat(page, item, locale);

    item.local_id = +`${item.date.getTime()}${String(item.post_id).replace('_', '')}`;

    if (item.hasText && item.text) {
      item.text = PageUtils.replaceTextBreaks(item.text);
      if (item.text.length > 150) {
        item.small_text = item.text.slice(0, 150) + '...';
        item.textCanShowMore = true;
      }
    }

    return item;
  }).sort((a, b) => b.date - a.date);
};

const preparePageStories = function (stories, locale) {
  if (!stories || !Array.isArray(stories)) {
    return [];
  }

  return stories.map(item => {
    try {
      // Safely parse and set stat
      item.stat = typeof item.stat == 'object' ? item.stat : JSON.parse(item.stat || '{}');
      
      // Ensure stat.stats exists
      if (!item.stat) {
        item.stat = { stats: {} };
      }
      if (!item.stat.stats) {
        item.stat.stats = {};
      }
      
      item.date = new Date(item.date).getTime();
      item.day = moment(item.date).startOf('day').unix();
      item.formatedStats = formatStoriesStat({ story: item.stat.stats, locale, social: item.social });
      return item;
    } catch (e) {
      console.error('Error processing story item:', e);
      // Return item with default values if there's an error
      return {
        ...item,
        date: new Date(item.date || Date.now()).getTime(),
        day: moment(item.date || Date.now()).startOf('day').unix(),
        formatedStats: { interactions: [], common: [] }
      };
    }
  });
}

const formatStoriesStat = ({ story, locale, social }) => {
  const storiesStatMap = {
    interactions: {
      ...(social === 'VK' && {
        replies: {
          locale: 'REPLIES',
          icon: 'bi bi-reply',
        },
      }),
      ...(social === 'IG' && {
        answers: {
          locale: 'REPLIES', //Для инсты это те же ответы что и в ВК
          icon: 'bi bi-reply',
        },
      }),
      likes: {
        locale: 'LIKES',
        icon: 'bi bi-heart',
      },
      shares: {
        locale: 'SHARES',
        icon: 'bi bi-arrow-return-left',
      },
      open_link: {
        locale: 'OPEN_LINK',
        icon: 'bi bi-link-45deg',
      },
    },
    common: {
      reach: {
        locale: 'REACH',
      },
      subscribers: {
        locale: 'FOLLOWERS',
      },
      views: { 
        locale: 'VIEWS' 
      },
      impressions: { 
        locale: 'VIEWS' //для инсты импрешионс это те же просмотры
      },
    },
  };

  if (social === 'IG') {
    storiesStatMap.navigation = {
      taps_forward: {
        locale: 'TAPS_FORWARD',
      },
      taps_back: {
        locale: 'TAPS_BACK',
      },
      exits: {
        locale: 'EXITS',
      },
    };
  }

  const storiesStats = {};
  for (const label in storiesStatMap) {
    storiesStats[label] = [];
  }

  for (const label in storiesStatMap) {
    for (const stat in storiesStatMap[label]) {
      // Add null check for story
      if (story && story.hasOwnProperty(stat)) {
        storiesStats[label].push({
          key: stat,
          value: story[stat] || 0,
          locale: storiesStatMap[label][stat].locale,
        });
      }
    }
  }

  return storiesStats;
};

const formatPostStat = (page, post, locale) => {

  const postStatMap = {
    total:
    {
      likes: {
        name: 'likes',
        social: ['VK', 'IG', 'FB']
      },
      reposts: {
        name: 'shares',
        social: ['VK', 'FB']
      },
      comments: {
        name: 'comments',
        social: ['VK', 'FB', 'IG']
      },
      views: {
        name: 'views',
        social: ['VK']
      }
    },
    engagement: {
      er: {
        locale: 'ER_POST',
        social: ['VK', 'IG', 'FB']
      },
      er_views: {
        locale: 'ER_VIEW',
        social: ["VK"]
      },
      ir: {
        is_admin: true,
        locale: 'IR',
        social: ['VK', 'IG', 'FB']
      },
      er_progressive: {
        locale: 'ER_PROGRESSIVE',
        social: ['VK', 'IG', 'FB']
      },
      reach_viral: {
        is_admin: true,
        locale: 'REACH_VIRAL',
        social: ['VK'],
        isInteger: true
      },
      reach: {
        is_admin: true,
        locale: 'REACH',
        social: ['IG', 'FB'],
        isInteger: true
      },
      reach_subscribers: {
        is_admin: true,
        locale: 'REACH_SUBSCR',
        social: ['VK', 'IG', 'FB'],
        isInteger: true
      },
      reach_total: {
        is_admin: true,
        locale: 'STATS_TABS_REACH_TITLE',
        social: ['VK'],
        isInteger: true
      },
      err: {
        locale: 'ERR',
        social: ['VK', 'IG', 'FB']
      }
    }

  }

  const postStats = { total: {}, engagement: {} }

  for (let key in postStatMap.total) {
    const canShowByAdmin = !(postStatMap.total[key].is_admin == true && page.is_admin == false)
    const canShowBySocial = postStatMap.total[key].social.includes(page.social)
    if (canShowByAdmin && canShowBySocial && post[key])
      postStats.total[postStatMap.total[key].name] = post[key]
  }

  for (let key in postStatMap.engagement) {
    const canShowByAdmin = !(postStatMap.engagement[key].is_admin == true && page.is_admin == false)
    const canShowBySocial = postStatMap.engagement[key].social.includes(page.social)
    if (canShowByAdmin && canShowBySocial && post[key]) {

      // postStats.engagement[locale.POSTS[postStatMap.engagement[key].locale]] = post[key] //*Archi
      // postStats.engagement['STATS_POSTS_'+postStatMap.engagement[key].locale] = post[key] //*Archi

      postStats.engagement[key] = post[key]

      if (!postStatMap.engagement[key].isInteger) {
        postStats.engagement[key] = postStats.engagement[key].toFixed(4);
        // postStats.engagement[locale.POSTS[postStatMap.engagement[key].locale]] = postStats.engagement[locale.POSTS[postStatMap.engagement[key].locale]].toFixed(4)
      }
    }
  }

  return postStats

}

const postUnion = (act, attach) => {
  const query = `
        select * from ? H left join ? T on H.post_id=T.post_id
    `
  const ac = [].concat(...act)
  const at = [].concat(...attach)


  return [alasql(query, [ac, at])]
}

const bestPostByEr = (posts, name) => {
  return alasql(`SELECT * FROM ? where name="${name}" ORDER BY er DESC LIMIT 1;`, [posts])
}

const preparePageHashTags = function (hashtags) {

  if (!hashtags.length) return [];

  const query = `
        SELECT TOP 50 hashtag, hashtag_cnt FROM (SELECT t.hashtag, COUNT(t.hashtag) AS hashtag_cnt FROM ? t GROUP BY t.hashtag) ORDER BY 2 DESC
    `;

  return alasql(query, [hashtags]);
}

const addDayToMembers = function (members) {

  return members.map(item => {
    item.day = moment(new Date(item.metrics_date)).startOf('day').toDate();
    item.unix = moment(new Date(item.metrics_date)).startOf('day').unix();
    return item;
  });
}

const reach_avg = (data) => {
  const res = alasql('select avg(reach) as reach_avg from ? where reach IS NOT NULL', [data.dataset_admin_stat])[0].reach_avg
  return res
}

const reachmobile_avg = (data) => {
  const res = alasql('select avg(mobile_reach) as reach_mobile_avg from ? where mobile_reach IS NOT NULL', [data.dataset_admin_stat])[0].reach_mobile_avg
  return res
}

const postsreach_avg = (posts) => {
  const res = alasql('select avg(reach) as reach_total from ? where reach IS NOT NULL ', [posts])[0].reach_total
  return res
}

const reachrate_avg = (posts) => {
  const res = (alasql('select avg(reach/subscribers_thisday) as reachrate_avg from ?', [posts])[0].reachrate_avg * 100)
  return res
}

const irposts_avg = (posts) => {
  const res = (alasql('select avg(ir) as ir from ?', [posts])[0].ir)
  return res
}


const irrposts_avg = (posts) => {
  const res = (alasql('select avg(irr) as irr from ?', [posts])[0].irr)
  return res
}


export default {
  preparePagePosts,
  preparePageHashTags,
  addDayToMembers,
  reach_avg,
  postsreach_avg,
  reachrate_avg,
  irposts_avg,
  irrposts_avg,
  postUnion,
  bestPostByEr,
  analizeIntroPages,
  calculatePageStat,
  calculateRawData,
  mapStat,
};
