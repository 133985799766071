<template>
  <div class="mod-socials">
    <span
      :class="['soc-auth', getCurrentSocialTokens.vkToken ? 'active' : '']"
      @click="getVK"
    >
      <span class="soc-ico soc-vk"></span>
      <span
        class="socials-logout"
        v-if="getCurrentSocialTokens.vkToken"
        @click.stop="removeToken('VK')"
      >
        <i class="bi bi-x"></i>
      </span>
    </span>
    <span
      :class="['soc-auth', getCurrentSocialTokens.fbToken ? 'active' : '']"
      @click="getFB"
    >
      <span class="soc-ico soc-fb"></span>
      <span
        class="socials-logout"
        v-if="getCurrentSocialTokens.fbToken"
        @click.stop="removeToken('FB')"
      >
        <i class="bi bi-x"></i>
      </span>
    </span>
    <span
      :class="['soc-auth', getCurrentSocialTokens.igToken ? 'active' : '']"
      @click="getIG"
    >
      <span class="soc-ico soc-ig"></span>
      <span
        class="socials-logout"
        v-if="getCurrentSocialTokens.igToken"
        @click.stop="removeToken('IG')"
      >
        <i class="bi bi-x"></i>
      </span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { AUTH_REMOVE_TOKEN } from '@/store/actions/auth';
import authMethodsMixin from '@/mixins/authMethodsMixin';

export default {
  name: 'SocialAuthButtons',
  mixins: [authMethodsMixin],
  props: {
    selectedAccessEmail: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters(['getSocialTokens', 'getProfile']),
    emailToUse() {
      return this.selectedAccessEmail || this.getProfile.email;
    },
    getCurrentSocialTokens() {
      if (!this.getSocialTokens || !this.emailToUse) return {};
      return this.getSocialTokens[this.emailToUse];
    },
  },
  methods: {
    async removeToken(social) {
      await this.$store.dispatch(AUTH_REMOVE_TOKEN, {
        social,
        email: this.emailToUse,
      });
    },
  },
};
</script>