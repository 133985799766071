import * as echarts from 'echarts';
import PageCharts from '../page-charts';
import i18n from '@/i18nSetup';
import moment from 'moment';
import comOpt from './common-options.json';
import { merge } from 'lodash';

const groupByPeriod = (data, period, name, aggregationType = 'last') => {
  // Clone the data to avoid mutating the original
  const result = [...data];
  
  if (period === 'daily') {
    // Daily view - no additional grouping needed
    return result;
  }
  
  // Group the data by the specified period
  const groupedData = {};
  
  result.forEach(day => {
    let key;
    const date = moment(day.date);
    
    if (period === 'weekly') {
      // Format: "Week X, YYYY" (where X is the week number)
      const weekNum = date.week();
      const year = date.year();
      
      // Get start and end of the week for better date display
      const startOfWeek = moment(date).startOf('week');
      const endOfWeek = moment(date).endOf('week');
      
      key = `Week ${weekNum}, ${year}`;
      
      // Include the date range in the data for tooltip display
      if (!groupedData[key]) {
        groupedData[key] = {
          displayDate: `${startOfWeek.format('MMM DD')} - ${endOfWeek.format('MMM DD')}, ${year}`,
          weekStart: startOfWeek.format('MMM DD'),
          weekEnd: endOfWeek.format('MMM DD'),
          weekYear: year,
          date: startOfWeek.valueOf(), // Use start of week as the date reference
          formatDate: key
        };
      }
    } else if (period === 'monthly') {
      // Format: "MMM YYYY" (e.g., "Jan 2025")
      key = date.format('MMM YYYY');
      
      // For monthly view, get the last day of the month
      const lastDayOfMonth = moment(date).endOf('month');
      const firstDayOfMonth = moment(date).startOf('month');
      
      if (!groupedData[key]) {
        groupedData[key] = {
          displayDate: date.format('MMM YYYY'),
          lastDayOfMonth: lastDayOfMonth.format('MMM DD'),
          date: firstDayOfMonth.valueOf(), // Use start of month as the date reference
          formatDate: key
        };
      }
    }
    
    // For each page index, process metrics based on aggregation type
    const pageCount = 10; // Assume a reasonable maximum number of pages
    for (let i = 0; i < pageCount; i++) {
      const metricKey = `${name}_${i}`;
      
      if (day[metricKey] !== undefined) {
        if (aggregationType === 'sum') {
          // Sum values for aggregation
          groupedData[key][metricKey] = (groupedData[key][metricKey] || 0) + day[metricKey];
        } else if (aggregationType === 'last' || !groupedData[key][metricKey]) {
          // For subscribers, use the last value in the period
          // If the date is more recent, update with this day's metrics and date
          if (!groupedData[key].lastDate || day.date > groupedData[key].lastDate) {
            groupedData[key][metricKey] = day[metricKey];
            groupedData[key].lastDate = day.date;
          }
        }
      }
    }
  });
  
  // Convert the grouped data back to an array and sort by date
  return Object.values(groupedData).sort((a, b) => a.date - b.date);
};

const aggregate = (pages, datesRangePosts, datesRangeStories, name, period = 'daily', aggregationType = 'last') => {
  // Safely get the chart data, ensuring we don't try to access undefined properties
  try {
    const days = PageCharts[name]({ 
      pages, 
      datesRangePosts, 
      datesRangeStories: datesRangeStories || []  // Ensure we always have an array
    }).flat();

    days.forEach(day => {
      day.formatDate = moment(day.date).format('MMM DD');
    });

    const filteredDays = days.filter(day => {
      const essentialKeys = ['date', 'day', 'formatDate'];
      return Object.keys(day).some(key => !essentialKeys.includes(key));
    });

    // Group by period if needed
    return period === 'daily' 
      ? filteredDays 
      : groupByPeriod(filteredDays, period, name, aggregationType);
  } catch (error) {
    console.error(`Error aggregating ${name} data:`, error);
    return []; // Return empty array if there's an error
  }
};

const create = function ({
  pages,
  datesRangePosts,
  datesRangeStories,
  name,
  ref,
  activePages,
  color,
  type,
  period = 'daily',
  aggregationType = 'last', // 'last' for subscribers, 'sum' for most metrics
  animation = true, // Default to true if not provided
}) {

  return new Promise((resolve, reject) => {
    if (activePages) {
      pages = pages.filter((p) => activePages[p.hash_id]);
    }

    const chart = echarts.init(ref);

    // Determine the appropriate aggregation type based on the metric name
    let actualAggregationType = aggregationType;
    if (name === 'subscribers') {
      // Subscribers should always use 'last'
      actualAggregationType = 'last';
    }

    // Aggregate data
    const data = aggregate(pages, datesRangePosts, datesRangeStories, name, period, actualAggregationType);

    // Find minimum and maximum values
    const allValues = data.flatMap(item => pages.map((page, i) => item[`${name}_${i}`]).filter(value => value !== undefined && value !== null));
    const minValue = Math.min(...allValues);
    const maxValue = Math.max(...allValues);

    // Calculate yAxis scaling
    const yAxisMin = minValue * 0.999;
    const yAxisMax = maxValue * 1.001;

    let option = merge({}, comOpt, {
      // Control animations based on the animation parameter
      animation: !!animation,
      animationDuration: animation ? 1000 : 0,
      animationEasing: animation ? 'cubicOut' : 'linear',
      animationThreshold: animation ? 2000 : 0,
      
      color: [],
      xAxis: {
        type: 'time',
        axisLabel: {
          show: true,
          formatter: function(value) {
            if (period === 'daily') {
              return moment(value).format('MMM DD');
            } else if (period === 'weekly' || period === 'monthly') {
              // For aggregated periods, use the custom label if available
              const dataItem = data.find(item => item.date === value);
              if (dataItem) {
                if (period === 'weekly' && dataItem.displayDate) {
                  return dataItem.displayDate;
                } else if (period === 'monthly' && dataItem.displayDate) {
                  return dataItem.displayDate;
                }
              }
              // Fallback to formatted date
              return moment(value).format('MMM DD');
            }
          }
        },
      },
      yAxis: {
        type: 'value',
        scale: true,
        min: yAxisMin.toFixed(0),
        max: yAxisMax.toFixed(0),
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          show: true,
          snap: true,
          label: { show: false },
          lineStyle: {
            color: '#C2D5FF',
            type: 'line'
          }
        },
        formatter: function (params) {
          if (params.length === 0) return '';
          
          let dateFormatted;
          // Find the data item matching this point
          const paramDate = params[0].data[0];
          const dataItem = data.find(item => item.date.toString() === paramDate.toString());
          
          if (period === 'weekly' && dataItem && dataItem.displayDate) {
            dateFormatted = dataItem.displayDate;
          } else if (period === 'monthly' && dataItem && dataItem.displayDate) {
            dateFormatted = dataItem.displayDate;
          } else {
            // For daily view or fallback
            dateFormatted = moment(paramDate).format('MMM DD, YYYY');
          }
          
          let tooltipHtml = `<div class="tooltip"><span class="date">${dateFormatted}</span><ul class="tooltip-list">`;
          let hasData = false;
          
          if (type === 'overview-one') {
            if (params[0].data[1] != null && params[0].data[1] !== 0) {
              hasData = true;
              tooltipHtml += `<li data-value-lines="${params[0].data[1]}">
                <div class="soc-sea ${pages[0].colorName}">${i18n.t(
                'INTRO_TABS_FOLLOWERS'
              )}</div>${params[0].data[1].toLocaleString('ru-RU')}
              </li>`;
            }
          } else if ((type === 'activity' || type === 'reach') && pages.length === 1) {
            pages.forEach((page, i) => {
              const param = params.find((p) => p.seriesName == i.toString());
              if (param && param.data[1] != null && param.data[1] !== 0) {
                hasData = true;
                tooltipHtml += `<li data-value-lines="${param.data[1]}">
                  <div class="soc-sea ${color.key}"><span class="soc-ico soc-${page.social.toLowerCase()}"></span>${page.owner_name_medium}</div>${param.data[1].toLocaleString('ru-RU')}
                </li>`;
              }
            });
          } else if (
            ((type === 'activity' || type === 'reach') && pages.length > 1) ||
            type === 'overview-many'
          ) {
            pages.forEach((page, i) => {
              const param = params.find((p) => p.seriesName == i.toString());
              if (param && param.data[1] != null) {
                hasData = true;
                tooltipHtml += `<li data-value-lines="${param.data[1]}">
                  <div class="soc-sea ${page.colorName}"><span class="soc-ico soc-${page.social.toLowerCase()}"></span>${page.owner_name_medium}</div>${param.data[1].toLocaleString('ru-RU')}
                </li>`;
              }
            });
          }
          tooltipHtml += '</ul></div>';
          return hasData ? tooltipHtml : '';
        },
      },
      series: [],
    });

    if (type === 'overview-one') {
      option.color = [color.value];
    } else if ((type === 'activity' || type === 'reach') && pages.length === 1) {
      option.color = [color.value];
    }

    if (data.length) {
      pages.forEach((page, i) => {
        if (data.some((item) => item[`${name}_${i}`])) {
          let curPageColor;

          if (((type === 'activity' || type === 'reach') && pages.length > 1) || type === 'overview-many') {
            curPageColor = page.colorCode;
          } else {
            curPageColor = color?.value || page.colorCode || '#000'; // Default to black
          }

          const seriesItem = {
            name: i.toString(),
            type: 'line',
            data: data.map((item) => [item.date, item[`${name}_${i}`]]),
            smooth: 0.4,
            connectNulls: true,
            lineStyle: {
              width: 1,
              color: curPageColor,
            },
            itemStyle: {
              color: curPageColor,
            },
            showSymbol: false,
            symbolSize: 6,
            emphasis: {
              focus: 'series',
              symbol: 'circle',
              itemStyle: {
                color: curPageColor,
                borderColor: `rgba(${echarts.color.parse(curPageColor).slice(0, 3).join(',')}, 0.3)`,
                borderWidth: 10,
                opacity: 1,
              },
            },
          };

          if (pages.length === 1) {
            seriesItem.areaStyle = {
              opacity: 1,
              //color: color?.value || page.colorCode,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: `rgba(${echarts.color.parse(curPageColor).slice(0, 3).join(',')}, 0.6)`,
                },
                {
                  offset: 1,
                  color: `rgba(${echarts.color.parse(curPageColor).slice(0, 3).join(',')}, 0.1)`,
                }
              ])
            };
          }

          option.series.push(seriesItem);
        }
      });
    }

    chart.setOption(option);
    resolve(chart);
    window.addEventListener('resize', () => {
      chart.resize();
    });
  });
};

export default create;
