<template>
  <div class="alerts-block" v-if="alerts.length > 0">
    <div v-for="alert in alerts" :key="alert.id">
      <Alert
        :id="alert.id"
        :message="alert.message"
        :isGlobal="true"
        :color="alert.color"
        :duration="alert.duration"
        @closed="removeAlert(alert)"
      />
    </div>
  </div>
</template>

<script>
import Alert from "./Alert.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "AlertManager",
  components: { Alert },
  computed: {
    ...mapState("alerts", ["alerts"]),
  },
  methods: {
    ...mapActions("alerts", ["removeAlert"]),
  },
};
</script>
