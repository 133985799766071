<template>
  <div class="drpdown mx-datepicker">
    <label @click.stop.prevent="openCalendar">{{ label }}</label>
    <span class="expand"></span>
    <div class="calendar-glob-container">
      <date-range-picker
        :autoApply="true"
        ref="picker"
        v-model="dateRange"
        @update="periodChanged"
        :date-format="dateFormat"
        :ranges="ranges"
        :min-date="minDate"
        :max-date="maxDate"
      >
        <template #ranges="{ ranges, clickRange }">
          <div class="ranges">
            <ul>
              <li
                v-for="(range, name) in ranges"
                :key="name"
                @click="clickRange(range)"
              >
                <b>{{ name }}</b>
                <small class="text-muted">
                  {{ range[0].toDateString() }} - {{ range[1].toDateString() }}
                </small>
              </li>
            </ul>
          </div>
        </template>
        <div slot="footer" class="slot">
          <div v-if="getProfile.plan === 'Basic'" class="alert basic-plan" v-html="$i18n.t(`ALERT_CALEND_BASIC`)">
          </div>
        </div>
      </date-range-picker>
    </div>
    
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import DateRangePicker from "vue2-daterange-picker";

export default {
  name: "calendar",
  components: {
    DateRangePicker,
  },
  props: {
    callback: Function,
    disabledDates: Function,
  },
  data() {
    return {
      isShowCalendar: false,
      dateRange: {
        startDate: moment().subtract(30, "days").format("YYYY-MM-DD"),
        endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    ...mapGetters(["getSelectedPages", "getPeriod", "getProfile"]),
    label() {
      return `${moment(this.getPeriod.start).format("DD/MM/YY")} - ${moment(
        this.getPeriod.end
      ).format("DD/MM/YY")}`;
    },
    minDate() {
      if (this.getProfile.plan === 'Basic') {
        return moment().subtract(14, 'days').startOf('day').toDate();
      } else {
        return null;
      }
    },
    maxDate() {
      return moment().endOf('day').toDate();
    },
    ranges() {
      return {
        [this.$t('CALEND_7')]: [
          moment().subtract(7, 'days').startOf('day').toDate(),
          moment().subtract(1, 'days').endOf('day').toDate(),
        ],
        [this.$t('CALEND_14')]: [
          moment().subtract(14, 'days').startOf('day').toDate(),
          moment().subtract(1, 'days').endOf('day').toDate(),
        ],
        [this.$t('CALEND_MONTH')]: [
          moment().startOf('month').startOf('day').toDate(),
          moment().subtract(1, 'days').endOf('day').toDate(),
        ],
      };
    },
  },
  methods: {
    ...mapMutations(["INTRO_SET_PERIOD"]),
    dateFormat(classes, date) {
      if (!classes.disabled) {
        if (this.disabledDates) classes.disabled = this.disabledDates(date);
        else classes.disabled = false;
      }
      return classes;
    },
    periodChanged(date) {
      const { startDate: start, endDate: end } = date;
      this.INTRO_SET_PERIOD({
        start: moment(start).format("YYYY-MM-DD"),
        end: moment(end).format("YYYY-MM-DD"),
      });
      if (this.callback) {
        this.callback();
      }
    },
    openCalendar() {
      this.$refs.picker.togglePicker();
    },
  },
};
</script>
