<template>
  <button
    :class="
      `btn-st-red export ${getSelectedPages.length > 0 ? '' : 'unactive'}`
    "
    @click="getSelectedPages.length === 0 && showGlobalAlert()"
  >
    <div
      class="drpdown"
      v-click-outside="
        () => {
          isReportOpen = false;
        }
      "
    >
      <input type="checkbox" name id="v1" v-model="isReportOpen" />
      <label for="v1" v-if="!isReportLoading">{{ $t('DASH_EXPORT') }} <i class="bi-download"></i></label>
      <scroll-loader
        loader-size="18"
        :loader-method="() => {}"
        :loader-enable="isReportLoading"
      ></scroll-loader>

      <ul v-if="getSelectedPages.length">
        <li
          @click="
            sendForReport('pptx');
            isReportOpen = false;
          "
        >
          {{ $t('DASH_EXP_AS') }} PPTX
        </li>
        <li
          @click="
            sendForReport('pdf');
            isReportOpen = false;
          "
        >
          {{ $t('DASH_EXP_AS') }} PDF
        </li>
        <li
          @click="
            sendForReport('xlsx');
            isReportOpen = false;
          "
        >
          {{ $t('DASH_EXP_AS') }} XLSX
        </li>
      </ul>
    </div>
  </button>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { INTRO_SEND_REPORT } from "../store/actions/intro";
import ClickOutside from "vue-click-outside";

export default {
  name: "report-button",
  computed: {
    ...mapGetters(["getSelectedPages"]),
  },
  methods: {
    ...mapActions([INTRO_SEND_REPORT]),
    async sendForReport(type) {
      if (this.isReportLoading) return;
      this.isReportLoading = true;
      try {
        await this.INTRO_SEND_REPORT(type);
      } catch (err) {
        console.log("err loading report", err);
      }
      this.isReportLoading = false;
    },
    showGlobalAlert() {
      this.$alert.show({
        message: this.$t("DASH_EXPORT_NO_PAGES"),
        type: "global",
        color: 'black'
      });
    },
  },
  mounted() {
    this.popupItem = this.$el;
  },
  data() {
    return {
      isReportOpen: false,
      isReportLoading: false,
    };
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style></style>
