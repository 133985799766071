const translations = {
  "greeting": {
    "en": 'Hello, {name}!',
    "ru": 'Привет, {name}!',
  },
  "farewell": {
    "en": 'Goodbye, {name}!',
    "ru": 'До свидания, {name}!',
  },
  "AUTH_ERROR_LOGIN_FAILED_WRONG_PASS": {
    "en": "Wrong password, try again.",
    "ru": "Неверный пароль, попробуйте снова.",
    "es": "Contraseña incorrecta, inténtalo de nuevo.",
    "pt": "Senha errada, tente novamente.",
    "de": "Falsches Passwort, versuchen Sie es erneut."
  },
  "AUTH_ERROR_LOGIN_FAILED_USER_REGISTRATION_NOT_COMPLETED": {
    "en": "Registration is not complete. Check email.",
    "ru": "Регистрация не завершена. Проверьте электронную почту.",
    "es": "La registración no está completa. Verifica tu correo electrónico.",
    "pt": "O registro não está completo. Verifique o e-mail.",
    "de": "Registrierung ist nicht abgeschlossen. Überprüfen Sie die E-Mail."
  },
  "FORGOT_FAILED_USER_NOT_FOUND": {
    "en": "User not found",
    "ru": "Пользователь не найден",
    "es": "Usuario no encontrado",
    "pt": "Usuário não encontrado",
    "de": "Benutzer nicht gefunden"
  },
  "AUTH_ERROR_REGISTER_FAILED_USER_EXISTS": {
    "en": "User already exists.",
    "ru": "Пользователь уже зарегистрирован.",
    "es": "El usuario ya está registrado.",
    "pt": "Usuário já registrado.",
    "de": "Benutzer ist bereits registriert."
  },
  "AUTH_COMMON_DONT_HAVE_ACCOUNT": {
    "en": "Don't have an account?",
    "ru": "Нет аккаунта?",
    "es": "¿No tienes una cuenta?",
    "pt": "Não tem uma conta?",
    "de": "Haben Sie kein Konto?"
  },
  "AUTH_COMMON_EMAIL": {
    "en": "Email",
    "ru": "Email",
    "es": "E-mail",
    "pt": "E-mail",
    "de": "E-Mail"
  },
  "AUTH_COMMON_PASSWORD": {
    "en": "Password",
    "ru": "Пароль",
    "es": "Contraseña",
    "pt": "Senha",
    "de": "Passwort"
  },
  "AUTH_COMMON_CONFIRM": {
    "en": "Confirm password",
    "ru": "Подтвердите пароль",
    "es": "Confirmar contraseña",
    "pt": "Confirme a senha",
    "de": "Passwort bestätigen"
  },
  "AUTH_COMMON_SIGNIN_TO": {
    "en": "Sign in",
    "ru": "Войти",
    "es": "Iniciar sesión",
    "pt": "Entrar",
    "de": "Anmelden"
  },
  "AUTH_COMMON_SIGN_UP": {
    "en": "Sign up",
    "ru": "Зарегистрироваться",
    "es": "Registrarse",
    "pt": "Inscrever-se",
    "de": "Registrieren"
  },
  "AUTH_COMMON_ENTER": {
    "en": "Sign in",
    "ru": "Войти",
    "es": "Iniciar sesión",
    "pt": "Iniciar sessão",
    "de": "Anmelden"
  },
  "AUTH_COMMON_CONTINUE": {
    "en": "Continue",
    "ru": "Продолжить",
    "es": "Continuar",
    "pt": "Continuar",
    "de": "Weitermachen"
  },
  "AUTH_COMMON_VALID_EMAIL": {
    "en": "Should be valid email.",
    "ru": "Должен быть действительный адрес электронной почты.",
    "es": "Debe ser un correo electrónico válido.",
    "pt": "Deve ser um email válido.",
    "de": "Sollte eine gültige E-Mail sein."
  },
  "AUTH_COMMON_VALID_PASSWORD": {
    "en": "Password should contain at least 7 symbols and no space characters.",
    "ru": "Пароль должен содержать не менее 7 символов и не содержать пробелов.",
    "es": "La contraseña debe contener al menos 7 símbolos y no caracteres de espacio.",
    "pt": "A senha deve conter pelo menos 7 símbolos e nenhum caractere de espaço.",
    "de": "Das Passwort sollte mindestens 7 Symbole enthalten und keine Leerzeichen."
  },
  "AUTH_SIGNUP_CREATE": {
    "en": "Create your account",
    "ru": "Создайте свой аккаунт",
    "es": "Crea tu cuenta",
    "pt": "Crie sua conta",
    "de": "Erstelle deinen Account"
  },
  "AUTH_SIGNUP_ALREADY_HAVE": {
    "en": "Already have an account?",
    "ru": "Уже есть аккаунт?",
    "es": "¿Ya tienes una cuenta?",
    "pt": "Já tem uma conta?",
    "de": "Haben Sie schon ein Konto?"
  },
  "AUTH_SIGNUP_SENT": {
    "en": "Activation mail has been sent. Check your email",
    "ru": "Письмо с активацией отправлено. Проверьте свою электронную почту",
    "es": "El correo de activación ha sido enviado. Verifica tu correo electrónico",
    "pt": "O e-mail de ativação foi enviado. Verifique seu e-mail",
    "de": "Aktivierungsmail wurde gesendet. Überprüfen Sie Ihre E-Mails"
  },
  "AUTH_SIGNUP_NOT_EQUAL": {
    "en": "Passwords are not the same.",
    "ru": "Пароли не совпадают.",
    "es": "Las contraseñas no son las mismas.",
    "pt": "As senhas não são iguais.",
    "de": "Passwörter sind nicht gleich."
  },
  "AUTH_SIGNIN_SIGNIN_TO": {
    "en": "Sign in to your account",
    "ru": "Войдите в свой аккаунт",
    "es": "Inicia sesión en tu cuenta",
    "pt": "Entre na sua conta",
    "de": "Melden Sie sich bei Ihrem Konto an"
  },
  "AUTH_COMMON_FORGOT_YOUR_PASS": {
    "en": "Forgot your password?",
    "ru": "Забыли пароль?",
    "es": "¿Olvidaste tu contraseña?",
    "pt": "Esqueceu sua senha?",
    "de": "Passwort vergessen?"
  },
  "AUTH_SIGNIN_STAY_IN": {
    "en": "Stay signed in",
    "ru": "Оставаться в системе",
    "es": "Permanecer conectado",
    "pt": "Permanecer conectado",
    "de": "Angemeldet bleiben"
  },
  "AUTH_FORGOT_RECOVERY": {
    "en": "Password recovery",
    "ru": "Восстановление пароля",
    "es": "Recuperación de contraseña",
    "pt": "Recuperação de senha",
    "de": "Passwort-Wiederherstellung"
  },
  "AUTH_FORGOT_RESET": {
    "en": "To reset your password, enter the email associated with your account.",
    "ru": "Чтобы сбросить пароль, введите адрес электронной почты, связанный с вашей учетной записью.",
    "es": "Para restablecer tu contraseña, ingresa el correo electrónico asociado a tu cuenta.",
    "pt": "Para redefinir sua senha, insira o e-mail associado à sua conta.",
    "de": "Um Ihr Passwort zurückzusetzen, geben Sie die E-Mail-Adresse ein, die mit Ihrem Konto verbunden ist."
  },
  "AUTH_FORGOT_SENT": {
    "en": "Request for change password sent to your email.",
    "ru": "Запрос на изменение пароля отправлен на вашу электронную почту.",
    "es": "Solicitud para cambiar la contraseña enviada a tu correo electrónico.",
    "pt": "Solicitação para alterar a senha enviada para o seu e-mail.",
    "de": "Anfrage zur Passwortänderung an Ihre E-Mail gesendet."
  },
  "AUTH_CHANGE_NEW_PASS": {
    "en": "Write your new password",
    "ru": "Введите новый пароль",
    "es": "Escribe tu nueva contraseña",
    "pt": "Escreva sua nova senha",
    "de": "Schreiben Sie Ihr neues Passwort"
  },
  "AUTH_CHANGE_PASSWORD": {
    "en": "Enter new password",
    "ru": "Введите новый пароль",
    "es": "Ingrese una nueva contraseña",
    "pt": "Digite uma nova senha",
    "de": "Neues Passwort eingeben"
  },
  "AUTH_CHANGE_CONFIRM": {
    "en": "Confirm",
    "ru": "Подтвердите",
    "es": "Confirmar",
    "pt": "Confirmar",
    "de": "Bestätigen"
  },
  "AUTH_CHANGE_SENT": {
    "en": "Your password was changed. You will be redirected to login. Or you can do it directly by link",
    "ru": "Ваш пароль был изменен. Вы будете перенаправлены на страницу входа. Или вы можете сделать это по ссылке",
    "es": "Tu contraseña fue cambiada. Serás redirigido a iniciar sesión. O puedes hacerlo directamente por enlace",
    "pt": "Sua senha foi alterada. Você será redirecionado para o login. Ou você pode fazer isso diretamente por link",
    "de": "Ihr Passwort wurde geändert. Sie werden zum Login weitergeleitet. Oder Sie können es direkt per Link tun"
  },
  "AUTH_ACTIVATE_ACTIVATING": {
    "en": "Account is activating",
    "ru": "Аккаунт активируется",
    "es": "La cuenta se está activando",
    "pt": "A conta está ativando",
    "de": "Konto wird aktiviert"
  },
  "AUTH_ACTIVATE_ACTIVATED": {
    "en": "Your account is activated. You will be redirected to login page",
    "ru": "Ваш аккаунт активирован. Вы будете перенаправлены на страницу входа",
    "es": "Tu cuenta está activada. Serás redirigido a la página de inicio de sesión",
    "pt": "Sua conta está ativada. Você será redirecionado para a página de login",
    "de": "Ihr Konto ist aktiviert. Sie werden zur Login-Seite weitergeleitet"
  },
  "AUTH_ACTIVATE_NO_REDIRECT": {
    "en": "If it hasn't happened, click to",
    "ru": "Если этого не произошло, нажмите на",
    "es": "Si no ha sucedido, haz clic en",
    "pt": "Se isso não aconteceu, clique em",
    "de": "Wenn es nicht passiert ist, klicken Sie auf"
  },
  "HEADER_SEARCH": {
    "en": "Filter pages",
    "ru": "Фильтр страниц",
    "es": "Filtrar páginas",
    "pt": "Filtrar páginas",
    "de": "Seiten filtern"
  },
  "HEADER_ACCESS_MANAGE": {
    "en": "Manage accesses",
    "ru": "Управление доступом",
    "es": "Gestionar accesos",
    "pt": "Gerenciar acessos",
    "de": "Zugriffsrechte-Manager"
  },
  "HEADER_REPORTS_MANAGE": {
    "en": "Manage reports",
    "ru": "Управление отчетами",
    "es": "Gestionar informes",
    "pt": "Gerenciar relatórios",
    "de": "Berichte verwalten"
  },
  "HEADER_LOG_OUT": {
    "en": "Log out",
    "ru": "Выйти",
    "es": "Cerrar sesión",
    "pt": "Sair",
    "de": "Abmelden"
  },
  "INTRO_START_EMPTY_LIST": {
    "en": "The pages list is empty",
    "ru": "Список страниц пуст",
    "es": "La lista de páginas está vacía",
    "pt": "A lista de páginas está vazia",
    "de": "Die Seitenliste ist leer"
  },
  "INTRO_START_START_JOURNEY": {
    "en": "Start using Lucmus right now with adding first page",
    "ru": "Начните использовать Lucmus прямо сейчас, добавив первую страницу",
    "es": "Comience a usar Lucmus ahora mismo agregando la primera página",
    "pt": "Comece a usar o Lucmus agora mesmo adicionando a primeira página",
    "de": "Beginnen Sie jetzt mit der Nutzung von Lucmus, indem Sie die erste Seite hinzufügen"
  },
  "INTRO_START_CURRENTLY_PLAN": {
    "en": "You don't have any active plan. Go to the Pricing page to choose convenient option or activate trial access.",
    "ru": "У вас нет активного тарифа. Перейдите на страницу Тарифов, чтобы выбрать удобный вариант или активировать тестовый доступ.",
    "es": "No tienes ningún plan activo. Ve a la página de Precios para elegir una opción conveniente o activa el acceso de prueba.",
    "pt": "Você não tem nenhum plano ativo. Vá para a página de Preços para escolher uma opção conveniente ou ative o acesso de teste.",
    "de": "Sie haben keinen aktiven Plan. Gehen Sie zur Preisseite, um eine bequeme Option auszuwählen oder den Testzugang zu aktivieren."
  },
  "INTRO_START_PRICING": {
    "en": "See our pricing plans",
    "ru": "Посмотрите наши тарифные планы",
    "es": "Ver nuestros planes de precios",
    "pt": "Veja nossos planos de preços",
    "de": "Sehen Sie sich unsere Preispläne an"
  },
  "INTRO_START_ADD_PAGE": {
    "en": "+ Add page",
    "ru": "+ Добавить страницу",
    "es": "+ Agregar página",
    "pt": "+ Adicionar página",
    "de": "+ Seite hinzufügen"
  },
  "INTRO_MAIN_SOCIAL_MEDIA": {
    "en": "Social media",
    "ru": "Социальные сети",
    "es": "Redes sociales",
    "pt": "Mídia social",
    "de": "Soziale Medien"
  },
  "INTRO_MAIN_DISPLAYING_BY": {
    "en": "Displaying:",
    "ru": "Группировка:",
    "es": "Mostrando:",
    "pt": "Exibindo:",
    "de": "Anzeigen:"
  },
  "INTRO_MAIN_BY_NAME": {
    "en": "By Name",
    "ru": "По имени",
    "es": "Por nombre",
    "pt": "Por nome",
    "de": "Nach Name"
  },
  "INTRO_MAIN_BY_SOCIAL": {
    "en": "By Social",
    "ru": "По соц. сети",
    "es": "Por social",
    "pt": "Por social",
    "de": "Nach sozial"
  },
  "INTRO_MAIN_BY_OWNER": {
    "en": "By Owner",
    "ru": "По владельцу",
    "es": "Por propietario",
    "pt": "Por proprietário",
    "de": "Nach Besitzer"
  },
  "INTRO_TABS_NAME": {
    "en": "Page name",
    "ru": "Страница",
    "es": "Nombre de la página",
    "pt": "Nome da página",
    "de": "Seitenname"
  },
  "INTRO_TABS_FOLLOWERS": {
    "en": "Followers",
    "ru": "Подписчики",
    "es": "Seguidores",
    "pt": "Seguidores",
    "de": "Anhänger"
  },
  "INTRO_TABS_GROWTH": {
    "en": "Growth",
    "ru": "Рост",
    "es": "Crecimiento",
    "pt": "Crescimento",
    "de": "Wachstum"
  },
  "INTRO_TABS_REACH": {
    "en": "Reach",
    "ru": "Охват",
    "es": "Alcance",
    "pt": "Alcance",
    "de": "Erreichen"
  },
  "INTRO_TABS_ER": {
    "en": "ER",
    "ru": "ER",
    "es": "ER",
    "pt": "ER",
    "de": "ER"
  },
  "INTRO_TABS_SOCIAL": {
    "en": "Social",
    "ru": "Соц. сеть",
    "es": "Social",
    "pt": "Social",
    "de": "Sozial"
  },
  "INTRO_TABS_LINK": {
    "en": "Link",
    "ru": "Ссылка",
    "es": "Enlace",
    "pt": "Link",
    "de": "Verknüpfung"
  },
  "INTRO_TABS_DELETE": {
    "en": "Delete",
    "ru": "Удалить",
    "es": "Eliminar",
    "pt": "Excluir",
    "de": "Löschen"
  },
  "INTRO_NOTIFICATIONS_NOTIFICATION_TITLE": {
    "en": "Analysis is completed.",
    "ru": "Анализ завершен.",
    "es": "El análisis está completo.",
    "pt": "A análise está concluída.",
    "de": "Analyse ist abgeschlossen."
  },
  "DASHBOARD_TABS_OVERVIEW": {
    "en": "Overview",
    "ru": "Обзор",
    "es": "Visión general",
    "pt": "Visão geral",
    "de": "Überblick"
  },
  "DASHBOARD_TABS_ACTIVITY": {
    "en": "Activity",
    "ru": "Активность",
    "es": "Actividad",
    "pt": "Atividade",
    "de": "Aktivität"
  },
  "DASHBOARD_TABS_POSTS_STATS": {
    "en": "Posts stats",
    "ru": "Статистика постов",
    "es": "Estadísticas de publicaciones",
    "pt": "Estatísticas de postagens",
    "de": "Beitragsstatistiken"
  },
  "DASHBOARD_TABS_REACH": {
    "en": "Reach",
    "ru": "Охват",
    "es": "Alcance",
    "pt": "Alcance",
    "de": "Reichweite"
  },
  "DASHBOARD_TABS_AUDIENCE": {
    "en": "Audience",
    "ru": "Аудитория",
    "es": "Audiencia",
    "pt": "Audiência",
    "de": "Publikum"
  },
  "DASHBOARD_TABS_POSTS_FEED": {
    "en": "Posts feed",
    "ru": "Лента постов",
    "es": "Feed de publicaciones",
    "pt": "Feed de postagens",
    "de": "Beitrags-Feed"
  },
  "DASHBOARD_TABS_STORIES_FEED": {
    "en": "Stories feed",
    "ru": "Лента историй",
    "es": "Feed de historias",
    "pt": "Feed de histórias",
    "de": "Story-Feed"
  },
  "DASHBOARD_ACTIVITY_CHARTS_likes": {
    "en": "Likes",
    "ru": "Лайки",
    "es": "Me gusta",
    "pt": "Curtidas",
    "de": "Likes"
  },
  "DASHBOARD_ACTIVITY_CHARTS_comments": {
    "en": "Comments",
    "ru": "Комментарии",
    "es": "Comentarios",
    "pt": "Comentários",
    "de": "Kommentare"
  },
  "DASHBOARD_ACTIVITY_CHARTS_reposts": {
    "en": "Shares",
    "ru": "Репосты",
    "es": "Compartidos",
    "pt": "Compartilhamentos",
    "de": "Teilen"
  },
  "DASHBOARD_ACTIVITY_CHARTS_views": {
    "en": "Views",
    "ru": "Просмотры",
    "es": "Vistas",
    "pt": "Visualizações",
    "de": "Ansichten"
  },
  "DASHBOARD_ACTIVITY_CHARTS_posts": {
    "en": "Posts",
    "ru": "Посты",
    "es": "Publicaciones",
    "pt": "Postagens",
    "de": "Beiträge"
  },
  "DASHBOARD_ACTIVITY_CHARTS_stories": {
    "en": "Stories",
    "ru": "Истории",
    "es": "Historias",
    "pt": "Histórias",
    "de": "Geschichten"
  },
  "DASHBOARD_ACTIVITY_CHARTS_interactions": {
    "en": "Interactions",
    "ru": "Взаимодействия",
    "es": "Interacciones",
    "pt": "Interações",
    "de": "Interaktionen"
  },
  "DASHBOARD_ACTIVITY_CHARTS_subunsub": {
    "en": "Subscribed / Unsubscribed",
    "ru": "Подписки / Отписки",
    "es": "Suscrito / No suscrito",
    "pt": "Inscrito / Não inscrito",
    "de": "Abonniert / Abgemeldet"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_textLenCount": {
    "en": "Count by text length",
    "ru": "Количество по длине текста",
    "es": "Contar por longitud del texto",
    "pt": "Contagem por comprimento de texto",
    "de": "Anzahl nach Textlänge"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_textLenEr": {
    "en": "ER by text length",
    "ru": "ER по длине текста",
    "es": "ER por longitud del texto",
    "pt": "ER por comprimento de texto",
    "de": "ER nach Textlänge"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_contentTypesCount": {
    "en": "Count by content type",
    "ru": "Количество по типу контента",
    "es": "Contar por tipo de contenido",
    "pt": "Contagem por tipo de conteúdo",
    "de": "Anzahl nach Inhaltstyp"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_textLenActivity": {
    "en": "Text length relative activity",
    "ru": "Относительная активность по длине текста",
    "es": "Actividad relativa por longitud del texto",
    "pt": "Atividade relativa por comprimento de texto",
    "de": "Relative Aktivität nach Textlänge"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_contentTypesEr": {
    "en": "ER by content type",
    "ru": "ER по типу контента",
    "es": "ER por tipo de contenido",
    "pt": "ER por tipo de conteúdo",
    "de": "ER nach Inhaltstyp"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_contentTypesActivity": {
    "en": "Content type relative activity",
    "ru": "Относительная активность по типу контента",
    "es": "Actividad relativa por tipo de contenido",
    "pt": "Atividade relativa por tipo de conteúdo",
    "de": "Relative Aktivität nach Inhaltstyp"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_hourActivity": {
    "en": "Activity by hours of day",
    "ru": "Активность по часам суток",
    "es": "Actividad por horas del día",
    "pt": "Atividade por horas do dia",
    "de": "Aktivität nach Stunden des Tages"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_weekActivity": {
    "en": "Activity by days of week",
    "ru": "Активность по дням недели",
    "es": "Actividad por días de la semana",
    "pt": "Atividade por dias da semana",
    "de": "Aktivität nach Wochentagen"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_activityHoursTextLen": {
    "en": "Text length activity by hours of day",
    "ru": "Активность по длине текста по часам суток",
    "es": "Actividad por longitud del texto por horas del día",
    "pt": "Atividade por comprimento de texto por horas do dia",
    "de": "Aktivität nach Textlänge nach Stunden des Tages"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_activityWeeksTextLen": {
    "en": "Text length activity by days of week",
    "ru": "Активность по длине текста по дням недели",
    "es": "Actividad por longitud del texto por días de la semana",
    "pt": "Atividade por comprimento de texto por dias da semana",
    "de": "Aktivität nach Textlänge nach Wochentagen"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_erHoursTextLen": {
    "en": "Text length ER by hours of day",
    "ru": "ER по длине текста по часам суток",
    "es": "ER por longitud del texto por horas del día",
    "pt": "ER por comprimento de texto por horas do dia",
    "de": "ER nach Textlänge nach Stunden des Tages"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_erWeeksTextLen": {
    "en": "Text length ER by days of week",
    "ru": "ER по длине текста по дням недели",
    "es": "ER por longitud del texto por días de la semana",
    "pt": "ER por comprimento de texto por dias da semana",
    "de": "ER nach Textlänge nach Wochentagen"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_hashTagCount": {
    "en": "Tags count",
    "ru": "Количество тегов",
    "es": "Conteo de etiquetas",
    "pt": "Contagem de tags",
    "de": "Anzahl der Tags"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_hashTagErAvg": {
    "en": "ER by tags",
    "ru": "ER по тегам",
    "es": "ER por etiquetas",
    "pt": "ER por tags",
    "de": "ER nach Tags"
  },
  "DASHBOARD_POSTS_STATS_CHARTS_hashTagActivityAvg": {
    "en": "Tags activity",
    "ru": "Активность тегов",
    "es": "Actividad de etiquetas",
    "pt": "Atividade de tags",
    "de": "Aktivität der Tags"
  },
  "DASHBOARD_REACH_CHARTS_reach": {
    "en": "Reach",
    "ru": "Охват",
    "es": "Alcance",
    "pt": "Alcance",
    "de": "Reichweite"
  },
  "DASHBOARD_REACH_CHARTS_reach_subscribers": {
    "en": "Reach followers",
    "ru": "Охват подписчиков",
    "es": "Alcanzar seguidores",
    "pt": "Alcance de seguidores",
    "de": "Follower erreichen"
  },
  "DASHBOARD_REACH_CHARTS_mobile_reach": {
    "en": "Mobile Reach",
    "ru": "Мобильный охват",
    "es": "Alcance móvil",
    "pt": "Alcance móvel",
    "de": "Mobile Reichweite"
  },
  "DASHBOARD_AUDIENCE_CHARTS_COUNTRIES": {
    "en": "By countries",
    "ru": "По странам",
    "es": "Por países",
    "pt": "Por países",
    "de": "Nach Ländern"
  },
  "DASHBOARD_AUDIENCE_CHARTS_AGES": {
    "en": "By ages",
    "ru": "По возрастам",
    "es": "Por edades",
    "pt": "Por idades",
    "de": "Nach Altersgruppen"
  },
  "DASHBOARD_DELETED": {
    "en": "Deleted pages",
    "ru": "Удаленные страницы",
    "es": "Páginas eliminadas",
    "pt": "Páginas excluídas",
    "de": "Gelöschte Seiten"
  },
  "DASHBOARD_DELETED_DISCLAIMER": {
    "en": "Deleted pages data will be permanently removed after 24 hours if not restored",
    "ru": "Данные удаленных страниц будут безвозвратно удалены через 24 часа, если не восстановить",
    "es": "Los datos de las páginas eliminadas se eliminarán permanentemente después de 24 horas si no se restauran",
    "pt": "Os dados das páginas excluídas serão removidos permanentemente após 24 horas se não forem restaurados",
    "de": "Die Daten gelöschter Seiten werden nach 24 Stunden dauerhaft gelöscht, wenn sie nicht wiederhergestellt werden"
  },
  "DASHBOARD_AUDIENCE_CHARTS_ONLINE": {
    "en": "Online heatmap",
    "ru": "Онлайн",
    "es": "Online",
    "pt": "Online",
    "de": "Online"
  },
  "ADD_PAGE_SEARCH": {
    "en": "Search",
    "ru": "Поиск",
    "es": "Buscar",
    "pt": "Pesquisar",
    "de": "Suche"
  },
  "ADD_PAGE_FOUND": {
    "en": "FOUND",
    "ru": "НАЙДЕНО",
    "es": "ENCONTRADO",
    "pt": "ENCONTRADO",
    "de": "GEFUNDEN"
  },
  "ADD_PAGE_WANT_ADD": {
    "en": "List of pages you probably want to add",
    "ru": "Список страниц, которые вы, возможно, хотите добавить",
    "es": "Lista de páginas que probablemente desees agregar",
    "pt": "Lista de páginas que você provavelmente deseja adicionar",
    "de": "Liste von Seiten, die Sie wahrscheinlich hinzufügen möchten"
  },
  "ADD_PAGE_SHOW_PREVIOUS": {
    "en": "Show previous",
    "ru": "Показать предыдущие",
    "es": "Mostrar anteriores",
    "pt": "Mostrar anteriores",
    "de": "Vorherige anzeigen"
  },
  "ADD_PAGE_SELECT_PAGE": {
    "en": "Add",
    "ru": "Добавить",
    "es": "Agregar",
    "pt": "Adicionar",
    "de": "Hinzufügen"
  },
  "ADD_PAGE_DELETE_PAGE": {
    "en": "Delete",
    "ru": "Удалить",
    "es": "Eliminar",
    "pt": "Excluir",
    "de": "Löschen"
  },
  "ADD_PAGE_SELECTED": {
    "en": "Selected",
    "ru": "Выбрано",
    "es": "Seleccionado",
    "pt": "Selecionado",
    "de": "Ausgewählt"
  },
  "ADD_PAGE_SHOW_NEXT": {
    "en": "Show next",
    "ru": "Показать следующие",
    "es": "Mostrar siguiente",
    "pt": "Mostrar próximo",
    "de": "Nächste anzeigen"
  },
  "ADD_PAGE_SIGN_IN_TOKENS": {
    "en": "Sign in with socials to connect the pages to your profile:",
    "ru": "Войдите через социальные сети, чтобы подключить страницы к своему профилю:",
    "es": "Inicia sesión con redes sociales para conectar las páginas a tu perfil:",
    "pt": "Faça login com redes sociais para conectar as páginas ao seu perfil:",
    "de": "Melden Sie sich mit sozialen Netzwerken an, um die Seiten mit Ihrem Profil zu verbinden:"
  },
  "ADD_PAGE_CURRENT_ACCOUNT": {
    "en": "Using account:",
    "ru": "Используемый аккаунт:",
    "es": "Usando cuenta:",
    "pt": "Usando conta:",
    "de": "Verwendetes Konto:"
  },
  "ADD_PAGE_COUNT_TO_ADD": {
    "en": "ADD {0} PAGE",
    "ru": "ДОБАВИТЬ {0} СТРАНИЦУ",
    "es": "AGREGAR {0} PÁGINA",
    "pt": "ADICIONAR {0} PÁGINA",
    "de": "{0} SEITE HINZUFÜGEN"
  },
  "ADD_PAGE_WAS_SELECTED": {
    "en": "Selected",
    "ru": "Выбрано",
    "es": "Seleccionado",
    "pt": "Selecionado",
    "de": "Ausgewählt"
  },
  "ADD_PAGE_PUT_URL": {
    "en": "Put the URL of the page",
    "ru": "Введите URL страницы",
    "es": "Pon la URL de la página",
    "pt": "Coloque o URL da página",
    "de": "Geben Sie die URL der Seite ein"
  },
  "ADD_PAGE_ERROR_NOTIFICATION_ERROR_FOUNDED_ACCOUNT_NOT_BUSINESS": {
    "en": "It's a private profile and can't be analyzed.",
    "ru": "Это частный профиль, и его нельзя проанализировать.",
    "es": "Es un perfil privado y no se puede analizar.",
    "pt": "É um perfil privado e não pode ser analisado.",
    "de": "Es ist ein privates Profil und kann nicht analysiert werden."
  },
  "ADD_PAGE_ERROR_NOTIFICATION_ERROR_UNEXPECTED": {
    "en": "Something happen with find this page. We will fix it ASAP.",
    "ru": "Что-то пошло не так при поиске этой страницы. Мы исправим это как можно скорее.",
    "es": "Ocurrió algo al encontrar esta página. Lo solucionaremos lo antes posible.",
    "pt": "Algo aconteceu com encontrar esta página. Vamos consertar o mais rápido possível.",
    "de": "Beim Finden dieser Seite ist etwas passiert. Wir werden es so schnell wie möglich beheben."
  },
  "ADD_PAGE_ERROR_NOTIFICATION_ERROR_FOUNDED_FB_GROUPS": {
    "en": "Fb groups cant be analyzed.",
    "ru": "Группы Fb не могут быть проанализированы.",
    "es": "No se pueden analizar los grupos de Fb.",
    "pt": "Grupos do Fb não podem ser analisados.",
    "de": "Fb-Gruppen können nicht analysiert werden."
  },
  "ADD_PAGE_ERROR_NOTIFICATION_ERROR_ACCOUNT_NO_BUSINESS": {
    "en": "Your account is not business. Change it in instagram settings and try again.",
    "ru": "Ваш аккаунт не бизнес. Измените это в настройках Instagram и попробуйте снова.",
    "es": "Tu cuenta no es de negocios. Cámbialo en la configuración de Instagram y vuelve a intentarlo.",
    "pt": "Sua conta não é comercial. Altere isso nas configurações do Instagram e tente novamente.",
    "de": "Ihr Konto ist kein Geschäftskonto. Ändern Sie dies in den Instagram-Einstellungen und versuchen Sie es erneut."
  },
  "ACCESS_MANAGER_RIGHTS_MANAGER": {
    "en": "Access rights",
    "ru": "Доступы",
    "es": "Gerente de derechos de acceso",
    "pt": "Gerenciador de direitos de acesso",
    "de": "Zugriffsrechte-Manager"
  },
  "ACCESS_MANAGER_GRANT_ACCESS": {
    "en": "Grant access",
    "ru": "Предоставить доступ",
    "es": "Conceder acceso",
    "pt": "Conceder acesso",
    "de": "Zugriff gewähren"
  },
  "ACCESS_MANAGER_FULL_ACCESS": {
    "en": "Full access",
    "ru": "Полный доступ",
    "es": "Acceso completo",
    "pt": "Acesso total",
    "de": "Voller Zugriff"
  },
  "ACCESS_MANAGER_READ_ONLY": {
    "en": "Read only",
    "ru": "Только чтение",
    "es": "Solo lectura",
    "pt": "Apenas leitura",
    "de": "Nur lesen"
  },
  "ACCESS_MANAGER_EMAIL": {
    "en": "Email",
    "ru": "E-mail",
    "es": "E-mail",
    "pt": "E-mail",
    "de": "E-Mail"
  },
  "ACCESS_MANAGER_ACCESS": {
    "en": "Access",
    "ru": "Доступ",
    "es": "Acceso",
    "pt": "Acesso",
    "de": "Zugriff"
  },
  "ACCESS_MANAGER_ACTION": {
    "en": "Action",
    "ru": "Действие",
    "es": "Acción",
    "pt": "Ação",
    "de": "Aktion"
  },
  "ACCESS_MANAGER_REMOVE_ACCESS": {
    "en": "Remove access",
    "ru": "Удалить доступ",
    "es": "Eliminar acceso",
    "pt": "Remover acesso",
    "de": "Zugriff entfernen"
  },
  "ACCESS_MANAGER_GIVE_ACCESS": {
    "en": "Add",
    "ru": "Добавить",
    "es": "Dar acceso",
    "pt": "Dar acesso",
    "de": "Zugriff geben"
  },
  "ACCESS_MANAGER_CLOSE": {
    "en": "Close",
    "ru": "Закрыть",
    "es": "Cerrar",
    "pt": "Fechar",
    "de": "Schließen"
  },
  "COMMON_SOCIALS_IG": {
    "en": "Instagram",
    "ru": "Instagram",
    "es": "Instagram",
    "pt": "Instagram",
    "de": "Instagram"
  },
  "COMMON_SOCIALS_FB": {
    "en": "Facebook",
    "ru": "Facebook",
    "es": "Facebook",
    "pt": "Facebook",
    "de": "Facebook"
  },
  "COMMON_SOCIALS_VK": {
    "en": "VK",
    "ru": "VK",
    "es": "VK",
    "pt": "VK",
    "de": "VK"
  },
  "ERRORS_PAGE_LIMIT": {
    "en": "You can't compare more than 5 pages at once",
    "ru": "Вы не можете сравнивать более 5 страниц одновременно",
    "es": "No puedes comparar más de 5 páginas a la vez",
    "pt": "Você não pode comparar mais de 5 páginas de uma vez",
    "de": "Sie können nicht mehr als 5 Seiten gleichzeitig vergleichen"
  },
  "STATS_POSTS_ER_POST": {
    "en": "ER post, %",
    "ru": "ER post, %",
    "es": "ER post, %",
    "pt": "ER post, %",
    "de": "ER post, %"
  },
  "STATS_POSTS_ER_VIEW": {
    "en": "ER view, %",
    "ru": "ER view, %",
    "es": "ER view, %",
    "pt": "ER view, %",
    "de": "ER view, %"
  },
  "STATS_POSTS_IR": {
    "en": "IR, %",
    "ru": "IR, %",
    "es": "IR, %",
    "pt": "IR, %",
    "de": "IR, %"
  },
  "STATS_POSTS_RICH_VIRAL": {
    "en": "Reach viral",
    "ru": "Вирусный охват",
    "es": "Alcance viral",
    "pt": "Alcance viral",
    "de": "Virale Reichweite"
  },
  "STATS_POSTS_RICH_SUBSCR": {
    "en": "Reach subscr",
    "ru": "Охват подписчиков",
    "es": "Alcance suscriptores",
    "pt": "Alcance de assinantes",
    "de": "Abonnentenreichweite"
  },
  "STATS_STORIES_REPLIES": {
    "en": "Replies",
    "ru": "Ответы",
    "es": "Respuestas",
    "pt": "Respostas",
    "de": "Antworten"
  },
  "STATS_STORIES_TAPS_FORWARD": {
    "en": "Forward",
    "ru": "Вперед",
    "es": "Adelante",
    "pt": "Avançar",
    "de": "Vorwärts"
  },
  "STATS_STORIES_TAPS_BACK": {
    "en": "Back",
    "ru": "Назад",
    "es": "Atrás",
    "pt": "Voltar",
    "de": "Zurück"
  },
  "STATS_STORIES_EXITS": {
    "en": "Exited",
    "ru": "Выходы",
    "es": "Salidas",
    "pt": "Saídas",
    "de": "Ausgestiegen"
  },
  "STATS_STORIES_REACH": {
    "en": "Reached",
    "ru": "Охвачено",
    "es": "Alcanzado",
    "pt": "Alcançado",
    "de": "Erreicht"
  },
  "STATS_STORIES_VIEWS": {
    "en": "Views",
    "ru": "Просмотры",
    "es": "Vistas",
    "pt": "Visualizações",
    "de": "Ansichten"
  },
  "STATS_TABLE_TOTAL_ALL_LIKES": {
    "en": "Likes",
    "ru": "Лайки",
    "es": "Me gusta",
    "pt": "Curtidas",
    "de": "Gefällt mir"
  },
  "STATS_TABLE_TOTAL_ALL_REPOSTS": {
    "en": "Shares",
    "ru": "Поделились",
    "es": "Compartidos",
    "pt": "Compartilhamentos",
    "de": "Geteilt"
  },
  "STATS_TABLE_TOTAL_ALL_COMMENTS": {
    "en": "Comments",
    "ru": "Комментарии",
    "es": "Comentarios",
    "pt": "Comentários",
    "de": "Kommentare"
  },
  "STATS_TABLE_TOTAL_ALL_VIEWS": {
    "en": "Views",
    "ru": "Просмотры",
    "es": "Vistas",
    "pt": "Visualizações",
    "de": "Ansichten"
  },
  "STATS_TABLE_TOTAL_ALL_RECORDS": {
    "en": "Posts",
    "ru": "Публикации",
    "es": "Publicaciones",
    "pt": "Postagens",
    "de": "Beiträge"
  },
  "STATS_TABLE_TOTAL_STORIES": {
    "en": "Stories",
    "ru": "Истории",
    "es": "Historias",
    "pt": "Histórias",
    "de": "Geschichten"
  },
  "STATS_TABLE_TOTAL_INTERACTIONS": {
    "en": "Interactions",
    "ru": "Взаимодействия",
    "es": "Interacciones",
    "pt": "Interações",
    "de": "Interaktionen"
  },
  "STATS_TABLE_AVERAGE_REACH_POST_AVG": {
    "en": "Reach post",
    "ru": "Охват поста",
    "es": "Alcance publicación",
    "pt": "Alcance da postagem",
    "de": "Beitragsreichweite"
  },
  "STATS_TABLE_AVERAGE_LIKES_AVG": {
    "en": "Likes",
    "ru": "Лайки",
    "es": "Me gusta",
    "pt": "Curtidas",
    "de": "Gefällt mir"
  },
  "STATS_TABLE_AVERAGE_INTERACTIONS_AVG": {
    "en": "Interactions",
    "ru": "Взаимодействия",
    "es": "Interacciones",
    "pt": "Interações",
    "de": "Interaktionen"
  },
  "STATS_TABLE_AVERAGE_REPOSTS_AVG": {
    "en": "Shares",
    "ru": "Поделились",
    "es": "Compartidos",
    "pt": "Compartilhamentos",
    "de": "Geteilt"
  },
  "STATS_TABLE_AVERAGE_VIEWS_AVG": {
    "en": "Views",
    "ru": "Просмотры",
    "es": "Vistas",
    "pt": "Visualizações",
    "de": "Ansichten"
  },
  "STATS_TABLE_AVERAGE_STORIES_REACH_AVG": {
    "en": "Reach stories",
    "ru": "Охват историй",
    "es": "Alcance historias",
    "pt": "Alcance das histórias",
    "de": "Reichweite der Geschichten"
  },
  "STATS_TABLE_AVERAGE_COMMENTS_AVG": {
    "en": "Comments",
    "ru": "Комментарии",
    "es": "Comentarios",
    "pt": "Comentários",
    "de": "Kommentare"
  },
  "STATS_TABLE_AVERAGE_PAGE_REACH_AVG": {
    "en": "Daily reach",
    "ru": "Дневной охват",
    "es": "Alcance diario",
    "pt": "Alcance diário",
    "de": "Tägliche Reichweite"
  },
  "STATS_TABLE_AVERAGE_PAGE_REACH_MOBILE_AVG": {
    "en": "Daily m. reach",
    "ru": "Моб. охват",
    "es": "Diario móvil",
    "pt": "Diário móvel",
    "de": "Tägliche mob. Reichweite"
  },
  "STATS_TABLE_ENGAGEMENT_IRR_POST_AVG": {
    "en": "IRR post, %",
    "ru": "IRR post, %",
    "es": "IRR post, %",
    "pt": "IRR post, %",
    "de": "IRR post, %"
  },
  "STATS_TABLE_FOLLOWERS_SUBSCRIBERS": {
    "en": "Followers",
    "ru": "Подписчики",
    "es": "Seguidores",
    "pt": "Seguidores",
    "de": "Abonnenten"
  },
  "STATS_TABLE_FOLLOWERS_GROWTH": {
    "en": "Growth",
    "ru": "Рост",
    "es": "Crecimiento",
    "pt": "Crescimento",
    "de": "Wachstum"
  },
  "STATS_TABS_COMMON_TITLE": {
    "en": "Common",
    "ru": "Общее",
    "es": "Común",
    "pt": "Comum",
    "de": "Allgemein"
  },
  "STATS_TABS_COMMON_SUBSCRIBERS": {
    "en": "Followers",
    "ru": "Подписчики",
    "es": "Seguidores",
    "pt": "Seguidores",
    "de": "Abonnenten"
  },
  "STATS_TABS_COMMON_LIKES": {
    "en": "Likes",
    "ru": "Лайки",
    "es": "Me gusta",
    "pt": "Curtidas",
    "de": "Gefällt mir"
  },
  "STATS_TABS_COMMON_REPOSTS": {
    "en": "Shares",
    "ru": "Поделились",
    "es": "Compartidos",
    "pt": "Compartilhamentos",
    "de": "Geteilt"
  },
  "STATS_TABS_COMMON_COMMENTS": {
    "en": "Comments",
    "ru": "Комментарии",
    "es": "Comentarios",
    "pt": "Comentários",
    "de": "Kommentare"
  },
  "STATS_TABS_COMMON_VIEWS": {
    "en": "Views",
    "ru": "Просмотры",
    "es": "Vistas",
    "pt": "Visualizações",
    "de": "Ansichten"
  },
  "STATS_TABS_COMMON_ER_PROGRESSIVE": {
    "en": "ER progressive",
    "ru": "ER прогрессивный",
    "es": "ER progresivo",
    "pt": "ER progressivo",
    "de": "ER progressiv"
  },
  "STATS_TABS_COMMON_POST_COUNT": {
    "en": "Posts count",
    "ru": "Количество постов",
    "es": "Conteo de publicaciones",
    "pt": "Contagem de postagens",
    "de": "Anzahl der Beiträge"
  },
  "STATS_TABS_PUBLICATIONS_TITLE": {
    "en": "Posts",
    "ru": "Посты",
    "es": "Publicaciones",
    "pt": "Postagens",
    "de": "Beiträge"
  },
  "STATS_TABS_PUBLICATIONS_AMT_BY_TEXT_LEN": {
    "en": "Count by text length",
    "ru": "Количество по длине текста",
    "es": "Conteo por longitud de texto",
    "pt": "Contagem por comprimento do texto",
    "de": "Anzahl nach Textlänge"
  },
  "STATS_TABS_PUBLICATIONS_ER_BY_TEXT_LEN": {
    "en": "ER by text length",
    "ru": "ER по длине текста",
    "es": "ER por longitud de texto",
    "pt": "ER por comprimento do texto",
    "de": "ER nach Textlänge"
  },
  "STATS_TABS_PUBLICATIONS_REL_ACT_BY_TEXT_LEN": {
    "en": "Text length relative activity",
    "ru": "Относительная активность по длине текста",
    "es": "Actividad relativa por longitud de texto",
    "pt": "Atividade relativa por comprimento do texto",
    "de": "Relative Aktivität nach Textlänge"
  },
  "STATS_TABS_PUBLICATIONS_AMT_BY_CONTENT_TYPE": {
    "en": "Count by content type",
    "ru": "Количество по типу контента",
    "es": "Conteo por tipo de contenido",
    "pt": "Contagem por tipo de conteúdo",
    "de": "Anzahl nach Inhaltstyp"
  },
  "STATS_TABS_PUBLICATIONS_ER_BY_CONTENT_TYPE": {
    "en": "ER by content type",
    "ru": "ER по типу контента",
    "es": "ER por tipo de contenido",
    "pt": "ER por tipo de conteúdo",
    "de": "ER nach Inhaltstyp"
  },
  "STATS_TABS_PUBLICATIONS_REL_ACT_BY_CONTENT_TYPE": {
    "en": "Content type relative activity",
    "ru": "Относительная активность по типу контента",
    "es": "Actividad relativa por tipo de contenido",
    "pt": "Atividade relativa por tipo de conteúdo",
    "de": "Relative Aktivität nach Inhaltstyp"
  },
  "STATS_TABS_PUBLICATIONS_HASHTAGS": {
    "en": "Tags",
    "ru": "Теги",
    "es": "Etiquetas",
    "pt": "Tags",
    "de": "Tags"
  },
  "STATS_TABS_PUBLICATIONS_HASHTAGS_ER": {
    "en": "ER by tags",
    "ru": "ER по тегам",
    "es": "ER por etiquetas",
    "pt": "ER por tags",
    "de": "ER nach Tags"
  },
  "STATS_TABS_PUBLICATIONS_REL_ACT_BY_HASHTAGS": {
    "en": "Relative tags activity",
    "ru": "Относительная активность по тегам",
    "es": "Actividad relativa por etiquetas",
    "pt": "Atividade relativa por tags",
    "de": "Relative Aktivität nach Tags"
  },
  "STATS_TABS_PUBLICATIONS_HOURS": {
    "en": "Hours of day",
    "ru": "Время суток",
    "es": "Horas del día",
    "pt": "Horas do dia",
    "de": "Stunden des Tages"
  },
  "STATS_TABS_PUBLICATIONS_DAYS_TEXT": {
    "en": "Activity by days of week",
    "ru": "Активность по дням недели",
    "es": "Actividad por días de la semana",
    "pt": "Atividade por dias da semana",
    "de": "Aktivität nach Wochentagen"
  },
  "STATS_TABS_PUBLICATIONS_HOURS_TEXT": {
    "en": "Activity by hours of day",
    "ru": "Активность по часам суток",
    "es": "Actividad por horas del día",
    "pt": "Atividade por horas do dia",
    "de": "Aktivität nach Stunden des Tages"
  },
  "STATS_TABS_REACH_TITLE": {
    "en": "Reach",
    "ru": "Охват",
    "es": "Alcance",
    "pt": "Alcance",
    "de": "Reichweite"
  },
  "STATS_TABS_REACH_REACH": {
    "en": "Reach",
    "ru": "Охват",
    "es": "Alcance",
    "pt": "Alcance",
    "de": "Reichweite"
  },
  "STATS_TABS_REACH_REACH_SUBSCRIBERS": {
    "en": "Reach followers",
    "ru": "Охват подписчиков",
    "es": "Alcance de seguidores",
    "pt": "Alcance de seguidores",
    "de": "Abonnenten erreichen"
  },
  "STATS_TABS_REACH_REACH_DAYS": {
    "en": "Daily reach",
    "ru": "Ежедневный охват",
    "es": "Alcance diario",
    "pt": "Alcance diário",
    "de": "Tägliche Reichweite"
  },
  "STATS_TABS_SOCIAL_TITLE": {
    "en": "Audience",
    "ru": "Аудитория",
    "es": "Audiencia",
    "pt": "Audiência",
    "de": "Publikum"
  },
  "STATS_TABS_SOCIAL_TOWNS": {
    "en": "Cities",
    "ru": "Города",
    "es": "Ciudades",
    "pt": "Cidades",
    "de": "Städte"
  },
  "STATS_TABS_SOCIAL_AGES": {
    "en": "Ages",
    "ru": "Возраст",
    "es": "Edades",
    "pt": "Idades",
    "de": "Alter"
  },
  "COMMON_WEEKS": {
    "en": [
      { "week": 0, "text": "Su" },
      { "week": 1, "text": "Mo" },
      { "week": 2, "text": "Tu" },
      { "week": 3, "text": "We" },
      { "week": 4, "text": "Th" },
      { "week": 5, "text": "Fr" },
      { "week": 6, "text": "Sa" }
    ],
    "ru": [
      { "week": 0, "text": "Вс" },
      { "week": 1, "text": "Пн" },
      { "week": 2, "text": "Вт" },
      { "week": 3, "text": "Ср" },
      { "week": 4, "text": "Чт" },
      { "week": 5, "text": "Пт" },
      { "week": 6, "text": "Сб" }
    ],
    "es": [
      { "week": 0, "text": "Do" },
      { "week": 1, "text": "Lu" },
      { "week": 2, "text": "Ma" },
      { "week": 3, "text": "Mi" },
      { "week": 4, "text": "Ju" },
      { "week": 5, "text": "Vi" },
      { "week": 6, "text": "Sa" }
    ],
    "pt": [
      { "week": 0, "text": "Do" },
      { "week": 1, "text": "Se" },
      { "week": 2, "text": "Te" },
      { "week": 3, "text": "Qa" },
      { "week": 4, "text": "Qi" },
      { "week": 5, "text": "Se" },
      { "week": 6, "text": "Sa" }
    ],
    "de": [
      { "week": 0, "text": "So" },
      { "week": 1, "text": "Mo" },
      { "week": 2, "text": "Di" },
      { "week": 3, "text": "Mi" },
      { "week": 4, "text": "Do" },
      { "week": 5, "text": "Fr" },
      { "week": 6, "text": "Sa" }
    ]
  },
  "DAYS_OF_WEEK_SUN": {
    "en": "Sunday",
    "ru": "Воскресенье",
    "es": "Domingo",
    "pt": "Domingo",
    "de": "Sonntag"
  },
  "DAYS_OF_WEEK_MON": {
    "en": "Monday",
    "ru": "Понедельник",
    "es": "Lunes",
    "pt": "Segunda-feira",
    "de": "Montag"
  },
  "DAYS_OF_WEEK_TUE": {
    "en": "Tuesday",
    "ru": "Вторник",
    "es": "Martes",
    "pt": "Terça-feira",
    "de": "Dienstag"
  },
  "DAYS_OF_WEEK_WED": {
    "en": "Wednesday",
    "ru": "Среда",
    "es": "Miércoles",
    "pt": "Quarta-feira",
    "de": "Mittwoch"
  },
  "DAYS_OF_WEEK_THU": {
    "en": "Thursday",
    "ru": "Четверг",
    "es": "Jueves",
    "pt": "Quinta-feira",
    "de": "Donnerstag"
  },
  "DAYS_OF_WEEK_FRI": {
    "en": "Friday",
    "ru": "Пятница",
    "es": "Viernes",
    "pt": "Sexta-feira",
    "de": "Freitag"
  },
  "DAYS_OF_WEEK_SAT": {
    "en": "Saturday",
    "ru": "Суббота",
    "es": "Sábado",
    "pt": "Sábado",
    "de": "Samstag"
  },  
  "PRC_HEADER": {
    "en": "Pricing",
    "ru": "Цены",
    "es": "Precios",
    "pt": "Preços",
    "de": "Preise"
  },
  "PRC_YEAR": {
    "en": "Billed Yearly",
    "ru": "За год",
    "es": "Anualmente",
    "pt": "Anualmente",
    "de": "Jährlich"
  },
  "PRC_MNTH": {
    "en": "Billed Monthly",
    "ru": "Помесячно",
    "es": "Mensualmente",
    "pt": "Mensalmente",
    "de": "Monatlich"
  },
  "PRC_PRD_MTH": {
    "en": "month",
    "ru": "месяц",
    "es": "mes",
    "pt": "mês",
    "de": "Monat"
  },
  "PRC_BILLED": {
    "en": "billed yearly",
    "ru": "при оплате за год",
    "es": "facturado anualmente",
    "pt": "faturado anualmente",
    "de": "jährlich abgerechnet"
  },
  "PRC_DSCNT": {
    "en": "Save",
    "ru": "Скидка",
    "es": "Ahorrar",
    "pt": "Economizar",
    "de": "Sparen"
  },
  "PRC_FREE": {
    "en": "Free",
    "ru": "Бесплатно",
    "es": "Gratis",
    "pt": "Grátis",
    "de": "Kostenlos"
  },
  "PRC_PRD": {
    "en": "month",
    "ru": "месяц",
    "es": "mes",
    "pt": "mês",
    "de": "Monat"
  },
  "PRC_ACT": {
    "en": "Let's started",
    "ru": "Выбрать",
    "es": "Empecemos",
    "pt": "Vamos começar",
    "de": "Lasst uns anfangen"
  },
  "PRC_USR": {
    "en": "Users",
    "ru": "Пользователи",
    "es": "Usuarios",
    "pt": "Usuários",
    "de": "Benutzer"
  },
  "PRC_SMP": {
    "en": "Pages",
    "ru": "Страниц",
    "es": "Páginas",
    "pt": "Páginas",
    "de": "Seiten"
  },
  "PRC_DEPTH": {
    "en": "Depth analysis",
    "ru": "Ретроспектива",
    "es": "Retrospección",
    "pt": "Retrospectiva",
    "de": "Rückschau"
  },
  "PRC_YEARS": {
    "en": "years",
    "ru": "года",
    "es": "años",
    "pt": "anos",
    "de": "Jahre"
  },
  "PRC_YES": {
    "en": "Yes",
    "ru": "Да",
    "es": "Sí",
    "pt": "Sim",
    "de": "Ja"
  },
  "PRC_14": {
    "en": "14 days",
    "ru": "14 дней",
    "es": "14 días",
    "pt": "14 dias",
    "de": "14 Tage"
  },
  "PRC_SPP": {
    "en": "Support",
    "ru": "Поддержка",
    "es": "Soporte",
    "pt": "Suporte",
    "de": "Unterstützung"
  },
  "PRC_NO": {
    "en": "No",
    "ru": "Нет",
    "es": "No",
    "pt": "Não",
    "de": "Nein"
  },
  "PRC_ACS": {
    "en": "",
    "ru": "",
    "es": "",
    "pt": "",
    "de": ""
  },
  "PRC_CMPR": {
    "en": "Comparing pages",
    "ru": "Сравнение страниц",
    "es": "Comparando páginas",
    "pt": "Comparando páginas",
    "de": "Seiten vergleichen"
  },
  "PRC_STR": {
    "en": "Stories stats for VK & Instagram",
    "ru": "Статистика историй для VK и Instagram",
    "es": "Estadísticas de historias para VK e Instagram",
    "pt": "Estatísticas de histórias para VK e Instagram",
    "de": "Geschichtenstatistiken für VK & Instagram"
  },
  "PRC_INN": {
    "en": "Inner social stats",
    "ru": "Внутренняя статистика",
    "es": "Estadísticas internas",
    "pt": "Estatísticas internas",
    "de": "Innere Statistiken"
  },
  "PRC_EXPORT": {
    "en": "Exports reports to",
    "ru": "Экспорт отчетов в",
    "es": "Exporta informes a",
    "pt": "Exporta relatórios para",
    "de": "Berichte exportieren nach"
  },
  "PRC_AREML": {
    "en": "Auto reports to email",
    "ru": "Автоматические отчеты на почту",
    "es": "Informes automáticos al correo electrónico",
    "pt": "Relatórios automáticos para e-mail",
    "de": "Automatische Berichte per E-Mail"
  },
  "PRC_API": {
    "en": "Access via API",
    "ru": "Доступ через API",
    "es": "Acceso a través de API",
    "pt": "Acesso via API",
    "de": "Zugang über API"
  },
  "PRC_RC": {
    "en": "Pay Russian card",
    "ru": "Оплата российской картой",
    "es": "Pagar con tarjeta rusa",
    "pt": "Pagar com cartão russo",
    "de": "Mit russischer Karte bezahlen"
  },
  "PRC_FC": {
    "en": "Pay Foreign card",
    "ru": "Оплата иностранной картой",
    "es": "Pagar con tarjeta extranjera",
    "pt": "Pagar com cartão estrangeiro",
    "de": "Mit ausländischer Karte bezahlen"
  },
  "PRC_AGNC": {
    "en": "Agency",
    "ru": "Агентство",
    "es": "Agencia",
    "pt": "Agência",
    "de": "Agentur"
  },
  "PRC_CMPN": {
    "en": "Company",
    "ru": "Компания",
    "es": "Compañía",
    "pt": "Empresa",
    "de": "Firma"
  },
  "PRC_EXC": {
    "en": "Exceptional",
    "ru": "Исключительный",
    "es": "Excepcional",
    "pt": "Excepcional",
    "de": "Außergewöhnlich"
  },
  "PRC_EXPR": {
    "en": "Expires",
    "ru": "Истекает",
    "es": "Expira",
    "pt": "Expira",
    "de": "Läuft ab"
  },
  "PRC_PLN": {
    "en": "Plan",
    "ru": "Тариф",
    "es": "Plan",
    "pt": "Plano",
    "de": "Plan"
  },
  "PRC_UPGR": {
    "en": "Upgrade",
    "ru": "Дополнить",
    "es": "Mejorar",
    "pt": "Atualizar",
    "de": "Aktualisieren"
  },
  "PRC_USED": {
    "en": "Pages",
    "ru": "Страниц",
    "es": "Páginas",
    "pt": "Páginas",
    "de": "Seiten"
  },
  "REPORTS_TO": {
    "en": "Send reports to",
    "ru": "Отправить отчеты на",
    "es": "Enviar informes a",
    "pt": "Enviar relatórios para",
    "de": "Berichte senden an"
  },
  "REPORTS_REG": {
    "en": "Regularity",
    "ru": "Как часто",
    "es": "Regularidad",
    "pt": "Regularidade",
    "de": "Regelmäßigkeit"
  },
  "REPORTS_REG_WK": {
    "en": "Weekly",
    "ru": "Еженедельно",
    "es": "Semanalmente",
    "pt": "Semanalmente",
    "de": "Wöchentlich"
  },
  "REPORTS_REG_MN": {
    "en": "Monthly",
    "ru": "Ежемесячно",
    "es": "Mensualmente",
    "pt": "Mensalmente",
    "de": "Monatlich"
  },
  "REPORTS_FORM": {
    "en": "Report export formats",
    "ru": "Форматы экспорта отчетов",
    "es": "Formatos de exportación de informes",
    "pt": "Formatos de exportação de relatórios",
    "de": "Berichtsexportformate"
  },
  "REPORTS_SAVE": {
    "en": "Save",
    "ru": "Сохранить",
    "es": "Guardar",
    "pt": "Salvar",
    "de": "Speichern"
  },
  "REPORTS_CPTN": {
    "en": "Automatic reports ",
    "ru": "Менеджер автоматических отчетов",
    "es": "Administrador de informes automáticos",
    "pt": "Gerenciador de relatórios automáticos",
    "de": "Automatischer Bericht-Manager"
  },
  "BTN_COMPARE": {
    "en": "Compare",
    "ru": "Сравнить",
    "es": "Comparar",
    "pt": "Comparar",
    "de": "Vergleichen"
  },
  "BTN_AUTO": {
    "en": "Auto reports",
    "ru": "Авто отчеты",
    "es": "Informes automáticos",
    "pt": "Relatórios automáticos",
    "de": "Automatische Berichte"
  },
  "DASH_SELECTED": {
    "en": "Selected pages",
    "ru": "Выбранные страницы",
    "es": "Páginas seleccionadas",
    "pt": "Páginas selecionadas",
    "de": "Ausgewählte Seiten"
  },
  "DASH_TOTAL": {
    "en": "Total",
    "ru": "Итого",
    "es": "Total",
    "pt": "Total",
    "de": "Gesamt"
  },
  "DASH_ENG": {
    "en": "Engagement",
    "ru": "Вовлеченность",
    "es": "Compromiso",
    "pt": "Engajamento",
    "de": "Engagement"
  },
  "DASH_AVE": {
    "en": "Average",
    "ru": "Средний",
    "es": "Promedio",
    "pt": "Média",
    "de": "Durchschnitt"
  },
  "DASH_GRW": {
    "en": "growth",
    "ru": "Рост",
    "es": "Crecimiento",
    "pt": "Crescimento",
    "de": "Wachstum"
  },
  "REPORTS_MANAGE": {
    "en": "Manage auto reports",
    "ru": "Автоотчеты",
    "es": "Administrar informes automáticos",
    "pt": "Gerenciar relatórios automáticos",
    "de": "Automatische Berichte verwalten"
  },
  "REPORTS_REPORT": {
    "en": "Report",
    "ru": "Отчет",
    "es": "Informe",
    "pt": "Relatório",
    "de": "Bericht"
  },
  "REPORTS_LIST": {
    "en": "Report for pages",
    "ru": "Отчет для страниц",
    "es": "Informe para páginas",
    "pt": "Relatório para páginas",
    "de": "Bericht für Seiten"
  },
  "REPORTS_LIST_PRD": {
    "en": "for period",
    "ru": "за период",
    "es": "por período",
    "pt": "por período",
    "de": "für Zeitraum"
  },
  "REPORTS_LIST_WAS": {
    "en": "was generated",
    "ru": "был сгенерирован",
    "es": "fue generado",
    "pt": "foi gerado",
    "de": "wurde generiert"
  },
  "REPORTS_DOWNLD": {
    "en": "Download report",
    "ru": "Скачать отчет",
    "es": "Descargar informe",
    "pt": "Baixar relatório",
    "de": "Bericht herunterladen"
  },
  "SORT_DATE": {
    "en": "Date",
    "ru": "Дата",
    "es": "Fecha",
    "pt": "Data",
    "de": "Datum"
  },
  "SORT_LIKES": {
    "en": "Likes",
    "ru": "Лайки",
    "es": "Me gusta",
    "pt": "Curtidas",
    "de": "Likes"
  },
  "SORT_BY": {
    "en": "Sort By",
    "ru": "Сортировать по",
    "es": "Ordenar por",
    "pt": "Ordenar por",
    "de": "Sortieren nach"
  },
  "SORT_SHARES": {
    "en": "Shares",
    "ru": "Поделились",
    "es": "Compartidos",
    "pt": "Compartilhamentos",
    "de": "Teilen"
  },
  "SORT_COMMS": {
    "en": "Comments",
    "ru": "Комментарии",
    "es": "Comentarios",
    "pt": "Comentários",
    "de": "Kommentare"
  },
  "SORT_VIEWS": {
    "en": "Views",
    "ru": "Просмотры",
    "es": "Vistas",
    "pt": "Visualizações",
    "de": "Ansichten"
  },
  "POST_MORE": {
    "en": "view more",
    "ru": "еще",
    "es": "ver más",
    "pt": "ver mais",
    "de": "mehr anzeigen"
  },
  "POST_LESS": {
    "en": "show less",
    "ru": "меньше",
    "es": "ver menos",
    "pt": "mostrar menos",
    "de": "weniger anzeigen"
  },
  "POST_POSTED": {
    "en": "Posted",
    "ru": "Опубликовано",
    "es": "Publicado",
    "pt": "Publicado",
    "de": "Gepostet"
  },
  "POST_INTER": {
    "en": "Interactions",
    "ru": "Взаимодействия",
    "es": "Interacciones",
    "pt": "Interações",
    "de": "Interaktionen"
  },
  "POST_DISCOV": {
    "en": "Discovery",
    "ru": "Открытие",
    "es": "Descubrimiento",
    "pt": "Descoberta",
    "de": "Entdeckung"
  },
  "POST_NAVIG": {
    "en": "Navigation",
    "ru": "Навигация",
    "es": "Navegación",
    "pt": "Navegação",
    "de": "Navigation"
  },
  "DASH_COMPARE": {
    "en": "Compare Pages",
    "ru": "Сравнить страницы",
    "es": "Comparar páginas",
    "pt": "Comparar páginas",
    "de": "Seiten vergleichen"
  },
  "USR_CONT": {
    "en": "Contact us",
    "ru": "Свяжитесь с нами",
    "es": "Contáctenos",
    "pt": "Contate-nos",
    "de": "Kontaktieren Sie uns"
  },
  "USR_PRVC": {
    "en": "Privacy Policy",
    "ru": "Политика конфиденциальности",
    "es": "Política de privacidad",
    "pt": "Política de Privacidade",
    "de": "Datenschutz-Bestimmungen"
  },
  "USR_TERMS": {
    "en": "Terms",
    "ru": "Условия",
    "es": "Términos",
    "pt": "Termos",
    "de": "Begriffe"
  },
  "USR_SAV": {
    "en": "Saving tokens",
    "ru": "Сохранение токенов",
    "es": "Guardando tokens",
    "pt": "Salvando tokens",
    "de": "Tokens speichern"
  },
  "DASH_EXPORT": {
    "en": "Export",
    "ru": "Экспорт",
    "es": "Exportar",
    "pt": "Exportar",
    "de": "Export"
  },
  "DASH_EXPORT_NO_PAGES": {
    "en": "Select pages to export a report",
    "ru": "Выберите страницы для экспорта отчета",
    "es": "Seleccione páginas para exportar un informe",
    "pt": "Selecione páginas para exportar um relatório",
    "de": "Wählen Sie Seiten aus, um einen Bericht zu exportieren"
  },
  "ADD_PAGE_PUT_URL": {
    "en": "Put URL of the page to search",
    "ru": "Вставьте URL страницы для поиска",
    "es": "Ingrese la URL de la página para agregar",
    "pt": "Insira a URL da página para adicionar",
    "de": "Geben Sie die URL der Seite ein, um hinzuzufügen"
  },
  "INTRO_HELPER_TEXT": {
    "en": "Select pages to view their statistics, or click on the page name.",
    "ru": "Выберите страницы, чтобы посмотреть их статистику, или кликните на название страницы.",
    "es": "Seleccione páginas para ver sus estadísticas, o haga clic en el nombre de la página.",
    "pt": "Selecione páginas para ver su estatística, ou clique no nome da página.",
    "de": "Wählen Sie Seiten aus, um ihre Statistik anzuzeigen, oder klicken Sie auf den Seitennamen."
  },
  "DASH_EXP_AS": {
    "en": "Export as",
    "ru": "Экспорт в",
    "es": "Exportar como",
    "pt": "Exportar como",
    "de": "Export als"
  },
  "ALERT_INVALID": {
    "en": "token for some pages is invalid. It's required to refresh social auth to continue pages analysis.",
    "ru": "токен для некоторых страниц недействителен. Требуется обновить авторизацию, чтобы продолжить анализ страниц.",
    "es": "el token para algunas páginas no es válido. Es necesario actualizar la autenticación social para continuar con el análisis de páginas.",
    "pt": "o token para algumas páginas é inválido. É necessário atualizar a autenticação social para continuar a análise das páginas.",
    "de": "Token für einige Seiten ist ungültig. Es ist erforderlich, die soziale Authentifizierung zu aktualisieren, um die Seitenanalyse fortzusetzen."
  },
  "ALERT_F5_BTN": {
    "en": "Refresh token",
    "ru": "Обновить токен",
    "es": "Actualizar token",
    "pt": "Atualizar token",
    "de": "Token aktualisieren"
  },
  "ALERT_PAGE_ADDED": {
    "en": "{count} page(s) successfully added",
    "ru": "{count} страница(ы) успешно добавлена(ы)",
    "de": "{count} Seite(n) erfolgreich hinzugefügt",
    "es": "Se han añadido correctamente {count} página(s)",
    "pt": "{count} página(s) adicionada(s) com sucesso"
  },
  "PAY_SUC": {
    "en": "Successful payment!",
    "ru": "Успешный платеж!",
    "es": "¡Pago exitoso!",
    "pt": "Pagamento bem-sucedido!",
    "de": "Erfolgreiche Zahlung!"
  },
  "PAY_FAIL": {
    "en": "Failed!",
    "ru": "Ошибка!",
    "es": "¡Falló!",
    "pt": "Falhou!",
    "de": "Gescheitert!"
  },
  "PAY_SUC_TXT": {
    "en": "You successfully bought an access to Lucmus. You can continue using the app on the Main page",
    "ru": "Вы успешно приобрели доступ к Lucmus. Вы можете продолжить использование приложения на Главной странице",
    "es": "Has comprado exitosamente acceso a Lucmus. Puedes continuar usando la aplicación en la página principal",
    "pt": "Você comprou com sucesso um acesso ao Lucmus. Você pode continuar usando o aplicativo na página principal",
    "de": "Sie haben erfolgreich Zugriff auf Lucmus gekauft. Sie können die App auf der Hauptseite weiter verwenden"
  },
  "PAY_FAIL_TXT": {
    "en": "The payment was declined. Check your bank card details and notifications to fix the problem.",
    "ru": "Платеж был отклонен. Проверьте данные вашей банковской карты и уведомления, чтобы исправить проблему.",
    "es": "El pago fue rechazado. Verifique los detalles de su tarjeta bancaria y las notificaciones para solucionar el problema.",
    "pt": "O pagamento foi recusado. Verifique os detalhes do seu cartão bancário e notificações para resolver o problema.",
    "de": "Die Zahlung wurde abgelehnt. Überprüfen Sie die Angaben zu Ihrer Bankkarte und die Benachrichtigungen, um das Problem zu beheben."
  },
  "PAY_MAIN": {
    "en": "Go to Main page",
    "ru": "Перейти на главную страницу",
    "es": "Ir a la página principal",
    "pt": "Ir para a página principal",
    "de": "Zur Hauptseite gehen"
  },
  "PAY_PRIC": {
    "en": "Back to Pricing page",
    "ru": "Назад к Тарифам",
    "es": "Volver a la página de precios",
    "pt": "Voltar à página de preços",
    "de": "Zurück zur Preisseite"
  },
  "AUTH_OR": {
    "en": "or",
    "ru": "или",
    "es": "o",
    "pt": "ou",
    "de": "oder"
  },
  "AUTH_COMMON_SIGNIN_WITH_GOOGLE": {
    "en": "Sign in with Google",
    "ru": "Войти через Google",
    "es": "Iniciar sesión con Google",
    "pt": "Entrar com o Google",
    "de": "Mit Google anmelden"
  },
  "AUTH_COMMON_SIGNUP_WITH_GOOGLE": {
    "en": "Sign up with Google",
    "ru": "Зарегистрироваться через Google",
    "es": "Registrarse con Google",
    "pt": "Inscrever-se com o Google",
    "de": "Mit Google registrieren"
  },
  "STATS_LOWEST": {
    "en": "Lowest",
    "ru": "Минимум",
    "es": "Mínimo",
    "pt": "Mínimo",
    "de": "Niedrigste"
  },
  "STATS_HIGHEST": {
    "en": "Highest",
    "ru": "Максимум",
    "es": "Máximo",
    "pt": "Máximo",
    "de": "Höchste"
  },
  "STATS_STORIES_ANSWERS": {
    "en": "Replies",
    "ru": "Ответы",
    "es": "Respuestas",
    "pt": "Respostas",
    "de": "Antworten"
  },
  "STATS_STORIES_LIKES": {
    "en": "Likes",
    "ru": "Лайки",
    "es": "Me gusta",
    "pt": "Curtidas",
    "de": "Likes"
  },
  "STATS_STORIES_FOLLOWERS": {
    "en": "Followers",
    "ru": "Подписчики",
    "de": "Follower",
    "es": "Seguidores",
    "pt": "Seguidores"
  },
  "STATS_STORIES_VIEWS": {
    "en": "Views",
    "ru": "Просмотры",
    "de": "Ansichten",
    "es": "Vistas",
    "pt": "Visualizações"
  },
  "STATS_STORIES_SHARES": {
    "en": "Shares",
    "ru": "Поделиться",
    "es": "Compartir",
    "pt": "Compartilhar",
    "de": "Teilen"
  },
  "STATS_STORIES_OPEN_LINK": {
    "en": "Clicks",
    "ru": "Переходы",
    "es": "Clics",
    "pt": "Cliques",
    "de": "Klicks"
  },
  "DASHBOARD_TEXT": {
    "en": "Text",
    "ru": "Текст",
    "de": "Text",
    "es": "Texto",
    "pt": "Texto"
  },
  "DASHBOARD_PHOTO": {
    "en": "Photo",
    "ru": "Фото",
    "de": "Foto",
    "es": "Foto",
    "pt": "Foto"
  },
  "DASHBOARD_VIDEO": {
    "en": "Video",
    "ru": "Видео",
    "de": "Video",
    "es": "Vídeo",
    "pt": "Vídeo"
  },
  "DASHBOARD_LINK": {
    "en": "Link",
    "ru": "Ссылка",
    "de": "Link",
    "es": "Enlace",
    "pt": "Link"
  },
  "DASHBOARD_LONG": {
    "en": "Long",
    "ru": "Длинный",
    "de": "Lang",
    "es": "Largo",
    "pt": "Longo"
  },
  "DASHBOARD_MEDIUM": {
    "en": "Medium",
    "ru": "Средний",
    "de": "Mittel",
    "es": "Medio",
    "pt": "Médio"
  },
  "DASHBOARD_SHORT": {
    "en": "Short",
    "ru": "Короткий",
    "de": "Kurz",
    "es": "Corto",
    "pt": "Curto"
  },
  "AUDIENCE_MEN": {
    "en": "Men",
    "ru": "Мужчины",
    "de": "Männer",
    "es": "Hombres",
    "pt": "Homens"
  },
  "AUDIENCE_WOMEN": {
    "en": "Women",
    "ru": "Женщины",
    "de": "Frauen",
    "es": "Mujeres",
    "pt": "Mulheres"
  },
  "AUDIENCE_ALL": {
    "en": "All",
    "ru": "Все",
    "de": "Alle",
    "es": "Todos",
    "pt": "Todos"
  },
  "FEED_PREV": {
    "en": "Prev",
    "ru": "Пред",
    "de": "Zurück",
    "es": "Anterior",
    "pt": "Anterior"
  },
  "FEED_NEXT": {
    "en": "Next",
    "ru": "След",
    "de": "Weiter",
    "es": "Siguiente",
    "pt": "Próximo"
  },
  "PROFILE_TITLE": {
    "en": "Profile",
    "ru": "Аккаунт",
    "de": "Profil",
    "es": "Perfil",
    "pt": "Perfil"
  },
  "PROFILE_SETTINGS": {
    "en": "Settings",
    "ru": "Настройки",
    "de": "Einstellungen",
    "es": "Configuración",
    "pt": "Configurações"
  },
  "PRC_ADMIN_USED": {
    "en": "Users",
    "ru": "Пользователей",
    "de": "Benutzer",
    "es": "Usuarios",
    "pt": "Usuários"
  },
  "ACCESS_MANAGER_NO_ACCESS": {
    "en": "No access rights have been granted yet.",
    "ru": "Вы пока не делились ни с кем доступом",
    "de": "Es wurden noch keine Zugriffsrechte gewährt.",
    "es": "Aún no se han concedido derechos de acceso.",
    "pt": "Nenhum direito de acesso foi concedido ainda."
  },
  "ALERT_BASIC_PLAN": {
    "en": "Your current plan is Basic. Upgrade for more features! <a href='plans'><i class='bi bi-lightning-fill'></i>Upgrade it!</a>",
    "ru": "Ваш тариф - Базовый. Приобретите полный для доступа ко всем функциям! <a href='plans'><i class='bi bi-lightning-fill'></i>Обновить!</a>",
    "de": "Ihr aktueller Plan ist Basic. Upgraden Sie für mehr Funktionen! <a href='plans'><i class='bi bi-lightning-fill'></i>Jetzt upgraden!</a>",
    "es": "Tu plan actual es Básico. ¡Mejora para obtener más funciones! <a href='plans'><i class='bi bi-lightning-fill'></i>Mejorar!</a>",
    "pt": "Seu plano atual é Básico. Atualize para mais recursos! <a href='plans'><i class='bi bi-lightning-fill'></i>Atualize agora!</a>"
  },
  "ALERT_PLAN_FINISHED": {
    "en": "Your plan has finished. Upgrade for more features! <a href='plans'><i class='bi bi-lightning-fill'></i>Upgrade it!</a>",
    "ru": "Ваш тариф закончился. Приобретите полный для доступа ко всем функциям! <a href='plans'><i class='bi bi-lightning-fill'></i>Обновить!</a>",
    "de": "Ihr Plan ist abgelaufen. Aktualisieren Sie für mehr Funktionen! <a href='plans'><i class='bi bi-lightning-fill'></i>Jetzt upgraden!</a>",
    "es": "Tu plan ha finalizado. ¡Actualiza para más funciones! <a href='plans'><i class='bi bi-lightning-fill'></i>¡Actualízalo!</a>",
    "pt": "Seu plano terminou. Atualize para mais recursos! <a href='plans'><i class='bi bi-lightning-fill'></i>Atualize agora!</a>"
  },
  "CALEND_7": {
    "en": "Last 7d",
    "ru": "Последние 7 дней",
    "de": "Letzte 7 Tage",
    "es": "Últimos 7 días",
    "pt": "Últimos 7 dias"
  },
  "CALEND_14": {
    "en": "Last 2 weeks",
    "ru": "Последние 2 недели",
    "de": "Letzte 2 Wochen",
    "es": "Últimas 2 semanas",
    "pt": "Últimas 2 semanas"
  },
  "CALEND_MONTH": {
    "en": "Last month",
    "ru": "Последний месяц",
    "de": "Letzter Monat",
    "es": "Último mes",
    "pt": "Último mês"
  },
  "ALERT_CALEND_BASIC": {
    "en": "Only 14 last days available on Basic plan. <a href='./plans'><i class='bi bi-lightning-fill'></i>Upgrade it!</a>",
    "ru": "На базовом плане доступны только последние 14 дней. <a href='./plans'><i class='bi bi-lightning-fill'></i>Обновить!</a>",
    "de": "Im Basisplan sind nur die letzten 14 Tage verfügbar. <a href='./plans'><i class='bi bi-lightning-fill'></i>Jetzt upgraden!</a>",
    "es": "En el plan Básico solo están disponibles los últimos 14 días. <a href='./plans'><i class='bi bi-lightning-fill'></i>Mejorar!</a>",
    "pt": "Somente os últimos 14 dias estão disponíveis no plano Básico. <a href='./plans'><i class='bi bi-lightning-fill'></i>Atualize agora!</a>"
  },
  "AUTH_ACTIVE": {
    "en": "Authenticated socials",
    "ru": "Авторизованные соцсети",
    "de": "Authentifizierte soziale Netzwerke",
    "es": "Redes sociales autenticadas",
    "pt": "Redes sociais autenticadas"
  },
  "ERROR_LOGIN": {
    "en": "You need to authenticate in that social to continue",
    "ru": "Вам нужно авторизоваться в этой соцсети для продолжения",
    "de": "Sie müssen sich in diesem sozialen Netzwerk authentifizieren, um fortzufahren",
    "es": "Necesitas autenticarte en esa red social para continuar",
    "pt": "Você precisa se autenticar nessa rede social para continuar"
  },
  "ERROR_ACCOUNT_NO_BUSINESS": {
    "en": "To analyze Instagram pages you need to auth using business profile connected with Facebook Public Page.",
    "ru": "Для анализа Instagram вам нужно авторизоваться используя бизнес-профиль, подключенный к Публичной странице Facebook (не персональной!).",
    "de": "Um Instagram-Seiten zu analysieren, müssen Sie sich mit einem Geschäftskonto authentifizieren, das mit einer Facebook-Öffentlichen Seite verbunden ist.",
    "es": "Para analizar páginas de Instagram, necesitas autenticarte con un perfil de negocio conectado con una página de Facebook pública.",
    "pt": "Para analisar páginas do Instagram, você precisa se autenticar com um perfil de negócio conectado a uma página do Facebook pública."
  },
  "ERROR_ADDING": {
    "en": "Error adding the page",
    "ru": "Ошибка при добавлении страницы",
    "de": "Fehler beim Hinzufügen der Seite",
    "es": "Error al añadir la página",
    "pt": "Erro ao adicionar a página"
  },
  "TOKEN_MISSING": {
    "en": "Social Token for {social} is missing, you need to log in",
    "ru": "Отсутствует токен соц сети {social}, вам нужно переавторизоваться",
    "de": "Das Token für {social} fehlt, Sie müssen sich anmelden",
    "es": "Falta el token de {social}, necesitas iniciar sesión",
    "pt": "Token da rede {social} ausente, você precisa fazer login"
  },
  "TOKEN_EXPIRED": {
    "en": "Social Token for {social} is expired, you need to relogin.",
    "ru": "Срок действия токена для {social} истек, вам нужно войти заново.",
    "de": "Das Token für {social} ist abgelaufen, Sie müssen sich erneut anmelden.",
    "es": "El token de {social} ha expirado, necesitas volver a iniciar sesión.",
    "pt": "O token da rede {social} expirou, você precisa fazer login novamente. Clique para autenticar"
  },
  "TOKEN_INVALID": {
    "en": "Social Token for {social} is restricted, you need to relogin",
    "ru": "Токен для {social} ограничен, вам нужно войти заново.",
    "de": "Das Token für {social} ist eingeschränkt, Sie müssen sich erneut anmelden",
    "es": "El token de {social} está restringido, necesitas volver a iniciar sesión.",
    "pt": "O token da rede {social} está restrito, você precisa fazer login novamente."
  },
  "PAGES_COUNT_LIMIT": {
    "en": "Pages count for your plan reached a limit. <a href='plans'><i class='bi bi-lightning-fill'></i>Upgrade it!</a>",
    "ru": "Достигнут лимит количества страниц для вашего тарифа. <a href='plans'><i class='bi bi-lightning-fill'></i>В тарифы</a>",
    "de": "Die Seitenanzahl für Ihren Tarif hat das Limit erreicht. <a href='plans'><i class='bi bi-lightning-fill'></i>Jetzt upgraden!</a>",
    "es": "El número de páginas de su plan ha alcanzado el límite. <a href='plans'><i class='bi bi-lightning-fill'></i>Mejorar!</a>",
    "pt": "A contagem de páginas do seu plano atingiu o limite. <a href='plans'><i class='bi bi-lightning-fill'></i>Atualizar!</a>"
  },
  "ALERT_PAGE_ANALYZED": {
    "en": "Page {page_name} has been analyzed!",
    "ru": "Страница {page_name} проанализирована!",
    "de": "Seite {page_name} wurde analysiert!",
    "es": "¡La página {page_name} ha sido analizada!",
    "pt": "A página {page_name} foi analisada!"
  },
  "ERROR_ALREADY_ANALYZED": {
    "en": "The page has been already added",
    "ru": "Страница уже была добавлена",
    "de": "Die Seite wurde bereits hinzugefügt",
    "es": "La página ya ha sido añadida",
    "pt": "A página já foi adicionada"
  },
  "ADMIN_LIMIT": {
    "en": "The users count has reached a limit for your plan. <a href='plans'><i class='bi bi-lightning-fill'></i>Upgrade it!</a>",
    "ru": "Количество пользователей достигло лимита вашего тарифа. <a href='plans'><i class='bi bi-lightning-fill'></i>Обновить</a>",
    "de": "Die Benutzeranzahl hat das Limit Ihres Plans erreicht. <a href='plans'><i class='bi bi-lightning-fill'></i>Jetzt upgraden!</a>",
    "es": "El número de usuarios ha alcanzado el límite de tu plan. <a href='plans'><i class='bi bi-lightning-fill'></i>¡Mejora ahora!</a>",
    "pt": "O número de usuários atingiu o limite do seu plano. <a href='plans'><i class='bi bi-lightning-fill'></i>Atualize agora!</a>"
  },
  "OTHER_ERROR": {
    "en": "Unknown error. Please, contact with the support",
    "ru": "Неизвестная ошибка. Пожалуйста, свяжитесь с поддержкой",
    "de": "Unbekannter Fehler. Bitte kontaktieren Sie den Support",
    "es": "Error desconocido. Por favor, contacta con el soporte",
    "pt": "Erro desconhecido. Por favor, entre em contato com o suporte"
  },
  "AUTH_ERROR_LOGIN_FAILED_USER_NOT_FOUND": {
    "en": "User not found",
    "ru": "Пользователь не найден",
    "de": "Benutzer nicht gefunden",
    "es": "Usuario no encontrado",
    "pt": "Usuário não encontrado"
  },
  "ERROR_DELETE_PAGE_ACCESS": {
    "en": "You have <b>Read only</b> access level. You can't delete pages",
    "ru": "У вас уровень доступа <b>Только чтение</b>. Вы не можете удалять страницы",
    "de": "Sie haben die Zugriffsberechtigung <b>Nur Lesen</b>. Sie können keine Seiten löschen",
    "es": "Tienes un nivel de acceso de <b>Solo lectura</b>. No puedes eliminar páginas",
    "pt": "Você tem nível de acesso <b>Apenas leitura</b>. Você não pode excluir páginas"
  },
  "PAGE_NOT_EXIST": {
    "en": "The page with this URL doesn't exist",
    "ru": "Страницы с таким URL не существует",
    "de": "Die Seite mit dieser URL existiert nicht",
    "es": "La página con esta URL no existe",
    "pt": "A página com este URL não existe"
  },
  "DELETE_CONFIRM": {
    "en": "Are you sure you want to delete this page?",
    "ru": "Вы уверены, что хотите удалить эту страницу?",
    "de": "Sind Sie sicher, dass Sie diese Seite löschen möchten?",
    "es": "¿Estás seguro de que quieres eliminar esta página?",
    "pt": "Tem certeza de que deseja excluir esta página?"
  },
  "RESTORE_CONFIRM": {
    "en": "Do you want to restore the page?",
    "ru": "Вы хотите восстановить страницу?",
    "de": "Möchten Sie die Seite wiederherstellen?",
    "es": "¿Desea restaurar la página?", 
    "pt": "Deseja restaurar a página?"
  },
  "PAGE_DELETE_SUCCESS": {
    "en": "The page was successfully deleted",
    "ru": "Страница была успешно удалена",
    "de": "Die Seite wurde erfolgreich gelöscht",
    "es": "La página se eliminó correctamente",
    "pt": "A página foi excluída com sucesso"
  },
  "RESTORE_CONFIRM_RESTORE": {
    "en": "Restore",
    "ru": "Восстановить",
    "de": "Wiederherstellen",
    "es": "Restaurar",
    "pt": "Restaurar"
  },
  "ALERT_PAGE_LIMIT_REACHED": {
    "en": "You have reached your page limit. Please upgrade your plan to add more pages.",
    "ru": "Вы достигли лимита страниц. Обновите тариф, чтобы добавить больше страниц.",
    "de": "Sie haben Ihr Seitenlimit erreicht. Bitte upgraden Sie Ihren Plan, um weitere Seiten hinzuzufügen.",
    "es": "Has alcanzado tu límite de páginas. Actualiza tu plan para agregar más páginas.",
    "pt": "Você atingiu seu limite de páginas. Atualize seu plano para adicionar mais páginas."
  },
  "ALERT_RESTORE_ERROR": {
    "en": "Error restoring page. Please try again.",
    "ru": "Ошибка при восстановлении страницы. Пожалуйста, попробуйте снова.",
    "de": "Fehler beim Wiederherstellen der Seite. Bitte versuchen Sie es erneut.",
    "es": "Error al restaurar la página. Por favor, inténtalo de nuevo.",
    "pt": "Erro ao restaurar a página. Por favor, tente novamente."
  },
  "DELETE_CANCEL": {
    "en": "Cancel",
    "ru": "Отмена",
    "de": "Abbrechen",
    "es": "Cancelar",
    "pt": "Cancelar"
  },
  "DELETE_CONFIRM_DELETE": {
    "en": "Delete",
    "ru": "Удалить",
    "de": "Löschen",
    "es": "Eliminar",
    "pt": "Excluir"
  },
  "PRICING_TITLE": {
    "en": "Pricing",
    "ru": "Тарифы",
    "de": "Preise",
    "es": "Precios",
    "pt": "Preços"
  },
  "SUCCESS_AUTH": {
    "en": "Success auth {social}",
    "ru": "Успешная авторизация в {social}",
    "de": "Erfolgreiche Authentifizierung in {social}",
    "es": "Éxito en la autenticación en {social}",
    "pt": "Sucesso na autenticação em {social}"
  },
  "PRC_PAY_RUSSIAN_CARD": {
    "en": "Pay by Russian card",
    "ru": "Российской картой",
    "de": "Russischen Karte ",
    "es": "Pagar con una tarjeta rusa",
    "pt": "Pagar com uma tarjeta russa"
  },
  "PRC_PAY_FOREIGN_CARD": {
    "en": "Pay by Foreign card",
    "ru": "Иностранной картой",
    "de": "Ausländischen Karte",
    "es": "Tarjeta extranjera",
    "pt": "Tarjeta estrangeira"
  },
  "STATS_TABLE_ENGAGEMENT_ER_DAY": "ER day, %",
  "STATS_TABLE_ENGAGEMENT_ER_POST": "ER post, %",
  "STATS_TABLE_ENGAGEMENT_ER_VIEW": "ER view, %",
  "STATS_TABLE_ENGAGEMENT_LR": "LR, %",
  "STATS_TABLE_ENGAGEMENT_TR": "TR, %",
  "STATS_TABLE_ENGAGEMENT_REACH_RATE_AVG": "RR, % ",
  "STATS_TABLE_ENGAGEMENT_ERR": "ERR, %",
  "STATS_TABLE_ENGAGEMENT_VIS_RATE": "Vis. Rate, %",
  "STATS_TABLE_ENGAGEMENT_VIEWS_RATE": "Views Rate",
  "STATS_TABLE_ENGAGEMENT_IR_POST_AVG": "IR post, %  ",
  "STATS_TABLE_ENGAGEMENT_IRR_POST_AVG": "IRR post, %  ",
  "STATS_TABLE_ENGAGEMENT_ER_PROGRESSIVE": "ER Progressive, %",
  "POSTS_FEED_ER": "ER post",
  "POSTS_FEED_ER_VIEWS": "ER view",
  "POSTS_FEED_IR": "IR",
  "POSTS_FEED_ER_PROGRESSIVE": "ER prog",
  "POSTS_FEED_REACH_VIRAL": "Viral Reach",
  "POSTS_FEED_REACH_SUBSCRIBERS": "Reach Followers",
  "POSTS_FEED_REACH_TOTAL": "Reach",
  "POSTS_FEED_ERR": "ERR",
  "POSTS_FEED_REACH": "Reach",
  "PRC_PLAN_BASIC": {
    "en": "Basic",
    "ru": "Базовый",
    "es": "Básico",
    "pt": "Básico",
    "de": "Basic"
  },
  "PRC_PLAN_AGENCY": {
    "en": "Agency",
    "ru": "Агентство",
    "es": "Agencia",
    "pt": "Agência",
    "de": "Agentur"
  },
  "PRC_PLAN_CORPORATE": {
    "en": "Corporate",
    "ru": "Корпоративный",
    "es": "Corporativo",
    "pt": "Corporativo",
    "de": "Unternehmen"
  },
  "PRC_PLAN_CUSTOM": {
    "en": "Custom",
    "ru": "Специальный",
    "es": "Costumbre",
    "pt": "Especial",
    "de": "Spezial"
  },
  "PRC_CUSTOM_SUBTITLE": {
    "en": "Custom pricing",
    "ru": "По запросу",
    "es": "Precio personalizado",
    "pt": "Preço personalizado",
    "de": "Individueller Preis"
  },
  "PRC_CONTACT": {
    "en": "Contact us",
    "ru": "Связаться с нами",
    "es": "Contacta con nosotros",
    "pt": "Contate-nos",
    "de": "Kontaktieren Sie uns"
  },
  "PRC_CUSTOM_DESCRIPTION": {
    "en": "A special plan for really large teams. Additional features, customizing reports and personalized metrics",
    "ru": "Специальный тариф для действительно больших команд. Дополнительные функции, настройка отчетов и персонализированные метрики",
    "es": "Un plan especial para equipos realmente grandes. Funciones adicionales, ajuste de informes y métricas personalizadas",
    "pt": "Um plano especial para equipes realmente grandes. Recursos adicionais, ajuste de relatórios e métricas personalizadas",
    "de": "Ein spezialisiertes Angebot für sehr große Teams. Zusätzliche Funktionen, Berichtsanpassungen und individuelle Metriken"
  },
  "PRC_CUSTOM_FEATURES": {
    "en": "Excel plan features, plus:",
    "ru": "Включает функции тарифа Excel, плюс:",
    "es": "Características del plan Excel, más:",
    "pt": "Recursos do plano Excel, mais:",
    "de": "Excel-Plan-Funktionen, plus:"
  },
  "PRC_CUSTOM_UNLIMITED_USERS": {
    "en": "Unlimited users",
    "ru": "Неограниченное количество пользователей",
    "es": "Usuarios ilimitados",
    "pt": "Usuários ilimitados",
    "de": "Unbegrenzte Benutzer"
  },
  "PRC_CUSTOM_UNLIMITED_PROFILES": {
    "en": "Unlimited social profiles",
    "ru": "Неограниченное количество профилей",
    "es": "Perfiles sociales ilimitados",
    "pt": "Perfis sociais ilimitados",
    "de": "Unbegrenzte soziale Profile"
  },
  "PRC_CUSTOM_UNLIMITED_GROUPS": {
    "en": "Unlimited groups",
    "ru": "Неограниченное количество групп",
    "es": "Grupos ilimitados",
    "pt": "Grupos ilimitados",
    "de": "Unbegrenzte Gruppen"
  },
  "PRC_CUSTOM_PARTNER": {
    "en": "Partner program membership",
    "ru": "Участие в партнерской программе",
    "es": "Membresía del programa de socios",
    "pt": "Associação ao programa de parceiros",
    "de": "Partnerprogramm-Mitgliedschaft"
  },
  "PRC_CUSTOM_WORKFLOW": {
    "en": "Content approval workflows for client collaboration",
    "ru": "Рабочие процессы утверждения контента для совместной работы с клиентами",
    "es": "Flujos de trabajo de aprobación de contenido para colaboración con clientes",
    "pt": "Fluxos de trabalho de aprovação de conteúdo para colaboração com clientes",
    "de": "Content-Approval-Workflows für die Zusammenarbeit mit Kunden"
  },
  "PRC_CUSTOM_TRY": {
    "en": "Try for free",
    "ru": "Попробовать бесплатно",
    "es": "Prueba gratis",
    "pt": "Experimente grátis",
    "de": "Kostenlos testen"
  },
  "PRC_CUSTOM_DEMO": {
    "en": "Get a demo",
    "ru": "Получить демо",
    "es": "Obtener una demo",
    "pt": "Obter uma demo",
    "de": "Demo anfordern"
  },
  "PRC_CUSTOM_VIEW_FEATURES": {
    "en": "View all features",
    "ru": "Посмотреть все функции",
    "es": "Ver todas las características",
    "pt": "Ver todos os recursos",
    "de": "Alle Funktionen anzeigen"
  },
  "PRC_TRIAL": {
    "en": "Try for free",
    "ru": "Попробовать бесплатно",
    "es": "Prueba gratis",
    "pt": "Experimente grátis",
    "de": "Kostenlos testen"
  },
  "PRC_TRIAL_PERIOD": {
    "en": "trial plan for 14 days",
    "ru": "пробный тариф на 14 дней",
    "es": "plan de prueba por 14 días",
    "pt": "plano de teste por 14 dias",
    "de": "Testplan für 14 Tage"
  },
  "TRIAL_PLAN_ACTIVATED": {
    "en": "Trial plan activated successfully! You can now use premium features for 14 days.",
    "ru": "Пробный тариф успешно активирован! Теперь вы можете использовать премиум функции в течение 14 дней.",
    "es": "¡Plan de prueba activado con éxito! Ahora puede utilizar las funciones premium durante 14 días.",
    "pt": "Plano de teste ativado com sucesso! Agora você pode usar recursos premium por 14 dias.",
    "de": "Testplan erfolgreich aktiviert! Sie können jetzt 14 Tage lang Premium-Funktionen nutzen."
  },
  // Engagement metrics descriptions
  "METRIC_DESC_ER_DAY": {
    "en": "Engagement Rate per Day shows the percentage of audience that interacts with content on a daily basis. It's calculated as the sum of all interactions divided by the number of days and followers count.",
    "ru": "Показатель вовлеченности за день показывает процент аудитории, которая взаимодействует с контентом по дням. Рассчитывается как сумма всех взаимодействий, деленная на количество дней и количество подписчиков.",
    "es": "La tasa de interacción por día muestra el porcentaje de audiencia que interactúa con el contenido diariamente. Se calcula como la suma de todas las interacciones dividida por el número de días y el recuento de seguidores.",
    "pt": "A Taxa de Engajamento por Dia mostra a porcentagem de audiência que interage com o conteúdo diariamente. É calculada como a soma de todas as interações dividida pelo número de dias e contagem de seguidores.",
    "de": "Die Engagement-Rate pro Tag zeigt den Prozentsatz der Zielgruppe, die täglich mit Inhalten interagiert. Sie wird als Summe aller Interaktionen geteilt durch die Anzahl der Tage und die Anzahl der Follower berechnet."
  },
  "METRIC_DESC_ER_POST": {
    "en": "Engagement Rate per Post shows the average percentage of audience that interacts with each post. It's calculated as the sum of likes, comments, and shares (when available) divided by the number of followers.",
    "ru": "Показатель вовлеченности на пост показывает средний процент аудитории, которая взаимодействует с каждым постом. Рассчитывается как сумма лайков, комментариев и репостов (если доступно), деленная на количество подписчиков.",
    "es": "La tasa de interacción por publicación muestra el porcentaje promedio de audiencia que interactúa con cada publicación. Se calcula como la suma de me gusta, comentarios y compartidos (cuando están disponibles) dividida por el número de seguidores.",
    "pt": "A Taxa de Engajamento por Post mostra a porcentagem média de audiência que interage com cada postagem. É calculada como a soma de curtidas, comentários e compartilhamentos (quando disponíveis) dividida pelo número de seguidores.",
    "de": "Die Engagement-Rate pro Beitrag zeigt den durchschnittlichen Prozentsatz der Zielgruppe, die mit jedem Beitrag interagiert. Sie wird als Summe von Likes, Kommentaren und Shares (wenn verfügbar) geteilt durch die Anzahl der Follower berechnet."
  },
  "METRIC_DESC_LR": {
    "en": "Love Rate shows the average percentage of audience that likes each post. It's calculated as the average number of likes per post divided by the number of followers.",
    "ru": "Love Rate показывает средний процент аудитории, которая ставит лайки на каждый пост. Рассчитывается как среднее количество лайков на пост, деленное на количество подписчиков.",
    "es": "Love Rate muestra el porcentaje promedio de audiencia que da me gusta a cada publicación. Se calcula como el número promedio de me gusta por publicación dividido por el número de seguidores.",
    "pt": "Love Rate mostra a porcentagem média de audiência que dá curtidas a cada postagem. É calculada como o número médio de curtidas por postagem dividido pelo número de seguidores.",
    "de": "Love Rate zeigt den durchschnittlichen Prozentsatz der Zielgruppe, die jeden Beitrag mit einem Like bewertet. Sie wird als durchschnittliche Anzahl von Likes pro Beitrag geteilt durch die Anzahl der Follower berechnet."
  },
  "METRIC_DESC_TR": {
    "en": "Talk Rate shows the average percentage of audience that comments on each post. It's calculated as the average number of comments per post divided by the number of followers.",
    "ru": "Talk Rate показывает средний процент аудитории, которая комментирует публикации. Рассчитывается как среднее количество комментариев на пост, деленное на количество подписчиков.",
    "es": "Talk Rate muestra el porcentaje promedio de audiencia que comenta en cada publicación. Se calcula como el número promedio de comentarios por publicación dividido por el número de seguidores.",
    "pt": "Talk Rate mostra a porcentagem média de audiência que comenta em cada postagem. É calculada como o número médio de comentários por postagem dividido pelo número de seguidores.",
    "de": "Talk Rate zeigt den durchschnittlichen Prozentsatz der Zielgruppe, die jeden Beitrag kommentiert. Sie wird als durchschnittliche Anzahl von Kommentaren pro Beitrag geteilt durch die Anzahl der Follower berechnet."
  },
  "METRIC_DESC_ER_VIEW": {
    "en": "Engagement Rate per View shows the percentage of viewers who interact with content. It's calculated as the sum of all interactions divided by the number of views.",
    "ru": "Показатель вовлеченности на просмотр показывает процент зрителей, которые взаимодействуют с контентом. Рассчитывается как сумма всех взаимодействий, деленная на количество просмотров.",
    "es": "La tasa de interacción por vista muestra el porcentaje de espectadores que interactúan con el contenido. Se calcula como la suma de todas las interacciones dividida por el número de vistas.",
    "pt": "A Taxa de Engajamento por Visualização mostra a porcentagem de visualizadores que interagem com o conteúdo. É calculada como a soma de todas as interações dividida pelo número de visualizações.",
    "de": "Die Engagement-Rate pro Ansicht zeigt den Prozentsatz der Betrachter, die mit Inhalten interagieren. Sie wird als Summe aller Interaktionen geteilt durch die Anzahl der Ansichten berechnet. Nur für VK verfügbar."
  },
  "METRIC_DESC_ERR": {
    "en": "Engagement Rate by Reach shows the percentage of reached audience that interacts with content. It's calculated as the sum of all interactions divided by the total reach.",
    "ru": "Показатель вовлеченности по охвату показывает процент охваченной аудитории, которая взаимодействует с контентом. Рассчитывается как сумма всех взаимодействий, деленная на общий охват.",
    "es": "La tasa de interacción por alcance muestra el porcentaje de audiencia alcanzada que interactúa con el contenido. Se calcula como la suma de todas las interacciones dividida por el alcance total.",
    "pt": "A Taxa de Engajamento por Alcance mostra a porcentagem de audiência alcançada que interage com o conteúdo. É calculada como a soma de todas as interações dividida pelo alcance total.",
    "de": "Die Engagement-Rate nach Reichweite zeigt den Prozentsatz der erreichten Zielgruppe, die mit Inhalten interagiert. Sie wird als Summe aller Interaktionen geteilt durch die Gesamtreichweite berechnet."
  },
  "METRIC_DESC_POST_REACHRATE_AVG": {
    "en": "Reach Rate shows the average percentage of followers that see your content. It's calculated as the average reach per post divided by the number of followers.",
    "ru": "Reach Rate показывает отношение уникального числа просмотров по отношению к числу подписчиков",
    "es": "Reach Rate muestra el porcentaje promedio de seguidores que ven tu contenido. Se calcula como el promedio de alcance por publicación dividido por el número de seguidores.",
    "pt": "Reach Rate mostra a porcentagem média de seguidores que veem seu conteúdo. É calculada como o alcance médio por postagem dividido pelo número de seguidores.",
    "de": "Reach Rate zeigt den durchschnittlichen Prozentsatz der Follower, die Ihre Inhalte sehen. Sie wird als durchschnittlicher Reichweite pro Beitrag geteilt durch die Anzahl der Follower berechnet."
  },
  "METRIC_DESC_VISIBILITY_RATE": {
    "en": "Visibility Rate shows the percentage of followers who viewed your posts. It's calculated as the number of views divided by the number of followers.",
    "ru": "Показатель видимости показывает отношение неуникального числа просмотров к числу подписчиков",
    "es": "Visibility Rate muestra el porcentaje de seguidores que han visto tus publicaciones. Se calcula como el número de visualizaciones dividido por el número de seguidores.",
    "pt": "Visibility Rate mostra a porcentagem de seguidores que viram suas publicações. É calculada como o número de visualizações dividido pelo número de seguidores.",
    "de": "Visibility Rate zeigt den Prozentsatz der Follower, die Ihre Inhalte ansehen. Sie wird als Anzahl der Ansichten geteilt durch die Anzahl der Follower berechnet."
  },
  "METRIC_DESC_ER_PROGRESSIVE": {
    "en": "ER Progressive is calculated as ER based on the number of followers on the publication date.",
    "ru": "ER Progressive рассчитывается как ER на основании числа подписчиков на дату публикации.",
    "es": "ER Progresivo se calcula como ER basado en el número de seguidores en la fecha de publicación.",
    "pt": "ER Progressivo é calculado como ER com base no número de seguidores na data da publicação.",
    "de": "ER Progressiv wird als ER basierend auf der Anzahl der Follower am Veröffentlichungsdatum berechnet."
  },
  "METRIC_DESC_IR_POST_AVG": {
    "en": "Interaction Rate post shows the average number of interactions per post relative to the audience size, including all available metrics, such as clicks, page visits, etc.",
    "ru": "Intaractions Rate post показывает среднее количество взаимодействий на пост относительно размера аудитории, учитывая вообще все доступные метрики, включая клики, переходы на страницу и т.д.",
    "es": "La tasa de interacción por publicación muestra el número promedio de interacciones por publicación en relación con el tamaño de la audiencia, incluyendo todas las métricas disponibles, como clics, visitas a página, etc.",
    "pt": "A Taxa de Interação por Postagem mostra o número médio de interações por postagem em relação ao tamanho da audiência, incluindo todas as métricas disponíveis, como cliques, visitas à página, etc.",
    "de": "Die Interaktionsrate pro Beitrag zeigt die durchschnittliche Anzahl von Interaktionen pro Beitrag im Verhältnis zur Größe der Zielgruppe, einschließlich aller verfügbaren Metriken, wie Klicks, Seitenbesuche, etc."
  },
  "METRIC_DESC_IRR_POST_AVG": {
    "en": "Interaction Rate by Reach shows the average number of interactions per post relative to the reach, including all available metrics, such as clicks, page visits, etc.",
    "ru": "Intaractions Rate by Reach показывает среднее количество взаимодействий на пост относительно охвата, учитывая вообще все доступные метрики, включая клики, переходы на страницу и т.д.",
    "es": "La tasa de interacción por alcance muestra el número promedio de interacciones por publicación en relación con el alcance, incluyendo todas las métricas disponibles, como clics, visitas a página, etc.",
    "pt": "A Taxa de Interação por Alcance mostra o número médio de interações por postagem em relação ao alcance, incluindo todas as métricas disponíveis, como cliques, visitas à página, etc.",
    "de": "Die Interaktionsrate nach Reichweite zeigt die durchschnittliche Anzahl von Interaktionen pro Beitrag im Verhältnis zur Reichweite, einschließlich aller verfügbaren Metriken, wie Klicks, Seitenbesuche, etc."
  },
  "DASH_NO_DESC_AVAILABLE": {
    "en": "No description available for this metric.",
    "ru": "Описание для этого показателя недоступно.",
    "es": "No hay descripción disponible para esta métrica.",
    "pt": "Nenhuma descrição disponível para esta métrica.",
    "de": "Keine Beschreibung für diese Metrik verfügbar."
  },
  "DASH_METRICS": {
    "en": "Metrics",
    "ru": "Метрики",
    "es": "Métricas",
    "pt": "Métricas",
    "de": "Metriken"
  },
  "DASH_TABLE_VIEW": {
    "en": "Table View",
    "ru": "Табличный вид",
    "es": "Vista de tabla",
    "pt": "Visualização em tabela",
    "de": "Tabellenansicht"
  },
  "DASH_SHOW_TABLE": {
    "en": "Show table view",
    "ru": "Показать в виде таблицы",
    "es": "Mostrar vista de tabla",
    "pt": "Mostrar visualização em tabela",
    "de": "Tabellenansicht anzeigen"
  },
  "DASH_SHOW_CHART": {
    "en": "Show chart view",
    "ru": "Показать графический вид",
    "es": "Mostrar vista de gráfico",
    "pt": "Mostrar visualização em gráfico",
    "de": "Diagrammansicht anzeigen"
  },
  "API_CREDENTIALS_TITLE": {
    "en": "API",
    "ru": "API",
    "es": "API",
    "pt": "API",
    "de": "API"
  },
  "API_KEY_LABEL": {
    "en": "API Key",
    "ru": "Ключ API",
    "es": "Clave API",
    "pt": "Chave API",
    "de": "API-Schlüssel"
  },
  "API_KEY_DESCRIPTION": {
    "en": "Use this token to access our API. Keep it secret and do not share it with anyone.",
    "ru": "Используйте этот токен для доступа к нашему API. Храните его в секрете и не передавайте никому.",
    "es": "Utiliza este token para acceder a nuestra API. Manténlo en secreto y no lo compartas con nadie.",
    "pt": "Use este token para acessar nossa API. Mantenha-o em segredo e não o compartilhe com ninguém.",
    "de": "Verwenden Sie diesen Token, um auf unsere API zuzugreifen. Halten Sie ihn geheim und teilen Sie ihn mit niemandem."
  },
  "PRC_CANCEL_SUBSCRIPTION": {
    "en": "Cancel subscription",
    "ru": "Отменить подписку",
    "es": "Cancelar suscripción",
    "pt": "Cancelar assinatura",
    "de": "Abonnement stornieren"
  },
  "NO_POSTS": {
    "en": "No posts for this period",
    "ru": "Нет постов за этот период",
    "es": "No hay posts para este período",
    "pt": "Nenhum post para este período",
    "de": "Keine Beiträge für diesen Zeitraum"
  },
  "PRC_NO_PLAN": {
    "en": "No plan",
    "ru": "Нет тарифа",
    "es": "Sin plan",
    "pt": "Sem plano",
    "de": "Kein Plan"
  },
  "NO_PLAN": {
    "en": "You don't have any active plan. Go to the <a href='./plans'>Pricing</a> page to choose convenient option.",
    "ru": "Нет активного тарифа. Перейдите на страницу <a href='./plans'>Тарифов</a>, чтобы выбрать удобный вариант.",
    "es": "No tienes ningún plan activo. Ve a la página de <a href='./plans'>Precios</a> para elegir una opción conveniente.",
    "pt": "Você não tem nenhum plano ativo. Vá para a página de <a href='./plans'>Preços</a> para escolher uma opção conveniente.",
    "de": "Sie haben keinen aktiven Plan. Gehen Sie zur <a href='./plans'>Preisseite</a>, um eine bequeme Option auszuwählen."
  },
  "ACTIVE_PLAN": {
    "en": "Active",
    "ru": "Активно",
    "es": "Activo",
    "pt": "Ativo",
    "de": "Aktiv"
  },
  "NEXT_PAYMENT": {
    "en": "Next payment",
    "ru": "Следующее списание",
    "es": "Próximo pago",
    "pt": "Próximo pagamento",
    "de": "Nächster Zahlung"
  },
  "ACTIVE_TILL": {
    "en": "Active till",
    "ru": "Активен до",
    "es": "Activo hasta",
    "pt": "Ativo até",
    "de": "Aktiv bis"
  },
  "PRC_CANCELLED": {
    "en": "Cancelled",
    "ru": "Отменено",
    "es": "Cancelado",
    "pt": "Cancelado",
    "de": "Abgebrochen"
  },
  "PRC_CANCEL_SUBSCRIPTION_CONFIRM": {
    "en": "Are you sure you want to cancel your subscription? You will still have access until the end of your billing period.",
    "ru": "Вы уверены, что хотите отменить подписку? У вас все равно будет доступ до конца расчетного периода.",
    "es": "¿Está seguro de que desea cancelar su suscripción? Seguirá teniendo acceso hasta el final de su período de facturación.",
    "pt": "Tem certeza de que deseja cancelar sua assinatura? Você ainda terá acesso até o final do seu período de cobrança.",
    "de": "Sind Sie sicher, dass Sie Ihr Abonnement kündigen möchten? Sie haben bis zum Ende Ihres Abrechnungszeitraums weiterhin Zugriff."
  },
  "PRC_SUBSCRIPTION_CANCELLED_SUCCESS": {
    "en": "Your subscription has been successfully cancelled. You will have access until the end of your billing period.",
    "ru": "Ваша подписка успешно отменена. У вас будет доступ до конца расчетного периода.",
    "es": "Su suscripción ha sido cancelada con éxito. Tendrá acceso hasta el final de su período de facturación.",
    "pt": "Sua assinatura foi cancelada com sucesso. Você terá acesso até o final do seu período de cobrança.",
    "de": "Ihr Abonnement wurde erfolgreich gekündigt. Sie haben bis zum Ende Ihres Abrechnungszeitraums Zugriff."
  },
  "PRC_CANCELLATION_ERROR": {
    "en": "An error occurred while cancelling your subscription. Please try again or contact support.",
    "ru": "При отмене подписки произошла ошибка. Пожалуйста, попробуйте еще раз или обратитесь в службу поддержки.",
    "es": "Se produjo un error al cancelar su suscripción. Inténtelo de nuevo o póngase en contacto con el servicio de asistencia.",
    "pt": "Ocorreu um erro ao cancelar sua assinatura. Tente novamente ou entre em contato com o suporte.",
    "de": "Beim Kündigen Ihres Abonnements ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support."
  },
  "PRC_CONFIRM_CANCEL": {
    "en": "Confirm",
    "ru": "Подтвердить",
    "es": "Confirmar",
    "pt": "Confirmar",
    "de": "Kündigung"
  },
  "COPY_TO_CLIPBOARD": {
    "en": "Copy to clipboard",
    "ru": "Копировать в буфер обмена",
    "es": "Copiar al portapapeles",
    "pt": "Copiar para a área de transferência",
    "de": "In die Zwischenablage kopieren"
  },
  "COPIED_TO_CLIPBOARD": {
    "en": "Copied to clipboard!",
    "ru": "Скопировано в буфер обмена!",
    "es": "¡Copiado al portapapeles!",
    "pt": "Copiado para a área de transferência!",
    "de": "In die Zwischenablage kopiert!"
  },
  "COPY_FAILED": {
    "en": "Failed to copy to clipboard",
    "ru": "Не удалось скопировать в буфер обмена",
    "es": "Error al copiar al portapapeles",
    "pt": "Falha ao copiar para a área de transferência",
    "de": "Fehler beim Kopieren in die Zwischenablage"
  },
  "API_AUTHORIZATION": {
    "en": "Authorization",
    "ru": "Авторизация",
    "es": "Autorización",
    "pt": "Autorização", 
    "de": "Autorisierung"
  },
  "API_AUTH_DESCRIPTION": {
    "en": "All requests require an `Authorization` header with a token in the Bearer format.",
    "ru": "Для всех запросов требуется заголовок `Authorization` с токеном в формате Bearer.",
    "es": "Todas las solicitudes requieren un encabezado `Authorization` con un token en formato Bearer.",
    "pt": "Todas as solicitações exigem um cabeçalho `Authorization` com um token no formato Bearer.",
    "de": "Alle Anfragen erfordern einen `Authorization`-Header mit einem Token im Bearer-Format."
  },
  "API_AUTH_ERROR": {
    "en": "Without a valid token, requests will return an error.",
    "ru": "Без корректного токена запросы будут возвращать ошибку.",
    "es": "Sin un token válido, las solicitudes devolverán un error.",
    "pt": "Sem um token válido, as solicitações retornarão um erro.",
    "de": "Ohne ein gültiges Token werden die Anfragen einen Fehler zurückgeben."
  },
  "API_GENERAL_INFO": {
    "en": "General Information",
    "ru": "Общие сведения",
    "es": "Información General",
    "pt": "Informações Gerais",
    "de": "Allgemeine Informationen"
  },
  "API_ALL_GET": {
    "en": "All methods use the GET HTTP method and accept parameters via query string.",
    "ru": "Все методы используют HTTP-метод GET и принимают параметры через query string.",
    "es": "Todos los métodos utilizan el método HTTP GET y aceptan parámetros a través de cadena de consulta.",
    "pt": "Todos os métodos usam o método HTTP GET e aceitam parâmetros via string de consulta.",
    "de": "Alle Methoden verwenden die GET HTTP-Methode und akzeptieren Parameter über die Query-String."
  },
  "API_DATE_FORMAT": {
    "en": "Date format is `YYYY-MM-DD`.",
    "ru": "Формат дат – `YYYY-MM-DD`.",
    "es": "El formato de fecha es `YYYY-MM-DD`.",
    "pt": "O formato de data é `YYYY-MM-DD`.",
    "de": "Das Datumsformat ist `YYYY-MM-DD`."
  },
  "API_JSON_RESPONSE": {
    "en": "Responses are returned in JSON format.",
    "ru": "Ответы возвращаются в формате JSON.",
    "es": "Las respuestas se devuelven en formato JSON.",
    "pt": "As respostas são retornadas em formato JSON.",
    "de": "Antworten werden im JSON-Format zurückgegeben."
  },
  "API_GET_PAGES": {
    "en": "1. Getting the List of Pages",
    "ru": "1. Получение списка страниц",
    "es": "1. Obtener la Lista de Páginas",
    "pt": "1. Obtendo a Lista de Páginas",
    "de": "1. Abrufen der Seitenliste"
  },
  "API_GET_PAGES_DESC": {
    "en": "Returns a list of all pages linked to the current user (determined by the token).",
    "ru": "Возвращает список всех страниц, привязанных к текущему пользователю (определяется по токену).",
    "es": "Devuelve una lista de todas las páginas vinculadas al usuario actual (determinado por el token).",
    "pt": "Retorna uma lista de todas as páginas vinculadas ao usuário atual (determinado pelo token).",
    "de": "Gibt eine Liste aller Seiten zurück, die mit dem aktuellen Benutzer verknüpft sind (wird durch das Token bestimmt)."
  },
  "API_HEADERS": {
    "en": "Headers",
    "ru": "Headers",
    "es": "Headers",
    "pt": "Headers",
    "de": "Headers"
  },
  "API_REQUEST_PARAMS": {
    "en": "Request Parameters",
    "ru": "Параметры запроса",
    "es": "Parámetros de la Solicitud",
    "pt": "Parâmetros da Solicitação",
    "de": "Anfrageparameter"
  },
  "API_NO_PARAMS": {
    "en": "No additional query parameters. Only the authorization header is used.",
    "ru": "Нет дополнительных query-параметров. Используется только заголовок авторизации.",
    "es": "Sin parámetros de consulta adicionales. Solo se utiliza el encabezado de autorización.",
    "pt": "Sem parâmetros de consulta adicionais. Apenas o cabeçalho de autorização é usado.",
    "de": "Keine zusätzlichen Abfrageparameter. Es wird nur der Autorisierungs-Header verwendet."
  },
  "API_REQUEST_EXAMPLE": {
    "en": "Request Example",
    "ru": "Пример запроса",
    "es": "Ejemplo de Solicitud",
    "pt": "Exemplo de Solicitação",
    "de": "Anforderungsbeispiel"
  },
  "API_RESPONSE_EXAMPLE": {
    "en": "Response Example (JSON)",
    "ru": "Пример ответа (JSON)",
    "es": "Ejemplo de Respuesta (JSON)",
    "pt": "Exemplo de Resposta (JSON)",
    "de": "Antwortbeispiel (JSON)"
  },
  "API_KEY_FIELDS": {
    "en": "Key Fields in the `pages` Object",
    "ru": "Ключевые поля в объекте `pages`",
    "es": "Campos Clave en el Objeto `pages`",
    "pt": "Campos Chave no Objeto `pages`",
    "de": "Schlüsselfelder im `pages`-Objekt"
  },
  "API_PAGE_ID_DESC": {
    "en": "Internal page ID in the system.",
    "ru": "Внутренний ID страницы в системе.",
    "es": "ID interno de la página en el sistema.",
    "pt": "ID interno da página no sistema.",
    "de": "Interne Seiten-ID im System."
  },
  "API_SOCIAL_DESC": {
    "en": "Social network type, for example `IG` (Instagram), `FB` (Facebook), `VK` (VKontakte).",
    "ru": "Тип соцсети, например `IG` (Instagram), `FB` (Facebook), `VK` (ВКонтакте).",
    "es": "Tipo de red social, por ejemplo `IG` (Instagram), `FB` (Facebook), `VK` (VKontakte).",
    "pt": "Tipo de rede social, por exemplo `IG` (Instagram), `FB` (Facebook), `VK` (VKontakte).",
    "de": "Art des sozialen Netzwerks, zum Beispiel `IG` (Instagram), `FB` (Facebook), `VK` (VKontakte)."
  },
  "API_HASH_ID_DESC": {
    "en": "Unique identifier for subsequent statistics requests.",
    "ru": "Уникальный идентификатор для последующих запросов статистики.",
    "es": "Identificador único para solicitudes de estadísticas posteriores.",
    "pt": "Identificador único para solicitações de estatísticas subsequentes.",
    "de": "Eindeutige Kennung für nachfolgende Statistikanfragen."
  },
  "API_GET_STATS": {
    "en": "2. Getting Statistics for Pages",
    "ru": "2. Получение статистики по страницам",
    "es": "2. Obtener Estadísticas de las Páginas",
    "pt": "2. Obtendo Estatísticas para Páginas",
    "de": "2. Abrufen von Statistiken für Seiten"
  },
  "API_GET_STATS_DESC": {
    "en": "Returns detailed statistics for the specified pages. For selection, you need to specify `hash_ids` and a time range.",
    "ru": "Возвращает детальную статистику по указанным страницам. Для выборки необходимо указать `hash_ids`, а также временной диапазон.",
    "es": "Devuelve estadísticas detalladas de las páginas especificadas. Para la selección, debe especificar `hash_ids` y un rango de tiempo.",
    "pt": "Retorna estatísticas detalhadas para as páginas especificadas. Para a seleção, você precisa especificar `hash_ids` e um intervalo de tempo.",
    "de": "Gibt detaillierte Statistiken für die angegebenen Seiten zurück. Für die Auswahl müssen Sie `hash_ids` und einen Zeitbereich angeben."
  },
  "API_HASH_IDS_DESC": {
    "en": "One or more `hash_id` separated by commas.",
    "ru": "Один или несколько `hash_id` через запятую.",
    "es": "Uno o más `hash_id` separados por comas.",
    "pt": "Um ou mais `hash_id` separados por vírgulas.",
    "de": "Eine oder mehrere `hash_id` durch Kommas getrennt."
  },
  "API_START_DATE_DESC": {
    "en": "Start date of the period in `YYYY-MM-DD` format.",
    "ru": "Дата начала периода в формате `YYYY-MM-DD`.",
    "es": "Fecha de inicio del período en formato `YYYY-MM-DD`.",
    "pt": "Data de início do período no formato `YYYY-MM-DD`.",
    "de": "Startdatum des Zeitraums im Format `YYYY-MM-DD`."
  },
  "API_END_DATE_DESC": {
    "en": "End date of the period in `YYYY-MM-DD` format.",
    "ru": "Дата окончания периода в формате `YYYY-MM-DD`.",
    "es": "Fecha de finalización del período en formato `YYYY-MM-DD`.",
    "pt": "Data de término do período no formato `YYYY-MM-DD`.",
    "de": "Enddatum des Zeitraums im Format `YYYY-MM-DD`."
  },
  "API_CURL_EXAMPLE": {
    "en": "cURL Example",
    "ru": "Пример cURL",
    "es": "Ejemplo de cURL",
    "pt": "Exemplo de cURL",
    "de": "cURL-Beispiel"
  },
  "API_STATUS_CODES": {
    "en": "Status Codes and Errors",
    "ru": "Коды статусов и ошибки",
    "es": "Códigos de Estado y Errores",
    "pt": "Códigos de Status e Erros",
    "de": "Statuscodes und Fehler"
  },
  "API_STATUS_DESC": {
    "en": "On a successful request, the `status` field usually has a value of `'OK'`. In case of an error, `'ERROR'` may be returned, and the response body will contain additional information.",
    "ru": "При успешном запросе поле `status` обычно имеет значение `'OK'`. В случае ошибки может вернуться `'ERROR'`, а тело ответа будет содержать дополнительную информацию.",
    "es": "En una solicitud exitosa, el campo `status` generalmente tiene un valor de `'OK'`. En caso de error, se puede devolver `'ERROR'`, y el cuerpo de la respuesta contendrá información adicional.",
    "pt": "Em uma solicitação bem-sucedida, o campo `status` geralmente tem um valor de `'OK'`. Em caso de erro, `'ERROR'` pode ser retornado, e o corpo da resposta conterá informações adicionais.",
    "de": "Bei einer erfolgreichen Anfrage hat das Feld `status` normalerweise den Wert `'OK'`. Im Fehlerfall kann `'ERROR'` zurückgegeben werden, und der Antworttext enthält zusätzliche Informationen."
  },
  "API_AUTH_ERROR_DESC": {
    "en": "If the `Authorization` header is missing or invalid, a 401 Unauthorized may be returned.",
    "ru": "Если заголовок `Authorization` отсутствует или недействителен, может вернуться 401 Unauthorized.",
    "es": "Si falta el encabezado `Authorization` o no es válido, se puede devolver un 401 No autorizado.",
    "pt": "Se o cabeçalho `Authorization` estiver ausente ou inválido, um 401 Não Autorizado pode ser retornado.",
    "de": "Wenn der `Authorization`-Header fehlt oder ungültig ist, kann ein 401 Unauthorized zurückgegeben werden."
  },
  "API_ADDITIONAL_NOTES": {
    "en": "Notes",
    "ru": "Примечания",
    "es": "Notas",
    "pt": "Notas",
    "de": "Zusätzliche Hinweise"
  },
  "API_MULTIPLE_HASH_IDS": {
    "en": "When passing multiple `hash_ids`: `?hash_ids=abc123,xyz789,def456&start=2025-03-01&end=2025-03-10`",
    "ru": "При передаче нескольких `hash_ids`: `?hash_ids=abc123,xyz789,def456&start=2025-03-01&end=2025-03-10`",
    "es": "Al pasar múltiples `hash_ids`: `?hash_ids=abc123,xyz789,def456&start=2025-03-01&end=2025-03-10`",
    "pt": "Ao passar vários `hash_ids`: `?hash_ids=abc123,xyz789,def456&start=2025-03-01&end=2025-03-10`",
    "de": "Beim Übergeben mehrerer `hash_ids`: `?hash_ids=abc123,xyz789,def456&start=2025-03-01&end=2025-03-10`"
  },
  "API_EMPTY_RESULT": {
    "en": "If there is no data in the returned arrays, there will be an empty result (for example, `'activity': []`).",
    "ru": "При отсутствии данных в возвращаемых массивах будет пустой результат (например, `'activity': []`).",
    "es": "Si no hay datos en las matrices devueltas, habrá un resultado vacío (por ejemplo, `'activity': []`).",
    "pt": "Se não houver dados nos arrays retornados, haverá um resultado vazio (por exemplo, `'activity': []`).",
    "de": "Wenn in den zurückgegebenen Arrays keine Daten vorhanden sind, gibt es ein leeres Ergebnis (zum Beispiel `'activity': []`)."
  },
  "API_DATE_FORMAT_STRICT": {
    "en": "The date format is strictly `YYYY-MM-DD`, and the start date cannot be greater than the end date.",
    "ru": "Формат даты строго `YYYY-MM-DD`, и дата начала не может быть больше даты окончания.",
    "es": "El formato de fecha es estrictamente `YYYY-MM-DD`, y la fecha de inicio no puede ser mayor que la fecha de finalización.",
    "pt": "O formato da data é estritamente `YYYY-MM-DD`, e a data de início não pode ser maior que a data de término.",
    "de": "Das Datumsformat ist streng `YYYY-MM-DD`, und das Startdatum kann nicht größer als das Enddatum sein."
  },
  "DASH_DAILY": {
    "en": "1D",
    "ru": "1Д",
    "es": "1D",
    "pt": "1D",
    "de": "1D"
  },
  "DASH_WEEKLY": {
    "en": "1W",
    "ru": "1Н",
    "es": "1W",
    "pt": "1W",
    "de": "1W"
  },
  "DASH_MONTHLY": {
    "en": "1M",
    "ru": "1М",
    "es": "1M",
    "pt": "1M",
    "de": "1M"
  }
}

export default translations;
