const SOCAIL_VISIBILITY_MAP = {
    likes: ['VK', 'FB', 'IG'],
    lr: ['VK', 'FB', 'IG'],
    ir: ['VK', 'FB', 'IG'],
    reposts: ['VK', 'FB'],
    comments: ['VK', 'FB', 'IG'],
    tr: ['VK', 'FB', 'IG'],
    erPost: ['VK', 'FB', 'IG'],
    erDay: ['VK', 'FB', 'IG'],
    views: ['VK'],
    erView: ['VK'],
    err: ['VK', 'FB', 'IG'],
    vrPost: ['VK'],
    vrDay: ['VK'],
    er_progressive: ['VK', 'FB', 'IG']
}

function visibilityByPage(type, social) {
    return SOCAIL_VISIBILITY_MAP[type].indexOf(social) >= 0
}

// export const visibilityByPages = (type, pages) => pages.some(page => SOCAIL_VISIBILITY_MAP[type].indexOf(page.social) >= 0);

function visibilityByPages(type, pages) {
    // If only pages argument is provided, assume er_progressive type
    if (!pages) {
        pages = type;
        type = 'er_progressive';
    }
    
    if (!Array.isArray(pages)) return false;
    if (!SOCAIL_VISIBILITY_MAP[type]) return false;
    
    return pages.some(page => SOCAIL_VISIBILITY_MAP[type].indexOf(page.social) >= 0)
}

function visibilityByAdminPage({ isAdmin }) {
    return !!isAdmin
}

function visibilityByAdminPages(pages) {
    if (!Array.isArray(pages)) return false;
    return pages.some(page => !!page.isAdmin)
}

export default {
  visibilityByPage,
  visibilityByPages,
  visibilityByAdminPage,
  visibilityByAdminPages,
};
