import {
  INTRO_SET_FILTER,
  INTRO_SET_PERIOD,
  INTRO_SET_ADDED,
  INTRO_SET_SELECTED,
  INTRO_SET_PAGES,
  INTRO_DROP_SELECTED,
  INTRO_SET_DISABLEDDATE,
  INTRO_UPDATE_STATUS_PAGES,
  INTRO_DELETE_ADDED,
  INTRO_UPDATE_ADDED,
  INTRO_SET_DELETED,
  INTRO_SET_SELECTED_ACCESS,
  INTRO_SEND_REPORT,
  INTRO_SET_ACCESS_MANAGER_VISIBLE,
  INTRO_SET_ADD_PAGE_VISIBLE,
  INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE,
  INTRO_SET_SEARCH_INPUT,
  INTRO_SET_ALERT,
  INTRO_SET_PAGES_RELOAD,
  INTRO_SET_SETTINGS_MODAL_VISIBLE,
  INTRO_SET_SETTINGS_ACTIVE_TAB,
  INTRO_OPEN_SETTINGS_MODAL,
  INTRO_SET_DELETE_CONFIRM,
  INTRO_SET_RESTORE_CONFIRM,
  INTRO_REANALYZE_PAGE,
} from "../actions/intro";
import { DASH_RELOAD_DATA_MUTATION } from "../actions/dash";
import { REPORTS_DOWNLOAD } from "../actions/reports";
import module from "../../models/AxiosModule";
const axios = module.Axios;
import pageStat from "../../models/page-stat";
import pageUtils from "@/models/page-utils";
import colors from "../../assets/selectedPagesColors.json";
import Vue from "vue";
import i18n from '@/i18nSetup';

const state = {
  pages: [],
  searchWord: "",
  filterType: localStorage.getItem("introFilter") || "By Name",
  period: "",
  addedHashes: [],
  pagesLoaded: false,
  disabledData: {
    minDate: "",
    maxDate: "",
  },
  selectedAccessAccount: [],
  pagesByHash: {},
  addPageVisible: false,
  autoReportsSettingsVisible: false,
  accessManagerVisible: false,
  sort: {
    name: 0,
    followers: 0,
    growth: 0,
    reach: 0,
    er: 0,
    type: "name",
  },
  alertStatus: false,
  alertText: "",
  colors: Object.entries(colors).reverse(),
  settingsModalVisible: false,
  settingsActiveTab: 'access',
  deleteConfirmId: null,
  restoreConfirmId: null,
};

const getters = {
  getSortStatus: (state) => state.sort,
  getPages: (state) => state.pages,
  sortPages: (state) => {
    if (state.sort[state.sort.type] == 0) return state.pages;
    const params = {
      name: "owner_name",
      followers: "followers",
      reach: "reach_avg",
      er: "er",
      growth: "growth",
    };

    const sortBy = params[state.sort.type];
    const copyPages = JSON.parse(JSON.stringify(state.pages));
    return copyPages.sort(({ [sortBy]: a }, { [sortBy]: b }) => {
      if (sortBy != "owner_name") {
        a = parseFloat(isNaN(a) ? -1 : a);
        b = parseFloat(isNaN(b) ? -1 : b);
      }

      if (state.sort[state.sort.type] == 1) {
        if (a < b) return 1;
        if (a > b) return -1;
        return 0;
      }

      if (state.sort[state.sort.type] == 2) {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      }
    });
  },
  getPagesBySearchWord: (state, getters) => {
    if (!state.searchWord) return getters.sortPages;
    return getters.sortPages.filter((page) => {
      return page.owner_name
        .toLowerCase()
        .includes(state.searchWord.toLowerCase());
    });
  },
  getPagesByName: (state, getters) => {
    return pageUtils.filterUniquePagesByAdmin(
      getters.getPagesBySearchWord.filter((page) => page.status !== "deleted")
    );
  },
  getPagesBySocial: (state, getters) => {
    const socPages = {
      VK: [],
      FB: [],
      IG: [],
    };

    pageUtils
      .groupBySocial(
        pageUtils.filterUniquePagesByAdmin(
          getters.getPagesBySearchWord.filter(
            (page) => page.status !== "deleted"
          )
        )
      )
      .forEach((soc) => {
        socPages[soc.social] = soc.pages;
      });
    return socPages;
  },
  getPagesByOwners: (state, getters) => {
    return pageUtils.groupByOwners(
      getters.getPagesBySearchWord.filter((page) => page.status !== "deleted")
    );
  },
  getDeletedPages: (state, getters) => {
    return getters.getPagesBySearchWord.filter(
      (page) => page.status == "deleted"
    );
  },
  getDeletedPagesByOwners: (state, getters) => {
    return pageUtils.groupByOwners(
      getters.getPagesBySearchWord.filter((page) => page.status == "deleted")
    );
  },
  getIntroFilterType: (state) => state.filterType,
  getPeriod: (state) => state.period,
  getAddedHashes: (state) => state.addedHashes,
  getSelectedPages: (state) => state.pages.filter((page) => page.isSelected),
  isPagesLoaded: (state) => state.pagesLoaded,
  getSelectedAccessAcount: (state) => state.selectedAccessAccount,
  getDisabledDates: ({ disabledData: { minDate, maxDate } }) => (date) => {
    date.setHours(0);
    return date < new Date(minDate) || date > new Date(maxDate);
  },
  isAddPageVisible: (state) => state.addPageVisible,
  isAccessManagerVisible: (state) => state.accessManagerVisible,
  isAutoReportsSettingsVisible: (state) => state.autoReportsSettingsVisible,
  getAlertStatus: (state) => state.alertStatus,
  getAlertText: (state) => state.alertText,
  getSearchInputText: (state) => state.searchWord,
  isSettingsModalVisible: (state) => state.settingsModalVisible,
  getSettingsActiveTab: (state) => state.settingsActiveTab,
  getDeleteConfirmId: state => state.deleteConfirmId,
  getRestoreConfirmId: state => state.restoreConfirmId,
};

const actions = {
  [INTRO_SET_PAGES]: async ({ commit, state, getters }, data) => {
    const {
      data: { dates, pages: rawPages, metrics, activity },
    } = await axios.post("get-pages", {
      ...state.period,
    });
    const [pages, added, pagesByHash] = pageStat.analizeIntroPages(
      rawPages,
      metrics,
      activity,
      state.addedHashes
    );

    const selected = getters.getSelectedPages;

    selected.forEach((page) => {
      pages[pagesByHash[page.hash_id]].isSelected = true;
      pages[pagesByHash[page.hash_id]].colorCode = page.colorCode;
      pages[pagesByHash[page.hash_id]].colorName = page.colorName;
    });

    commit(INTRO_SET_DISABLEDDATE, dates);
    commit(INTRO_SET_PAGES, {
      pages,
      pagesByHash,
    });
    if (added.length) commit(INTRO_SET_ADDED, added);
  },
  [INTRO_SET_SELECTED]: ({ commit, dispatch, state, rootGetters }, data) => {
    const isSelected = state.pages[state.pagesByHash[data.hash_id]].isSelected;
    if (rootGetters.getSelectedPages.length < 5) {
      // const [colorName, colorCode] = Object.entries(colors)[rootGetters.getSelectedPages.length]

      commit(INTRO_SET_SELECTED, { ...data });
      if (rootGetters.getAnalizedPages.length) {
        dispatch(DASH_RELOAD_DATA_MUTATION);
      }
    } else if (isSelected) {
      commit(INTRO_SET_SELECTED, { ...data });
      dispatch(DASH_RELOAD_DATA_MUTATION);
    } else {
      dispatch(INTRO_SET_ALERT, "PAGE_LIMIT");
    }
  },
  [INTRO_UPDATE_STATUS_PAGES]: async ({ commit, dispatch, state }, data) => {
    const {
      data: { dates, pages: rawPages, metrics, activity },
    } = await axios.post("get-pages", {
      ...state.period,
      hash_ids: state.addedHashes,
    });
    const finished = pageStat
      .analizeIntroPages(rawPages, metrics, activity, state.addedHashes)[0]
      .filter((page) => page.status != "added");
    const finishedAdd = state.addedHashes.filter(
      (v) => finished.filter((f) => f.hash_id == v).length
    );

    if (finishedAdd.length) {

      finishedAdd.forEach(f => {
        const pageName = finished.find(t => t.hash_id == f)
        const message = i18n.t('ALERT_PAGE_ANALYZED', {page_name: pageName?.fullowner_name || pageName?.owner_name});
        dispatch(
          'alerts/addAlert',
          {
            message,
            color: 'white'
          },
          { root: true }
        );  
      })

      commit(INTRO_DELETE_ADDED, finishedAdd);
    }

    commit(INTRO_UPDATE_STATUS_PAGES, finished);
  },
  [INTRO_SEND_REPORT]: async ({ commit, dispatch, getters }, type) => {
    const hash_ids = getters.getSelectedPages.map((s) => s.hash_id).join(",");
    const { start, end } = getters.getPeriod;
    await axios
      .post("create-report", { hash_ids, start, end, type })
      .then((response) => {
        const filename = response.data.hashId.substring(0, 8);
        dispatch(REPORTS_DOWNLOAD, { hashId: response.data.hashId, type }).then(
          (response) => {
            if (response.status != 200) return;
            const fileURL = window.URL.createObjectURL(response.data);
            const fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", `${filename}.${type}`);
            fileLink.click();
          }
        );
      });
  },
  [INTRO_SET_ALERT]: ({ commit, dispatch }, payload) => {
    // Check if payload is a string (old behavior) or an object (new behavior)
    const message = typeof payload === 'string' 
      ? i18n.t('ERRORS_PAGE_LIMIT') 
      : payload.text || i18n.t('ERRORS_PAGE_LIMIT');
    
    // Use color from payload or default to empty string
    const color = typeof payload === 'object' && payload.color ? payload.color : '';
    
    dispatch(
      'alerts/addAlert',
      {
        message: message,
        duration: 5000,
        color: color
      },
      { root: true }
    );
  },
  [INTRO_OPEN_SETTINGS_MODAL]: ({ commit }, tabName) => {
    commit(INTRO_SET_SETTINGS_ACTIVE_TAB, tabName);
    commit(INTRO_SET_SETTINGS_MODAL_VISIBLE, true);
  },
  [INTRO_REANALYZE_PAGE]: async ({ commit, dispatch }, { hash_id }) => {
    try {
      await axios.post("reanalyze-page", { hash_id });
      
      dispatch(
        'alerts/addAlert',
        {
          message: i18n.t('PAGE_REANALYZE_SUCCESS'),
          duration: 5000,
          color: '',
        },
        { root: true }
      );
      
      // Update the page status to "analyzing"
      const page = state.pages[state.pagesByHash[hash_id]];
      if (page) {
        const updatedPage = { ...page, status: "analyzing" };
        Vue.set(state.pages, state.pagesByHash[hash_id], updatedPage);
      }
      
      return { success: true };
    } catch (error) {
      dispatch(
        'alerts/addAlert',
        {
          message: error.response?.data?.error_message || i18n.t('PAGE_REANALYZE_ERROR'),
          duration: 5000,
          color: 'red',
        },
        { root: true }
      );
      
      return { 
        success: false,
        error: error.response?.data || error
      };
    }
  },
};

const mutations = {
  [INTRO_SET_FILTER]: (state, resp) => {
    localStorage.setItem("introFilter", resp);
    state.filterType = resp;
  },

  [INTRO_SET_PERIOD]: (state, resp) => {
    state.period = resp;
  },

  [INTRO_UPDATE_ADDED]: (state, hash_id) => {
    if (!state.addedHashes.includes(hash_id)) {
      state.addedHashes.push(hash_id);

      const page = {
        ...state.pages[state.pagesByHash[hash_id]],
        status: "added",
      };
      Vue.set(state.pages, state.pagesByHash[hash_id], page);
    }
  },
  [INTRO_SET_ADDED]: (state, hashes) => {
    state.addedHashes = hashes;
  },
  [INTRO_DELETE_ADDED]: (state, hashes) => {
    state.addedHashes = state.addedHashes.filter(
      (v) => !hashes.filter((f) => f == v).length
    );
  },
  [INTRO_SET_PAGES]: (state, { pages, pagesByHash }) => {
    state.pages = pages;
    state.pagesByHash = pagesByHash;
    state.pagesLoaded = true;
  },
  [INTRO_SET_PAGES_RELOAD]: (state, { pages, pagesByHash }) => {
    state.pages = pages;
    state.pagesByHash = pagesByHash;
    state.pagesLoaded = false;
  },
  [INTRO_SET_SELECTED]: (state, { hash_id }) => {
    const isSelected = state.pages[state.pagesByHash[hash_id]].isSelected;

    if (isSelected) {
      const length = state.colors.push([
        state.pages[state.pagesByHash[hash_id]].colorName,
        state.pages[state.pagesByHash[hash_id]].colorCode,
      ]);

      if (length == 5) state.colors = Object.entries(colors).reverse();
    } else {
      const [colorName, colorCode] = state.colors.pop();
      state.pages[state.pagesByHash[hash_id]].colorName = colorName;
      state.pages[state.pagesByHash[hash_id]].colorCode = colorCode;
    }
    state.pages[state.pagesByHash[hash_id]].isSelected = !isSelected;
  },
  [INTRO_DROP_SELECTED]: (state) => {
    for (const page of state.pages) {
      page.isSelected = false;
    }
    state.colors = Object.entries(colors).reverse();
  },
  [INTRO_SET_DISABLEDDATE]: (state, { minDate, maxDate }) => {
    state.disabledData = {
      minDate,
      maxDate,
    };
  },
  [INTRO_UPDATE_STATUS_PAGES]: (state, finished) => {
    for (const fpage of finished) {
      for (const page of state.pages) {
        if (fpage.hash_id == page.hash_id) {
          for (const key in fpage) {
            page[key] = fpage[key];
          }
        }
      }
    }
  },
  [INTRO_SET_DELETED]: (state, hash_id) => {
    const page = {
      ...state.pages[state.pagesByHash[hash_id]],
      status: "deleted",
    };
    Vue.set(state.pages, state.pagesByHash[hash_id], page);
  },
  [INTRO_SET_SELECTED_ACCESS]: (state, access) => {
    state.selectedAccessAccount = access;
  },
  [INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE]: (state, status) => {
    state.autoReportsSettingsVisible = status;
  },
  [INTRO_SET_ACCESS_MANAGER_VISIBLE]: (state, status) => {
    state.accessManagerVisible = status;
  },
  [INTRO_SET_ADD_PAGE_VISIBLE]: (state, status) => {
    state.addPageVisible = status;
  },
  [INTRO_SET_SEARCH_INPUT]: (state, data) => {
    state.searchWord = data;
  },
  [INTRO_SET_ALERT]: (state, { status, text }) => {
    state.alertStatus = status;
    state.alertText = text;
  },
  [INTRO_SET_SETTINGS_MODAL_VISIBLE]: (state, status) => {
    state.settingsModalVisible = status;
  },
  [INTRO_SET_SETTINGS_ACTIVE_TAB]: (state, tabName) => {
    state.settingsActiveTab = tabName;
  },
  [INTRO_SET_DELETE_CONFIRM]: (state, hash_id) => {
    state.deleteConfirmId = hash_id;
  },
  [INTRO_SET_RESTORE_CONFIRM]: (state, hash_id) => {
    state.restoreConfirmId = hash_id;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
