<template>
  <div>
    <div class="com-pages">
      <div class="com-main1">
        <div class="panel main-info">
          <h2>{{ $t('STATS_TABS_COMMON_SUBSCRIBERS') }}</h2>
          <div class="cont">
            <div class="info-list">
              <div
                v-for="(page, index) in getAnalizedPages"
                :class="`dash-page ${isActivePage(page.hash_id)}`"
                :key="index"
                @click="changeStatusPageSubChat(page.hash_id)"
              >
                <div class="soc-block">
                  <span
                  :class="`soc-ico soc-${page.social.toLowerCase()}`"
                ></span>
                </div>
                <img :src="page.owner_avatar" alt />

                
                <div class="d-page-cred">
                  <p :title="page.owner_name">{{ page.owner_name_short }}</p>
                  <span class="foll-count"><i class="bi bi-people-fill"></i>{{ page.stats.followers.subscribers.value }}</span>
                  <span :class="`growth ${classGrowth(page)}`">{{
                    page.stats.followers.growth.value
                  }}</span>
                </div>
              </div>
            </div>
            <div class="m-chart" style="width: 100%">
              <div
                class="chart"
                :ref="`overviewManySubscribers`"
              ></div>
              <div class="period-btns">
                <button @click="setPeriod('daily')" :class="{ active: activePeriod === 'daily' }">{{ $t('DASH_DAILY') }}</button>
                <button @click="setPeriod('weekly')" :class="{ active: activePeriod === 'weekly' }">{{ $t('DASH_WEEKLY') }}</button>
                <button @click="setPeriod('monthly')" :class="{ active: activePeriod === 'monthly' }">{{ $t('DASH_MONTHLY') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="panel main-total">
          <h2>{{ $t('DASH_TOTAL') }} <div class="settings"><button @click="toggleTotalView" :title="showTotalTable ? $t('DASH_SHOW_CHART') : $t('DASH_SHOW_TABLE')"><i class="bi" :class="showTotalTable ? 'bi-bar-chart' : 'bi-table'"></i></button></div></h2>
          <div v-show="!showTotalTable">
            <div class="cont">
              <div class="metrics-list">
                <span class="m-metric">
                  <i class="bi bi-heart"></i>
                  {{ $t('STATS_TABLE_TOTAL_ALL_LIKES') }}
                </span>
                <span v-if="canShowShares" class="m-metric">
                  <i class="bi bi-arrow-return-left"></i>
                  {{ $t('STATS_TABLE_TOTAL_ALL_REPOSTS') }}
                </span>
                <span class="m-metric">
                  <i class="bi bi-chat-right-dots"></i>
                  {{ $t('STATS_TABLE_TOTAL_ALL_COMMENTS') }}
                </span>
                <span class="m-metric">
                  <i class="bi bi-collection"></i>
                {{ $t('STATS_TABLE_TOTAL_ALL_RECORDS') }}
                </span>
                <span v-if="canShowViews" class="m-metric">
                  <i class="bi bi-eye"></i>
                  {{ $t('STATS_TABLE_TOTAL_ALL_VIEWS') }}
                </span>
                <span v-if="canShowStories" class="m-metric">
                  <i class="bi bi-stickies"></i>
                  {{ $t('STATS_TABLE_TOTAL_STORIES') }}
                </span>
                <span v-if="canShowInteractions" class="m-metric">
                  <i class="bi bi-lightning-charge"></i>
                  {{ $t('STATS_TABLE_TOTAL_INTERACTIONS') }}
                </span>
              </div>
              <div class="m-chart">
                <div
                  class="chart"
                  :style="`height: ${chartNames.length * 50}px;`"
                  :ref="`overviewManyAll`"
                ></div>
              </div>
            </div>
          </div>
          <div v-show="showTotalTable">
            <div class="cont table">
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>{{ $t('DASH_METRICS') }}</th>
                      <th v-for="(page, index) in getAnalizedPages" :key="index">
                        <div :class="`soc-sea`">
                          <span class="page-caption">{{ page.owner_name_short }}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="social-row">
                      <td>{{ $t('INTRO_TABS_SOCIAL') }}</td>
                      <td v-for="(page, index) in getAnalizedPages" :key="index">
                        <span :class="`soc-ico soc-${page.social.toLowerCase()}`"></span>
                      </td>
                    </tr>
                    <tr v-for="name in chartNames" :key="name">
                      <td>{{ getTotalMetricLabel(name) }}</td>
                      <td v-for="(page, pageIndex) in getAnalizedPages" :key="`${name}-${pageIndex}`">
                        {{ page.stats.total[name] ? page.stats.total[name].value : '-' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="com-main2">
        <div class="panel main-eng">
          <h2>{{ $t('DASH_ENG') }} <div class="settings"><button @click="toggleView" :title="showTable ? $t('DASH_SHOW_CHART') : $t('DASH_SHOW_TABLE')"><i class="bi" :class="showTable ? 'bi-bar-chart' : 'bi-table'"></i></button></div></h2>
          <div v-show="!showTable">
            <div class="cont charts">
              <div class="metrics-tabs">
                <div
                  class="metric-block"
                  :class="activeClassEngagement(key)"
                  :key="index"
                  v-for="({ key, locale }, index) in engagementMap"
                  @click="() => selectActiveEngagement(key)"
                >
                  {{ locale }}
                  <div class="info-panel" :class="{ 'active': activeInfoPanel === index }">
                    <i class="bi bi-info-square" @click.stop="toggleInfoPanel($event, index)"></i>
                    <div class="info-panel-content" :ref="`infoPanelContent_${index}`">
                      {{ getMetricDescription(key) }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="m-tabs-charts">
                <!-- <scroll-loader
                  class="dash-loader"
                  :loader-method="() => {}"
                  :loader-enable="!gaugeLoaded"></scroll-loader> -->
                <div class="radar-overview"
                  :ref="`gaugeOverview`"
                ></div>
                <ul class="tooltip-list">
                  <template v-for="(page, index) in getAnalizedPages">
                    <li v-if="page.stats.engagement[activeEngagement]" :key="index"
                      @mouseover="onLegendItemMouseOver(index)"
                      @mouseleave="onLegendItemMouseLeave(index)"
                      >
                      <div :class="`soc-sea ${page.colorName}`">
                        <span
                          :class="`soc-ico soc-${page.social.toLowerCase()}`"
                        ></span>
                        <span class="page-caption">{{ page.owner_name_short }}</span>
                      </div>
                      {{ page.stats.engagement[activeEngagement].value + "%" }}
                    </li>
                  </template>
                </ul>
              </div>
            </div>
          </div>
          <div v-show="showTable">
            <div class="cont table">
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>{{ $t('DASH_METRICS') }}</th>
                      <th v-for="(page, index) in getAnalizedPages" :key="index">
                        <div :class="`soc-sea`">
                          
                          <span class="page-caption">{{ page.owner_name_short }}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="social-row">
                      <td>{{ $t('INTRO_TABS_SOCIAL') }}</td>
                      <td v-for="(page, index) in getAnalizedPages" :key="index">
                        <span :class="`soc-ico soc-${page.social.toLowerCase()}`"></span>
                      </td>
                    </tr>
                    <tr v-for="({ key, locale }, metricIndex) in engagementMap" :key="metricIndex">
                      <td>{{ locale }}</td>
                      <td v-for="(page, pageIndex) in getAnalizedPages" :key="`${metricIndex}-${pageIndex}`">
                        {{ page.stats.engagement[key] ? formatValue(page.stats.engagement[key].value) : '-' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="panel main-av">
          <h2>{{ $t('DASH_AVE') }} <div class="settings"><button @click="toggleAverageView" :title="showAverageTable ? $t('DASH_SHOW_CHART') : $t('DASH_SHOW_TABLE')"><i class="bi" :class="showAverageTable ? 'bi-bar-chart' : 'bi-table'"></i></button></div></h2>
          <div v-show="!showAverageTable">
            <div class="cont">
              <div class="metrics-tabs">
                <div
                  class="metric-block"
                  :class="activeClassAverage(key)"
                  :key="index"
                  v-for="({ key, locale }, index) in averageMap"
                  @click="selectActiveAverage(key)"
                >
                  {{ locale }}
                </div>
              </div>
              <div class="m-tabs-numbers">
                <div class="m-borders">
                  <template v-for="(page, index) in getAnalizedPages">
                    <div v-if="page.stats.average[activeAverage]" :key="index">
                      {{ page.stats.average[activeAverage].value }}
                      <span :class="`soc-sea ${page.colorName}`">
                        <span
                          :class="`soc-ico soc-${page.social.toLowerCase()}`"
                        ></span>
                        {{ page.owner_name_short }}
                      </span>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div v-show="showAverageTable">
            <div class="cont table">
              <div class="table-container">
                <table>
                  <thead>
                    <tr>
                      <th>{{ $t('DASH_METRICS') }}</th>
                      <th v-for="(page, index) in getAnalizedPages" :key="index">
                        <div :class="`soc-sea`">
                          <span class="page-caption">{{ page.owner_name_short }}</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="social-row">
                      <td>{{ $t('INTRO_TABS_SOCIAL') }}</td>
                      <td v-for="(page, index) in getAnalizedPages" :key="index">
                        <span :class="`soc-ico soc-${page.social.toLowerCase()}`"></span>
                      </td>
                    </tr>
                    <tr v-for="({ key, locale }, metricIndex) in averageMap" :key="metricIndex">
                      <td>{{ locale }}</td>
                      <td v-for="(page, pageIndex) in getAnalizedPages" :key="`${metricIndex}-${pageIndex}`">
                        {{ page.stats.average[key] ? page.stats.average[key].value : '-' }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import linesChart from "@/models/charts/elines";
//import gaugeChart from "@/models/charts/gauge";
import gorizontalColumns from "@/models/charts/egorizontal-columns";

import RadarManyPosts from "@/models/charts/eradar-posts-stats-many-many";

import PageStat from "@/models/page-stat";

import i18n from '@/i18nSetup';

export default {
  name: "Dash-overview-many",
  computed: {
    ...mapGetters([
      "getAnalizedPages",
      "getUnionPagesData",
      "getAnalizedAdminPages",
    ]),
    canShowStories() {
      const { stories } = this.getUnionPagesData;
      return !!stories.length;
    },
    canShowViews() {
      const pagesLength = this.getAnalizedPages.length;
      const noViewsPagesLength = this.getAnalizedPages.filter(
        (page) => page.social == "FB" || page.social == "IG"
      ).length;
      return !(pagesLength === noViewsPagesLength);
    },
    canShowShares() {
      const pagesLength = this.getAnalizedPages.length;
      const noSharesPagesLength = this.getAnalizedPages.filter(
        (page) => page.social == "IG"
      ).length;
      return !(pagesLength === noSharesPagesLength);
    },
    canShowInteractions() {
      return !!this.getAnalizedAdminPages.length;
    },
    chartNames() {
      return [
        "likes",
        "reposts",
        "comments",
        "posts",
        "views",
        "stories",
        "interactions",
      ].filter((name) => {
        if (name == "views") return this.canShowViews;
        else if (name == "stories") return this.canShowStories;
        else if (name == "reposts") return this.canShowShares;
        else if (name == "interactions") return this.canShowInteractions;
        else return true;
      });
    },
    engagementMap() {
      if (!this.getAnalizedPages.length) return [];
      return Object.entries(PageStat.mapStat)
        .filter(([key, value]) => value.type == "engagement")
        .map(([key, value]) => {
          let isValueExist = this.getAnalizedPages
            .map((p) => p.stats.engagement[key])
            .some((statObj) => statObj != undefined);
          if (!isValueExist) return undefined;
          return {
            key,
            locale: i18n.t(`STATS_TABLE_${value.type.toUpperCase()}_${value.locale}`)
          };
        })
        .filter((stat) => stat != undefined);
    },
    averageMap() {
      if (!this.getAnalizedPages.length) return [];
      return Object.entries(PageStat.mapStat)
        .filter(([key, value]) => value.type == "average")
        .map(([key, value]) => {
          let isValueExist = this.getAnalizedPages
            .map((p) => p.stats.average[key])
            .some((statObj) => statObj != undefined);
          if (!isValueExist) return undefined;
          return {
            key,
            locale: i18n.t(`STATS_TABLE_${value.type.toUpperCase()}_${value.locale}`)
          };
        })
        .filter((stat) => stat != undefined);
    },
  },
  data() {
    return {
      activeEngagement: "er_day",
      activeAverage: "likes_avg",
      loaded: false,
      charts: {
        subscribers: [],
        total: [],
        gauge: null,
      },
      gaugeLoaded: false,
      subscribersChatActivePages: {},
      activeInfoPanel: null,
      showTable: false,
      showAverageTable: false,
      showTotalTable: false,
      resizeObserver: null,
      initialRender: true,
      isResizing: false,
      activePeriod: 'daily',
    };
  },
  methods: {
    isActivePage(hash) {
      if (this.subscribersChatActivePages[hash] == undefined) return "";
      if (this.subscribersChatActivePages[hash]) return "active";
      if (!this.subscribersChatActivePages[hash]) return "";
      return "";
    },
    classGrowth(page) {
      const intGrowth = parseInt(page.stats.followers.growth.value);
      if (intGrowth > 0) return "positive";
      if (intGrowth < 0) return "negative";
      return "";
    },
    async renderCharts() {
      try {
        await this.renderSubscribersChart();
        await this.renderTotalCharts();
        await this.renderRadarMany();
      } catch (error) {
        console.error("Error rendering charts:", error);
      }
    },
    async renderSubscribersChart() {
      if (this.isResizing) return;
      this.isResizing = true;
      
      const { pages, datesRangePosts } = this.getUnionPagesData;
      if (this.charts.subscribers.length)
        this.charts.subscribers.map((c) => c.dispose());
      
      const animation = this.initialRender;
      
      try {
        this.charts.subscribers = await Promise.all([
          linesChart({
            pages,
            datesRangePosts,
            name: "subscribers",
            ref: this.$refs.overviewManySubscribers,
            activePages: this.subscribersChatActivePages,
            type: "overview-many",
            animation: animation,
            period: this.activePeriod
          }),
        ]);
      } catch (error) {
        console.error("Error rendering subscribers chart:", error);
      }
      
      this.isResizing = false;
    },
    async renderRadarMany() {
      if (this.isResizing) return;
      this.isResizing = true;
      
      const pages = [...this.getAnalizedPages.entries()];
      const filteredPages = pages.filter(item => item[1].stats.engagement.hasOwnProperty(this.activeEngagement));

      // Dispose existing chart if it exists
      if (this.charts.gauge && this.charts.gauge.chart) {
        this.charts.gauge.chart.dispose();
      }
      
      const animation = this.initialRender;
      
      const obj = {
        chartColumns: [this.activeEngagement],
        chartData: filteredPages.map(item => {
          const chartDataItem = {};
          Object.keys(item[1].stats.engagement).forEach(key => {
            chartDataItem[key] = parseFloat(item[1].stats.engagement[key].value.replace(',', '.'));
          });
          return chartDataItem;
        }),
        colors: filteredPages.map(item => item[1].colorCode),
        pages: filteredPages.map(item => item[1]),
        ref: [this.$refs[`gaugeOverview`]],
        title: 'test',
        animation: animation,
      };

      const chartInstance = await RadarManyPosts(obj);
      this.charts.gauge = chartInstance;
      
      this.isResizing = false;
    },
    async renderTotalCharts() {
      if (this.isResizing) return;
      this.isResizing = true;
      
      const { pages, datesRangePosts, stories } = this.getUnionPagesData;
      
      // Dispose existing chart if it exists
      if (this.charts.total.length) {
        this.charts.total.forEach(chart => {
          if (chart && typeof chart.dispose === 'function') {
            chart.dispose();
          }
        });
      }
      
      const animation = this.initialRender;
      
      this.charts.total = await gorizontalColumns({
        pages,
        datesRange: datesRangePosts,
        names: this.chartNames,
        ref: this.$refs[`overviewManyAll`],
        animation: animation,
      });
      
      this.isResizing = false;
    },
    activeClassEngagement(key) {
      if (key == this.activeEngagement) return "active";
    },
    activeClassAverage(key) {
      if (key == this.activeAverage) return "active";
    },
    selectActiveEngagement(key) {
      this.gaugeLoaded = false;
      this.initialRender = false;
      this.activeEngagement = key;
      
      this.$nextTick(async () => {
        await this.renderRadarMany();
        this.gaugeLoaded = true;
      });
    },
    selectActiveAverage(key) {
      this.activeAverage = key;
    },
    changeStatusPageSubChat(hash) {
      Object.keys(this.subscribersChatActivePages).forEach(key => {
        this.subscribersChatActivePages[key] = false;
      });
      this.subscribersChatActivePages[hash] = true;
      this.subscribersChatActivePages = { ...this.subscribersChatActivePages };
      this.$nextTick(() => {
        this.renderSubscribersChart();
      });
    },
    onLegendItemMouseOver(index) {
      if (this.charts.gauge && this.charts.gauge.chart) {
        this.charts.gauge.chart.dispatchAction({
          type: 'highlight',
          seriesIndex: index,
        });
      }
    },
    onLegendItemMouseLeave(index) {
      if (this.charts.gauge && this.charts.gauge.chart) {
        this.charts.gauge.chart.dispatchAction({
          type: 'downplay',
          seriesIndex: index,
        });
      }
    },
    toggleInfoPanel(event, index) {
      if (this.activeInfoPanel !== null && this.activeInfoPanel !== index) {
        this.activeInfoPanel = null;
      }
      
      if (this.activeInfoPanel === index) {
        this.activeInfoPanel = null;
      } else {
        this.activeInfoPanel = index;
      }
      
      event.stopPropagation();
    },
    handleClickOutside(event) {
      if (this.activeInfoPanel !== null) {
        if (!event.target.closest('.info-panel-content') && 
            !event.target.classList.contains('bi-info-square')) {
          this.activeInfoPanel = null;
        }
      }
    },
    getMetricDescription(metricName) {
      const translationKey = `METRIC_DESC_${metricName.toUpperCase()}`;
      if (this.$te(translationKey)) {
        return this.$t(translationKey);
      } else {
        return this.$t('DASH_NO_DESC_AVAILABLE', 'No description available for this metric.');
      }
    },
    formatValue(value) {
      if (!value) return '-';
      if (typeof value === 'string' && value.includes('%')) {
        return value;
      }
      return value + '%';
    },
    toggleView() {
      this.showTable = !this.showTable;
      
      this.initialRender = false;
      
      if (!this.showTable) {
        this.$nextTick(() => {
          this.renderRadarMany();
        });
      }
    },
    toggleAverageView() {
      this.showAverageTable = !this.showAverageTable;
      this.initialRender = false;
    },
    toggleTotalView() {
      // Store previous state to check if we're actually toggling
      const previousState = this.showTotalTable;
      this.showTotalTable = !this.showTotalTable;
      
      // Set initialRender to false to disable animations on rerender
      this.initialRender = false;
      
      this.$nextTick(() => {
        // Only rerender charts if we actually toggled the view
        if (previousState !== this.showTotalTable) {
          // If switching back to chart view, ensure the total chart is rendered
          if (!this.showTotalTable) {
            this.renderTotalCharts();
          }
          
          // Always rerender the subscribers chart when the layout changes
          // but only do it once
          this.renderSubscribersChart();
        }
      });
    },
    getTotalMetricLabel(name) {
      const translationKey = `STATS_TABLE_TOTAL_ALL_${name.toUpperCase()}`;
      if (this.$te(translationKey)) {
        return this.$t(translationKey);
      } else {
        return name.charAt(0).toUpperCase() + name.slice(1);
      }
    },
    handleResize() {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      
      // Set initialRender to false to disable animations on rerender
      this.initialRender = false;
      
      // Prevent multiple concurrent redraws
      if (this.isResizing) return;
      
      this.$nextTick(() => {
        // Rerender all charts when the container size changes
        this.renderSubscribersChart();
        
        // Only rerender other charts if their respective views are visible
        if (!this.showTable) {
          this.renderRadarMany();
        }
        
        if (!this.showTotalTable) {
          this.renderTotalCharts();
        }
      });
    },
    setPeriod(period) {
      if (this.activePeriod !== period) {
        this.activePeriod = period;
        this.initialRender = false;
        this.renderSubscribersChart();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      const { pages } = this.getUnionPagesData;

      // Initialize active pages
      for (const [index, page] of pages.entries()) {
        if (index === 0) {
          this.subscribersChatActivePages[page.hash_id] = true;
        } else {
          this.subscribersChatActivePages[page.hash_id] = false;
        }
      }
      
      // Create a new object to ensure reactivity
      this.subscribersChatActivePages = Object.assign({}, this.subscribersChatActivePages);
      
      // Initial render with animations
      this.initialRender = true;
      this.renderCharts();
      
      // Disable animations after initial render
      setTimeout(() => {
        this.initialRender = false;
      }, 1000);
    });

    // Set up event listeners
    document.addEventListener('click', this.handleClickOutside);
    window.addEventListener('resize', this.handleResize);
    
    // Set up resize observer
    this.resizeObserver = new ResizeObserver(this.handleResize);
    
    if (this.$refs.overviewManySubscribers) {
      const container = this.$refs.overviewManySubscribers.closest('.panel');
      if (container) {
        this.resizeObserver.observe(container);
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
    
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
