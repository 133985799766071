export const INTRO_SET_FILTER = 'INTRO_SET_FILTER'
export const INTRO_SET_PERIOD = 'INTRO_SET_PERIOD'
export const INTRO_SET_ADDED = 'INTRO_SET_ADDED'
export const INTRO_SET_PAGES = 'INTRO_SET_PAGES'
export const INTRO_SET_SELECTED = 'INTRO_SET_SELECTED'
export const INTRO_DROP_SELECTED = 'INTRO_DROP_SELECTED'
export const INTRO_SET_DISABLEDDATE = 'INTRO_SET_DISABLEDDATE'
export const INTRO_UPDATE_STATUS_PAGES = 'INTRO_UPDATE_STATUS_PAGES'
export const INTRO_DELETE_ADDED = 'INTRO_DELETE_ADDED'
export const INTRO_UPDATE_ADDED = 'INTRO_UPDATE_ADDED'
export const INTRO_SET_DELETED = 'INTRO_SET_DELETED'
export const INTRO_SET_SELECTED_ACCESS = 'INTRO_SET_SELECTED_ACCESS'
export const INTRO_SEND_REPORT = 'INTRO_SEND_REPORT'
export const INTRO_SET_ACCESS_MANAGER_VISIBLE = 'INTRO_SET_ACCESS_MANAGER_VISIBLE'
export const INTRO_SET_ADD_PAGE_VISIBLE = 'INTRO_SET_ADD_PAGE_VISIBLE'
export const INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE = 'INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE'
export const INTRO_SET_SEARCH_INPUT = 'INTRO_SET_SEARCH_INPUT'
export const INTRO_SET_ALERT = 'INTRO_SET_ALERT'
export const INTRO_SET_PAGES_RELOAD = 'INTRO_SET_SELECTED_BY_RELOAD'
export const INTRO_SET_SETTINGS_MODAL_VISIBLE = 'INTRO_SET_SETTINGS_MODAL_VISIBLE';
export const INTRO_SET_SETTINGS_ACTIVE_TAB = 'INTRO_SET_SETTINGS_ACTIVE_TAB';
export const INTRO_OPEN_SETTINGS_MODAL = 'INTRO_OPEN_SETTINGS_MODAL';
export const INTRO_SET_DELETE_CONFIRM = 'INTRO_SET_DELETE_CONFIRM';
export const INTRO_SET_RESTORE_CONFIRM = 'INTRO_SET_RESTORE_CONFIRM';
export const INTRO_REANALYZE_PAGE = 'INTRO_REANALYZE_PAGE';