<template>
  <div v-if="visible" class="alert" :class="[colorClass, { 'al-fixed': isGlobal }]">
    <span v-html="message"></span>
    <span class="close" @click="close"><i class="bi bi-x-lg"></i></span>
  </div>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    id: { // добавляем id в props
      type: Number,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    isGlobal: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'red',
    },
    duration: {
      type: Number,
      default: 5000,
    },
  },
  data() {
    return {
      visible: false,
      timer: null,
    };
  },
  computed: {
    colorClass() {
      return this.color;
    },
  },
  methods: {
    show() {
      this.visible = true;
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          this.close();
        }, this.duration);
      }
    },
    close() {
      this.visible = false;
      this.$emit('closed', this.id); // передаем id при закрытии
      if (this.timer) {
        clearTimeout(this.timer);
      }
    },
  },
  mounted() {
    this.show();
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
};
</script>
