<template>
  <div>
    <Dash-stories-feed-sort-panel />
    <div class="com-pages stories-compare">
      <template v-for="page in getAnalizedPages">
        <div :key="page.owner_id" class="panel stories-feed" v-if="page.stories.length">
          <div class="page-caption-posts">
            <img :src="page.owner_avatar" alt />

            <div>
              <p>{{page.owner_name}}</p>
              <span v-if="page.social =='FB'">Facebook</span>
              <span v-if="page.social =='IG'">Instagram</span>
              <span v-if="page.social =='VK'">VK</span>
            </div>
          </div>
          <ul class="posts stories">
            <Dash-stories-card
              :story="story"
              :key="`${index}${story.stat.id}`"
              v-for="(story,index) in page.stories"
            />
          </ul>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import DashStoriesFeedSortPanel from "./Dash-stories-feed-sort-panel";
import DashStoriesCard from "./Dash-stories-card";

export default {
  name: "Dash-stories-feed-many",
  computed: {
    ...mapGetters(["getSelectedPages", "loadingStatus", "getAnalizedPages"]),
  },
  components: {
    DashStoriesFeedSortPanel,
    DashStoriesCard,
  },
  data() {
    return {
      viewMoreList: [],
    };
  },
  methods: {
    getTime(post) {
      const date = new Date(post.date_from * 1000);
      return `${date.getHours()}:${
        (date.getMinutes() < 10 ? "0" : "") + date.getMinutes()
      }`;
    },
    getDate(post) {
      const date = post.date_from * 1000;

      return this.moment(date).format("DD/MM/YYYY");
    },
    calcTotal(stat) {
      return Object.values(stat).reduce((a, b) => a + b, 0);
    },
  },
};
</script>

<style>
</style>