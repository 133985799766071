<template>
  <li>
    <div class="stories-face">
      <a
        :href="image"
        :class="'stories-' + tuid"
        :data-glightbox="isImage(image) ? 'type: image' : ''"
      >
        <img :src="image" alt />
      </a>
      <span class="content-type picture"></span>
    </div>
    <div class="stories-stats">
      <div class="text">
        <span class="date">{{ getTime }} {{ getDate }}</span>
      </div>

      <ul class="stories-block">
        <!-- Total Interactions -->
        <li>
          <span class="m-metric" title="interactions">
            <i class="bi bi-lightning-charge"></i>
            {{ $t('POST_INTER') }}
          </span>
          <span>
            {{ calcTotal(story.formatedStats.interactions) }}
          </span>
        </li>

        <!-- Interactions Details -->
        <li v-for="item in story.formatedStats.interactions" :key="item.key">
          {{ $t('STATS_STORIES_' + item.locale) }}
          <span>{{ item.value }}</span>
        </li>

        <!-- Common Stats -->
        <li v-for="item in story.formatedStats.common" :key="item.key">
          {{ $t('STATS_STORIES_' + item.locale) }}
          <span>{{ item.value }}</span>
        </li>

        <!-- Navigation Stats (IG Only) -->
        <template v-if="calcTotal(story.formatedStats.navigation) !== null">
          <li>
            {{ $t('POST_NAVIG') }}
            <span>
              {{ calcTotal(story.formatedStats.navigation) }}
            </span>
          </li>
          <li v-for="item in story.formatedStats.navigation" :key="item.key">
            {{ $t('STATS_STORIES_' + item.locale) }}
            <span>{{ item.value }}</span>
          </li>
        </template>
      </ul>
    </div>
  </li>
</template>

<script>
import GLightbox from "glightbox";

export default {
  name: "Dash-stories-card",
  data() {
    return {
      tuid: null,
    };
  },
  props: {
    story: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.tuid = this.randomHash();
    this.initLightbox();
  },
  updated() {
    this.initLightbox();
  },
  computed: {
    getTime() {
      const date = new Date(this.story.date_from * 1000);
      return `${date.getHours()}:${(date.getMinutes() < 10 ? "0" : "") + date.getMinutes()}`;
    },
    getDate() {
      const date = this.story.date_from * 1000;
      return this.moment(date).format("DD/MM/YYYY");
    },
    image() {
      if (this.story.image) {
        return "data:image/jpeg;base64," + Buffer.from(this.story.image, "binary").toString("base64");
      } else {
        return this.story.stat.photo.sizes[this.story.stat.photo.sizes.length - 1].url;
      }
    },
  },
  methods: {
    calcTotal(statsArray) {
      if (Array.isArray(statsArray) && statsArray.length) {
        return statsArray.reduce((total, item) => total + item.value, 0);
      }
      return null;
    },
    randomHash() {
      return Math.random().toString(36).substr(2, 8);
    },
    initLightbox() {
      const lightbox = GLightbox({
        selector: `.stories-${this.tuid}`,
      });
      lightbox.on("slide_before_load", (slide) => {
        this.onBeforeLoad(slide);
      });
    },
    isImage(url) {
      return /\.(jpeg|jpg|jpe|gif|png|apn|webp|svg)(\?.*)?$/i.test(url) ||
         /^data:image\/(jpeg|jpg|jpe|gif|png|apn|webp|svg);base64,/i.test(url);
    },
    onBeforeLoad(slide) {
    },
  },
};
</script>

