<template>
  <div>
    <SettingsModal
      v-if="isSettingsModalVisible"
      @close="INTRO_SET_SETTINGS_MODAL_VISIBLE(false)"
    ></SettingsModal>
    <!-- <AutoReportSettings
      v-if="isAutoReportsSettingsVisible"
      @close="INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE(false)"
    ></AutoReportSettings> -->
    <AddPage v-if="isAddPageVisible" @close="INTRO_SET_ADD_PAGE_VISIBLE(false)"></AddPage>
    <!-- <AccessManager v-if="isAccessManagerVisible" @close="INTRO_SET_ACCESS_MANAGER_VISIBLE(false)"></AccessManager> -->
  </div>
</template>

<script>
import AddPage from "./AddPage";
import AutoReportSettings from "./AutoReportSettings";
import AccessManager from "./AccessManager";
import SettingsModal from "./Settings";
import { mapGetters, mapMutations } from "vuex";
import {
  INTRO_SET_ACCESS_MANAGER_VISIBLE,
  INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE,
  INTRO_SET_ADD_PAGE_VISIBLE,
  INTRO_SET_SETTINGS_MODAL_VISIBLE
} from "../../store/actions/intro";

export default {
  name: "Modals",
  components: {
    AddPage,
    AutoReportSettings,
    AccessManager,
    SettingsModal
  },
  computed: {
    ...mapGetters([
      "isAddPageVisible",
      "isAccessManagerVisible",
      "isAutoReportsSettingsVisible",
      "isSettingsModalVisible"
    ])
  },
  ...mapMutations([
    INTRO_SET_ACCESS_MANAGER_VISIBLE,
    INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE,
    INTRO_SET_ADD_PAGE_VISIBLE,
    INTRO_SET_SETTINGS_MODAL_VISIBLE
  ])
};
</script>

<style>
</style>