// columns-one.js

import * as echarts from 'echarts';
import moment from 'moment';
import comOpt from './common-options.json';
import { merge } from 'lodash';

const formatLabels = (data, period = 'daily') => {
  return data
    .map((day) => {
      let dateFormat = 'MMM DD';
      
      // Use different date formats based on period
      if (period === 'weekly') {
        dateFormat = 'MMM DD'; // Start of week
      } else if (period === 'monthly') {
        dateFormat = 'MMM YYYY';
      }
      
      return {
        ...day,
        unix: day.date,
        date: moment(day.date).format(dateFormat),
      };
    })
    .sort((a, b) => a.unix - b.unix);
};

const create = ({ page, ref, chartData, fields, category, color, period = 'daily' }) => {
  return new Promise((resolve, reject) => {
    const container = ref[0];
    const chart = echarts.init(container);

    const formattedData = formatLabels(chartData, period);

    const xAxisData = formattedData.map((item) => item[category]);

    // Prepare series data
    const series = fields.map((fieldItem) => {
      const data = formattedData.map((item) => {
        let value = item[fieldItem.field];
        // Convert unsubscribed values to negative
        if (fieldItem.field === 'unsubscribed') {
          // value = -value;
        }
        return value;
      });
      return {
        name: fieldItem.field,
        type: 'bar',
        stack: 'total', // Stack both series on top of each other
        data: data,
        //barWidth: '30%',
        barMaxWidth: 12,
        itemStyle: {
          borderRadius: 3,
          borderWidth: 0.5,
          borderColor: '#fff',
          color: (params) => {
            if (params.value < 0) {
              // Use a different color for negative values (unsubscribed)
              return echarts.color.lift(page.colorCode, -0.2);
            } else {
              return page.colorCode;
            }
          },
        },
        label: {
          show: false,
        },
        emphasis: {
          focus: 'series',
        },
      };
    });

    const option = merge({}, comOpt, {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
        },
        formatter: (params) => {
          let tooltipHtml = `<div class='tooltip'>
            <span class='date'>${params[0].axisValueLabel}
              <span class="soc-sea ${color}">
                <!-- Additional HTML can go here -->
              </span>
            </span>
            <ul class='tooltip-list'>`;

          params.forEach((item) => {
            tooltipHtml += `<li data-value="${Math.abs(item.value)}">
              <div class="soc-sea ${color}">
                ${item.seriesName}
              </div>${Math.abs(item.value)}</li>`;
          });

          tooltipHtml += '</ul></div>';
          return tooltipHtml;
        },
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
      },
      yAxis: {
        type: 'value',
      },
      series: series,
      color: [page.colorCode, echarts.color.lift(page.colorCode, -0.2)],
    });

    chart.setOption(option);

    chart.on('finished', () => {
      resolve(chart);
    });
  });
};

export default create;