import * as echarts from 'echarts';
import comOpt from './common-options.json';
import { merge } from 'lodash';

const formatLabels = (data) => {
  return data.map((day) => {
    if (Number.isInteger(day.text)) {
      return {
        ...day,
        text: `${day.text}:00`,
      };
    } else {
      return day;
    }
  });
};

const create = ({ pages, ref, chartData, field, category, colors }) => {
  return new Promise((resolve, reject) => {
    // Ensure ref[0] exists
    if (!ref || !ref[0]) {
      reject(new Error('Reference to DOM element is not provided'));
      return;
    }
    const formattedData = formatLabels(chartData.data);

    const categories = formattedData.map((item) => item[category]);

    // Prepare series data
    const series = [];
    const colorValues = Object.values(colors);

    chartData.graphs.forEach((page, index) => {
      const data = formattedData.map((item) => item[page.field]);

      const stackName = page.pageId;

      series.push({
        name: page.owner_name,
        type: 'bar',
        data: data,
        stack: stackName,
        itemStyle: {
          color: colorValues[index],
          borderRadius: 3,
          borderWidth: 0.5,
          borderColor: '#fff',
        },
        emphasis: {
          disabled: true,
        },
        z: 220,
        barMaxWidth: 12,
      });
    });

    const tooltipFormatter = (params) => {
      if (params.length === 0) return '';
      const categoryValue = params[0].axisValue;
      let tooltipHtml = `<div class='tooltip'><span class='date'>${categoryValue}</span><ul class='tooltip-list'>`;
      let hasData = false;
    
      params.forEach((param) => {
        if (param.data !== 0) {
          hasData = true;
          const seriesIndex = param.seriesIndex;
          const page = chartData.graphs[seriesIndex];
          
          // Format the data value to 2 decimal places
          const formattedValue = param.data.toFixed(2);
    
          tooltipHtml += `<li data-value="${formattedValue}">
            <div class="soc-sea" style="color:${colorValues[seriesIndex]}">
              <span class="soc-ico soc-${page.social.toLowerCase()}"></span>
              ${page.title}
            </div>${formattedValue}%
          </li>`;
        }
      });
    
      tooltipHtml += '</ul></div>';
      return hasData ? tooltipHtml : '';
    };
    

    // Chart options
    const option = merge({}, comOpt, {
      
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          z: 210,
        },
        formatter: tooltipFormatter,
      },
      xAxis: {
        type: 'category',
        data: categories,
      },
      yAxis: {
        type: 'value',
      },
      series: series,
      color: colorValues,
    });

    const myChart = echarts.init(ref[0]);
    myChart.setOption(option);

    myChart.on('finished', () => {
      resolve({ chart: myChart });
    });

    myChart.on('error', (error) => {
      reject(error);
    });
    window.addEventListener('resize', () => {
      myChart.resize();
    });
  });
};

export default create;
