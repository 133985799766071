import * as echarts from 'echarts';

const create = function ({ ref, chartData, chartColumns, colors, pages, animation = true }) {
  return new Promise((resolve, reject) => {
    const chart = echarts.init(ref[0], null, {
      renderer: 'canvas',
      useDirtyRect: false,
    });

    // Calculate the maximum value among all pages for scaling
    const dataValues = pages.map((page, i) => {
      const value = chartData[i] ? chartData[i][chartColumns[0]] : 0;
      return value;
    });
    const maxValue = Math.max(...dataValues) * 1.3;

    // Prepare the series data
    const radiusWidth = 8; // Adjust as needed for the thickness of the rings
    const series = pages.map((page, idx) => {
      const value = chartData[idx] ? chartData[idx][chartColumns[0]] : 0;
      const remainingValue = maxValue - value;

      // Set the inner and outer radius for each ring
      const outerRadius = 100 - idx * (radiusWidth + 5) - 5; // Spacing between rings
      const innerRadius = outerRadius - radiusWidth;

      const color = page.colorCode;

      return {
        name: page.owner_name || `Page ${idx + 1}`,
        type: 'pie',
        clockwise: true,
        radius: [`${innerRadius}%`, `${outerRadius}%`],
        center: ['50%', '50%'],
        label: {
          show: false,
        },
        clickable: false,
        labelLine: {
          show: false,
        },
        data: [
          {
            value: value,
            name: page.owner_name,
            itemStyle: {
              color: color,
              borderRadius: 10,
            },
            emphasis: {
              scale: true,
              scaleSize: 1.2,
              itemStyle: {
                color: color,
                borderWidth: 1,
                borderColor: color,
              },
            },
          },
          {
            value: remainingValue,
            name: 'empty',
            itemStyle: {
              color: 'transparent', // Make the remaining part transparent
            },
            tooltip: {
              show: false,
            },
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            emphasis: {
              itemStyle: {
                color: 'transparent',
              },
            },
          },
        ],
      };
    });

    const option = {
      series: series,
      animation: animation,
      animationDuration: animation ? 1000 : 0,
      animationEasing: animation ? 'cubicOut' : 'linear',
      animationThreshold: animation ? 2000 : 0
    };

    chart.setOption(option);

    resolve({ chart, colors: pages.map((p) => p.colorCode) });

    window.addEventListener('resize', () => {
      chart.resize();
    });
  });
};

export default create;
