import * as echarts from 'echarts';
import PageCharts from '../page-charts';
import comOpt from './common-options.json';
import { merge } from 'lodash';

const aggregateFunctions = (names) => {
  return names.map((name) => PageCharts[name]);
};

const groupByPeriod = (data, period, names) => {
  // Clone the data to avoid mutating the original
  const result = [...data];
  
  if (period === 'daily') {
    // Daily view - no additional grouping needed
    return result;
  }
  
  // Group the data by the specified period
  const groupedData = {};
  
  result.forEach(day => {
    let key;
    const date = moment(day.date);
    
    if (period === 'weekly') {
      // Format: "Week X, YYYY" (where X is the week number)
      const weekNum = date.week();
      const year = date.year();
      
      // Get start and end of the week for better date display
      const startOfWeek = moment(date).startOf('week');
      const endOfWeek = moment(date).endOf('week');
      
      key = `Week ${weekNum}, ${year}`;
      
      // Store the date range for tooltip display
      if (!groupedData[key]) {
        groupedData[key] = {
          displayDateRange: `${startOfWeek.format('MMM DD, YYYY')} - ${endOfWeek.format('MMM DD, YYYY')}`
        };
      }
    } else if (period === 'monthly') {
      // Format: "MMM YYYY" (e.g., "Jan 2025")
      key = date.format('MMM YYYY');
    }
    
    if (!groupedData[key]) {
      groupedData[key] = {};
    }
    
    // For each metric, sum the values
    names.forEach(name => {
      const metricKey = `${name}_0`;
      if (day[metricKey] !== undefined) {
        groupedData[key][metricKey] = (groupedData[key][metricKey] || 0) + day[metricKey];
      }
    });
    
    // Store the date (will be overwritten, so the last one will remain)
    groupedData[key].date = day.date;
    groupedData[key].formatDate = key; // Use the period key as the formatted date
  });
  
  // Convert the grouped data back to an array
  const dataArray = Object.keys(groupedData).map(key => ({
    ...groupedData[key],
    formatDate: key
  }));
  
  return dataArray;
};

const aggregate = (pages, datesRangePosts, datesRangeStories, names, period = 'daily') => {
  const days = aggregateFunctions(names)
    .map((f) => f({ pages, datesRangePosts, datesRangeStories }))
    .flat();
  const result = {};

  for (const day of days) {
    day.formatDate = moment(day.date).format('MMM DD');
    result[day.formatDate] = {
      ...result[day.formatDate],
      ...day,
    };
  }
  
  const dataArray = Object.values(result);
  
  // Return the data grouped by the selected period
  return groupByPeriod(dataArray, period, names);
};

const create = ({ pages, datesRangePosts, datesRangeStories, names, ref, colors, period = 'daily' }) => {
  return new Promise((resolve, reject) => {
    const chart = echarts.init(ref, null, {
      renderer: 'canvas',
      useDirtyRect: false,
    });

    const data = aggregate(pages, datesRangePosts, datesRangeStories, names, period);

    const categories = data.map((item) => item.formatDate);

    const series = names.map((name, index) => {
      const metricKey = `${name}_0`;
      const dataSeries = data.map((item) => ({
        value: item[metricKey] || 0,
        date: item.date,
        displayDateRange: item.displayDateRange,
        formatDate: item.formatDate
      }));
      
      return {
        name: name,
        type: 'bar',
        stack: 'total',
        data: dataSeries,
        barWidth: '20%',
        itemStyle: {
          color: colors[index].value,
          borderRadius: 3,
          borderWidth: 0.5,
          borderColor: '#fff',
        },
        emphasis: {
          focus: 'series',
        },
      };
    });

    const option = merge({}, comOpt, {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          lineStyle: {
            color: '#C2D5FF',
            type: 'line'
          }
        },
        formatter: function (params) {
          if (params.length === 0) return '';
          
          let dateDisplay;
          // For weekly view, display date range instead of week number
          if (period === 'weekly' && params[0].data && params[0].data.displayDateRange) {
            dateDisplay = params[0].data.displayDateRange;
          } else if (period === 'daily' && params[0].data && params[0].data.date) {
            dateDisplay = moment(params[0].data.date).format('MMM DD, YYYY');
          } else {
            dateDisplay = params[0].axisValue;
          }
          
          let tooltipHtml = `<div class='tooltip'><span class='date'>${dateDisplay}</span><ul class='tooltip-list'>`;
          params.forEach((param) => {
            tooltipHtml += `<li>
                <div class="soc-sea ${colors[param.seriesIndex].key}">${param.seriesName}</div>${param.data.value.toLocaleString('ru-RU')}
              </li>`;
          });
          tooltipHtml += '</ul></div>';
          
          return tooltipHtml;
        },
        //appendToBody: true
      },
      xAxis: {
        type: 'category',
        data: categories,
      },
      yAxis: {
        type: 'value',
      },
      series: series,
    });

    chart.setOption(option);

    resolve(chart);
    window.addEventListener('resize', () => {
      chart.resize();
    });
  });

};

export default create;