import i18n from '@/i18nSetup';
import module from '@/models/AxiosModule';

function transformToAssocArray(prmstr) {
  var params = {};
  var prmarr = prmstr.split("&");
  for (var i = 0; i < prmarr.length; i++) {
    var tmparr = prmarr[i].split("=");
    params[tmparr[0]] = tmparr[1];
  }
  return params;
}

function getSearchParameters() {
  var prmstr = window.location.search.substr(1);
  return prmstr != null && prmstr != "" ? transformToAssocArray(prmstr) : {};
}

async function changeLanguage(lang, getProfile) {
  i18n.locale = lang;
  localStorage.setItem('userLanguage', lang);
  try {
    const axios = module.Axios;
    const response = await axios.post("set-locale", {
      email: getProfile.email,
      locale: lang.toLowerCase()
    });
    console.log("Locale set successfully:", response.data);
  } catch (error) {
    console.error("Error setting locale:", error);
  }      
}

export default {
  getSearchParameters,
  changeLanguage
}