import request from "superagent";
import jsonp from "./jsonp";
import axios from "axios";
import alasql from "alasql";
// import store from '@/store';
import { handleInvalidToken } from './store-utils';

// const axiosVk = axios.create({
//   baseURL: 'https://api.vk.com/method',
//   params: {
//     v: process.env.VUE_APP_VK_API_VERSION,
//   },
// });

// axiosVk.interceptors.response.use(
//   response => response,
//   error => {
//     if (error.response && error.response.data && error.response.data.error) {
//       const err = error.response.data.error;
//       if (err.error_code === 5) { // VK invalid token error code
//         console.log("VK token is invalid");
//         handleInvalidToken('VK');
//       }
//     }
//     return Promise.reject(error);
//   }
// );

async function fetchVkData(method, params = {}) {
  const baseUrl = 'https://api.vk.com/method/';
  const url = new URL(method, baseUrl);

  // Добавляем общие параметры
  url.searchParams.append('v', process.env.VUE_APP_VK_API_VERSION);
  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

  try {
    // Выполняем запрос
    const response = await request.get(url.toString()).use(jsonp);

    // Проверяем, что тело ответа содержит поле 'response'
    if (response.body && response.body.response) {
      return response.body.response; // Возвращаем успешный ответ
    }

    // Если в теле ответа нет 'response', но есть 'error', выбрасываем ошибку
    if (response.body && response.body.error) {
      throw response.body.error; // Ошибка API VK
    }

    // Если структура ответа неожиданная
    throw new Error("Unexpected response format from VK API");

  } catch (error) {
    // Обработка токенов
    if (error.error_code === 5) { // VK ошибка "невалидный токен"
      console.log("VK token is invalid");
      handleInvalidToken('VK');
    }

    // Пробрасываем ошибку дальше
    throw error;
  }
}


const axiosFb = axios.create({
  baseURL: 'https://graph.facebook.com/v9.0',
});

axiosFb.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.data && error.response.data.error) {
      const err = error.response.data.error;
      if (err.code === 190) { // Facebook invalid token error code
        console.log("Facebook token is invalid");
        handleInvalidToken('FB');
      }
    }
    return Promise.reject(error);
  }
);

const axiosIg = axios.create({
  baseURL: 'https://graph.facebook.com/v9.0',
});

axiosIg.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.data && error.response.data.error) {
      const err = error.response.data.error;
      if (err.code === 190) { // Instagram invalid token error code
        console.log("Instagram token is invalid");
        handleInvalidToken('IG');
      }
    }
    return Promise.reject(error);
  }
);

const parseUrl = (url) => {
  if (url.indexOf("vk.com") >= 0 || url.indexOf("vkontakte.ru") >= 0) {
    var vkPattern = /^(?:http:\/\/|https:\/\/)?(?:www.)?(?:m.)?(?:vk.com|vkontakte.ru|new.vk.com)\/([a-zA-Z0-9._]{2,})(?:\?\S+)?$/gi;
    var result = vkPattern.exec(url);

    if (result && result.length == 2) {
      if (result[1].startsWith("public")) {
        result[1] = result[1].replace("public", "");
      }
      return {
        social: "VK",
        page: result[1],
        type: "page",
      };
    } else {
      return undefined;
    }
  }
  if (url.indexOf("fb.com") >= 0 || url.indexOf("facebook.com") >= 0) {
    url = decodeURIComponent(url);

    var fbPattern = /^(?:http:\/\/|https:\/\/)?(?:www.)?(?:m.)?(?:mobile.)?(?:web.)?(?:facebook.com|fb.com)\/(groups\/|pages\/[.\-_a-zA-Z0-9\u00C0-\u017F\u0400-\u04ff]{2,}\/)?([.\-_a-zA-Z0-9\u00C0-\u017F\u0400-\u04ff]{2,})(?:\/|\?)?(?:\S*)?$/gi;
    var result = fbPattern.exec(url);

    if (result && result.length == 3) {
      if (result[0] && result[2]) {
        return {
          social: "FB",
          page: result[2],
          type: "page",
        };
      }
    } else {
      return undefined;
    }
  }

  if (url.indexOf("instagram.com") >= 0) {
    var igPattern = /^(?:http:\/\/|https:\/\/)?(?:www.)?(?:m.)?(?:instagram.com)\/([a-zA-Z0-9._]{2,})(?:\/|\?)?(?:\S*)?$/gi;
    var result = igPattern.exec(url);

    if (result && result.length == 2) {
      return {
        social: "IG",
        type: "page",
        page: result[1],
        request: result[1],
      };
    } else {
      return undefined;
    }
  }
};

const updateExpiredImg = async (post, tokens) => {
  if (post.social == "IG") return await getIgPostAttaches(post, tokens.igToken);
  else if (post.social == "FB")
    return await getFbPostAttaches(post, tokens.fbToken);
};

const getIgPostAttaches = async (post, token) => {
  const [id, pageToken] = await getBusinessPagesIg(token);

  const result = {};
  let paging = "";
  let count = 0;
  while (true) {
    count += 1;
    if (count == 50) return result;
    const cursorAfter = paging ? `.after(${paging})` : "";

    const url = `https://graph.facebook.com/v3.1/${id}?fields=business_discovery.username(${post.owner_domain}){media${cursorAfter}{media_url,children{media_type,media_url}}}&access_token=${pageToken}`;

    const {
      body: {
        business_discovery: {
          media: {
            data,
            paging: {
              cursors: { after },
            },
          },
        },
      },
    } = await request.get(url).use(jsonp);
    data.forEach((media) => {
      if (!media.children) {
        result[media.id] = [media.media_url];
      } else {
        const filtered = media.children.data.filter(
          (child) => child.media_type == "IMAGE"
        );
        result[media.id] = [media.media_url];
        result[media.id].push(...filtered.map((child) => child.media_url));
      }
    });

    paging = after;

    if (result[post.post_id]) {
      return result;
    }
  }
};
const getFbPostAttaches = async ({ post_id }, token) => {
  const url = `https://graph.facebook.com/v3.1/${post_id}?fields=attachments&access_token=${token}`;

  const {
    body: {
      attachments: { data },
    },
  } = await request.get(url).use(jsonp);

  const post = data[0];
  const attaches = [];
  if (!post.subattachments || !post.subattachments.data.length) {
    attaches.push(post.media.image.src);
  } else {
    attaches.push(post.media.image.src);
    attaches.push(
      ...post.subattachments.data.map((sub) => {
        return sub.media.image.src;
      })
    );
  }
  const result = {
    [post_id]: attaches,
  };

  return result;
};

const getVkPageInfo = async (domain, token) => {
  try {
    const params = {
      group_ids: domain,
      access_token: token,
      fields: 'members_count'
    }
    const response = await fetchVkData('groups.getById', params);    
    const data = response;
    if (data && data.length) return data[0];
    else throw data.error;
  } catch (err) {
    if (err.error_code === 100)
      throw new Error("ERROR_FOUNDED_ACCOUNT_NOT_BUSINESS");
    else throw new Error("ERROR_UNEXPECTED");
  }
};

const getFbPageInfo = async (domain, token) => {
  try {
    const response = await axiosFb.get(`/${domain}`, {
      params: {
        fields: 'name,picture.type(large),username,followers_count',
        access_token: token.fbToken,
        appsecret_proof: token.proofToken,
      },
    });
    if (response.data && !response.data.error) return response.data;
    else if (response.data && response.data.error) throw response.data.error;
    else throw new Error();
  } catch (err) {
    console.log(err);
    if (err.code === 803)
      throw new Error("ERROR_FOUNDED_ACCOUNT_NOT_BUSINESS");
    else if (err.code === 10) throw new Error("ERROR_FOUNDED_FB_GROUPS");
    else throw new Error("ERROR_UNEXPECTED");
  }
};

const getBusinessPagesIg = async (token) => {
  let pages = [];
  let paging = undefined;

  while (true) {
    const { body } = await request
      .get("https://graph.facebook.com/v9.0/me/accounts", {
        fields: "instagram_business_account",

        access_token: token,
        after: paging,
      })
      .use(jsonp);

    if (!body.data.length) break;
    pages.push(...body.data);
    paging = body.paging.cursors.after;
  }

  const business_pages = pages
    .map((item) => {
      if (
        item.instagram_business_account &&
        item.instagram_business_account.id
      ) {
        return item.instagram_business_account.id;
      }
    })
    .filter((item) => item);

  if (business_pages.length) return [business_pages[0], token];

  const {
    body: { data },
  } = await request
    .get("https://graph.facebook.com/v9.0/me/accounts", {
      access_token: token,
    })
    .use(jsonp);

  for (const page of data) {
    const p = await request
      .get(`https://graph.facebook.com/v9.0/${page.id}`, {
        fields: "instagram_business_account",
        access_token: page.access_token,
      })
      .use(jsonp);
    if (p.body.instagram_business_account) {
      return [p.body.instagram_business_account.id, page.access_token];
    }
  }
  throw new Error("ERROR_ACCOUNT_NO_BUSINESS");
};

const getIgPageInfo = async (domain, token) => {
  const [id, pageToken] = await getBusinessPagesIg(token);
  try {
    const response = await axiosIg.get(`/${id}`, {
      params: {
        fields: `business_discovery.username(${domain}){followers_count,profile_picture_url,media_count,username,name}`,
        access_token: pageToken,
      },
    });
    return response.data.business_discovery;
  } catch (err) {
    if (err.response && err.response.data.error.code === 110) {
      throw new Error("ERROR_FOUNDED_ACCOUNT_NOT_BUSINESS");
    } else {
      throw new Error("ERROR_UNEXPECTED");
    }
  }
};

const findVkAdminGroups = async (token, pages) => {
  if (!pages) pages = [];
  try {
    const params = {
      access_token: token,
      filter: 'admin,moder,editor',
      extended: 1,
    }
    const response = await fetchVkData('groups.get', params);
    const items = response.items;
    if (!items) return [];
    const result = items.filter(a => !pages.find(p => +p.owner_id == a.id));
    return result;
  } catch (err) {
    return [];
  }
};

const findVkAdminGroupsForTokens = async (token, pages = []) => {
  const params = {
    access_token: token,
    filter: 'admin',
    extended: 1,
  };

  try {
    const response = await fetchVkData('groups.get', params);
    const items = response?.items || [];
    const result = [];
    for (const group of items) {
      if (pages.find((p) => +p.owner_id === group.id)) {
        result.push(group.id);
      }
    }
    return result;
  } catch (err) {
    console.error("Error fetching VK admin groups:", err);
    return [];
  }
};

const findFbAdminGroups = async (token, pages) => {
  if (!pages) pages = [];
  if (!token) return [];
  try {
    const response = await axiosFb.get('/me/accounts', {
      params: {
        access_token: token.fbToken,
      },
    });
    const ids = response.data.data.map(i => i.id);
    const items = await Promise.all(ids.map(i => getFbPageInfo(i, token)));
    const result = items.filter(a => !pages.find(p => +p.owner_id == a.id));
    return result;
  } catch (err) {
    return [];
  }
};

const findIgAdminGroups = async (token, pages) => {
  if (!pages) pages = [];
  if (!token) return [];

  const business_pages = [];

  try {
    const response = await axiosIg.get('/me/accounts', {
      params: {
        access_token: token,
      },
    });
    const data = response.data.data;

    for (const page of data) {
      const p = await axiosIg.get(`/${page.id}`, {
        params: {
          fields: 'instagram_business_account',
          access_token: page.access_token,
        },
      });
      if (p.data.instagram_business_account) {
        business_pages.push({
          id: p.data.instagram_business_account.id,
          token: page.access_token,
        });
      }
    }

    const result = business_pages.filter(element => !pages.find(p => +p.owner_id == element.id));
    const res = await Promise.all(
      result.map(i => axiosIg.get(`/${i.id}`, {
        params: {
          fields: 'followers_count,profile_picture_url,media_count,username,name',
          access_token: i.token,
        },
      }))
    );
    return res;
  } catch (err) {
    return [];
  }
};

// const token = localStorage.getItem("user-token");
// const Axios = axios.create({
//   baseURL: process.env.VUE_APP_API,
//   headers: {
//     authorization: `bearer ${token}`,
//   },
// });

// Axios.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       localStorage.removeItem("user-token"); // <-- удаляем токен
//       window.location = "/"; // <-- редирект на главную
//     } else {
//       return Promise.reject(error); // <-- не забываем возвращать ошибку для дальнейшей обработки
//     }
//   }
// );

const setPersistense = (bus) => {
  localStorage.setItem(
    "hash_ids",
    Array.isArray(bus.statPages) ? JSON.stringify(bus.statPages) : bus.statPages
  );
  localStorage.setItem("period", JSON.stringify(bus.period));
};

const groupBySocial = (pages) => {
  return alasql("SELECT social, ARRAY(_) as pages from ? group by social", [
    pages,
  ]);
};

const groupByOwners = (pages) => {
  return alasql("SELECT email, ARRAY(_) as pages from ? group by email", [
    pages,
  ]);
};

const filterUniquePagesByAdmin = (pages) => {
  const mapPages = new Map();
  pages.forEach((page, index) => {
    const existPage = mapPages[page.owner_id];

    if (!existPage) {
      mapPages.set(page.owner_id, page);
    } else {
      const isAccessMore = existPage.is_admin < page.is_admin;
      if (isAccessMore) mapPages.set(page.owner_id, page);
    }
  });
  return [...mapPages.values()];
};

const getPersistense = () => {
  return {
    hash_ids: localStorage.getItem("hash_ids"),
    period: localStorage.getItem("period"),
  };
};

const mergeStoriesAttachments = (stories, attachments) => {
  return alasql("select * from ? H left join ? T on H.id_story = T.id_story", [
    stories,
    attachments,
  ]);
};

const formatNumbers = (num) => {
  if (num == undefined || isNaN(num)) return num;
  const formated = num.toString().replace(" ", "");
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  if (formated.startsWith("+") || formated.startsWith("-")) {
    return (
      formated[0] +
      parseFloat(formated.slice(1))
        .toString()
        .replace(regexp, " ")
    );
  } else if (formated != "")
    return parseFloat(num)
      .toString()
      .replace(regexp, " ");
  else return num;
};

const isValidPassword = (pass) => {
  const regExp = /^([a-zA-Z0-9@#$%^&(){}[\]:;<>,.?=\/~_+-=|!\*]){7,}($)/g;
  return regExp.test(pass);
};

const isValidEmail = (email) => {
  const regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regExp.test(email);
};

const replaceTextBreaks = (text) =>
  text.replace(/\\n/g, "\n").replace(/\\r/g, "\r");

export default {
  parseUrl,
  getVkPageInfo,
  // Axios,
  getFbPageInfo,
  getIgPageInfo,
  setPersistense,
  getPersistense,
  groupBySocial,
  groupByOwners,
  findVkAdminGroups,
  findFbAdminGroups,
  findIgAdminGroups,
  findVkAdminGroupsForTokens,
  updateExpiredImg,
  mergeStoriesAttachments,
  formatNumbers,
  isValidEmail,
  isValidPassword,
  filterUniquePagesByAdmin,
  replaceTextBreaks,
  getBusinessPagesIg,
};
