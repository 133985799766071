import * as echarts from 'echarts';
import moment from 'moment';
import comOpt from './common-options.json';
import { merge } from 'lodash';

// Helper function to format labels
const formatLabels = (data, field, period = 'daily') => {
  if (field === 'hour_activity') {
    return data.map(day => ({
      ...day,
      label: `${day.label}:00`,
    }));
  }
  
  if (field !== 'growth') return data;
  
  const formatted = data.map(day => {
    let dateFormat = 'MMM DD';
    
    // Use different date formats based on period
    if (period === 'weekly') {
      dateFormat = 'MMM DD';  // Will show start of week
    } else if (period === 'monthly') {
      dateFormat = 'MMM YYYY';
    }
    
    return {
      ...day,
      unix: day.date,
      date: moment(day.date).format(dateFormat),
    };
  });
  
  return formatted.sort((a, b) => a.unix - b.unix);
};

// Helper function to adjust color brightness
const adjustColorBrightness = (col, amt) => {
  let usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  const num = parseInt(col, 16);
  let r = (num >> 16) + amt;
  let b = ((num >> 8) & 0x00ff) + amt;
  let g = (num & 0x0000ff) + amt;

  r = Math.min(255, Math.max(0, r));
  b = Math.min(255, Math.max(0, b));
  g = Math.min(255, Math.max(0, g));

  return (
    (usePound ? '#' : '') +
    (g | (b << 8) | (r << 16)).toString(16).padStart(6, '0')
  );
};

// Helper function to remove duplicates from data
const removeDuplicates = (data, key) => {
  const seen = new Set();
  return data.filter(item => {
    const identifier = key ? item[key] : JSON.stringify(item);
    return seen.has(identifier) ? false : seen.add(identifier);
  });
};

// Create chart function
const create = ({ pages, ref, chartData, field, category, colors, period = 'daily' }) => {
  return new Promise((resolve, reject) => {
    chartData = Array.isArray(chartData) ? chartData : [];

    // Debug the input chart data
    console.log('ecolumns input data:', { field, category, period, chartDataLength: chartData.length });
    if (chartData.length > 0) {
      console.log('First data item:', chartData[0]);
    }

    // Remove duplicates in chart data based on a unique key (e.g., `date` or `category`)
    const deduplicatedData = removeDuplicates(chartData, category);

    const chartDom = ref[0];
    if (!chartDom) {
      return reject();
    }

    const myChart = echarts.init(chartDom);

    // Format and process the deduplicated data
    const data = formatLabels(deduplicatedData, field, period);
    const xAxisData = Array.isArray(data) ? data.map(item => item[category]) : [];

    // Debug the processed data
    console.log('Processed data:', { 
      dataLength: data.length,
      xAxisDataLength: xAxisData.length
    });
    
    if (data.length > 0 && xAxisData.length === 0) {
      console.warn('No xAxis data extracted. First data item:', data[0], 'Category:', category);
    }

    const series = pages.map((page, index) => {
      const dataValues = data.map(item => item[`${field}_${index}`] || 0);
      return {
        name: page.owner_name,
        type: 'bar',
        stack: null,
        data: dataValues,
        barMaxWidth: 20,
        z: 220,
        barGap: '50%',
        itemStyle: {
          color: function (params) {
            return params.value < 0
              ? adjustColorBrightness(page.colorCode, -20)
              : page.colorCode;
          },
          borderRadius: [3, 3, 3, 3],
        },
        emphasis: {
          focus: 'series',
          itemStyle: {
            color: page.colorCode,
          },
        },
      };
    });

    const option = merge({}, comOpt, {
      backgroundColor: 'transparent',
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow',
          label: { show: false },
          z: 200,
        },
        formatter: params => {
          if (params.length === 0) return '';
          let tooltipText = `<div class='tooltip'><span class='date'>${params[0].axisValueLabel}</span><ul class='tooltip-list'>`;
          let hasData = false;
          params.forEach(param => {
            if (param.value != null && param.value !== 0) {
              hasData = true;
              const page = pages.find(p => p.owner_name === param.seriesName);
              tooltipText += `
                <li data-value="${param.value}">
                  <div class="soc-sea ${page.colorName}">
                    <span class="soc-ico soc-${page.social.toLowerCase()}"></span>
                    ${page.owner_name_medium}
                  </div>${param.value}${field !== 'growth' && field !== 'hashtag_cnt' ? '%' : ''}
                </li>`;
            }
          });
          tooltipText += '</ul></div>';
          return hasData ? tooltipText : '';
        },
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
      },
      yAxis: {
        type: 'value',
      },
      series: series,
    });

    myChart.setOption(option);

    window.addEventListener('resize', myChart.resize);
    resolve({ chart: myChart });
  });
};

export default create;
