<template>
  <div>
    <div class="post-stats" v-columns="{ columns: 1, breakpoints: { 550: 2, 1200: 3 } }">

      <div
        :class="chart.type == 'radar' ? 'panel' : 'panel types-radial'"
        :key="index"
        v-for="(chart, index) in chartNames"
      >
        <div class="panel-content" v-if="chart.type == 'radarMany'">
          <h2>{{ $t(`DASHBOARD_POSTS_STATS_CHARTS_${chart.name}`) }}</h2>
          <div
            class="content radar-chart"
            :key="i"
            v-for="(column, i) in [...chart.columns].reverse()"
          >
            <div class="m-chart">
              <div
                class="chart"
                :ref="`${chart.name}_${i}`"
              ></div>
            </div>
            <div class="p-stats-cont list" v-if="chart.data.length">
              <p>{{ column }}</p>
              <ul class="tooltip-list">
                <li 
                :key="y"
                v-for="(page, y) in pagesWithPosts"
                @mouseover="onTextBlockMouseOver(chart, i, y, true)"
                @mouseleave="onTextBlockMouseLeave(chart, i, y, true)"
                >
                  <div :class="`soc-sea ${page.colorName}`">
                    <span
                      :class="`soc-ico soc-${page.social.toLowerCase()}`"
                    ></span>
                    <span class="page-caption">{{ page.owner_name_medium }}</span>
                  </div>
                  {{ chart.data[y][[...chart.columnDataNames][i]] + "%" }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          v-if="chart.type == 'stacked' || chart.type == 'columns'"
          class="panel-content"
        >
          <h2>{{ $t(`DASHBOARD_POSTS_STATS_CHARTS_${chart.name}`) }}</h2>
          <div class="content bar-chart">
            <div class="m-chart">
              <div class="chart" :ref="chart.name"></div>
            </div>
          </div>
        </div>
        <div v-if="chart.type == 'radar'" class="panel-content">
          <h2>{{ $t(`DASHBOARD_POSTS_STATS_CHARTS_${chart.name}`) }}</h2>
          <div
            class="content radar-chart"
            :key="i"
            v-for="(page, i) in pagesWithPosts"
            v-show="chart.data[i]"
          >
            <div class="m-chart">
              <div
                class="chart"
                :ref="`${chart.name}_${i}`"
              ></div>
            </div>
            <div class="p-stats-cont" :style="`color: ${colorsByPages[i]}`">
              <div
                class="type"
                :key="chart.name + y"
                v-for="(column, y) in chart.columns"
                @mouseover="onTextBlockMouseOver(chart, i, y, false)"
                @mouseleave="onTextBlockMouseLeave(chart, i, y, false)"
              >
                <span v-if="chart.data[i]"
                  >{{ formatLabelStat(chart.data[i][chart.columnDataNames[y]])
                  }}{{ chart.percent ? "%" : "" }}</span
                >
                <b :style="`opacity: ${1 - (y / 10) * 2}`">{{ column }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import RadarPosts from "@/models/charts/pie-posts-stats";
import RadarManyPosts from "@/models/charts/eradar-posts-stats-many-many";
import ColumnsPosts from "@/models/charts/ecolumns";
import StackedPosts from "@/models/charts/estacked";
import columnsDirective from "@/directives/columnsDirective.js";
import i18n from '@/i18nSetup';

import pageCharts from "@/models/page-charts";

const colors = [
  "#4979E2",
  "#8C49E2",
  "#F39930",
  "#09ADB8",
  "#ff2e94",
  "#FFEC43",
  "#1FDC76",
  "#CDF335",
  "#60D9FF",
  "#1DABD8",
  "#779501",
  "#C8EF2C",
  "#FAC2C2",
];

export default {
  name: "Dash-posts-stats-many",
  components: {},
  directives: {
    columns: columnsDirective
  },  
  computed: {
    ...mapGetters([
      "getAnalizedPages",
      "getUnionPagesData",
      "getSocialOfAdminPageInAnalize",
      "getAnalizedAdminPages",
    ]),
    commonColorsValues() {
      return Object.values(this.$colors);
    },
    pagesWithPosts() {
      return this.getAnalizedPages.filter((page) => page.posts.length);
    },
  },
  data() {
    const columnContent = [
      this.$t('DASHBOARD_TEXT'),
      this.$t('DASHBOARD_PHOTO'),
      this.$t('DASHBOARD_VIDEO'),
      this.$t('DASHBOARD_LINK')
    ];
    const columnLength = [
      this.$t('DASHBOARD_LONG'),
      this.$t('DASHBOARD_MEDIUM'),
      this.$t('DASHBOARD_SHORT')
    ];
    const columnLengthEr = [
      `${this.$t('DASHBOARD_SHORT')}, ER`,
      `${this.$t('DASHBOARD_MEDIUM')}, ER`,
      `${this.$t('DASHBOARD_LONG')}, ER`
    ];

    return {
      loaded: false,
      colorsByPages: [],
      chartNames: [
        {
          name: "textLenCount",
          chart: RadarPosts,
          type: "radar",
          columns: columnLength,
          columnDataNames: ["l_cnt", "m_cnt", "s_cnt"],
          data: [],
          instances: null,
        },
        {
          name: "textLenEr",
          chart: RadarManyPosts,
          type: "radarMany",
          columns: columnLengthEr,
          columnDataNames: ["long_er", "medium_er", "short_er"],
          data: [],
          instances: null,
        },
        {
          name: "contentTypesCount",
          chart: RadarPosts,
          type: "radar",
          columns: columnContent,
          columnDataNames: ["text_cnt", "photo_cnt", "video_cnt", "link_cnt"],
          data: [],
          instances: null,
        },
        {
          name: "textLenActivity",
          chart: RadarPosts,
          type: "radar",
          columns: columnLength,
          columnDataNames: [
            "l_avg_activity",
            "m_avg_activity",
            "s_avg_activity",
          ],
          percent: true,
          data: [],
          instances: null,
        },
        {
          name: "contentTypesEr",
          chart: RadarManyPosts,
          type: "radarMany",
          columns: columnContent,
          columnDataNames: ["text_cnt", "photo_cnt", "video_cnt", "link_cnt"],
          data: [],
          instances: null,
        },
        {
          name: "contentTypesActivity",
          chart: RadarPosts,
          type: "radar",
          columns: columnContent,
          columnDataNames: [
            "t_avg_activity",
            "p_avg_activity",
            "v_avg_activity",
            "l_avg_activity",
          ],
          percent: true,
          data: [],
          instances: null,
        },
        {
          name: "hourActivity",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          field: "hour_activity",
          category: "label",
          data: [],
          instances: null,
        },
        {
          name: "weekActivity",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          category: "label",
          field: "week_activity",
          data: [],
          instances: null,
        },
        {
          name: "activityHoursTextLen",
          chart: StackedPosts,
          type: "stacked",
          columns: columnContent,
          category: "text",
          data: [],
          instances: null,
        },
        {
          name: "activityWeeksTextLen",
          chart: StackedPosts,
          type: "stacked",
          columns: columnContent,
          category: "text",
          data: [],
          instances: null,
        },
        {
          name: "erHoursTextLen",
          chart: StackedPosts,
          type: "stacked",
          columns: columnContent,
          category: "text",
          data: [],
          instances: null,
        },
        {
          name: "erWeeksTextLen",
          chart: StackedPosts,
          type: "stacked",
          columns: columnContent,
          category: "text",
          data: [],
          instances: null,
        },
        {
          name: "hashTagCount",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          field: "hashtag_cnt",
          category: "hashtag",
          data: [],
          instances: null,
        },
        {
          name: "hashTagErAvg",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          field: "hashtag_er_avg",
          category: "hashtag",
          data: [],
          instances: null,
        },
        {
          name: "hashTagActivityAvg",
          chart: ColumnsPosts,
          type: "columns",
          columns: columnContent,
          field: "hashtag_activity_avg",
          category: "hashtag",
          data: [],
          instances: null,
        },
      ],
    };
  },
  methods: {
    updateTranslations() {
      const columnContent = [
        this.$t('DASHBOARD_TEXT'),
        this.$t('DASHBOARD_PHOTO'),
        this.$t('DASHBOARD_VIDEO'),
        this.$t('DASHBOARD_LINK')
      ];
      const columnLength = [
        this.$t('DASHBOARD_LONG'),
        this.$t('DASHBOARD_MEDIUM'),
        this.$t('DASHBOARD_SHORT')
      ];
      const columnLengthEr = [
        `${this.$t('DASHBOARD_SHORT')}, ER`,
        `${this.$t('DASHBOARD_MEDIUM')}, ER`,
        `${this.$t('DASHBOARD_LONG')}, ER`
      ];

      this.chartNames = this.chartNames.map(chart => {
        if (chart.name.includes('textLen')) {
          chart.columns = chart.name.includes('Er') ? columnLengthEr : columnLength;
        } else {
          chart.columns = columnContent;
        }
        return chart;
      });
    },
    formatLabelStat(n) {
      return n && (n % 1 === 0 ? n : n.toFixed(2));
    },
    getColorCode(index) {
      const colors = Object.values(this.$colors);
      return colors[index];
    },
    prepareData() {
      const { posts } = this.getUnionPagesData;
      this.chartNames = this.chartNames
        .map((chart) => {
          let data;
          if (chart.type != "stacked" && chart.type != "columns")
            data = pageCharts[chart.name](posts);
          else data = pageCharts[chart.name](this.pagesWithPosts, posts);

          return {
            ...chart,
            data,
          };
        })
        .filter((chart) => Object.entries(chart.data).length);
    },
    async renderCharts() {
      this.colorsByPages = this.pagesWithPosts.map((p) => p.colorCode);
      this.prepareData();
      const that = this;

      const allChartPromises = this.chartNames.map((chart, i) => {
        const chartColumns = chart.columnDataNames;

        if (chart.type === "radar") {
          return that.pagesWithPosts.map((page, y) => {
            return chart.chart({
              ref: that.$refs[`${chart.name}_${y}`],
              pages: [that.pagesWithPosts[y]],
              chartData: chart.data[y],
              chartColumns,
              color: this.colorsByPages[y],
              field: chart.field,
              category: chart.category,
              title: i18n.t(`DASHBOARD_POSTS_STATS_CHARTS_${chart.name}`)
            });
          });
        } else if (chart.type === "radarMany") {
          return chart.columns.map((column, y) => {
            return chart.chart({
              ref: that.$refs[`${chart.name}_${y}`],
              pages: that.pagesWithPosts,
              chartData: chart.data,
              chartColumns: [chartColumns[y]],
              color: that.getColorCode(y),
              colors: that.$colors,
              field: chart.field,
              category: chart.category,
              title: i18n.t(`DASHBOARD_POSTS_STATS_CHARTS_${chart.name}`)
            });
          });
        } else if (chart.type === "stacked" || chart.type === "columns") {
          return chart.chart({
            ref: that.$refs[chart.name],
            pages: that.pagesWithPosts,
            chartData: chart.data,
            chartColumns,
            color: that.getColorCode(i),
            colors: that.$colors,
            field: chart.field,
            category: chart.category,
            title: i18n.t(`DASHBOARD_POSTS_STATS_CHARTS_${chart.name}`)
          });
        }
      });

      for (const [i, chartPromises] of allChartPromises.entries()) {
        if (Array.isArray(chartPromises)) {
          const chartInstances = await Promise.all(chartPromises);
          this.chartNames[i].instances = chartInstances;
        } else {
          this.chartNames[i].instances = await chartPromises;    
        }
      }

      this.loaded = true;
      setTimeout(() => {
        const el = document.getElementsByClassName("post-stats");
        if (el && el[0]) {
          el[0].classList.add("compare-pages");
        }
        // this.resizeAllGridItems();
      }, 0);
    },
    onTextBlockMouseOver(chart, chartIndex, dataIndex, isMany) {
      let chartInstance;
      if (chart.type === 'radar') {
        chartInstance = chart.instances[chartIndex];
        const dataIdx = dataIndex;
        if (chartInstance && chartInstance.chart) {
          chartInstance.chart.dispatchAction({
            type: 'highlight',
            seriesIndex: 0,
            dataIndex: dataIdx,
          });
        }
      } else if (chart.type === 'radarMany') {
        chartInstance = chart.instances[chartIndex];
        const seriesIndex = dataIndex;
        if (chartInstance && chartInstance.chart) {
          chartInstance.chart.dispatchAction({
            type: 'highlight',
            seriesIndex: seriesIndex,
          });
        }
      }
    },
    onTextBlockMouseLeave(chart, chartIndex, dataIndex, isMany) {
      let chartInstance;
      if (chart.type === 'radar') {
        chartInstance = chart.instances[chartIndex];
        const dataIdx = dataIndex;
        if (chartInstance && chartInstance.chart) {
          chartInstance.chart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: dataIdx,
          });
        }
      } else if (chart.type === 'radarMany') {
        chartInstance = chart.instances[chartIndex];
        const seriesIndex = dataIndex;
        if (chartInstance && chartInstance.chart) {
          chartInstance.chart.dispatchAction({
            type: 'downplay',
            seriesIndex: seriesIndex,
          });
        }
      }
    },
  },
  mounted() {
    this.updateTranslations();
    setTimeout(this.renderCharts, 0);
    window.addEventListener("resize", this.resizeAllGridItems);
    setTimeout(() => {
      const el = document.getElementsByClassName("post-stats");
      if (el && el[0]) {
        el[0].classList.add("compare-pages");
      }
    }, 0);

    // Watch for language changes
    this.$watch(
      () => this.$i18n.locale,
      () => {
        this.updateTranslations();
        this.renderCharts();
      }
    );
  },
  beforeDestroy() {

  },  
};
</script>

<style></style>
