<template>
  <div class="mod-dial settings-setup" @click="INTRO_SET_SETTINGS_MODAL_VISIBLE(false)">
    <div class="mod-cont" @click.stop>
      <span class="close" @click="INTRO_SET_SETTINGS_MODAL_VISIBLE(false)">
        <i class="bi bi-x-lg"></i>
      </span>
      <div class="mod-body mod-settings">
        <div class="settings-body">
          <ul class="settings-tabs">
            <li class="btn-st-grey" :class="{ active: activeTab === 'plan' }" @click="activeTab = 'plan'">
                {{ $t('PROFILE_TITLE') }}
            </li>
            <li class="btn-st-grey"  :class="{ active: activeTab === 'access' }" @click="activeTab = 'access'">
                {{ $t('ACCESS_MANAGER_RIGHTS_MANAGER') }}
            </li>
            <li class="btn-st-grey" v-if="showApiTab" :class="{ active: activeTab === 'api' }" @click="activeTab = 'api'">
                {{ $t('API_CREDENTIALS_TITLE') }}
            </li>
            <li class="btn-st-grey" style="display:none;" :class="{ active: activeTab === 'autoreport' }" @click="activeTab = 'autoreport'">
                {{ $t('REPORTS_MANAGE') }} <!--пока скрыл, не тестилось-->
            </li>
          </ul>

          <!-- Tab Content -->
          <div class="tab-content">
            <div v-show="activeTab === 'plan'">
              <!-- Plan Information Content -->
              <h3 class="tab-title">{{ $t('PROFILE_TITLE') }}</h3>
              <div class="account-info">
                <div class="info-block">
                  <span class="info-title">E-mail</span>
                  <span class="info-value">{{ getProfile.email }} <span class="logout" @click.stop="AUTH_LOGOUT">({{ $t('HEADER_LOG_OUT') }})</span></span>
                </div>
                <div class="info-block">
                  <span class="info-title">{{ $t('PRC_PLN') }}</span>
                  <span class="info-value">{{ getProfile.planName }} 
                    <span class="cancel-sub" @click="cancelSubscription" v-if="getProfile.payment_system === 'stripe' && getProfile.subscription_status !== 'cancelled'">({{ $t('PRC_CANCEL_SUBSCRIPTION') }})</span>
                    <span class="cancel-sub" @click="cancelSubscription" v-if="getProfile.payment_system === 'cloudpayments' && getProfile.subscription_status !== 'cancelled'">({{ $t('PRC_CANCEL_SUBSCRIPTION') }})</span>
                    <span class="cancel-sub" style="cursor: default; text-decoration: auto;" v-if="getProfile.subscription_status === 'cancelled'">cancelled</span>
                    <span class="cancel-sub" style="cursor: default; text-decoration: auto;" v-if="getProfile.payment_system === 'trial'">trial</span>
                  </span>
                  
                </div>
                <div class="info-block">
                  <span class="info-title">{{ $t('PRC_USED') }}</span>
                  <span class="info-value">{{ getProfile.pageCount }}/{{ getProfile.pageLimit }}</span>
                </div>
                <div class="info-block">
                  <span class="info-title">{{ $t('PRC_ADMIN_USED') }}</span>
                  <span class="info-value">{{ getProfile.adminUserCount }}/{{ getProfile.adminLimit }}</span>
                </div>
                <div class="info-block">
                  <span class="info-title">{{ $t('PRC_EXPR') }} </span>
                  <span class="info-value">{{ getProfile.expire }}</span>
                </div>
              </div>
              <div class="mod-foot">
                <div class="b14">
                  {{ $t("AUTH_ACTIVE") }}
                </div>
                <SocialAuthButtons :selectedAccessEmail="userEmail" />
              </div>
            </div>
            <div v-show="activeTab === 'access'">
              <AccessManager />
            </div>
            <div v-show="activeTab === 'api' && showApiTab">
              <h3 class="tab-title">{{ $t('API_CREDENTIALS_TITLE') }}</h3>
              <div class="api-documentation">
                <div class="api-credentials-section">
                  <div class="api-key-container">
                  <span class="info-title">{{ $t('API_KEY_LABEL') }}</span>
                    <div class="api-key-wrapper">
                      <input type="text" readonly :value="userToken" class="api-key-field" />
                      <button class="copy-button" @click="copyToken" :title="$t('COPY_TO_CLIPBOARD')">
                        <i class="bi bi-clipboard"></i>
                      </button>
                    </div>
                  </div>
                  <div class="api-description">
                    <p>{{ $t('API_KEY_DESCRIPTION') }}</p>
                  </div>
                </div>
                
                <div class="api-base-url">
                  <p><span>Base URL:</span> <code>https://api.lucmus.com</code></p>
                </div>
                
                <div class="api-section">
                  <h3 class="api-section-title">{{ $t('API_AUTHORIZATION') }}</h3>
                  <div class="api-section-content">
                    <p>{{ $t('API_AUTH_DESCRIPTION') }}</p>
                    <div class="api-example">
                      <code>Authorization: bearer &lt;ваш_jwt_токен&gt;</code>
                    </div>
                    <p class="api-note">{{ $t('API_AUTH_ERROR') }}</p>
                  </div>
                </div>
                
                <div class="api-section">
                  <h3 class="api-section-title">{{ $t('API_GENERAL_INFO') }}</h3>
                  <div class="api-section-content">
                    <ul class="api-list">
                      <li>{{ $t('API_ALL_GET') }}</li>
                      <li>{{ $t('API_DATE_FORMAT') }}</li>
                      <li>{{ $t('API_JSON_RESPONSE') }}</li>
                </ul>
                  </div>
                </div>
                
                <div class="api-section">
                  <h3 class="api-section-title">{{ $t('API_GET_PAGES') }}</h3>
                  <div class="api-endpoint">
                    <div class="http-method">GET</div>
                    <div class="endpoint-url">/pages/all</div>
                  </div>
                  <div class="api-section-content">
                    <p>{{ $t('API_GET_PAGES_DESC') }}</p>
                    
                    <h4 class="api-subsection-title">{{ $t('API_HEADERS') }}</h4>
                    <div class="parameter-table">
                      <div class="parameter-row">
                        <div class="parameter-name"><strong>Authorization</strong> (обязательный)</div>
                        <div class="parameter-value"><code>bearer &lt;ваш_jwt_токен&gt;</code></div>
                      </div>
                    </div>
                    
                    <h4 class="api-subsection-title">{{ $t('API_REQUEST_PARAMS') }}</h4>
                    <p>{{ $t('API_NO_PARAMS') }}</p>
                    
                    <h4 class="api-subsection-title">{{ $t('API_REQUEST_EXAMPLE') }}</h4>
                    <div class="code-block request">
                      <pre>GET https://api.lucmus.com/pages/all
Authorization: bearer &lt;ваш_jwt_токен&gt;</pre>
                    </div>
                    
                    <h4 class="api-subsection-title">{{ $t('API_RESPONSE_EXAMPLE') }}</h4>
                    <div class="code-block response">
                      <pre>{
  "status": "OK",
  "pages": [
    {
      "page_id": 367,
      "social": "IG",
      "owner_id": "17841400831935560",
      "owner_name": "pepsi",
      "owner_avatar": "https://alpha.popsters.com/monitoring/api/images/avatar/4f45402e87d5261cf92a9c70815d1530.jpg",
      "owner_url": "https://instagram.com/pepsi",
      "hash_id": "5dcccfb3370c4f4c9d8cb33427a4d9",
      "status": "finished",
      "email": "ragul2004@yandex.ru",
      "is_admin": 0,
      "owner_type": "page",
      "owner_domain": "pepsi",
      "need_token_change": 0,
      "error_code": "2500"
    },
    /* Additional entries... */
  ]
}</pre>
                    </div>
                    
                    <h4 class="api-subsection-title">{{ $t('API_KEY_FIELDS') }}</h4>
                    <div class="parameter-list">
                      <div class="parameter-item">
                        <div class="parameter-name"><strong>page_id</strong></div>
                        <div class="parameter-description">{{ $t('API_PAGE_ID_DESC') }}</div>
                      </div>
                      <div class="parameter-item">
                        <div class="parameter-name"><strong>social</strong></div>
                        <div class="parameter-description">{{ $t('API_SOCIAL_DESC') }}</div>
                      </div>
                      <div class="parameter-item">
                        <div class="parameter-name"><strong>hash_id</strong></div>
                        <div class="parameter-description">{{ $t('API_HASH_ID_DESC') }}</div>
                      </div>
                      <!-- Additional fields are available in documentation but shortened for clarity -->
                    </div>
                  </div>
                </div>
                
                <div class="api-section">
                  <h3 class="api-section-title">{{ $t('API_GET_STATS') }}</h3>
                  <div class="api-endpoint">
                    <div class="http-method">GET</div>
                    <div class="endpoint-url">/pages/stats</div>
                  </div>
                  <div class="api-section-content">
                    <p>{{ $t('API_GET_STATS_DESC') }}</p>
                    
                    <h4 class="api-subsection-title">{{ $t('API_HEADERS') }}</h4>
                    <div class="parameter-table">
                      <div class="parameter-row">
                        <div class="parameter-name"><strong>Authorization</strong> (обязательный)</div>
                        <div class="parameter-value"><code>bearer &lt;ваш_jwt_токен&gt;</code></div>
                      </div>
                    </div>
                    
                    <h4 class="api-subsection-title">{{ $t('API_REQUEST_PARAMS') }}</h4>
                    <div class="parameter-table">
                      <div class="parameter-row">
                        <div class="parameter-name"><strong>hash_ids</strong> (string, обязательный)</div>
                        <div class="parameter-description">{{ $t('API_HASH_IDS_DESC') }}</div>
                      </div>
                      <div class="parameter-row">
                        <div class="parameter-name"><strong>start</strong> (string, обязательный)</div>
                        <div class="parameter-description">{{ $t('API_START_DATE_DESC') }}</div>
                      </div>
                      <div class="parameter-row">
                        <div class="parameter-name"><strong>end</strong> (string, обязательный)</div>
                        <div class="parameter-description">{{ $t('API_END_DATE_DESC') }}</div>
                      </div>
                    </div>
                    
                    <h4 class="api-subsection-title">{{ $t('API_REQUEST_EXAMPLE') }}</h4>
                    <div class="code-block request">
                      <pre>GET https://api.lucmus.com/pages/stats?hash_ids=5dcccfb3370c4f4c9d8cb33427a4d9&start=2025-03-01&end=2025-03-23
Authorization: bearer &lt;ваш_jwt_токен&gt;
Accept: application/json
Content-Type: application/json;charset=UTF-8</pre>
                    </div>
                    
                    <h4 class="api-subsection-title">{{ $t('API_CURL_EXAMPLE') }}</h4>
                    <div class="code-block curl">
                      <pre>curl --location --request GET 'https://api.lucmus.com/pages/stats?hash_ids=5dcccfb3370c4f4c9d8cb33427a4d9&start=2025-03-01&end=2025-03-23' \
--header 'accept: application/json, text/plain, */*' \
--header 'authorization: bearer &lt;ваш_jwt_токен&gt;' \
--header 'content-type: application/json;charset=UTF-8'</pre>
                    </div>
                  </div>
                </div>
                
                <div class="api-section">
                  <h3 class="api-section-title">{{ $t('API_STATUS_CODES') }}</h3>
                  <div class="api-section-content">
                    <p>{{ $t('API_STATUS_DESC') }}</p>
                    <p>{{ $t('API_AUTH_ERROR_DESC') }}</p>
                  </div>
                </div>
                
                <div class="api-section">
                  <h3 class="api-section-title">{{ $t('API_ADDITIONAL_NOTES') }}</h3>
                  <div class="api-section-content">
                    <ul class="api-list">
                      <li>{{ $t('API_MULTIPLE_HASH_IDS') }}</li>
                      <li>{{ $t('API_EMPTY_RESULT') }}</li>
                      <li>{{ $t('API_DATE_FORMAT_STRICT') }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="activeTab === 'autoreport'">
              <AutoReportSettings />
            </div>
            
            <button class="btn-st-viol btn-st-bg" @click="INTRO_SET_SETTINGS_MODAL_VISIBLE(false)">
              {{ $t('ACCESS_MANAGER_CLOSE') }}
            </button>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import AccessManager from './AccessManager';
import { mapGetters, mapMutations, mapActions } from "vuex";
import AutoReportSettings from './AutoReportSettings';
import { AUTH_LOGOUT, AUTH_SOCIAL_TOKENS, AUTH_GET_PROFILE, AUTH_CANCEL_SUBSCRIPTION } from "@/store/actions/auth";
import AddPage from './AddPage';
import { INTRO_SET_SETTINGS_MODAL_VISIBLE } from "../../store/actions/intro";
import SocialAuthButtons from '@/components/SocialAuthButtons.vue';
import module from '../../models/AxiosModule';
const axios = module.Axios;

export default {
  name: 'SettingsModal',
  components: {
    AccessManager,
    AutoReportSettings,
    AddPage,
    SocialAuthButtons
  },
  data() {
    return {
      activeTab: 'plan',
      userToken: '',
    };
  },
  computed: {
    ...mapGetters(['getProfile','getSocialTokens']),
    userEmail() {
      return this.getProfile.email;
    },
    showApiTab() {
      const planName = this.getProfile?.planName?.toLowerCase() || '';
      return planName !== 'agency' && planName !== 'basic';
    },
  },
  methods: {
    ...mapMutations({
      INTRO_SET_SETTINGS_MODAL_VISIBLE: INTRO_SET_SETTINGS_MODAL_VISIBLE,
    }),
    ...mapActions([AUTH_LOGOUT, AUTH_GET_PROFILE, AUTH_CANCEL_SUBSCRIPTION]),
    
    async copyToken() {
      try {
        await navigator.clipboard.writeText(this.userToken);
        this.$store.dispatch('alerts/addAlert', {
          message: this.$t('COPIED_TO_CLIPBOARD'),
          color: 'success',
          duration: 3000
        }, { root: true });
      } catch (err) {
        console.error('Failed to copy text: ', err);
        this.$store.dispatch('alerts/addAlert', {
          message: this.$t('COPY_FAILED'),
          color: 'danger',
          duration: 3000
        }, { root: true });
      }
    },
    
    async cancelSubscription() {
      try {
        // Confirm cancellation with user
        if (!confirm(this.$t('PRC_CANCEL_SUBSCRIPTION_CONFIRM'))) {
          return;
        }
        
        // Call store action to cancel subscription
        await this.$store.dispatch(AUTH_CANCEL_SUBSCRIPTION);
        
        // Show success message
        this.$store.dispatch('alerts/addAlert', {
          message: this.$t('PRC_SUBSCRIPTION_CANCELLED_SUCCESS'),
          color: 'success',
          duration: 5000
        }, { root: true });
      } catch (error) {
        console.error('Error cancelling subscription:', error);
        // Show error message
        this.$store.dispatch('alerts/addAlert', {
          message: error.error_message || this.$t('PRC_CANCELLATION_ERROR'),
          color: 'danger',
          duration: 5000
        }, { root: true });
      }
    }
  },
  async mounted() {
    if (!this.getSocialTokens || Object.keys(this.getSocialTokens).length === 0) {
      await this.$store.dispatch(AUTH_SOCIAL_TOKENS);
    }
    // Get token from localStorage
    try {
      this.userToken = localStorage.getItem('user-token') || '';
    } catch (e) {
      console.error('Error accessing localStorage:', e);
      this.userToken = '';
    }
    
    // Redirect from API tab if not allowed
    if (this.activeTab === 'api' && !this.showApiTab) {
      this.activeTab = 'plan';
    }
  },
  watch: {
    // Watch for plan changes to redirect if needed
    'getProfile.planName'() {
      if (this.activeTab === 'api' && !this.showApiTab) {
        this.activeTab = 'plan';
      }
    }
  }
};
</script>
