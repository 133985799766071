<template>
  <div>
    <div class="line-charts">
      <div
        class="panel"
        v-show="canShowChart(name)"
        :key="index"
        v-for="(name, index) in chartNames"
      >
        <h2>{{ $t(`DASHBOARD_REACH_CHARTS_${name}`) }}</h2>
        <div class="m-chart">
          <div
            class="chart"
            :ref="`reach${name}`"
            style="width: 100%; height: 100%"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import PageCharts from "@/models/page-charts";
import linesChart from "@/models/charts/elines";

export default {
  name: "Dash-activity-body",
  computed: {
    ...mapGetters([
      "getSelectedPages",
      "loadingStatus",
      "getAnalizedPages",
      "getUnionPagesData",
      "getSocialOfPageInAnalize",
    ]),
    locale() {
      return this.$locale.DASHBOARD.REACH_CHARTS;
    },
  },

  components: {},
  data() {
    return {
      chartNames: ["reach", "reach_subscribers", "mobile_reach"],
      loaded: false,
      charts: [],
    };
  },
  methods: {
    canShowChart(name) {
      const socials = this.getSocialOfPageInAnalize;
      if (name == "reach_subscribers" || name == "mobile_reach") {
        if (socials.VK) return true;
        else return false;
      } else return true;
    },
    async renderCharts() {
      this.loaded = false;
      const { pages, datesRangePosts } = this.getUnionPagesData;
      const that = this;
      const colors = Object.entries(this.$colors).map(([key, value]) => {
        return { key, value };
      });
      for (const [index, name] of this.chartNames.entries()) {
        this.charts.push(
          await linesChart({
            pages,
            datesRange: datesRangePosts,
            datesRangePosts: datesRangePosts,
            name,
            ref: that.$refs[`reach${name}`][0],
            type: "reach",
            color: colors[index],
          })
        );
      }
    },
  },
  async mounted() {
    setTimeout(this.renderCharts, 0);
  },
  beforeDestroy() {
  },
};
</script>

<style></style>
