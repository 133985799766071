<template>
  <div>

    <div class="mod-access">
      <h3 class="tab-title">{{ $t("ACCESS_MANAGER_RIGHTS_MANAGER") }}</h3>

      <div class="access-body">
        <span class="text-header">{{ $t("ACCESS_MANAGER_GRANT_ACCESS") }}</span>
        <form action class="inp-rad">
          <input
            type="radio"
            name="radio"
            id="x1"
            @click="access_type = true"
            :checked="access_type"
          />
          <label for="x1">{{ $t("ACCESS_MANAGER_FULL_ACCESS") }}</label>
          <input
            type="radio"
            name="radio"
            id="x2"
            @click="access_type = false"
            :checked="!access_type"
          />
          <label for="x2">{{ $t("ACCESS_MANAGER_READ_ONLY") }}</label>
        </form>
        <form action class="inp-btn">
          <input
            type="text"
            class="inp-st"
            placeholder="Email"
            v-model="admin_email"
          />
          <button class="btn-st-red" type="button" @click="giveAccess">
            {{ $t("ACCESS_MANAGER_GIVE_ACCESS") }}
          </button>
        </form>
      </div>
      <div class="tab-wrapper">
        <table class="acc-tab">
          <thead>
            <tr>
              <th>{{ $t("ACCESS_MANAGER_EMAIL") }}</th>
              <th>{{ $t("ACCESS_MANAGER_ACCESS") }}</th>
              <th>{{ $t("ACCESS_MANAGER_ACTION") }}</th>
            </tr>
          </thead>
          <tbody :key="index" v-for="(admin, index) in getGrantedAccesses">
            <tr>
              <td>{{ admin.email }}</td>
              <td>
                <div class="drpdown">
                  <input type="checkbox" :id="'z1_' + index" name />
                  <label :for="'z1_' + index">{{
                    admin.access_type
                      ? $t("ACCESS_MANAGER_FULL_ACCESS")
                      : $t("ACCESS_MANAGER_READ_ONLY")
                  }}</label>
                  <span class="expand"></span>
                  <ul>
                    <li
                      v-if="!admin.access_type"
                      @click="
                        changeAccess({ email: admin.email, access_type: 1 })
                      "
                    >
                      {{ $t("ACCESS_MANAGER_FULL_ACCESS") }}
                    </li>
                    <li
                      v-if="admin.access_type"
                      @click="
                        changeAccess({ email: admin.email, access_type: 0 })
                      "
                    >
                      {{ $t("ACCESS_MANAGER_READ_ONLY") }}
                    </li>
                  </ul>
                </div>
              </td>
              <td>
                <span class="remove" @click="deleteAdmin(admin.email)">{{
                  $t("ACCESS_MANAGER_REMOVE_ACCESS")
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Container for inline alerts -->
        <div ref="alertContainer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { INTRO_SET_ACCESS_MANAGER_VISIBLE } from "../../store/actions/intro";
import {
  ADMIN_DELETE,
  ADMIN_SET,
  ADMIN_CHANGE_ACCESS,
} from "../../store/actions/admin";
import i18n from "@/i18nSetup";

import module from '@/models/AxiosModule'

export default {
  name: "AccessManager",
  computed: {
    ...mapGetters(["getGrantedAccesses"]),
  },

  data() {
    return {
      access_type: false,
      admin_email: undefined,
      emptyAlertInstance: null,
    };
  },
  mounted() {
    this.fetchGrantedAccesses();
  },
  watch: {
    getGrantedAccesses(newVal) {
      if (newVal.length === 0) {
        this.showEmptyTableAlert();
      } else {
        this.clearAlert();
      }
    },
  },
  methods: {
    ...mapActions({
      deleteAdmin: ADMIN_DELETE,
      changeAccess: ADMIN_CHANGE_ACCESS,
      fetchGrantedAccesses: ADMIN_SET,
    }),
    ...mapMutations([INTRO_SET_ACCESS_MANAGER_VISIBLE]),

    giveAccess() {
      const axios = module.Axios;
      axios
        .post("create-admin-access", {
          access_type: this.access_type,
          admin_email: this.admin_email,
        })
        .then(() => {
          this.$store.dispatch(ADMIN_SET).then(() => {
            if (this.getGrantedAccesses.length > 0) {
              this.clearAlert();
            }
          });
        })
        .catch((error) => {
          if (
            error?.response?.data
          ) {
            console.error("Error Data:", error.response.data);
            if (error.response.data?.error_code) {
              this.showInlineAlert(this.$t(error.response.data?.error_code));
            } else {
              this.showInlineAlert(this.$t("OTHER_ERROR"));
            }
          } else {
            console.error("Error:", error.message); // Log error message
          }
        });
    },
    showEmptyTableAlert() {
      this.emptyAlertInstance = this.$alert.show({
        message: i18n.t("ACCESS_MANAGER_NO_ACCESS"),
        type: "inline",
        color: "white",
        component: this,
        duration: 99999,
      });
    },
    clearAlert() {
      if (this.emptyAlertInstance) {
        this.emptyAlertInstance.close();
        this.emptyAlertInstance = null;
      }
    },
    showInlineAlert(message) {
      this.$alert.show({
        message: message,
        type: 'inline',
        color: '',
        component: this,
        target: this.$refs.alertContainer,
      });
    },    
  },
};
</script>
