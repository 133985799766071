<template>
  <div class="sorting-panel">
    <div class="text">{{ $t('SORT_BY') }}:</div>
    <ul class="sorts">
      <li v-for="option in availableSortOptions" :key="option.key">
        <button
          @click="setStoriesSortType(option.key)"
          :class="`btn-st-grey ${isActiveSort(option.key)}`"
        >
          {{ $t(option.label) }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { DASH_SET_STORIES_SORT_TYPE } from "../../../store/actions/dash";
import { sortOptionsMap } from "@/store/sortOptionsMap"; // Adjust the path as needed

export default {
  name: "Dash-stories-feed-sort-panel",
  data() {
    return {
      sortOptionsMap,
    };
  },
  computed: {
    ...mapGetters({
      getStoriesSortStatus: "getStoriesSortStatus",
      getStoriesSortActiveType: "getStoriesSortActiveType",
      stories: 'getStories',
    }),
    socialsInStories() {
      const socialsSet = new Set();
      this.stories.forEach((story) => {
        if (story.social) {
          socialsSet.add(story.social);
        }
      });
      return Array.from(socialsSet);
    },
    availableSortOptions() {
      return this.sortOptionsMap.filter((option) => {
        return option.socials.some((social) => this.socialsInStories.includes(social));
      });
    },
  },
  methods: {
    ...mapMutations({
      setStoriesSortType: DASH_SET_STORIES_SORT_TYPE,
    }),
    isActiveSort(type) {
      if (type !== this.getStoriesSortActiveType) return "";
      if (!this.getStoriesSortStatus) return "active negative";
      return "active positive";
    },
  },
};
</script>
