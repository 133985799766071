<template>
  <div>
    <div class="mod-reports">
      <h3 class="tab-title">{{ $t('REPORTS_MANAGE') }}</h3>
      <div class="mod-soc-pres">
        <div class="sel-pages">
          <div class="labels-block">
            <div class="sel-pg" :key="index" v-for="(page, index) in pages">
              <img :src="page.owner_avatar" alt />
              <div>
                <p>{{ page.owner_name }}</p>
                <span v-if="page.social == 'FB'">Facebook</span>
                <span v-if="page.social == 'IG'">Instagram</span>
                <span v-if="page.social == 'VK'">VK</span>
              </div>
              <span class="close" @click="unselectPage(page.hash_id)"><i class="bi bi-x-lg"></i></span>
            </div>
          </div>
          
        </div>
      </div>
      <div class="mod-inner">
        <div class="b16">{{ $t('REPORTS_TO') }}:</div>
        <div class="labels-block">
          <span
            class="label-mail"
            :key="index"
            v-for="(email, index) in emails"
          >
            {{ email }}
            <span class="close" @click="deleteEmail(email)"><i class="bi bi-x-lg"></i></span>
          </span>
        </div>

        <form action class="inp-btn">
          <input
            type="text"
            class="inp-st"
            placeholder="Receiving email"
            v-model="email"
          />
          <button class="btn-st-red" type="button" @click="addEmail()">
            {{ $t('ADD_PAGE_SELECT_PAGE') }}
          </button>
        </form>
        <div class="b16">{{ $t('REPORTS_REG') }}</div>
        <form action class="inp-rad">
          <input
            type="radio"
            name="radio"
            id="x1"
            @click="period = 'week'"
            :checked="period == 'week' ? true : false"
          />
          <label for="x1">{{ $t('REPORTS_REG_WK') }}</label>
          <input
            type="radio"
            name="radio"
            id="x2"
            @click="period = 'month'"
            :checked="period == 'month' ? true : false"
          />
          <label for="x2">{{ $t('REPORTS_REG_MN') }}</label>
        </form>
        <div class="b16">{{ $t('REPORTS_FORM') }}</div>
        <form action class="inp-chk">
          <input
            type="checkbox"
            name="checkbox"
            id="y1"
            class="inp-chk"
            @click="type = 'pptx'"
            :checked="type == 'pptx' ? true : false"
          />
          <label for="y1" class="inp-chk">PPTX</label>
          <input
            type="checkbox"
            name="checkbox"
            id="y2"
            class="inp-chk"
            @click="type = 'pdf'"
            :checked="type == 'pdf' ? true : false"
          />
          <label for="y2" class="inp-chk">PDF</label>
          <input
            type="checkbox"
            name="checkbox"
            id="y3"
            class="inp-chk"
            @click="type = 'xlsx'"
            :checked="type == 'xlsx' ? true : false"
          />
          <label for="y3" class="inp-chk">XLSX</label>
        </form>
      </div>
      <button class="btn-st-viol btn-st-bg btn-st-green" type="button" @click="makeAutoReport">
        {{ $t('REPORTS_SAVE') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE } from "../../store/actions/intro";
import module from '@/models/AxiosModule'

export default {
  name: "AutoReportSettings",
  computed: mapGetters([
    "getProfile",
    "getHash",
    "getPages",
    "getSelectedPages",
  ]),

  data() {
    return {
      period: "week",
      type: "pdf",
      title: "",
      emails: [],
      email: undefined,
      pages: [],
    };
  },
  mounted() {
    this.emails = [this.getProfile.email];
    this.pages = JSON.parse(JSON.stringify(this.getSelectedPages));
  },
  methods: {
    ...mapMutations([INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE]),
    makeAutoReport: function () {
      const hash_ids = this.pages.map((page) => page.hash_id).join(",");
      const names = this.pages.map((page) => page.owner_name);
      const axios = module.Axios;

      axios
        .post("create-auto-report", {
          hash_ids,
          period: this.period,
          type: this.type,
          emails: this.emails,
          names,
        })
        .then((res) => {
          this.INTRO_SET_AUTOREPORTS_SETTINGS_VISIBLE(false);
        });
    },
    addEmail() {
      if (!this.email.length) return;
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      this.email = String(this.email).toLowerCase();
      if (!re.test(this.email) || this.emails.includes(this.email)) return;
      this.emails.push(this.email);
    },
    deleteEmail(email) {
      if (this.emails.length == 1) return;
      this.emails = this.emails.filter((em) => em != email);
    },
    unselectPage(hash) {
      if (this.pages.length == 1) return;
      this.pages = this.pages.filter((p) => p.hash_id != hash);
    },
  },
};
</script>
