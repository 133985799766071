<template>
  <div
    class="header"
    v-click-outside="
      () => {
        showDropdown = false;
      }
    "
  >
    <div class="logo" @click="redirectToIntro()">
      <img src="@/assets/images/Main_Black.svg" alt />
    </div>
    <span class="dev"></span>
    <div class="search-panel" ref="planInfo">
      <div class="search-container" v-if="showInput">
        <i
          :class="[searchInput ? 'bi bi-x-lg rd-btn' : 'bi bi-search']"
          @click="searchInput && (searchInput = '')"
        ></i>
        <input
          type="text"
          v-model="searchInput"
          class="search-panel"
          :placeholder="$t('HEADER_SEARCH')"
          size="auto"
        />
      </div>
      
    </div>
    <div class="profile-panel">
      <div class="drpdown">
        <input type="checkbox" name id="z1" v-model="showDropdown" />
        <label for="z1" class="profiles-list">{{ getProfile.email }}</label>
        <span class="expand"></span>
        <ul class="drp-profile">
          <div class="plan-info">
            <div class="active-plan" :class="{ 'no-plan': !getProfile.plan }">
              <template v-if="getProfile.plan">
                {{ getProfile.planName }}
              </template>
              <template v-else>
                {{ $t('PRC_NO_PLAN') }}
              </template>
            </div>
            <span class="plan-details">{{ $t('PRC_USED') }}: {{ pageCount }}/{{ getProfile.plan ? getProfile.pageLimit : '0' }}</span>
            <span class="plan-details">{{ $t('PRC_ADMIN_USED') }}: {{ getProfile.adminUserCount }}/{{ getProfile.plan ? getProfile.adminLimit : '0' }}</span>
            <span class="exp-plan">{{ $t('PRC_EXPR') }}: 
              <template v-if="!getProfile.plan">
                <u class="expired">-</u>
              </template>
              <template v-else-if="isPlanExpired">
                <u class="expired">{{ getProfile.expire }}</u>
              </template>
              <template v-else>
                {{ getProfile.expire }}
              </template>
            </span>
            <a class="btn-st-red" href="plans" target="_self">{{ $t('PRC_UPGR') }}</a>
          </div>
          <!--<div class="plan-info">
            <div class="active-plan">
              {{ $t('PRC_PLN') }}: <span>Free</span>
            </div>
            <span class="plan-details">{{ $t('PRC_USED') }}: 2/2</span>
            <a class="btn-st-red" href="/plans" target="_self">{{ $t('PRC_UPGR') }}</a>
          </div>-->
          <li
            @click.stop="navigateToPlans"
          >
            {{ $t('PRICING_TITLE') }}
          </li>
          <li
            @click.stop="
              INTRO_SET_SETTINGS_MODAL_VISIBLE(true);
              showDropdown = false;
            "
          >
            {{ $t('PROFILE_SETTINGS') }}
          </li>
          <!-- <li
            @click.stop="
              $router.push({ path: '/reports' });
              showDropdown = false;
            "
          >
            {{ $t('HEADER_REPORTS_MANAGE') }}
          </li> -->
          <li @click.stop="AUTH_LOGOUT">{{ $t('HEADER_LOG_OUT') }}</li>
          <div class="drp-options">
            <label for="io8" class="lang-set">
              <input type="checkbox" name="io8" id="io8">
              <i class="bi bi-globe"></i>
              <ul class="lang-list">
                <li @click="changeLanguage('en', getProfile)">English</li>
                <li @click="changeLanguage('de', getProfile)">Deutsch</li>
                <li @click="changeLanguage('es', getProfile)">Español</li>
                <li @click="changeLanguage('pt', getProfile)">Português</li>
                <li @click="changeLanguage('ru', getProfile)">Русский</li>
              </ul>
            </label>
          </div>
        </ul>
      </div>
    </div>
    <div class="head-alerts" ref="headAlerts"></div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import {
  INTRO_SET_ACCESS_MANAGER_VISIBLE,
  INTRO_SET_SETTINGS_MODAL_VISIBLE,
  INTRO_SET_SEARCH_INPUT,
  INTRO_SET_PAGES,
} from "../store/actions/intro";
import {
  AUTH_GET_PROFILE,
  AUTH_GET_ALERTS,
} from "../store/actions/auth";
import { AUTH_LOGOUT } from "@/store/actions/auth";
import ClickOutside from "vue-click-outside";

import Alert from '@/components/Alert.vue';
import module from '@/models/AxiosModule'

import utils from "@/utils.js"
const { changeLanguage } = utils;

export default {
  name: "Header",
  components: {
    Alert
  },
  props: {
    showInput: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["getProfile", "getSearchInputText", "getPages"]),
    locale() {
    },
    searchInput: {
      get() {
        return this.getSearchInputText;
      },
      set(value) {
        this.INTRO_SET_SEARCH_INPUT(value);
      },
    },
    isPlanExpired() {
      if (!this.getProfile || !this.getProfile.expire) return false;
      
      // Handle DD-MM-YYYY format
      const [day, month, year] = this.getProfile.expire.split('-').map(Number);
      const expireDate = new Date(year, month - 1, day); // month is 0-based in JS Date
      const today = new Date();
      
      // Reset time parts to compare just dates
      expireDate.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);
      
      return expireDate < today;
    },
    pageCount() {
      return this.getPages.filter(p => p.email === this.getProfile.email && p.status !== 'deleted').length;
    },
  },
  data() {
    return {
      showDropdown: false,
      planAlertInstance: null,
    };
  },
  watch: {
    getProfile: {
      handler(newVal) {
        if (newVal && newVal.expire) {
          // Handle DD-MM-YYYY format
          const [day, month, year] = newVal.expire.split('-').map(Number);
          const expireDate = new Date(year, month - 1, day); // month is 0-based in JS Date
          const today = new Date();
          
          // Reset time parts to compare just dates
          expireDate.setHours(0, 0, 0, 0);
          today.setHours(0, 0, 0, 0);
          
          if (expireDate < today) {
            this.showPlanAlert(true);
          } else {
            this.clearPlanAlert();
          }
        } else {
          this.clearPlanAlert();
        }
      },
      immediate: true
    },
  },
  mounted() {
    this.popupItem = this.$el;
    this.AUTH_GET_PROFILE();
    this.AUTH_GET_ALERTS();
    this.showFormAlert();
  },
  methods: {
    ...mapMutations([INTRO_SET_ACCESS_MANAGER_VISIBLE, INTRO_SET_SEARCH_INPUT, INTRO_SET_SETTINGS_MODAL_VISIBLE]),
    ...mapActions([AUTH_LOGOUT, INTRO_SET_PAGES, AUTH_GET_PROFILE, AUTH_GET_ALERTS]),
    redirectToIntro() {
      if (this.$route.path == "/main") this.INTRO_SET_PAGES();
      else this.$router.push({ path: "/main" });
    },
    showPlanAlert(isExpired = false) {
      if (!this.planAlertInstance) {
        this.planAlertInstance = this.$alert.show({
          message: this.$i18n.t(isExpired ? 'ALERT_PLAN_FINISHED' : 'ALERT_BASIC_PLAN'),
          type: 'inline',
          color: 'basic-plan',
          component: this,
          duration: 0,
          target: this.$refs.planInfo,
        });
      }
    },
    showFormAlert() {
      if (localStorage.getItem('formAlertDismissed') === 'true') {
        return;
      }
      
      this.planFormAlertInstance = this.$alert.show({
        message: 'Пожалуйста, заполните опрос о сервисе <a href="https://forms.gle/GzRyACobhTYdHkRZ6" target="_blank">здесь</a>',
        type: 'inline',
        color: 'byzant',
        component: this,
        duration: 0,
        target: this.$refs.headAlerts
      });
      
      // Store original close method
      const originalClose = this.planFormAlertInstance.close;
      
      this.planFormAlertInstance.close = () => {
        localStorage.setItem('formAlertDismissed', 'true');
        originalClose.call(this.planFormAlertInstance);
      };
    },
    clearPlanAlert() {
      if (this.planAlertInstance) {
        this.planAlertInstance.close();
        this.planAlertInstance = null;
      }
    },
    navigateToPlans() {
      if (this.$route.path !== '/plans') {
        this.$router.push({ path: '/plans' }).catch(() => {});
      }
      this.showDropdown = false;
    },
    changeLanguage
  },
  directives: {
    ClickOutside,
  },
};
</script>

<style></style>