import "core-js/stable";
import "regenerator-runtime/runtime";

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import 'regenerator-runtime/runtime';

import Vue from "vue";
import App from "./App";
import router from "./router";
import store from "./store";
import ScrollLoader from "vue-scroll-loader";
import VueYandexMetrika from "vue-yandex-metrika";
import AlertService from './models/AlertService';

import "./assets/main.css";
import "./assets/temp.css";
import VueResource from "vue-resource";
// import Axios from "./models/AxiosModule";
import moment from "moment";
Vue.prototype.moment = moment;

import colors from "./assets/colors.json";
Vue.config.ignoredElements = ["mod"];

// Vue.prototype.$Axios = Axios;
Vue.prototype.$colors = colors;

window.colors = colors;

import Alert from './components/Alert.vue';
import i18n from './i18nSetup';

Vue.use(ScrollLoader);
Vue.use(VueResource);
Vue.use(AlertService);
Vue.component('Alert', Alert);
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload);

Vue.http.options.emulateJSON = true;

Vue.config.productionTip = false;

/* Vue.use(VueYandexMetrika, {
  id: 70303045,
  router: router,
  env: process.env.NODE_ENV,
}); */

//remove violations
(function () {
  const originalAddEventListener = EventTarget.prototype.addEventListener;
  EventTarget.prototype.addEventListener = function (type, listener, options) {
    const newOptions = options || {};
    if (['wheel', 'mousewheel', 'touchstart', 'touchmove'].includes(type)) {
      if (typeof newOptions === 'object') {
        newOptions.passive = true;
      } else {
        newOptions = {
          capture: newOptions,
          passive: true,
        };
      }
    }
    return originalAddEventListener.call(this, type, listener, newOptions);
  };
})();

//end


new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

