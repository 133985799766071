import * as echarts from 'echarts';
import i18n from '@/i18nSetup';

const localeMap = {
    "likes": "ALL_LIKES",
    "reposts": "ALL_REPOSTS",
    "comments": "ALL_COMMENTS",
    "views": "ALL_VIEWS",
    "posts": "ALL_RECORDS",
    "stories": 'STORIES',
    "interactions": "INTERACTIONS",
};

// Ensure every value is defined; replace undefined values with 0
const aggregate = (pages, names, locale) => {
    const data = [];
    for (const name of names) {
        const value = {};
        for (const [index, page] of pages.entries()) {
            // Assign value or set it to 0 if undefined
            value['value' + index] = page.stats.total[name]
                ? parseInt(page.stats.total[name].value.replace(/\s+/g, ""))
                : 0;
        }
        // Calculate percentages based on the max value
        const arr = Object.values(value);
        const max = Math.max(...arr);
        for (let key of Object.keys(value)) {
            value[key + '_percent'] = max > 0 ? value[key] / max : 0; // Avoid NaN if max is 0
        }
        value.category = i18n.t('STATS_TABLE_TOTAL_' + localeMap[name]);
        data.push(value);
    }

    return data.reverse(); // Reverse the data to match desired order
};

const create = ({ pages, ref, names, data, locale, animation = true }) => {
    return new Promise((resolve, reject) => {
        if (!ref) return;

        let chartData, categoryField, valueField, isNamesMode;
        if (names) {
            chartData = aggregate(pages, names, locale);
            categoryField = 'category';
            valueField = (index) => `value${index}_percent`;
            isNamesMode = true;
        } else {
            chartData = data;
            categoryField = 'age';
            valueField = (index) => `proc_${index}`;
            isNamesMode = false;
        }

        const chart = echarts.init(ref);

        // Extract category labels for yAxis data
        const yAxisData = chartData.map(item => item[categoryField]);

        const dimensions = [categoryField];
        pages.forEach((page, index) => {
            dimensions.push(valueField(index));
        });

        const dataset = {
            dimensions: dimensions,
            source: chartData
        };

        const series = pages.map((page, index) => ({
            name: page.owner_name_medium,
            type: 'bar',
            encode: {
                x: valueField(index),
                y: categoryField
            },
            itemStyle: {
                color: page.colorCode,
                borderRadius: 3,
            },
            barWidth: 4,
            barGap: '100%',
            label: {
                show: false
            }
        }));

        const xAxis = {
            type: 'value',
            show: false
        };

        const yAxis = {
            type: 'category',
            data: yAxisData, // Set yAxis data to ensure correct category order
            inverse: false,
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
                show: true, // Show category labels on y-axis
                fontSize: 12,
                formatter: function (value) {
                    return value;
                }
            }
        };

        // Tooltip with proper 0 value handling
        const tooltip = {
            trigger: 'axis',
            backgroundColor: 'transparent',
            borderWidth: 0,
            padding: 0,
            transitionDuration: 0.4,
            axisPointer: {
                type: 'shadow'
            },
            formatter: function (params) {
                const category = params[0].axisValue;
                const dataIndex = params[0].dataIndex;
                const dataItem = chartData[dataIndex];
                let tooltipHtml = `<div class='tooltip'><span class='date'>${category}</span><ul class='tooltip-list'>`;
                
                pages.forEach((page, idx) => {
                    let value, displayValue;
                    if (isNamesMode) {
                        // For overviewManyAll chart, display actual values
                        const valueFieldName = 'value' + idx;
                        value = dataItem[valueFieldName] || 0; // Ensure 0 for missing values
                        displayValue = value;
                    } else {
                        // For ages charts, display percentages
                        const valueFieldName = valueField(idx);
                        value = dataItem[valueFieldName] || 0; // Ensure 0 for missing percentages
                        displayValue = value + '%';
                    }
                    
                    tooltipHtml += `<li data-value="${value}">
                        <div class="soc-sea ${page.colorName}">
                            <span class="soc-ico soc-${page.social.toLowerCase().toLocaleString('ru-RU')}"></span>
                            ${page.owner_name_medium}
                        </div>${displayValue.toLocaleString('ru-RU')}
                    </li>`;
                });
                tooltipHtml += '</ul></div>';
                
                // Check if all values are zero and return an empty string to hide the tooltip
                const totalValue = Object.keys(dataItem)
                    .filter(key => key.includes('value') || key.includes('percent'))
                    .reduce((sum, key) => sum + (dataItem[key] || 0), 0);

                return totalValue === 0 ? '' : tooltipHtml; // Hide tooltip if no valid data
            }
        };

        const option = {
            dataset: dataset,
            tooltip: tooltip,
            xAxis: xAxis,
            yAxis: yAxis,
            grid: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            series: series,
            animation: animation,
            animationDuration: animation ? 1000 : 0,
            animationEasing: animation ? 'cubicOut' : 'linear',
            animationThreshold: animation ? 2000 : 0
        };

        chart.setOption(option);

        resolve(chart);
        window.addEventListener('resize', () => {
            chart.resize();
        });
    });
};

export default create;
