<template>
  <div>
    <Dash-stories-feed-sort-panel />
    <div class="one-page">
      <div class="panel">
        <ul class="posts stories">
          <Dash-stories-card :story="story" :key="story.stat.id" v-for="story in page.stories" />
        </ul>
        <p class="no-posts" v-if="page.stories.length === 0">{{ $t('NO_POSTS') }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import DashStoriesFeedSortPanel from "./Dash-stories-feed-sort-panel";
import DashStoriesCard from "./Dash-stories-card";

export default {
  name: "Dash-stories-feed-one",
  components: {
    DashStoriesFeedSortPanel,
    DashStoriesCard,
  },
  computed: {
    ...mapGetters(["getAnalizedPages"]),
    page() {
      return this.getAnalizedPages[0];
    },
  },
};
</script>
