import module from '../../models/AxiosModule'
const axios = module.Axios
import router from '../../router/index'
import qs from 'qs';
import {
  AUTH_REQUEST,
  AUTH_LOGIN,
  AUTH_GOOGLE,
  AUTH_REGISTER,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  AUTH_FORGOT,
  AUTH_CHANGEPASS,
  AUTH_REQUEST_COMPLETE,
  AUTH_SOCIAL_TOKENS,
  AUTH_ACTIVATE,
  AUTH_REMOVE_TOKEN,
  AUTH_GET_PROFILE,
  UPDATE_PROFILE,
  AUTH_GET_ALERTS,
  AUTH_REMOVE_SOCIAL_TOKEN,
  AUTH_ACTIVATE_TRIAL,
  AUTH_CANCEL_SUBSCRIPTION
} from "@/store/actions/auth"

import { INTRO_SET_ALERT } from "../actions/intro";

import i18n from '@/i18nSetup';


const state = {
  token: localStorage.getItem('user-token') || '',
  status: '',
  profile: {
    email: localStorage.getItem('user-email') || ''
  },
  socialTokens: undefined,
  // alerts: []
}

const getters = {
  isAuthenticated: state => !!state.token,
  getToken: state => state.token,
  authStatus: state => state.status,
  getProfile: state => state.profile,
  getSocialTokens: state => state.socialTokens,
  getMySocialTokens: state => state.socialTokens[state.profile.email],
  // getAlerts: state => state.alerts
}



const authRequest = function (action, data, resolveCb, rejectCb) {
  return new Promise((resolve, reject) => {

    axios.post(action, data).then((response) => {
      resolveCb(response, resolve);
    }, function (response) {
      rejectCb(response, reject);
    })
  });
}

const actions = {
  [AUTH_LOGIN]: ({ commit, dispatch }, data) => {
    commit(AUTH_REQUEST, 'login')

    return authRequest('login', data, function (response, resolve) {
      if (response.data.token) {
        localStorage.setItem('user-token', response.data.token)
      }
      if (response.data.email) {
        localStorage.setItem('user-email', response.data.email)
      }
      axios.defaults.headers.authorization = `bearer ${response.data.token}`
      commit(AUTH_SUCCESS, response)

      // Получаем данные о тарифе после успешного логина
      dispatch(AUTH_GET_PROFILE)
      dispatch(AUTH_GET_ALERTS)

      resolve(response)
    }.bind(data), function (error, reject) {
      commit(AUTH_ERROR, error.response.data)
      localStorage.removeItem('user-token')
      localStorage.removeItem('user-email')
      reject(error.response.data)
    })
  },
  [AUTH_GOOGLE]: ({
    commit,
    dispatch
  }, data) => {
    commit(AUTH_REQUEST, 'login');
    return authRequest('google', data, function (response, resolve) {
      if (response.data.token) {
        localStorage.setItem('user-token', response.data.token)
      }
      if (response.data.email) {
        localStorage.setItem('user-email', response.data.email)
      }
      axios.defaults.headers.authorization = `bearer ${response.data.token}`
      commit(AUTH_SUCCESS, response)
      // Получаем данные о тарифе после успешного логина
      dispatch(AUTH_GET_PROFILE)
      dispatch(AUTH_GET_ALERTS)
      resolve(response)
    }.bind(data), function (error, reject) {
      commit(AUTH_ERROR, error.response.data)
      localStorage.removeItem('user-token')
      localStorage.removeItem('user-email')
      reject(error.response.data)
    });
  },
  [AUTH_REGISTER]: ({ commit, dispatch }, data) => {
    commit(AUTH_REQUEST, 'signup')

    return new Promise((resolve, reject) => {
      axios.post('signup', qs.stringify(data))
        .then(response => {
          axios.defaults.headers.authorization = `bearer ${response.data.token}`
          commit(AUTH_SUCCESS, response)
          resolve(response)
        })
        .catch(error => {
          commit(AUTH_ERROR, error.response.data)
          localStorage.removeItem('user-token')
          localStorage.removeItem('user-email')
          reject(error.response.data)
        })
    })
  },
  [AUTH_FORGOT]: ({
    commit,
    dispatch
  }, data) => {

    return new Promise((resolve, reject) => {

      commit(AUTH_REQUEST, 'forgot')

      const debug = process.env.NODE_ENV !== 'production';

      let url;
      if (debug) {
        url = 'http://localhost:9260/forgot';
      } else {
        url = 'https://lucmus.com/api/login';
      }

      const options = {
        method: 'POST',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        data: qs.stringify(data),
        url: 'forgot',
      };

      axios(options)
        .then(resp => {
          // commit(AUTH_SUCCESS, resp)
          resolve(resp)
        })
        .catch(err => {
          commit(AUTH_ERROR, err.response.data)
          reject(err.response.data)
        })
    })
  },
  [AUTH_CHANGEPASS]: ({
    commit,
    dispatch
  }, data) => {
    commit(AUTH_REQUEST, 'changepass');
    return authRequest('changepass', data, function (response, resolve) {
      commit(AUTH_REQUEST_COMPLETE)
      resolve(response)
    }, function (error, reject) {
      commit(AUTH_ERROR, error.response.data)
      reject(error.response.data)
    });
  },
  [AUTH_ACTIVATE]: ({
    commit,
    dispatch
  }, data) => {
    return authRequest('activate', data, function (response, resolve) {
      commit(AUTH_REQUEST_COMPLETE)
      resolve(response)
    }, function (error, reject) {
      commit(AUTH_ERROR, error.response.data)
      reject(error.response.data)
    });
  },
  [AUTH_LOGOUT]: ({
    commit,
    dispatch
  }) => {
    return new Promise((resolve, reject) => {
      localStorage.removeItem('user-token')
      localStorage.removeItem('user-email')
      commit(AUTH_LOGOUT)
      commit('RESET_STORE')
      router.push({ path: "/auth/login" })



      resolve()
    })
  },
  [AUTH_SOCIAL_TOKENS]: async ({
    commit,
    dispatch
  }) => {
    await axios.post('get-social-token', state.profile).then(({ data }) => {
      commit(AUTH_SOCIAL_TOKENS, {
        tokens: data.tokens
      })

    })
  },
  [AUTH_REMOVE_TOKEN]: async ({ commit, dispatch, state }, payload) => {
    const data = {
      email: state.profile.email,
      social: payload.social
    };
    try {
      const response = await axios.post('remove-social-token', data);
      if (response.data && response.data.success === true) {
        commit(AUTH_REMOVE_SOCIAL_TOKEN, payload.social);
        dispatch(AUTH_SOCIAL_TOKENS);
      }
    } catch (error) {
      console.error('Failed to remove social token:', error);
    }
  },
  [AUTH_GET_PROFILE]: ({ commit, state }) => {
    commit(AUTH_REQUEST, 'get-profile')

    // Используем authRequest для выполнения POST-запроса
    return authRequest('get-profile', { email: state.profile.email }, function (response, resolve) {

      if (response.data.data?.locale) {
        i18n.locale = response.data.data.locale.toLowerCase();
      }

      // Map numeric plan codes to their respective translation keys
      if (response.data.data && response.data.data.plan) {
        const planCode = parseInt(response.data.data.plan);
        let planKey = '';
        
        // Map plan codes to translation keys
        switch (planCode) {
          case 100:
            planKey = 'PRC_PLAN_BASIC';
            break;
          case 200:
            planKey = 'PRC_PLAN_AGENCY';
            break;
          case 300:
            planKey = 'PRC_PLAN_CORPORATE';
            break;
          case 400:
            planKey = 'PRC_PLAN_CUSTOM';
            break;
          default:
            planKey = '-';
        }
        
        // Add translated plan name if a valid plan code was found
        if (planKey) {
          response.data.data.planName = i18n.t(planKey);
        }
      } else if (response.data.data && response.data.data.plan === null) {
        // Handle case when no plan exists (plan is null)
        response.data.data.planName = i18n.t('PRC_NO_PLAN');
      }

      // Обновляем данные профиля в state
      commit('UPDATE_PROFILE', response.data.data)
      commit(AUTH_REQUEST_COMPLETE)
      
      if (state.profile.daysLeft <= 0) {
        commit(INTRO_SET_ALERT, { status: true, text: 'Ваш тариф закончился. Перейдите в оплаты' })
      }
      resolve(response)
    }, function (error, reject) {
      commit(AUTH_ERROR, error.response.data)
      reject(error.response.data)
    });
  },
  [AUTH_GET_ALERTS]: ({ dispatch, state }) => {
    return authRequest(
      'get-alerts',
      { email: state.profile.email },
      function (response, resolve) {
        const alerts = response.data.data;

        // Dispatch 'addAlert' for each alert
        alerts.forEach((alert) => {
          const message = i18n.t(alert.alert_type, {social: alert.social});
          dispatch(
            'alerts/addAlert',
            {
              id: alert.alert_id,
              message,
              alert_type: alert.alert_type,
              social: alert.social,
              color: alert.color || '',
              duration: alert.duration || 5000,
              is_server: true
            },
            { root: true }
          );
        });

        resolve(response);
      },
      function (error, reject) {
        // Handle error if needed
        reject(error);
      }
    );
  },
  [AUTH_ACTIVATE_TRIAL]: ({ commit, dispatch, state }) => {
    commit(AUTH_REQUEST, 'activate-trial')
    
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_API + 'create-test-plan', { email: state.profile.email })
        .then(response => {
          if (response.data && response.data.success === 'ok') {
            // Instead of manually updating profile, fetch fresh data from server
            dispatch(AUTH_GET_PROFILE).then(() => {
              commit(AUTH_REQUEST_COMPLETE)
              
              // Show global alert about trial activation
              dispatch(INTRO_SET_ALERT, { 
                status: true, 
                text: i18n.t('TRIAL_PLAN_ACTIVATED'),
                color: 'success'
              }, { root: true })
              
              // Redirect to main dashboard
              router.push({ path: '/main' })
              
              // Return success response
              resolve({ success: true })
            }).catch(profileError => {
              commit(AUTH_ERROR, profileError)
              reject({ 
                success: false, 
                error: 'Failed to fetch updated profile data'
              })
            })
          } else {
            commit(AUTH_ERROR, response.data)
            // Return error response
            reject({ success: false, error: response.data?.error_message || 'UNKNOWN_ERROR' })
          }
        })
        .catch(error => {
          commit(AUTH_ERROR, error.response?.data || error)
          // Return error response with proper message
          reject({ 
            success: false, 
            error: error.response?.data?.error_message || 'UNKNOWN_ERROR_OCCURRED'
          })
        })
    })
  },
  [AUTH_CANCEL_SUBSCRIPTION]: ({ commit, dispatch, state }) => {
    commit(AUTH_REQUEST, 'cancel-subscription')
    
    return new Promise((resolve, reject) => {
      // Get payment system from the profile, with fallbacks
      const paymentSystem = state.profile.payment_system;
                           
      axios.post('cancel-subscription', {
        email: state.profile.email,
        payment_system: paymentSystem
      })
        .then(response => {
          commit(AUTH_REQUEST_COMPLETE)
          
          if (response.data && response.data.success) {
            // Refresh profile data after successful cancellation
            dispatch(AUTH_GET_PROFILE)
            resolve(response.data)
          } else {
            reject(response.data || { error: 'Unknown error occurred' })
          }
        })
        .catch(error => {
          commit(AUTH_ERROR, error.response?.data)
          reject(error.response?.data || error)
        })
    })
  },
}

const mutations = {
  [AUTH_REQUEST]: (state, type) => {
    state.status = `loading-${type}`;
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success'
    // 
    state.token = resp.data.token
    state.profile.email = resp.data.email
    // state.hasLoadedOnce = true
  },
  [UPDATE_PROFILE]: (state, resp) => {
    state.profile = { ...state.profile, ...resp }
    if (state.profile.expire) {
      const expireDate = moment(state.profile.expire);
      state.profile.expire = expireDate.format("DD-MM-YYYY");

      // Вычисляем остаток дней до окончания тарифа
      const today = moment();
      const daysLeft = expireDate.diff(today, 'days');
      state.profile.daysLeft = daysLeft;
    }
  },
  [AUTH_REQUEST_COMPLETE]: (state) => {
    state.status = ''
  },
  [AUTH_ERROR]: (state, resp) => {
    state.status = 'error'

  },
  [AUTH_LOGOUT]: (state) => {
    state.token = ''
    state.status = ''
    state.profile = {
      email: ''
    }
  },
  [AUTH_SOCIAL_TOKENS]: (state, resp) => {
    state.socialTokens = resp.tokens
  },
  [AUTH_REMOVE_SOCIAL_TOKEN]: (state, social) => {
    if (state.socialTokens && state.profile && state.profile.email) {
      if (state.socialTokens[state.profile.email]) {
        delete state.socialTokens[state.profile.email][social];
      }
    }
  },  
}

export default {
  state,
  getters,
  actions,
  mutations,
}
