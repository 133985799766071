export default {
    methods: {
      getVK() {
        window.location.href = `https://oauth.vk.com/authorize?client_id=${process.env.VUE_APP_VK_APP_ID}&display=page&redirect_uri=${process.env.VUE_APP_SOCIAL_REDIRECT}&scope=video,groups,offline,stats,stories&response_type=token&v=${process.env.VUE_APP_VK_API_VERSION}`;
      },
      getFB() {
        window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?type=user_agent&client_id=${process.env.VUE_APP_FB_APP_ID}&scope=public_profile,email,read_insights,pages_show_list,pages_read_engagement&redirect_uri=${process.env.VUE_APP_SOCIAL_REDIRECT}&state=facebook`;
      },
      getIG() {
        window.location.href = `https://www.facebook.com/v19.0/dialog/oauth?client_id=${process.env.VUE_APP_IG_APP_ID}&redirect_uri=${process.env.VUE_APP_SOCIAL_REDIRECT}&response_type=token&scope=email,instagram_basic,pages_show_list,read_insights,instagram_manage_insights,pages_read_engagement,business_management,pages_manage_metadata&state=instagram`;
      },
    },
  };  