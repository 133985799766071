import Vue from 'vue';
import AlertComponent from '@/components/Alert.vue';
import store from '@/store';

const AlertConstructor = Vue.extend(AlertComponent);

const AlertService = {
  show({ message, type = 'global', color = 'red', duration = 5000, component, target }) {
    if (type === 'inline' && component) {
      // Create a new Alert instance for inline alerts
      const alertInstance = new AlertConstructor({
        propsData: {
          id: Date.now(),
          isGlobal: false,
          color,
          duration,
          message,
        },
      });

      alertInstance.$mount();

      // Determine where to insert the alert
      let parentElement = component.$el;
      if (target) {
        if (typeof target === 'string') {
          parentElement = component.$el.querySelector(target);
        } else if (target instanceof HTMLElement) {
          parentElement = target;
        } else if (target instanceof Vue) {
          parentElement = target.$el;
        }
      }

      if (parentElement) {
        parentElement.appendChild(alertInstance.$el);

        alertInstance.show();

        alertInstance.$on('closed', () => {
          parentElement.removeChild(alertInstance.$el);
          alertInstance.$destroy();
        });
      } else {
        console.warn('Alert target not found in component.');
      }

      // Return the alert instance
      return alertInstance;
    } else {
      // For global alerts, dispatch an action to the Vuex store
      const alert = {
        id: Date.now(),
        message,
        color,
        duration,
      };

      store.dispatch('alerts/addAlert', alert);
      return null;
    }
  },
};

export default {
  install(Vue) {
    Vue.prototype.$alert = AlertService;
  },
};