import Vue from 'vue'
import Router from 'vue-router'

const ReportsList = () => import('../pages/ReportsList.vue')
const Intro = () => import('../pages/Intro/Intro.vue')
import Dashboard from '../pages/Dashboard/Dashboard.vue'
import Plans from '../pages/Plans.vue'
import PaymentStatus from '../pages/PaymentStatus.vue'
const Login = () => import('../pages/auth/Login')
const Signup = () => import('../pages/auth/Signup')
const Forgot = () => import('../pages/auth/Forgot')
const Changepass = () => import('../pages/auth/Changepass')
const Auth = () => import('../pages/auth/Index')
const Activate = () => import('../pages/auth/Activate')
const Socialauth = () => import('../pages/auth/Socialauth')
import PaymentSuccess from '../pages/PaymentSuccess.vue';
import PaymentCancel from '../pages/PaymentCancel.vue';
import PaymentForm from '../pages/PaymentForm.vue';

import store from '../store'


Vue.use(Router)

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }

  next({ path: '/main' })
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {

    next()
    return
  }
  next('/auth/login')
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Intro,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/main',
      component: Intro,
      beforeEnter: ifAuthenticated,
    },
    {
      path: '/dashboard',
      component: Dashboard,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/plans',
      component: Plans
    },
    {
      path: '/paymentstatus',
      component: PaymentStatus
    },
    {
      path: '/reports',
      component: ReportsList,
      beforeEnter: ifAuthenticated
    },
    {
      path: '/payment-success',
      name: 'PaymentSuccess',
      component: PaymentSuccess,
    },
    {
      path: '/payment-cancel',
      name: 'PaymentCancel',
      component: PaymentCancel,
    },
    {
      path: '/PaymentForm',
      name: 'PaymentForm',
      component: PaymentForm,
    },
    {
      path: '/auth',
      component: Auth,
      children: [
        {
          path: '',
          component: Login,
          beforeEnter: (to, from, next) =>
            next({ path: '/auth/login' })
        },
        {
          path: 'login',
          component: Login,
          beforeEnter: ifNotAuthenticated
        },
        {
          path: 'signup',
          component: Signup,
          beforeEnter: ifNotAuthenticated
        },
        {
          path: 'forgot',
          component: Forgot,
          beforeEnter: ifNotAuthenticated
        },
        {
          path: 'changepass/:code',
          component: Changepass,
          beforeEnter: ifNotAuthenticated,
          props: true
        },
        {
          path: 'activate/:activateCode',
          component: Activate,
          props: true
        },
        {
          path: 'socialauth/',
          component: Socialauth,
        },
        
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {


  next()

})


export default router