<template>
  <div class="alert" v-if="getSocialNames.length && isShow">
    <p>
      {{getSocialNames}} {{ $t('ALERT_INVALID') }} <a href="#">{{ $t('ALERT_F5_BTN') }}</a>
    </p>
    <span class="close" @click="isShow=false"><i class="bi bi-x-lg"></i></span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "change-token-alert",
  computed: {
    ...mapGetters(["getPagesBySocial"]),
    locale() {
      return this.$locale;
    },
    getSocialNames() {
      let names = [];
      for (const social of Object.keys(this.getPagesBySocial)) {
        if (
          this.getPagesBySocial[social].some((page) => page.need_token_change)
        ) {
          //names.push(this.locale.COMMON.SOCIALS[social]);
          names.push(this.$t('COMMON_SOCIALS_'+[social])); //$('COMMON_SOCIALS_')[IG,VK,FB] *ARCHI
        }
      }
      return names.join(', ')
    },
  },
  data(){
    return {
      isShow: true
    }
  }
};
</script>

<style>
</style>