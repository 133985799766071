import * as echarts from 'echarts';

// Function to lighten a color
const lightenColor = (color, percent) => {
  const f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    '#' +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
};

const create = function ({ ref, pages, chartData, chartColumns, color }) {
  return new Promise((resolve, reject) => {
    const chart = echarts.init(ref[0], null, {
      renderer: 'canvas',
      useDirtyRect: false,
    });

    if (!chartData) {
      reject(new Error('No chart data provided'));
      return;
    }

    // Calculate the total value for percentage calculation
    const totalValue = chartColumns.reduce((acc, val) => acc + chartData[val], 0);

    // Prepare data with lightened colors for each page and column
    let opacity = 0; // Initial opacity for lightening effect

    const data = chartColumns.map((column, idx) => {
      const value = chartData[column];
      const percentOfTotal = ((value / totalValue) * 100).toFixed(2);

      // Generate a lightened color variation for each column
      const lightenedColor = lightenColor(color, opacity);
      opacity += 0.25; // Increase lightening effect for the next series

      return {
        value: value,
        name: column,
        itemStyle: {
          color: lightenedColor,
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
        },
        emphasis: {
          scale: true,
          scaleSize: 1.1, // Adjust as needed
          itemStyle: {
            borderWidth: 1,
          },
        },
      };
    });

    const option = {
      legend: {
        show: false,
      },
      series: [
        {
          name: chartData.name || 'Data',
          type: 'pie',
          radius: ['77%', '90%'], // Inner and outer radius for a donut chart
          center: ['50%', '50%'],
          startAngle: 90,
          data: data,
          label: {
            show: false,
          },
          labelLine: {
            show: false,
          },
        },
      ],
    };

    chart.setOption(option);

    resolve({ chart, colors: chartColumns.map((col) => color) });
    window.addEventListener('resize', () => {
      chart.resize();
      });
  });
};

export default create;
