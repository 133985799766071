<template>
  <transition>
    <div class="mod-dial" @click="INTRO_SET_ADD_PAGE_VISIBLE(false)">
      <div class="mod-cont add-page-modal" @click.stop>
        <span class="close" @click.stop="INTRO_SET_ADD_PAGE_VISIBLE(false)"
          ><i class="bi bi-x-lg"></i
        ></span>
        <div class="mod-body">
          <form v-on:submit.prevent="searchPage" class="inp-btn">
            <input
              type="text"
              class="inp-st"
              :placeholder="$t('ADD_PAGE_PUT_URL')"
              v-model="inputUrl"
            />
            <button
              type="button"
              class="btn-st-red"
              @click.prevent.stop="searchPage"
            >
              {{ $t("ADD_PAGE_SEARCH") }}
            </button>
          </form>
          <!-- <button class="btn-st-red" @click="showInlineAlert">Inline</button>
          <button class="btn-st-red" @click="showGlobalAlert">Global</button> -->
          <div ref="alertContainer" class="alerts-container"></div>
          <div
            v-if="
              getCurrentSocialTokens.vkToken ||
              getCurrentSocialTokens.igToken ||
              getCurrentSocialTokens.fbToken
            "
          >
            <div v-if="searched">
              <div class="mod-inner">
                <span class="soc-sea">
                  {{ $t("ADD_PAGE_FOUND") }}
                </span>
                <ul class="res">
                  <li
                    :key="index"
                    v-for="(page, index) in foundedPages"
                    @click.capture="selectFounded(page)"
                    @mouseenter="page.hover = true" 
                    @mouseleave="page.hover = false"
                  >
                    <img :src="page.avatar" alt />
                    <span class="caption">{{ page.name }}</span>
                    <button type="button" class="btn-st-tr" 
                      :class="{ 'page_selected': page.selected }">
                      <template v-if="!page.selected">
                        {{ $t("ADD_PAGE_SELECT_PAGE") }}
                      </template>
                      <template v-if="page.selected && !page.hover">
                        {{ $t("ADD_PAGE_SELECTED") }}
                      </template>
                      <template v-if="page.selected && page.hover">
                        {{ $t("ADD_PAGE_DELETE_PAGE") }}
                      </template>
                    </button>
                  </li>
                </ul>
              </div>
              <div class="mod-inner" v-if="getPreviosSelected.length">
                <span class="soc-sea">
                  <!-- <span class="soc-ico"></span> -->
                  {{ $t("ADD_PAGE_WAS_SELECTED") }}
                </span>
                <ul class="res">
                  <li
                    :key="index"
                    v-for="(page, index) in getPreviosSelected"
                    @click.capture="selectSuggestion(page, false)"
                    @mouseenter="page.hover = true" 
                    @mouseleave="page.hover = false"
                  >
                    <img :src="page.avatar" alt />
                    <span class="caption">{{ page.name }}</span>
                    <button type="button" class="btn-st-tr" 
                      :class="{ 'page_selected': page.selected }">
                      <template v-if="!page.selected">
                        {{ $t("ADD_PAGE_SELECT_PAGE") }}
                      </template>
                      <template v-if="page.selected && !page.hover">
                        {{ $t("ADD_PAGE_SELECTED") }}
                      </template>
                      <template v-if="page.selected && page.hover">
                        {{ $t("ADD_PAGE_DELETE_PAGE") }}
                      </template>
                    </button>
                  </li>
                </ul>
              </div>
            </div>

            <!-- isSearching == {{ isSearching }} loadingAdminPages = 
            {{ loadingAdminPages }} -->

            <scroll-loader
              :loader-method="() => {}"
              :loader-enable="loadingAdminPages || isSearching"
              loader="dots"
            ></scroll-loader>
            <change-token-alert v-if="!loadingAdminPages && !isSearching" />

            <div v-if="canShowSuggestedPages">
              <div class="b16">{{ $t("ADD_PAGE_WANT_ADD") }}</div>

              <div class="mod-inner" v-if="canAddFB.length > 0">
                <span class="soc-sea">
                  <span class="soc-ico soc-fb"></span>
                </span>
                <span
                  v-if="pagination.FB.current"
                  v-on:click="--pagination.FB.current"
                  class="next"
                  >{{ $t("ADD_PAGE_SHOW_PREVIOUS") }}</span
                >

                <ul class="res">
                  <li
                    :key="index"
                    v-for="(page, index) in getPagination('FB')"
                    @click.capture="selectSuggestion(page)"
                    @mouseenter="page.hover = true" 
                    @mouseleave="page.hover = false"
                  >
                    <img :src="page.avatar" alt />
                    <span class="caption">{{ page.name }}</span>

                    <button type="button" class="btn-st-tr" 
                      :class="{ 'page_selected': page.selected }">
                      <template v-if="!page.selected">
                        {{ $t("ADD_PAGE_SELECT_PAGE") }}
                      </template>
                      <template v-if="page.selected && !page.hover">
                        {{ $t("ADD_PAGE_SELECTED") }}
                      </template>
                      <template v-if="page.selected && page.hover">
                        {{ $t("ADD_PAGE_DELETE_PAGE") }}
                      </template>
                    </button>
                  </li>
                </ul>
                <span
                  v-if="pagination.FB.current < pagination.FB.total - 1"
                  v-on:click="++pagination.FB.current"
                  class="next"
                  >{{ $t("ADD_PAGE_SHOW_NEXT") }}</span
                >
              </div>
              <div class="mod-inner" v-if="canAddIG.length > 0">
                <span class="soc-sea">
                  <span class="soc-ico soc-ig"></span>
                </span>
                <span
                  v-if="pagination.IG.current"
                  v-on:click="--pagination.IG.current"
                  class="next"
                  >{{ $t("ADD_PAGE_SHOW_PREVIOUS") }}</span
                >
                <ul class="res">
                  <li
                    :key="index"
                    v-for="(page, index) in getPagination('IG')"
                    @click.capture="selectSuggestion(page)"
                    @mouseenter="page.hover = true" 
                    @mouseleave="page.hover = false"
                  >
                    <img :src="page.avatar" alt />
                    <span class="caption">{{ page.name }}</span>
                    <button type="button" class="btn-st-tr" 
                      :class="{ 'page_selected': page.selected }">
                      <template v-if="!page.selected">
                        {{ $t("ADD_PAGE_SELECT_PAGE") }}
                      </template>
                      <template v-if="page.selected && !page.hover">
                        {{ $t("ADD_PAGE_SELECTED") }}
                      </template>
                      <template v-if="page.selected && page.hover">
                        {{ $t("ADD_PAGE_DELETE_PAGE") }}
                      </template>
                    </button>
                  </li>
                </ul>
                <span
                  v-if="pagination.IG.current < pagination.IG.total - 1"
                  v-on:click="++pagination.IG.current"
                  class="next"
                  >{{ $t("ADD_PAGE_SHOW_NEXT") }}</span
                >
              </div>
              <div class="mod-inner" v-if="canAddVK.length > 0">
                <span class="soc-sea">
                  <span class="soc-ico soc-vk"></span>
                </span>
                <span
                  v-if="pagination.VK.current"
                  v-on:click="--pagination.VK.current"
                  class="next"
                  >{{ $t("ADD_PAGE_SHOW_PREVIOUS") }}</span
                >
                <ul class="res">
                  <li
                    :key="index"
                    v-for="(page, index) in getPagination('VK')"
                    @click.capture="selectSuggestion(page)"
                    @mouseenter="page.hover = true" 
                    @mouseleave="page.hover = false"
                  >
                    <img :src="page.avatar" alt />
                    <span class="caption">{{ page.name }}</span>
                    <button type="button" class="btn-st-tr" 
                      :class="{ 'page_selected': page.selected }">
                      <template v-if="!page.selected">
                        {{ $t("ADD_PAGE_SELECT_PAGE") }}
                      </template>
                      <template v-if="page.selected && !page.hover">
                        {{ $t("ADD_PAGE_SELECTED") }}
                      </template>
                      <template v-if="page.selected && page.hover">
                        {{ $t("ADD_PAGE_DELETE_PAGE") }}
                      </template>
                    </button>
                  </li>
                </ul>
                <span
                  v-if="pagination.VK.current < pagination.VK.total - 1"
                  v-on:click="++pagination.VK.current"
                  class="next"
                  >{{ $t("ADD_PAGE_SHOW_NEXT") }}</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="mod-foot">
          <div class="add-page-block">
            <div class="drpdown" v-if="filterByWriteAccess.length > 0">
              <input
                type="checkbox"
                name
                id="d1"
                v-model="isAccessDrpChecked"
              />
              <label for="d1">
                <b>{{ $t("ADD_PAGE_CURRENT_ACCOUNT") }}</b>
                {{ selectedAccessEmail }}
              </label>
              <span class="expand"></span>
              <ul>
                <li
                  @click="selectAccount(access.owner_email)"
                  :key="index"
                  v-for="(access, index) in filterByWriteAccess"
                >
                  {{ access.owner_email }}
                </li>
              </ul>
            </div>
            <div class="add-button-block">
              <span class="btn-animation"></span>
              <button
                type="button"
                v-if="getCountOfSelected"
              class="btn-st-red"
              @click="addToMonitoring"
            >
                {{
                  $t("ADD_PAGE_COUNT_TO_ADD").replace("{0}", getCountOfSelected)
                }}
              </button>
            </div>
          </div>
          <div class="b14">
            {{ $t("ADD_PAGE_SIGN_IN_TOKENS") }}
          </div>

          <SocialAuthButtons :selectedAccessEmail="selectedAccessEmail" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import PageUtils from "../../models/page-utils";
import uuid from "uuid";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { AUTH_SOCIAL_TOKENS, AUTH_REMOVE_TOKEN } from "@/store/actions/auth";
import SocialAuthButtons from "@/components/SocialAuthButtons.vue";
import {
  INTRO_SET_PAGES,
  INTRO_SET_ADD_PAGE_VISIBLE,
  INTRO_SET_ALERT,
} from "../../store/actions/intro";
import Vue from "vue";
import ChangeTokenAlert from "../ChangeTokenAlert.vue";
import Alert from "@/components/Alert.vue";
import authMethodsMixin from "@/mixins/authMethodsMixin";
import module from "@/models/AxiosModule";

export default {
  name: "AddPage",
  mixins: [authMethodsMixin],
  components: {
    ChangeTokenAlert,
    Alert,
    SocialAuthButtons,
  },
  computed: {
    ...mapGetters([
      "getSocialTokens",
      "getPeriod",
      "getProfile",
      "getAllowedAccesses",
      "getPages",
      "getPagesBySocial",
    ]),
    locale() {
      return this.$locale.ADD_PAGE;
    },
    canShowSuggestedPages() {
      const res =
        !this.loadingAdminPages &&
        !this.searched &&
        (this.canAddVK.length > 0 ||
          this.canAddIG.length > 0 ||
          this.canAddFB.length > 0);

      return res;
    },

    filterByWriteAccess() {
      if (this.getAllowedAccesses.length > 1)
        return this.getAllowedAccesses.filter(
          (access) =>
            access.owner_email != this.selectedAccessEmail && access.access_type
        );
      else return [];
    },
    getCurrentSocialTokens() {
      if (!this.getSocialTokens || !this.selectedAccessEmail) return {};

      console.log(
        "tokens",
        this.getSocialTokens,
        this.getSocialTokens[this.selectedAccessEmail]
      );

      return this.getSocialTokens[this.selectedAccessEmail];
    },
    getSelectedSuggestion() {
      return [...this.canAddFB, ...this.canAddIG, ...this.canAddVK].filter(
        (page) => page.selected
      );
    },
    getSelectedFounded() {
      if (!this.foundedPages) return [];
      return this.foundedPages.filter((page) => page.selected);
    },
    getPreviosSelected() {
      if (!this.canAdd) return [];
      return [...this.canAddFB, ...this.canAddIG, ...this.canAddVK].filter(
        (page) => page.previosSelected
      );
    },
    getCountOfSelected() {
      return this.getSelectedSuggestion.length + this.getSelectedFounded.length;
    },
    getAllSelected() {
      return [...this.getSelectedSuggestion, ...this.getSelectedFounded];
    },
  },

  data() {
    return {
      inputUrl: "",
      accessPublicToken: true,

      foundedPages: [],

      searched: false,
      isSearching: false,
      selected: [],

      canAddIG: [],
      canAddFB: [],
      canAddVK: [],
      pagination: {
        VK: {
          current: 0,
          total: 0,
        },
        FB: {
          current: 0,
          total: 0,
        },
        IG: {
          current: 0,
          total: 0,
        },
      },
      alert: false,
      error_notification: "",

      selectedAccessEmail: undefined,
      loadingAdminPages: false,
      isAccessDrpChecked: false,
      refresh: true,
    };
  },
  created() {
    window.addEventListener(
      "storage",
      async ({ key, newValue }) => {
        if (key != "message" || !newValue) return;
        const { command, data: token } = JSON.parse(newValue);
        if (command == "auth") {
          try {
            if (token.hasOwnProperty("ig_token")) {
              const hasBusinessAccount = !!(await PageUtils.getBusinessPagesIg(
                token.ig_token
              ));
              if (!hasBusinessAccount) {
                throw new Error("ERROR_ACCOUNT_NO_BUSINESS");
              }
            }
            const axios = module.Axios;
            await axios.post("social-token", { token });
            await this.$store.dispatch(AUTH_SOCIAL_TOKENS);

            this.$store.dispatch(INTRO_SET_PAGES);

            if (this.isSearching) this.searchPage();
            else this.findGroups();
          } catch (err) {
            if (this.locale.ERROR_NOTIFICATION[err.message]) {
              this.alert = true;
              this.error_notification =
                this.locale.ERROR_NOTIFICATION[err.message];
              setTimeout(() => {
                this.alert = false;
                this.error_notification = "";
              }, 12000);
            }
          }
        }
      },
      false
    );
  },
  async mounted() {
    if (!this.getSocialTokens) await this.$store.dispatch(AUTH_SOCIAL_TOKENS);
    this.selectedAccessEmail = this.getProfile.email;
    this.findGroups();
  },

  methods: {
    ...mapActions({
      removeSocialToken: "AUTH_REMOVE_TOKEN",
      INTRO_SET_PAGES: "INTRO_SET_PAGES",
    }),
    ...mapMutations([INTRO_SET_ADD_PAGE_VISIBLE, INTRO_SET_ALERT]),
    selectFounded(page) {
      page.selected = !page.selected;
      if (page.selected) {
        this.$nextTick(() => {
          page.hover = true;
        });
      } else {
        page.hover = false;
      }
    },
    selectSuggestion(page, flag = true) {
      page.selected = !page.selected;
      if (page.selected) {
        this.$nextTick(() => {
          page.hover = true;
        });
      } else {
        page.hover = false;
      }
      if (flag) page.previosSelected = true;
    },

    selectAccount(email) {
      this.isAccessDrpChecked = false;
      this.selectedAccessEmail = email;
      
      // Check if currently found pages are already analyzed by the newly selected account
      if (this.foundedPages.length > 0) {
        for (const foundPage of this.foundedPages) {
          const isAlreadyAnalyzed = this.getPages.some(
            (p) =>
              p.social === foundPage.data.social &&
              p.owner_url === foundPage.data.owner_url &&
              p.email === email
          );
          
          if (isAlreadyAnalyzed) {
            this.showInlineAlert(this.$t("ERROR_ALREADY_ANALYZED"));
            // Deselect the page if it's currently selected
            if (foundPage.selected) {
              foundPage.selected = false;
            }
          }
        }
      }
      
      this.findGroups();
    },
    getPagination(soc) {
      return this[`canAdd${soc}`].slice(
        this.pagination[soc].current * 3,
        this.pagination[soc].current * 3 + 3
      );
    },

    getPageTokensVK: async function () {
      const tokens = this.getSocialTokens[this.selectedAccessEmail];
      const pages = this.getPagesBySocial.VK.filter(
        ({ email }) => email == this.selectedAccessEmail
      );
      const group_ids = await PageUtils.findVkAdminGroupsForTokens(
        tokens.vkToken,
        pages
      );
      window.location.href = `https://oauth.vk.com/authorize?client_id=${
        process.env.VUE_APP_VK_APP_ID
      }&display=page&redirect_uri=${
        process.env.VUE_APP_SOCIAL_REDIRECT
      }&group_ids=${group_ids.join(
        ","
      )}&scope=stories&response_type=token&v=5.103`;
    },
    findGroups: async function () {
      if (this.searched) return;
      const tokens = this.getSocialTokens[this.selectedAccessEmail];
      const pages = this.getPages.filter(
        ({ email }) => email == this.selectedAccessEmail
      );
      this.loadingAdminPages = true;

      const loadVK = async () => {
        if (!tokens.vkToken) return [];
        try {
          return (await PageUtils.findVkAdminGroups(tokens.vkToken, pages)).map(
            (r) => {
              return {
                selected: false,
                hover: false,
                name: r.name,
                avatar: r.photo_50,
                data: {
                  owner_id: r.id,
                  owner_domain: r.screen_name,
                  owner_type: "page",
                  owner_name: r.name,
                  owner_avatar: r.photo_50,
                  owner_url: "https://vk.com/" + r.screen_name,
                  social: "VK",
                  is_admin: r.is_admin,
                  social_token: tokens.vkToken,
                },
              };
            }
          );
        } catch (error) {
          if (
            error.response &&
            error.response.error &&
            error.response.error.error_code === 5
          ) {
            console.log("VK token is invalid");
            this.removeSocialToken({ social: "VK" });
          } else {
            console.log("An error occurred while loading VK groups", error);
          }
          return [];
        }
      };
      const loadFb = async () => {
        if (!tokens.fbToken) return [];
        try {
          const groups = await PageUtils.findFbAdminGroups(
            { fbToken: tokens.fbToken, proofToken: tokens.proof_token },
            pages
          );
          return groups.map((found) => {
            return {
              selected: false,
              hover: false,
              name: found.name,
              avatar: found.picture.data.url,
              data: {
                owner_id: found.id,
                owner_domain: found.username,
                owner_type: "page",
                owner_name: found.name,
                owner_avatar: found.picture.data.url,
                owner_url: "https://facebook.com/" + found.username,
                social: "FB",
                is_admin: 0,
                social_token: tokens.fbToken,
              },
            };
          });
        } catch (error) {
          if (
            error.response &&
            error.response.body &&
            error.response.body.error &&
            error.response.body.error.code === 190
          ) {
            console.log("Facebook token is invalid");
            this.removeSocialToken({ social: "FB" });
          } else {
            console.log(
              "An error occurred while loading Facebook groups",
              error
            );
          }
          return [];
        }
      };
      const loadIG = async () => {
        if (!tokens.igToken) return [];
        try {
          return (await PageUtils.findIgAdminGroups(tokens.igToken, pages)).map(
            (p) => {
              const found = p.data;
              return {
                selected: false,
                hover: false,
                name: found.name,
                avatar: found.profile_picture_url,
                data: {
                  owner_id: found.id,
                  owner_domain: found.username,
                  owner_type: "page",
                  owner_name: found.name,
                  owner_avatar: found.profile_picture_url,
                  owner_url: "https://instagram.com/" + found.username,
                  social: "IG",
                  is_admin: 0,
                  social_token: tokens.igToken,
                },
              };
            }
          );
        } catch (error) {
          if (
            error.response &&
            error.response.data &&
            error.response.data.error &&
            error.response.data.error.code === 190
          ) {
            console.log("Instagram token is invalid");
            this.removeSocialToken({ social: "IG" });
          } else {
            console.log(
              "An error occurred while loading Instagram groups",
              error
            );
          }
          return [];
        }
      };

      const suggested = await Promise.all([loadVK(), loadFb(), loadIG()]);

      [this.canAddVK, this.canAddFB, this.canAddIG] = suggested;

      this.pagination.IG.total = Math.ceil(this.canAddIG.length / 3);
      this.pagination.FB.total = Math.ceil(this.canAddFB.length / 3);
      this.pagination.VK.total = Math.ceil(this.canAddVK.length / 3);
      this.loadingAdminPages = false;
    },
    async searchPage() {
      const parsed = PageUtils.parseUrl(this.inputUrl);
      if (!parsed) {
        this.showInlineAlert(this.$t("ADD_PAGE_PUT_URL"), "red");
        return;
      }
      this.isSearching = true;

      const tokens = this.getSocialTokens[this.selectedAccessEmail];

      try {
        let found = {};
        const social = parsed.social;

        if (!tokens) {
          this.showInlineAlert(this.$t("ERROR_LOGIN"));
          this.isSearching = false;
          return;
        }

        let token = "";
        if (social === "VK") {
          token = tokens.vkToken;
        } else if (social === "FB") {
          token = tokens.fbToken;
        } else if (social === "IG") {
          token = tokens.igToken;
        }

        if (!token) {
          this.showInlineAlert(this.$t("ERROR_LOGIN"));
          this.isSearching = false;
          return;
        }

        if (social === "FB" && !tokens.proof_token) {
          this.showInlineAlert(this.$t("ERROR_LOGIN"));
          this.isSearching = false;
          return;
        }

        if (social === "VK") {
          found = await this.searchVkPage(parsed.page, token);
        } else if (social === "FB") {
          found = await this.searchFbPage(parsed.page, {
            fbToken: tokens.fbToken,
            proofToken: tokens.proof_token,
          });
        } else if (social === "IG") {
          found = await this.searchIgPage(parsed.page, token);
        }

        if (!found) {
          this.isSearching = false;
          return;
        }

        found.selected = true;
        found.hover = false;
        found.social_token = token;

        // Check if the page is already being analyzed by the currently selected account
        const alreadyAnalyzing = this.getPages.some(
          (p) =>
            p.social === found.data.social &&
            p.owner_url === found.data.owner_url &&
            p.email === this.selectedAccessEmail && 
            p.status !== 'deleted'
        );

        if (alreadyAnalyzing) {
          this.showInlineAlert(this.$t("ERROR_ALREADY_ANALYZED"));
          this.isSearching = false;
          return;
        }

        if (
          !this.foundedPages.some(
            (page) => page.data.owner_id == found.data.owner_id
          )
        ) {
          this.inputUrl = "";
          this.foundedPages.push(found);
        }

        this.searched = true;
      } catch (err) {
        console.log(err);
        if (!this.foundedPages.length) this.searched = false;
        this.alert = true;
        if (this.locale.ERROR_NOTIFICATION[err.message]) {
          this.error_notification = this.locale.ERROR_NOTIFICATION[err.message];
          setTimeout(() => {
            this.alert = false;
            this.error_notification = "";
          }, 12000);
        }
      }

      this.isSearching = false;
    },
    async searchVkPage(page, token) {
      try {
        const found = await PageUtils.getVkPageInfo(page, token);
        const foundInfoPage = {};
        foundInfoPage.name = found.name;
        foundInfoPage.avatar = found.photo_50;
        foundInfoPage.hover = false;
        foundInfoPage.data = {
          owner_id: found.id,
          owner_domain: found.screen_name,
          owner_type: "page",
          owner_name: found.name,
          owner_avatar: found.photo_50,
          owner_url: "https://vk.com/" + found.screen_name,
          social: "VK",
          is_admin: found.is_admin,
          social_token: token,
        };
        return foundInfoPage;
      } catch (error) {
        this.$alert.show({
          message: this.$i18n.t(error.message),
          type: "global",
          color: "red",
        });
      }
    },
    async searchIgPage(page, token) {
      try {
        const pageInfo = await PageUtils.getIgPageInfo(page, token);

        const foundInfoPage = {};
        const found = pageInfo;
        foundInfoPage.name = found.name;
        foundInfoPage.avatar = found.profile_picture_url;
        foundInfoPage.show = true;
        foundInfoPage.hover = false;
        foundInfoPage.data = {
          owner_id: found.id,
          owner_domain: found.username,
          owner_type: "page",
          owner_name: found.name,
          owner_avatar: found.profile_picture_url,
          owner_url: "https://instagram.com/" + found.username,
          social: "IG",
          social_token: token,
          is_admin: 0,
        };
        return foundInfoPage;
      } catch (error) {
        this.$alert.show({
          message: this.$i18n.t(error.message),
          type: "global",
          color: "black",
        });
      }
    },
    async searchFbPage(page, token) {
      try {
        const foundInfoPage = {};
        const found = await PageUtils.getFbPageInfo(page, token);
        foundInfoPage.name = found.name;
        foundInfoPage.avatar = found.picture.data.url;
        foundInfoPage.hover = false;
        foundInfoPage.data = {
          owner_id: found.id,
          owner_domain: found.username,
          owner_type: "page",
          owner_name: found.name,
          owner_avatar: found.picture.data.url,
          owner_url: "https://facebook.com/" + found.username,
          social: "FB",
          social_token: token.fbToken,
          is_admin: 0,
        };
        return foundInfoPage;
      } catch (error) {
        this.$alert.show({
          message: this.$i18n.t(error.message),
          type: "inline",
          type: "global",
          color: "black",
        });
      }
    },

    async addToMonitoring() {
      const selectedPages = this.getAllSelected.map((s) => {
        return {
          ...s.data,
          hash_id: uuid.v4().replace(/-/g, "").substring(0, 30),
        };
      });

      if (!selectedPages.length) return;
      try {
        const axios = module.Axios;
        const response = await axios.post("add-page", {
          pages: selectedPages,
          owner_email: this.selectedAccessEmail,
        });

        this.INTRO_SET_PAGES().then(() => {
          if (!this.searched) this.findGroups();
        });
        this.foundedPages = this.foundedPages.filter((page) => !page.selected);
        this.searched = false;

        const count = selectedPages.length;
        this.$nextTick(() => {
          this.$alert.show({
            message: this.$t("ALERT_PAGE_ADDED", { count }),
            type: "global",
            color: "",
          });
        });

        const idsToGetPublicToken = selectedPages
          .filter((p) => p.social === "VK" && p.is_admin)
          .map((p) => p.owner_id);

        if (idsToGetPublicToken.length) {
          window.location.href = `https://oauth.vk.com/authorize?client_id=${
            process.env.VUE_APP_VK_APP_ID
          }&display=page&redirect_uri=${
            process.env.VUE_APP_SOCIAL_REDIRECT
          }&group_ids=${idsToGetPublicToken.join(
            ","
          )}&scope=stories&response_type=token&v=5.103`;
        }
      } catch (error) {
        if (error?.response && error?.response?.data) {
          console.error("Error Data:", error.response.data);
          if (error.response.data?.error_code) {
            this.showInlineAlert(this.$t(error.response.data?.error_code));
          } else {
            this.showInlineAlert(this.$t("ERROR_ADDING"));
          }
        } else {
          console.error("Error:", error.message); // Log error message
        }
      }
    },
    async removeToken(social) {
      await this.$store.dispatch(AUTH_REMOVE_TOKEN, {
        social,
        email: this.selectedAccessEmail,
      });
    },
    showInlineAlert(message, color = "") {
      this.$alert.show({
        message: message,
        type: "inline",
        color: color,
        component: this,
        target: this.$refs.alertContainer,
      });
    },
    showGlobalAlert() {
      this.$alert.show({
        message: this.$t("TOKEN_EXPIRED"),
        type: "global",
        color: "",
      });
    },
  },
};
</script>
